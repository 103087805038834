import React from 'react';
import { Link } from 'react-router-dom';
import cls from 'classname';
import { Row, Col, Spin } from 'antd';
import Icon from '../../../Icon';
import ButtonComponent from "../../../Components/Button";
import InputComponent from "../../../Components/Input";

const statusMap = {
  1: 'In Progress',
  2: 'Ready',
};

export const TopLine = ({
  product,
  statusLoading,
  onChangeStatus,
  openModal,
  onSave,
  disabledSave
}) => (
  <Row
    gutter={[
      { xs: 0, sm: 0, md: 16 },
      { xs: 0, sm: 0, md: 16 },
    ]}
    style={{ marginTop: 24, margin: "24px 32px" }}
  >
    <Col flex="1 1">
      <div className="block-with-label">
        <span className="block-with-label_label">Preview</span>
        <InputComponent
          className="block-with-label_input"
          name="preview"
          value={`${process.env.REACT_APP_BASE_URI}/portfolio/product/${product?.product_id}`}
          suffix={
            <Link
              to={`/portfolio/product/${product?.product_id}`}
              target="_blank"
            >
              <Icon type="external_link" role="icon" color="#225aea" />
            </Link>
          }
          disabled
        />
      </div>
    </Col>

    <Col flex="162px">
      <div className="block-with-label">
        <span className="block-with-label_label">Product ID</span>
        <InputComponent
          className="block-with-label_input"
          name="preview"
          value={product?.product_id}
          disabled
        />
      </div>
    </Col>

    <Col flex="231px">
      <div className="block-with-label">
        <span className="block-with-label_label">Status</span>
        <Spin spinning={statusLoading}>
          <div className="status-view">
            <div
              className={cls('status-view_indicator', {
                'status-view_indicator--active': product?.status?.id === 2,
                'status-view_indicator--disabled': !product?.status?.id,
              })}
            />
            {Object.entries(statusMap).map(([id, name]) => (
              <div
                key={id}
                className={cls('status-view_btn', {
                  'status-view_btn--active': product?.status?.id === +id,
                })}
                onClick={() => onChangeStatus(Number.parseInt(id))}
              >
                {name}
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </Col>

    <Col flex="224px">
      <div className="block-with-label">
        <span className="block-with-label_label"> </span>
        <div className="block-with-label_btn-wrap">
          <ButtonComponent
            type="primary"
            className="btn-primary red"
            onClick={() => openModal('product_delete')}
          >
            Delete
          </ButtonComponent>
          <ButtonComponent type="primary"
                           className="btn-primary"
                           onClick={onSave}
                           disabled={disabledSave}
          >
            Save
          </ButtonComponent>
        </div>
      </div>
    </Col>
  </Row>
);

export default TopLine;
