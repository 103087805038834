import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import SalesTrackerCreators from './reducer';
import PlanCreators from '../../Redux/PlanRedux';
import UserCreators from '../User/reducer';
import BreadcrumbsCreators from '../Breadcrumbs/actions';
import { useTranslation } from 'react-i18next';
import TopPageHeader from './pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import UserTrackings from './components/UserTrackings';
import VideoTutorialModal from '../ShopifySearch/components/VideoTutorialModal';
import LimitChangeModal from '../../Components/BillingSubPage/LimitChangeModal';
import DeleteTrackingModal from './components/DeleteTrackingModal';
import CanNotRemoveTracking from '../ProductDatabasePage/components/CanNotRemoveTracking';
import GatheringDataModal from './components/GatheringDataModal';
import UntrackableDeleteModal from './components/UntrackableDeleteModal';
import NumbersBreakdownDatabaseBlock from '../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock';
import PopularToday from './components/PopularToday';
import './styles.less';

const SalesTrackerPage = (
  {
    userInfo,
    isMobile,
    salesTracker,
    dashboardInfo,
    limits,
    setView,
    getStores,
    getProducts,
    deleteProduct,
    deleteStore,
    connectProduct,
    connectStore,
    connectStoreNew,
    getStoreSuggestions,
    resetStoreSuggestions,
    getPlans,
    getUserInfo,
    addBreadCrumb,
    clearBreadCrumbs,
    resetInnerStore,
    resetInnerProduct,
    getPopularStores,
    getPopularProducts,
  }) => {

  const {t} = useTranslation();

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const { view } = useParams();
  const navigate = useNavigate();
  const isYearly = userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';

  const [firstLoading, setFirstLoading] = useState(true);
  const [displayFormat, setDisplayFormat] = useState('details');
  const [inputText, setInputText] = useState('');
  const [modal, setModal] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState(null);

  const skeleton = firstLoading && (salesTracker?.[view]?.loading || salesTracker?.[view]?.popular?.loading);

  function viewHandler(view) {
    navigate(`/sales-tracker/${view}`);
    setView(view);
    setInputText('');
    setDisplayFormat('details');
  }

  function disconnectHandler(data) {
    if (view === 'stores') {
      deleteStore(data);
    } else {
      deleteProduct(data);
    }
  }

  function connectHandler() {
    if (view === 'stores') {
      searchSuggestions === null ?
        connectStore({url: inputText})
        :
        connectStoreNew({ ...searchSuggestions, full_data: true })
      setSearchSuggestions(null);
    } else {
      connectProduct({url: inputText});
    }
    setInputText('');
  }

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    getPlans();
    getUserInfo();
    clearBreadCrumbs();
    addBreadCrumb({
      name: t('Sales tracker'),
      link: `/sales-tracker/${view}`,
    });
    resetInnerStore();
    resetInnerProduct();
  }, []);

  useEffect(() => {
    if (firstLoading) {
      setTimeout(() => setFirstLoading(false), 1000);
    } else {
      if (view === 'stores') {
        getStores({page: 1,page_size: 50});
      } else {
        getProducts({page: 1, page_size: 100});
      }
    }
  }, [JSON.stringify(userInfo?.subscriptions?.[0])]);

  useEffect(() => {
    view === 'stores' ? getPopularStores() : getPopularProducts();
    setFirstLoading(true);
    setTimeout(() => setFirstLoading(false), 1000);
  }, [view])

  return (
    <div className={'product-database-page sales-tracker-page'}>
      <TopPageHeader icon={'sales_tracker_dashboard'}
                     title={'My trackings'}
                     subtitle={'Track sales of stores and products'}
                     takeTourHandler={() => window.Intercom('startTour', 433088)}
                     watchTutorialHandler={() => setModal('tutorial')}
                     increaseLimitsHandler={() => setModal('edit_limits')}
                     skeleton={skeleton}
                     isMobile={isMobile}
                     withCredits={false}
                     withLimits={limits}
                     isYearly={isYearly}
                     isTrial={isTrial}
                     isTrippleMenuMobile={true}
                     isTrippleMenu={true}
      />
      <PageOptions setDisplayFormat={setDisplayFormat}
                   displayFormat={displayFormat}
                   isMobile={isMobile}
                   view={view}
                   setView={viewHandler}
                   setInputText={setInputText}
                   inputText={inputText}
                   skeleton={skeleton}
                   disabled={salesTracker?.stores?.loading || salesTracker?.products?.loading || salesTracker?.stores?.pinLoading || salesTracker?.products?.pinLoading || salesTracker?.stores?.trackingLoading || salesTracker?.products?.trackingLoading}
                   connectHandler={connectHandler}
                   setSearchSuggestions={setSearchSuggestions}
                   suggestions={salesTracker?.stores?.suggestions?.results || []}
                   suggestionsLoading={salesTracker?.stores?.suggestions?.loading}
                   getStoreSuggestions={getStoreSuggestions}
                   resetStoreSuggestions={resetStoreSuggestions}
      />
      {
        salesTracker?.[view]?.popular?.loading ?
          <PopularToday view={view}
                        skeleton={salesTracker?.[view]?.popular?.loading}
                        data={salesTracker?.[view]?.popular?.results || []}
          />
          :
          salesTracker?.[view]?.popular?.results?.length ?
            <PopularToday view={view}
                          skeleton={salesTracker?.[view]?.popular?.loading}
                          data={salesTracker?.[view]?.popular?.results || []}
            />
            :
            null
      }
      <UserTrackings view={view}
                     displayFormat={displayFormat}
                     setModal={setModal}
                     setRecordToDelete={setRecordToDelete}
                     skeleton={skeleton}
      />

      <LimitChangeModal setModal={setModal}
                        visible={modal === 'edit_limits'}
                        isMobile={isMobile}
                        data={{
                          storeLimit: dashboardInfo?.limits?.stores?.allowed < 50 ? 50: dashboardInfo?.limits?.stores?.allowed,
                          productLimit: dashboardInfo?.limits?.products?.allowed < 100 ? 100: dashboardInfo?.limits?.products?.allowed,
                          subscription: userInfo?.subscriptions?.[0]
                        }}
      />
      <DeleteTrackingModal setModal={setModal}
                           visible={modal === 'delete_tracking'}
                           isMobile={isMobile}
                           record={recordToDelete}
                           disconnectHandler={disconnectHandler}
                           view={view}
      />
      <UntrackableDeleteModal setModal={setModal}
                              visible={modal === 'untrackable_delete_tracking'}
                              isMobile={isMobile}
                              record={recordToDelete}
                              disconnectHandler={disconnectHandler}
                              view={view}
      />
      <GatheringDataModal setModal={setModal}
                          visible={modal === 'gathering_data'}
                          isMobile={isMobile}
      />
      <CanNotRemoveTracking setModal={setModal}
                            visible={modal === 'can_not_remove_tracking'}
                            isMobile={isMobile}
      />
      <NumbersBreakdownDatabaseBlock product={recordToDelete}
                                     loading={false}
                                     visible={modal === 'numbers_breakdown'}
                                     setModal={setModal}
      />
      <VideoTutorialModal visible={modal === 'tutorial'}
                          setModal={setModal}
                          isMobile={isMobile}
                          link={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  salesTracker: state.salesTracker,
  dashboardInfo: state.dashboard.result,
  limits: state.salesTracker.stores?.limits,
});

const mapDispatchToProps = (dispatch) => ({
  setView: (data) => dispatch(SalesTrackerCreators.setView(data)),
  getStores: (data) => dispatch(SalesTrackerCreators.getTrackedStoresRequest(data)),
  getPopularStores: () => dispatch(SalesTrackerCreators.getPopularStoresRequest()),
  getProducts: (data) => dispatch(SalesTrackerCreators.getTrackedProductsRequest(data)),
  getPopularProducts: (data) => dispatch(SalesTrackerCreators.getPopularProductsRequest(data)),
  deleteProduct: (data) => dispatch(SalesTrackerCreators.unTrackProductByIdRequest(data)),
  deleteStore: (data) => dispatch(SalesTrackerCreators.unTrackStoreByIdRequest(data)),
  connectProduct: (data) => dispatch(SalesTrackerCreators.trackProductByUrlRequest(data)),
  connectStore: (data) => dispatch(SalesTrackerCreators.trackStoreByUrlRequest(data)),
  connectStoreNew: (data) => dispatch(SalesTrackerCreators.trackStoreByIdRequest(data)),
  getStoreSuggestions: (data) => dispatch(SalesTrackerCreators.getTrackSuggestionsRequest(data)),
  resetStoreSuggestions: () => dispatch(SalesTrackerCreators.resetSuggestions()),
  resetInnerStore: () => dispatch(SalesTrackerCreators.resetInnerStore()),
  resetInnerProduct: () => dispatch(SalesTrackerCreators.resetInnerProduct()),
  getPlans: () => dispatch(PlanCreators.plansRequest()),
  getUserInfo: () => dispatch(UserCreators.userRequest()),
  addBreadCrumb: (data) => dispatch(BreadcrumbsCreators.addBreadCrumb(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsCreators.clearBreadCrumbs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerPage);
