import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import cls from "classname";
import {Col, Collapse, Divider, Row, Switch} from "antd";
import InputProductFilter from "../../ProductDatabasePage/components/InputProductFilter";
import {ArrowSelectDown} from "../../../Icon/img";
import MinMaxInput from "../../ProductDatabasePage/components/MinMaxInput";
import DropdownDateBlock from "../../ProductDatabasePage/components/DropdownDateBlock";
import DropdownBlockTest from "../../ProductDatabasePage/components/DropdownBlockTest";
import ButtonComponent from "../../../Components/Button";
import {useSelector} from "react-redux";

const {Panel} = Collapse;

const minMaxInputsData = [
  {
    field: 'sales',
    iconType: 'database_sales',
    text: 'Sales',
    tooltip: 'Monthly sales amount per product'
  }, {
    field: 'revenue',
    iconType: 'database_revenue',
    text: 'Revenue',
    tooltip: 'Monthly revenue generated per product'
  }, {
    field: 'products',
    iconType: 'database_store_products',
    text: 'Store products',
    tooltip: 'Amount of product listings in the store'
  }]

const switchFilterOptions = [
  {text: 'Exclude Untracked Stores', key: 'only_tracked', disabled: false},
];

const keyWords = ['Domain'];

const dateOptions = [
  {id: 1, name: 'Last 30 days'},
  {id: 2, name: 'Last 90 days'},
  {id: 3, name: 'Last 6 months'},
  {id: 4, name: 'Last 12 months'},
];

const AdminFiltersBlock = (
  {
    data,
    getStores,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    isChanged,
    setIsChanged,
  }
) => {
  const currencyOptions = useSelector(store => store.productDatabase.filters.store_currency) || [];
  const domainOptions = useSelector(store => store.productDatabase.filters.domain_tld) || [];
  const languageOptions = useSelector(store => store.productDatabase.filters.store_language) || [];

  const [switchFilters, setSwitchFilters] = useState({
    only_tracked: false,
  });
  const [inputFilters, setInputFilters] = useState({
    domain: {include: null, exclude: null},
  });
  const [minMaxFilters, setMinMaxFilters] = useState({
    sales: {min: null, max: null},
    revenue: {min: null, max: null},
    products: {min: null, max: null},
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    store_created_at: { min: null, max: null, id: null },
    language: 'All',
    currency: 'All',
    domain_tld: 'All',
  });

  const firstLoading = useRef(true);
  //advanced filters show/hide
  const [showAdvanced, setShowAdvanced] = useState(false);

  //first loading to avoid search on render
  const {t} = useTranslation();

  //if filters were changed
  const checkIfChanged = () => {
    if (
      Object.keys(switchFilters).some((key) => switchFilters[key] !== false) ||
      Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null) ||
      Object.keys(minMaxFilters).some((key) => minMaxFilters[key].min !== null || minMaxFilters[key].max !== null) ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'store_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return dropdownFilters[key] !== 'All';
      })
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(false);
  };

  //function to parse preset period dynamically
  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return {min: min, max: max}
  }

  //competitors research search api call
  const applyFilters = (new_search=false) => {
    getStores({
      page: pageNumber,
      page_size: pageSize,
      new_search: new_search,
      ordering: [sortOrder],
      filters: {
        ...switchFilters,
        ...inputFilters,
        ...minMaxFilters,
        store_created_at: dropdownFilters?.store_created_at?.id ? parseDates('store_created_at') : {min: dropdownFilters?.store_created_at?.min, max: dropdownFilters?.store_created_at?.max},
        language: dropdownFilters.language === 'All' ? null : dropdownFilters.language,
        currency: dropdownFilters.currency === 'All' ? null : dropdownFilters.currency,
        domain_tld: dropdownFilters.domain_tld === 'All' ? null : dropdownFilters.domain_tld,
      },
    });
  };

  //reset filters and pagination and competitor
  const resetFilters = () => {
    Object.keys(switchFilters).forEach((key) => setSwitchFilters(state => ({...state, [key]: false})));
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({
      ...state,
      [key]: {include: null, exclude: null}
    })));
    Object.keys(minMaxFilters).forEach((key) => setMinMaxFilters(state => ({...state, [key]: {min: null, max: null}})));
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'store_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({...state, [key]: 'All'}));
    });
    setSortOrder(null);
    setPageNumber(1);
    setPageSize(50);
  };

  //switch filters hanndler
  const onChangeSwitch = (value, name) => {
    setSwitchFilters(state => ({...state, [name]: value}));
  };

  //tags input include/exclude handler
  const onChangeInputFilter = (e, field, type) => {
    e.persist();
    setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? e.target.value.split(',')[0].trim() + ',' : state[field][type] + ',' + e.target.value.split(',')[0].trim() } }));
  };

  const onPasteInputFilter = (e, field, type) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? val?.[i].trim() + ',' : [...new Set([...state[field][type].split(','), val?.[i].trim()])].join(',') } }));
    }
  };

  const onChangeMinMaxFilter = useCallback((e, field, type) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setMinMaxFilters(state => ({
        ...state,
        [field]: {...state[field], [type]: e.target.value.trim().length ? +parsedValue : null}
      }));
    }
  }, []);

  const handleSearch =(new_search=false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
    }
  }

  //search when pagination/loaded preset changed except 1st render
  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else applyFilters();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  //check if changed to enable/disable search btn
  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [switchFilters, minMaxFilters, inputFilters, dropdownFilters]);

  const adminFilters = document.querySelector('.admin-store-table');

  useEffect(() => {
    if (adminFilters) {
      if (!data?.length) {
        if (showAdvanced) adminFilters.style.height = 623 + 564 + 'px';
        else adminFilters.style.height = 'unset';
      } else adminFilters.style.height = 'unset';
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [showAdvanced])

  return (
    <>
        <Row className="product-main-block_wrapper"
             gutter={[48, 0]}
             style={{ margin: "24px 0" }}
        >
          <Col xs={24} md={12} style={{paddingRight: 12}}>
            <div className="input-filter-wrapper">
              {keyWords.map(el =>
                <InputProductFilter key={'include_' + el.toLowerCase()}
                                    el={el}
                                    onChangeInputFilter={onChangeInputFilter}
                                    onPasteInputFilter={onPasteInputFilter}
                                    inputFilters={inputFilters}
                                    setInputFilters={setInputFilters}
                                    type={'include'}
                                    setCompetitor={null}
                />,
              )}
            </div>
          </Col>

          <Col xs={24} md={12} style={{paddingLeft: 12}}>
            <div className="input-filter-wrapper">
              {keyWords.map(el =>
                <InputProductFilter key={'exclude_' + el.toLowerCase()}
                                    el={el}
                                    onChangeInputFilter={onChangeInputFilter}
                                    onPasteInputFilter={onPasteInputFilter}
                                    inputFilters={inputFilters}
                                    setInputFilters={setInputFilters}
                                    type={'exclude'}
                                    setCompetitor={null}
                />,
              )}
            </div>
          </Col>


          <Collapse collapsible={'header'} ghost={true}>
            <Panel key={1} showArrow={false} header={
              <div className="advanced-filters-toggle-wrapper" onClick={() => setShowAdvanced(!showAdvanced)}>
                <div className="advanced-filters-toggle-divider"/>
                <span className={cls('advanced-filters-toggle')}
                >{t('Advanced Filters')} <ArrowSelectDown/> </span>
                <div className="advanced-filters-toggle-divider"/>
              </div>
            }>
              <div className={cls("advanced-options-wrapper")}
              >
                <div className={'advanced-min-max'} gutter={[48, 0]}>
                  <Col xs={24} md={24} style={{maxWidth: '50%', paddingRight: 24}} className={'custom-competitor-filter-block'}>
                    {
                      minMaxInputsData
                        .map((el, index) => index % 2 === 0 &&
                          (
                            <MinMaxInput data={minMaxFilters}
                                         field={el.field}
                                         iconType={el.iconType}
                                         text={el.text}
                                         key={el.iconType}
                                         tooltip={el.tooltip}
                                         onChange={onChangeMinMaxFilter}
                            />)
                        )
                    }
                  </Col>
                  <Col xs={24} md={24} style={{maxWidth: '50%', paddingLeft: 24}}>
                    {
                      minMaxInputsData
                        .map((el, index) => index % 2 !== 0 && (
                          <MinMaxInput data={minMaxFilters}
                                       field={el.field}
                                       iconType={el.iconType}
                                       text={el.text}
                                       tooltip={el.tooltip}
                                       key={el.iconType}
                                       onChange={onChangeMinMaxFilter}
                          />)

                        )
                    }
                  </Col>
                </div>
                <Divider style={{margin: '24px auto', minWidth: 'unset', width: 'calc(100% - 48px)'}}/>

                <Col span={24} className="selects-wrapper">

                  <DropdownDateBlock
                    label={'Store creation date'}
                    id={'store_created_at'}
                    iconType={'database_calendar'}
                    data={dateOptions}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                  />

                  <DropdownBlockTest
                    label={'Store language'}
                    id={'language'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_globe'}
                    searchPlaceholder={'Search for language...'}
                    data={languageOptions}
                  />

                  <DropdownBlockTest
                    label={'Store currency'}
                    id={'currency'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_currency'}
                    searchPlaceholder={'Search for currency...'}
                    data={currencyOptions}
                  />

                  <DropdownBlockTest
                    label={'Domain TLD'}
                    id={'domain_tld'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_domain'}
                    searchPlaceholder={'Search for TLD...'}
                    data={domainOptions}
                  />
                </Col>
              </div>

            </Panel>

          </Collapse>

          <Divider style={{margin: '0 auto 24px', minWidth: 'auto'}}/>

          <Col xs={24} md={24}>
            <div className="switch-wrapper">
              <div className="switch-options-wrapper">
                {switchFilterOptions.map(el =>
                  <div key={el.key + 1} className="switch-option">
                    <Switch checked={switchFilters[el.key]}
                            name={el.key}
                            key={el.key}
                            onChange={(state) => onChangeSwitch(state, el.key)}
                            disabled={el.disabled}
                    />
                    <span onClick={() => onChangeSwitch(!switchFilters[el.key], el.key)}
                          style={{cursor: 'pointer', pointerEvents: el.disabled ? 'none': 'unset'}}
                    >
                      {t(el.text)}
                    </span>
                  </div>)}
              </div>
              <div className="switch-button-wrapper">
                <ButtonComponent className={'switch-button-reset'}
                                 text={t('Reset Filter')}
                                 onClick={resetFilters}
                />
                <ButtonComponent className={'switch-button-apply switch-button-reset'}
                                 text={t('Search')}
                                 onClick={() => handleSearch(true)}
                                 disabled={false}
                />
              </div>
            </div>
          </Col>
        </Row>
    </>
  );
};

export default AdminFiltersBlock;
