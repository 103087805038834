import React from 'react';

const PaginationSkeleton = ({ isMobile }) => {
  return (
    <div className="product-database-table-header">
      {isMobile ? (
        <>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 68, height: 20 },
                { width: 70, height: 32 },
                { width: 64, height: 20 },
              ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                style={{ width: el.width, height: el.height }}/>))
            }
          </div>
          <div className={'product-database-table-header-skeleton'} style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="link skeleton" style={{ width: 32, height: 32 }}/>
            <div className={'product-database-table-header-skeleton-page-pagination'}>
              {
                [
                  { width: 53, height: 20 },
                  { width: 70, height: 32 },
                  { width: 53, height: 20 },
                ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                  style={{ width: el.width, height: el.height }}/>))
              }
            </div>
            <span className="link skeleton" style={{ width: 32, height: 32, margin: 0 }}/>
          </div>
        </>
      ) : (
        <>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 68, height: 20 },
                { width: 70, height: 32 },
                { width: 64, height: 20 },
              ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                style={{ width: el.width, height: el.height }}/>))
            }
          </div>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 34, height: 20 },
                { width: 70, height: 32 },
                { width: 53, height: 20 },
                { width: 32, height: 32 },
                { width: 32, height: 32 },
              ].map((el, index) => (<span key={el.width * el.height * index}
                                          className="link skeleton" style={{ width: el.width, height: el.height }}/>))
            }
          </div>
        </>
      )}
    </div>
  );
};

export default PaginationSkeleton;
