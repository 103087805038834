import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getTopStoresFilters = () =>
  request.api.get(`/v2/sales_tracker/top_stores/filters/`, null);

export const getTopProductsFilters = () =>
  request.api.get(`/v2/sales_tracker/top_products/filters/`, null);

export const getTopStoresPresets = () =>
  request.api.get(`/sales_tracker/top_stores/filter_presets/`, null);

export const getTopProductsPresets = () =>
  request.api.get(`/sales_tracker/top_products/filter_presets/`, null);

export const getTopStores = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return request.api.get(`/v2/sales_tracker/top_stores/?${queryString}`);
};

export const getTopProducts = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return request.api.get(`/v2/sales_tracker/top_products/?${queryString}`);
};

export const getTopStoresCount = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return request.api.get(`/v2/sales_tracker/top_stores/count/?${queryString}`);
};

export const getTopProductsCount = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return request.api.get(`/v2/sales_tracker/top_products/count/?${queryString}`);
};

export const getProductPriceHistorySalesTracker = (data) => {
  const { internal_shop_id, product_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/charts/price/${internal_shop_id}/${product_id}/?${queryString}`;
  return request.api.get(url, null);
};

//V2_Stores_General
export const getPopularStores = () =>
  request.api.get(`/v2/sales_tracker/popular/stores/`);

export const getTrackedStores = (data) => {
  const { page, page_size } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/?${queryString}`;
  return request.api.get(url, null);
}

export const trackStoreById = (data) => {
  const { internal_shop_id, full_data } = data;

  const url = `/v2/sales_tracker/stores/start_tracking/${internal_shop_id}/`;
  return request.api.post(url, encryptedData({full_data: full_data}));
}

export const trackStoreByUrl = (url) =>
  request.api.post(`/v2/sales_tracker/stores/start_tracking/`, encryptedData(url));

export const unTrackStoreById = (data) =>
  request.api.delete(`/v2/sales_tracker/stores/${data?.internal_shop_id}/`);

export const pinStoreById = (data) =>
  request.api.post(`/v2/sales_tracker/stores/${data?.internal_shop_id}/pin/`);

export const unPinStoreById = (data) =>
  request.api.delete(`/v2/sales_tracker/stores/${data?.internal_shop_id}/pin/`);

export const getTrackSuggestions = (data) =>
  request.api.get(`/v2/sales_tracker/stores/suggestions/?domain=${data?.domain}`);

// V2_Store_Inner_Page
export const getStoreReport = (data) => {
  const { internal_shop_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/report/?${queryString}`;

  return request.api.get(url, null, {responseType: 'blob'});
}

export const getStoreHeader = (data) =>
  request.api.get(`/v2/sales_tracker/stores/${data?.internal_shop_id}/header/`);

export const getStoreSalesAndRevenueShort = (data) => {
  const { internal_shop_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/charts/short/?${queryString}`;

  return request.api.get(url);
}

export const getStoreSalesAndRevenue = (data) => {
  const { internal_shop_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/charts/?${queryString}`;

  return request.api.get(url);
}

export const getStoreProductsQuickView = (data) => {
  const { internal_shop_id, page, page_size, ordering } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }

  if (ordering) {
    searchParams.append('ordering', ordering);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/products/quickview/?${queryString}`;

  return request.api.get(url);
}

export const getStoreProductsShort = (data) => {
  const { internal_shop_id, page, page_size } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/products/short/?${queryString}`;

  return request.api.get(url);
}

export const getStoreProducts = (data) => {
  const { internal_shop_id, page, page_size, created_at__gte, created_at__lte, ordering } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  if (created_at__gte) {
    searchParams.append('created_at__gte', created_at__gte);
  }

  if (created_at__lte) {
    searchParams.append('created_at__lte', created_at__lte);
  }

  if (ordering) {
    searchParams.append('ordering', ordering);
  }

  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/products/?${queryString}`;

  return request.api.get(url);
}

export const getStoreCompetitorsShort = (data) =>
  request.api.get(`/v2/sales_tracker/stores/${data?.internal_shop_id}/competitors/short/`);

export const getStoreCompetitors = (data) => {
  const {
    internal_shop_id,
    page,
    page_size,
    created_at__gte,
    created_at__lte,
    languages,
    products_count__gte,
    products_count__lte,
    revenue__gte,
    revenue__lte,
    sales__gte,
    sales__lte,
    ordering,
  } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (ordering) {
    searchParams.append('ordering', ordering);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  if (created_at__gte) {
    searchParams.append('created_at__gte', created_at__gte);
  }

  if (created_at__lte) {
    searchParams.append('created_at__lte', created_at__lte);
  }

  if (languages?.length) {
    languages.forEach((language) => searchParams.append('languages', language));
  }

  if (products_count__gte) {
    searchParams.append('products_count__gte', products_count__gte);
  }

  if (products_count__lte) {
    searchParams.append('products_count__lte', products_count__lte);
  }

  if (revenue__gte) {
    searchParams.append('revenue__gte', revenue__gte);
  }

  if (revenue__lte) {
    searchParams.append('revenue__lte', revenue__lte);
  }

  if (sales__gte) {
    searchParams.append('sales__gte', sales__gte);
  }

  if (sales__lte) {
    searchParams.append('sales__lte', sales__lte);
  }

  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/competitors/?${queryString}`;

  return request.api.get(url);
}

export const getStoreCompetitorsCount = (data) => {
  const {
    internal_shop_id,
    page,
    page_size,
    created_at__gte,
    created_at__lte,
    languages,
    products_count__gte,
    products_count__lte,
    revenue__gte,
    revenue__lte,
    sales__gte,
    sales__lte
  } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  if (created_at__gte) {
    searchParams.append('created_at__gte', created_at__gte);
  }

  if (created_at__lte) {
    searchParams.append('created_at__lte', created_at__lte);
  }

  if (languages?.length) {
    languages.forEach((language) => searchParams.append('languages', language));
  }

  if (products_count__gte) {
    searchParams.append('products_count__gte', products_count__gte);
  }

  if (products_count__lte) {
    searchParams.append('products_count__lte', products_count__lte);
  }

  if (revenue__gte) {
    searchParams.append('revenue__gte', revenue__gte);
  }

  if (revenue__lte) {
    searchParams.append('revenue__lte', revenue__lte);
  }

  if (sales__gte) {
    searchParams.append('sales__gte', sales__gte);
  }

  if (sales__lte) {
    searchParams.append('sales__lte', sales__lte);
  }

  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/competitors/count/?${queryString}`;

  return request.api.get(url);
}

export const getStoreCompetitorsFilters = (data) =>
  request.api.get(`/v2/sales_tracker/stores/${data?.internal_shop_id}/competitors/filters/`);

export const getStoreAdsShort = (data) => {
  const { internal_shop_id, page, page_size } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/ads/short/?${queryString}`;

  return request.api.get(url);
}

export const getStoreAds = (data) => {
  const {
    ad_copy_language,
    ad_created_at__gte,
    ad_created_at__lte,
    call_to_action,
    ordering,
    page,
    page_size,
    website_provider,
    creative_filters,
    internal_shop_id
  } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  if (ad_created_at__gte) {
    searchParams.append('ad_created_at__gte', ad_created_at__gte);
  }

  if (ad_created_at__lte) {
    searchParams.append('ad_created_at__lte', ad_created_at__lte);
  }

  if (ad_copy_language?.length) {
    ad_copy_language.forEach((language) => searchParams.append('ad_copy_language', language));
  }

  if (call_to_action?.length) {
    call_to_action.forEach((language) => searchParams.append('call_to_action', language));
  }

  if (website_provider?.length) {
    website_provider.forEach((language) => searchParams.append('website_provider', language));
  }

  if (creative_filters?.length) {
    creative_filters.forEach((language) => searchParams.append('creative_filters', language));
  }

  if (ordering) {
    searchParams.append('ordering', ordering);
  }

  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/ads/?${queryString}`;

  return request.api.get(url);
}

export const getStoreAdsCount = (data) => {
  const {
    ad_copy_language,
    ad_created_at__gte,
    ad_created_at__lte,
    call_to_action,
    website_provider,
    internal_shop_id,
    creative_filters,
  } = data;

  const searchParams = new URLSearchParams();

  if (ad_created_at__gte) {
    searchParams.append('ad_created_at__gte', ad_created_at__gte);
  }

  if (ad_created_at__lte) {
    searchParams.append('ad_created_at__lte', ad_created_at__lte);
  }

  if (ad_copy_language?.length) {
    ad_copy_language.forEach((language) => searchParams.append('ad_copy_language', language));
  }

  if (call_to_action?.length) {
    call_to_action.forEach((language) => searchParams.append('call_to_action', language));
  }

  if (website_provider?.length) {
    website_provider.forEach((language) => searchParams.append('website_provider', language));
  }

  if (creative_filters?.length) {
    creative_filters.forEach((language) => searchParams.append('creative_filters', language));
  }

  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/ads/count/?${queryString}`;

  return request.api.get(url);
}

export const getStoreAdsFilters = (data) =>
  request.api.get(`/v2/sales_tracker/stores/${data?.internal_shop_id}/ads/filters/`);

export const getStoreAppsShort = (data) =>
  request.api.get(`/v2/sales_tracker/stores/${data?.internal_shop_id}/apps/short/`);

export const getStoreApps = (data) => {
  const { internal_shop_id, page, page_size } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/stores/${internal_shop_id}/apps/?${queryString}`;

  return request.api.get(url);
}

//V2_Products_General
export const getPopularProducts = () =>
  request.api.get(`/v2/sales_tracker/popular/products/`);

export const getTrackedProducts = (data) => {
  const { page, page_size } = data;

  const searchParams = new URLSearchParams();

  if (page) {
    searchParams.append('page', page);
  }

  if (page_size) {
    searchParams.append('page_size', page_size);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/products/?${queryString}`;
  return request.api.get(url, null);
}

export const trackProductById = (data) => {
  const { internal_shop_id, product_id, full_data } = data;

  const url = `/v2/sales_tracker/products/start_tracking/${internal_shop_id}/${product_id}/`;
  return request.api.post(url, encryptedData({full_data: full_data}));
}

export const trackProductByUrl = (data) =>
  request.api.post(`/v2/sales_tracker/products/start_tracking/`, encryptedData({url: data?.url}));

export const unTrackProductById = (data) =>
  request.api.delete(`/v2/sales_tracker/products/${data?.internal_shop_id}/${data?.product_id}/`);

export const pinProductById = (data) => {
  const { internal_shop_id, product_id } = data;

  const url = `/v2/sales_tracker/products/${internal_shop_id}/${product_id}/pin/`;
  return request.api.post(url);
}

export const unPinProductById = (data) =>
  request.api.delete(`/v2/sales_tracker/products/${data?.internal_shop_id}/${data?.product_id}/pin/`);

// V2_Product_InnerPage
export const getProductReport = (data) => {
  const { internal_shop_id, product_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/products/${internal_shop_id}/${product_id}/report/?${queryString}`;

  return request.api.get(url, null, {responseType: 'blob'});
}

export const getProductHeader = (data) =>
  request.api.get(`/v2/sales_tracker/products/${data?.internal_shop_id}/${data?.product_id}/header/`);

export const getProductReturnAndRevenue = (data) => {
  const { internal_shop_id, product_id, date_gte, date_lte } = data;

  const searchParams = new URLSearchParams();

  if (date_gte) {
    searchParams.append('date__gte', date_gte);
  }

  if (date_lte) {
    searchParams.append('date__lte', date_lte);
  }
  const queryString = searchParams.toString();
  const url = `/v2/sales_tracker/products/${internal_shop_id}/${product_id}/charts/?${queryString}`;

  return request.api.get(url);
}
