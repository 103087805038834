import React, { useEffect, useState,useCallback } from 'react';
import { connect } from 'react-redux';
import Creators from '../reducer';
import cls from 'classname';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Tooltip, Switch } from 'antd';
import SalesTrackerTopPageSkeleton from './components/SalesTrackerTopPageSkeleton';
import SalesTrackerTopTable from './components/SalesTopTrackerTopTable';
import SalesTopTrackerPageTableSkeleton from './components/SalesTopTrackerPageTableSkeleton';
import PaginationSkeleton from './components/PaginationSkeleton';
import Icon from '../../../Icon';
import ReactPlayer from 'react-player';
import DropdownBarFilter from './components/DropdownBarFilter';
import FilterTag from './components/FilterTag';
import DropdownList from './components/DropdownList';
import { CanNotRemoveTrackingContent } from '../../ProductDatabasePage/components/CanNotRemoveTracking';
import StoreProductsModal from '../components/StoreProductsModal';
import SearchWithTags from './components/SearchWithTags';
import PriceHistory from './components/PriceHistory';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import './SalesTrackerTopPage.less';
import DeleteTrackingModalStoreProducts from '../components/DeleteTrackingModalStoreProducts';
import TopPageLimits from '../components/TopPageLimits';
import DatabaseProductsPagination from '../../ProductDatabasePage/components/DatabaseProductsPagination';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import ButtonComponent from '../../../Components/Button';

dayjs.extend(utc);

const periodFilters = [
  { title: 'Last 7 days', filter: 'week' },
  { title: 'Last 30 days', filter: 'month' },
];

const SalesTrackerTopPage = ({
  isMobile,
  limits,
  loading,
  spinner,
  trackingLoading,
  filtersLoading,
  presetsLoading,
  getTopStoresFilters,
  getTopProductsFilters,
  getTopStores,
  getTopProducts,
  getTopStoresCount,
  getTopProductsCount,
  countLoading,
  countStores,
  countProducts,
  storesFilters,
  productsFilters,
  topStores,
  topProducts,
  userInfo,
  getStoreProductsModal,
  storeProductsModalTableDataLoading,
  storeProductsModalTableData,
  resetStoreProductsModal,
  fetching,
  connectTopStore,
  deleteTopStore,
  connectTopProduct,
  deleteTopProduct,
  storesCount,
  productsCount,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const view = location.pathname.split('/')[3] || 'Stores';

  const dateOptions = [
    {id: 1, name: 'Today', value: 'Last 0 days', days: 0},
    {id: 2, name: 'Yesterday', value: 'Last 1 days', days: 1},
    {id: 3, name: 'Last 7 days', value: 'Last 7 days', days: 7},
    {id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30},
    {id: 5, name: 'Last 90 days', value: 'Last 90 days', days: 90},
    {id: 6, name: 'Last 6 months', value: 'Last 6 months', days: 180},
    {id: 7, name: 'Last 12 months', value: 'Last 12 months', days: 365}
  ];

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const isBasicPlan =
    userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isStandardPlan =
    userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() ===
    'standard';
  const isPremiumPlan =
    userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'premium';

  const filterOptions =
    view === 'stores'
      ? [
          { title: 'Revenue', id: 'revenue' },
          { title: 'Sales', id: 'sales', isStore: true },
          { title: 'Products', id: 'products_count' },
          { title: 'Language', id: 'languages' },
          { title: 'Creation date', id: 'created_at' },
        ]
      : [
          { title: 'Price', id: 'price' },
          { title: 'Revenue', id: 'revenue' },
          { title: 'Sales', id: 'sales' },
          { title: 'Language', id: 'languages' },
          { title: 'Creation date', id: 'created_at' },
        ];

  const [activePeriod, setActivePeriod] = useState({
    title: 'Last 30 days',
    filter: 'month',
  });
  const [showAllCharts, setShowAllCharts] = useState(false);
  const switchFilterOptions = [
    { text: 'Show all charts', key: 'show_all_charts', disabled: false },
  ];
  const initialValue = () => {
    let currentFilters = view === 'stores' ? storesFilters : productsFilters;
    let res = {};
    for (let filter in currentFilters) {
      if (filter !== 'languages' && filter !== 'created_at') {
        res[filter] = {
          min: currentFilters?.[filter]?.[activePeriod?.filter]?.min,
          max: currentFilters?.[filter]?.[activePeriod?.filter]?.max,
        };
      } else {
        if (filter === 'languages') {
          res[filter] = null;
        } else {
          res[filter] = { min: null, max: null };
        }
      }
    }
    return res;
  };

  const [value, setValue] = useState(initialValue());
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [loadedPreset, setLoadedPreset] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);
  const [storeProductsToShow, setStoreProductsToShow] = useState(null);
  const [showTags, setShowTags] = useState(false);
  const [newSearch, setNewSearch] = useState(true);
  const [timestamp, setTimestamp] = useState(
    Math.floor(new Date().getTime() / 3600000),
  );
  const [firstLoading, setFirstLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);

  const modalBlocksWidth = {
    deleteTracking: 512,
    canNotRemoveTracking: 450,
    storeProducts: 1120,
    priceHistory: 1040,
  };

  const modalBlocks = {
    deleteTracking: (
      <DeleteTrackingModalStoreProducts
        setModal={setModal}
        record={recordToDelete}
        setVisible={setVisible}
        deleteStore={deleteTopStore}
        deleteProduct={deleteTopProduct}
        view={view}
        isTopPage={true}
      />
    ),
    canNotRemoveTracking: (
      <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile} />
    ),
    storeProducts: (
      <StoreProductsModal
        getStoreProductsModal={getStoreProductsModal}
        storeProductsModalTableDataLoading={storeProductsModalTableDataLoading}
        storeProductsModalTableData={storeProductsModalTableData}
        storeId={storeProductsToShow}
        resetStoreProductsModal={resetStoreProductsModal}
        minDate={null}
        maxDate={null}
        isMobile={isMobile}
      />
    ),
    priceHistory: (
      <PriceHistory product={recordToPriceHistory} salesTopProducts />
    ),
  };

  const viewName = () => {
    const firstLetter = view.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = view.slice(1);
    return firstLetterCap + remainingLetters;
  };

  const searchSubmit = (withEmptySearch = false) => {
    if (loading || spinner || fetching || trackingLoading) return;
    setLoadedPreset(null);
    if (pageNumber === 1) applyFilters(false, withEmptySearch);
    else setPageNumber(1);
  };

  const checkIfChanged = (withState = true) => {
    let currentFilters = view === 'stores' ? storesFilters : productsFilters;
    if (
      Object.keys(value).some((el) => {
        if (el !== 'languages' && el !== 'created_at') {
          return (
            (value?.[el]?.min !==
              currentFilters?.[el]?.[activePeriod?.filter]?.min &&
              value?.[el]?.min !== null) ||
            (value?.[el]?.max !==
              currentFilters?.[el]?.[activePeriod?.filter]?.max &&
              value?.[el]?.max !== null)
          );
        } else {
          if (el === 'languages') {
            return value?.[el] !== null;
          } else {
            return value?.[el]?.min !== null || value?.[el]?.max !== null;
          }
        }
      }) ||
      searchValue
    ) {
      if (withState) setShowTags(true);
      return true;
    } else {
      if (withState) setShowTags(false);
      return false;
    }
  };

  const resetFilters = (isInit = false) => {
    setLoadedPreset(null);
    setSearchValue('');
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    if (isInit) setActivePeriod({ title: 'Last 30 days', filter: 'month' });
    setValue(initialValue());
    setNewSearch(true);
    setTimestamp(Math.floor(new Date().getTime() / 3600000));
    setFirstLoading(true);
    setShowAllCharts(false);
  };

  const applyFilters = (newSearch = false) => {
    !newSearch && setPageNumber(1);
    let data = {
      page: pageNumber,
      page_size: pageSize,
      timestamp: timestamp,
      new_search: newSearch,
    };

    if (sortOrder !== null) {
      data.ordering = [sortOrder];
    }

    const currentFilters = view === "stores" ? storesFilters : productsFilters;

    const filters = {
      languages: value?.languages || [],
      global_search: searchValue?.length ? searchValue : null,
      sales: {
        min: value?.sales?.min,
        max: value?.sales?.max,
      },
      revenue: {
        min: value?.revenue?.min,
        max: value?.revenue?.max,
      },
      created_at: {
        min: value?.created_at?.min,
        max: value?.created_at?.max,
      },
      products_count: {
        min: value?.products_count?.min,
        max: value?.products_count?.max,
      },
      price: {
        min: value?.price?.min,
        max: value?.price?.max,
      },
    };

    Object.keys(filters).forEach((key) => {
    if (
        filters[key]?.min === currentFilters?.[key]?.[activePeriod?.filter]?.min &&
        filters[key]?.max === currentFilters?.[key]?.[activePeriod?.filter]?.max &&
        key !== "languages"  &&
        key !== "global_search"
      ) {
        delete filters[key];
      }
    });

  if (filters.languages && Array.isArray(filters.languages)) {
    const selectedLanguages = value?.languages;
    filters.languages = filters.languages
      .filter((lang) => selectedLanguages.includes(lang.code))
      .map((langObj) => langObj.code);
    if (filters.languages.length === 0) {
      delete filters.languages;
    }
  }

    if (activePeriod?.filter === "week" || activePeriod?.filter === "month") {
      const prefix = activePeriod?.filter === "week" ? "week" : "month";

      if (filters.sales?.min !== undefined) {
        data[`${prefix}_sales__gte`] = filters.sales.min;
      }
      if (filters.sales?.max !== undefined) {
        data[`${prefix}_sales__lte`] = filters.sales.max;
      }
      if (filters.revenue?.min !== undefined) {
        data[`${prefix}_revenue__gte`] = filters.revenue.min;
      }
      if (filters.revenue?.max !== undefined) {
        data[`${prefix}_revenue__lte`] = filters.revenue.max;
      }

      delete filters.sales;
      delete filters.revenue;
    }

    if (filters.created_at?.min !== undefined) {
      data[`created_at__gte`] = filters.created_at.min;
    }
    if (filters.created_at?.max !== undefined) {
      data[`created_at__lte`] = filters.created_at.max;
    }

    if (filters.products_count?.min !== undefined) {
      data[`products_count__gte`] = filters.products_count.min;
    }
    if (filters.products_count?.max !== undefined) {
      data[`products_count__lte`] = filters.products_count.max;
    }
    if (filters.price?.min !== undefined) {
      data[`price__gte`] = filters.price.min;
    }
    if (filters.price?.max !== undefined) {
      data[`price__lte`] = filters.price.max;
    }

    delete filters.created_at;
    delete filters.products_count;
    delete filters.price;

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        data[key] = value;
      }
    });

    const queryParams = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          queryParams.append(key, val);
        });
      } else if (key === 'global_search' && typeof value === 'string') {
        const searchArray = value.split(',').map((item) => item.trim()).filter(Boolean);
        searchArray.forEach((item) => {
          queryParams.append('global_search', item);
        });
      } else if (value !== null && value !== undefined) {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();

    if (view === "stores") {
      getTopStores(queryString);
    } else {
      getTopProducts(queryString);
    }
  };

  const handleCount = (key = {}) => {
    let data = {
      page: 1,
      page_size: pageSize,
    };

    const filters = {
      languages: value?.languages || [],
      global_search: searchValue?.length ? searchValue : null,
      sales: {
        min: value?.sales?.min,
        max: value?.sales?.max,
      },
      revenue: {
        min: value?.revenue?.min,
        max: value?.revenue?.max,
      },
      created_at: {
        min: value?.created_at?.min,
        max: value?.created_at?.max,
      },
      products_count: {
        min: value?.products_count?.min,
        max: value?.products_count?.max,
      },
      price: {
        min: value?.price?.min,
        max: value?.price?.max,
      },
    };

    const currentFilters = view === "stores" ? storesFilters : productsFilters;
    const currentFilterValues = currentFilters?.[key.id]?.[activePeriod?.filter] || {};
    const prefix = activePeriod?.filter === "week" ? "week" : "month";
      if (key.id === 'languages' && key.value && Array.isArray(key.value)) {
        const selectedLanguages = key.value;
        filters.languages = key.value
          .map((langObj) => langObj.code);

        if (key.value.length === 0) {
          delete filters.languages;
        }
      }

    if (key.id !== 'languages' && filters.languages && Array.isArray(filters.languages)) {
      const selectedLanguages = value?.languages;
      filters.languages = filters?.languages
        .map((langObj) => langObj.code);
      if (filters.languages.length === 0) {
        delete filters.languages;
      }
    }
    if (
      key.id !== "created_at"
    ) {
        data[`created_at__gte`] = value?.created_at?.min;
        data[`created_at__lte`] = value?.created_at?.max;
      delete filters.created_at;
    }

    if (activePeriod?.filter === "week" || activePeriod?.filter === "month") {
      if (key.id === 'sales' && key.value.min !== currentFilterValues?.min) {
        data[`${prefix}_sales__gte`] = key.value.min;
      }
      if (key.id === 'sales' && key.value.max !== currentFilterValues?.max) {
        data[`${prefix}_sales__lte`] = key.value.max;
      }
      if (key.id === 'revenue' && key.value.min !== currentFilterValues?.min) {
        data[`${prefix}_revenue__gte`] = key.value.min;
        delete filters.revenue;
      }
      if (key.id === 'revenue' && key.value.max !== currentFilterValues?.max) {
        data[`${prefix}_revenue__lte`] = key.value.max;
        delete filters.revenue;
      }

      delete filters.sales;
      delete filters.revenue;
    }
    if (key.id === 'created_at' &&  key.value.min !== currentFilters?.created_at?.[prefix]?.min) {
      data[`created_at__gte`] = key.value.min;
      delete filters.created_at;
    }
    if (key.id === 'created_at' &&  key.value.max !== currentFilters?.created_at?.[prefix]?.max) {
      data[`created_at__lte`] = key.value.max;
      delete filters.created_at;
    }
    if (key.id === 'products' &&  key.value.min !== currentFilters?.products_count?.[prefix]?.min) {
      data[`products_count__gte`] =  key.value.min;
      delete filters.products_count;
    }
    if (key.id === 'products' &&  key.value.max !== currentFilters?.products_count?.[prefix]?.max) {
      data[`products_count__lte`] =  key.value.max;
      delete filters.products_count;
    }
    if (key.id === 'price' && key.value.min !== currentFilterValues?.min) {
      data[`price__gte`] = key.value.min;
      delete filters.price;
    }
    if (key.id === 'price' && key.value.max !== currentFilterValues?.max) {
      data[`price__lte`] = key.value.max;
      delete filters.price;
    }

    delete filters.price;
    delete filters.products_count;

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        data[key] = value;
      }
    });
    const queryParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          queryParams.append(key, val);
        });
      } else if (key === 'global_search' && typeof value === 'string') {
        const searchArray = value.split(',').map((item) => item.trim()).filter(Boolean);
        searchArray.forEach((item) => {
          queryParams.append('global_search', item);
        });
      } else if (value !== null && value !== undefined) {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();
    if (view === "stores") {
      getTopStoresCount(queryString);
    } else {
      getTopProductsCount(queryString);
    }
  };

  useEffect(() => {
    document.title = `Top ${viewName()} - Dropship`;
    resetFilters(true);
    setSortOrder(null);
    if (!firstLoading) {
      setFirstLoading(true);
    }
    if (view === 'stores') {
      if (
        !Object.keys(storesFilters).length ||
        dayjs().utc().endOf('day').diff(dayjs().utc(), 'seconds') < 3
      )
        getTopStoresFilters();
    } else {
      if (
        !Object.keys(productsFilters).length ||
        dayjs().utc().endOf('day').diff(dayjs().utc(), 'seconds') < 3
      )
        getTopProductsFilters();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [view]);

  useEffect(() => {
    checkIfChanged();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [value]);

  useEffect(() => {
    if (!loadedPreset) resetFilters();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [activePeriod?.title]);

  const handleSort = useCallback(({ order, columnKey }) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  useEffect(() => {
      if (
        !firstLoading &&
        Object.keys(view === 'stores' ? storesFilters : productsFilters)
          .length &&
        !loading
      ) {
        if (newSearch) setNewSearch(false);
      }
      if(!firstLoading && searchValue.length === 0){
        applyFilters(true)
      }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [value, pageSize, pageNumber,sortOrder,searchValue]);

  useEffect(() => {
    if (firstLoading) {
      applyFilters(true);
      setFirstLoading(false);
      setNewSearch(false);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [newSearch, firstLoading]);

  return (
    <Spin spinning={ (spinner || fetching || trackingLoading) && !(loading || filtersLoading || presetsLoading)}>
      <div className={cls('sales-tracker-top-page')}>
        {loading || filtersLoading || presetsLoading ? (
          <SalesTrackerTopPageSkeleton isMobile={isMobile} />
        ) : (
          <>
            <div className={cls('sales-tracker-top-page-header',{'topStores': true})}>
              <div className="title-wrapper">
                <div className="title-wrapper-icon">
                  <Icon type={`top_${view}_icon`} role={'icon'} />
                </div>
                <div className="sales-tracker-top-page-subheader">
                  <h3 className="sales-tracker-top-page-title">
                   {!isMobile ? t(`Top Performing ${viewName()}`) : t(`Top ${viewName()}`)}
                   {isMobile ?
                      <Icon
                        type={'attention_outline'}
                        role="button"
                        width={16}
                        height={16}
                        tooltipProps={{
                          trigger: 'hover',
                          placement: 'bottomRight',
                          overlayClassName: 'subscription-tooltip dropdown-bar-tooltip',
                          getPopupContainer: (trigger) => trigger.parentNode,
                        }}
                        titleText={t(`This list of ${view} will update every 24 hours`)}
                      />
                      :
                      null
                      }
                  </h3>
                   {isMobile && <ButtonComponent
                      className={'sales-tracker-top-page-watch-tutorial'}
                      onClick={() => setVisibleVideo(true)}
                    >
                      <Icon
                        type={'watch_tutorial'}
                        role={'icon'}
                        width={24}
                        height={24}
                      />
                      <span>{t('Watch tutorial')}</span>
                    </ButtonComponent>
                   }
                  {!isMobile && <h4 className="sales-tracker-top-page-subtitle">
                    {t(`This list of ${view} will update every 24 hours`)}
                  </h4>}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isMobile &&<div
                  className={'sales-tracker-top-page-watch-tutorial'}
                  onClick={() => setVisibleVideo(true)}
                >
                  <Icon
                    type={'watch_tutorial'}
                    role={'icon'}
                    width={24}
                    height={24}
                  />
                  <span>{t('Watch tutorial')}</span>
                </div>}
                <TopPageLimits view={view} limits={limits} t={t} />
              </div>
            </div>
            <div className="main-filter-wrapper">
              <div className="main-top-filter-wrapper">
                <Tooltip
                  placement="top"
                  title={isTrial ? t('Feature disabled during Trial') : null}
                  destroyTooltipOnHide={true}
                >
                  <div className="main-filter-search">
                    <SearchWithTags
                      searchValue={searchValue}
                      search={search}
                      setSearch={setSearch}
                      setSearchValue={setSearchValue}
                      searchSubmit={searchSubmit}
                      disabled={
                        isTrial || isBasicPlan || view === 'products'
                          ? isStandardPlan
                          : false
                      }
                      disabledBtn={
                        loading || filtersLoading || spinner || presetsLoading || trackingLoading
                      }
                      placeholder={`Search...`}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={isTrial ? 'Feature disabled during Trial' : null}
                  destroyTooltipOnHide={true}
                >
                  <div className="period-filter-wrapper">
                    <div
                      className={cls('period-filter-active', {
                        'period-filter-active-disabled':
                          isTrial ||
                          isBasicPlan ||
                          (view === 'products' && isStandardPlan),
                      })}
                      style={{
                        left: isMobile
                          ? `calc((100% / 2) * ${periodFilters
                              .map((elem) => elem?.title)
                              .indexOf(activePeriod?.title)})`
                          : `${
                              periodFilters
                                .map((elem) => elem?.title)
                                .indexOf(activePeriod?.title) * 184
                            }px`,
                      }}
                    />
                    {periodFilters.map((el) => (
                      <div
                        key={el?.title}
                        className={cls('period-filter', {
                          active: el?.title === activePeriod?.title,
                          disabled:
                            isTrial ||
                            isBasicPlan ||
                            (view === 'products' && isStandardPlan),
                        })}
                        onClick={() => {
                          if (
                            el?.title === activePeriod?.title ||
                            isTrial ||
                            isBasicPlan ||
                            (view === 'products' && isStandardPlan)
                          )
                            return;
                          else {
                            setLoadedPreset(null);
                            setActivePeriod(el);
                          }
                        }}
                      >
                        {t(el?.title)}
                      </div>
                    ))}
                  </div>
                </Tooltip>
              </div>
              <div className="main-filters-wrapper">
                {view === 'stores' ? (
                  <div className="main-filter-dropdown-wrapper">
                    <DropdownBarFilter
                      key={'Revenue'}
                      title={t('Revenue')}
                      tooltip={
                        'Store revenue generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                      }
                      id={'revenue'}
                      isStore={view === 'stores'}
                      min={storesFilters?.revenue?.[activePeriod?.filter]?.min}
                      max={storesFilters?.revenue?.[activePeriod?.filter]?.max}
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan}
                      countLoading={countLoading}
                      count={countStores}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownBarFilter
                      key={'Sales'}
                      title={t('Sales')}
                      tooltip={
                        'Number of sales the store generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                      }
                      id={'sales'}
                      isStore={view === 'stores'}
                      min={storesFilters?.sales?.[activePeriod?.filter]?.min}
                      max={storesFilters?.sales?.[activePeriod?.filter]?.max}
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan}
                      countLoading={countLoading}
                      count={countStores}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownBarFilter
                      key={'Products'}
                      title={t('Products')}
                      tooltip={'Amount of product listings in the store.'}
                      id={'products_count'}
                      isStore={view === 'stores'}
                      min={
                        storesFilters?.products_count?.[activePeriod?.filter]?.min
                      }
                      max={
                        storesFilters?.products_count?.[activePeriod?.filter]?.max
                      }
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan}
                      countLoading={countLoading}
                      count={countStores}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownList
                      title={t('Language')}
                      id={'languages'}
                      value={value}
                      setValue={setValue}
                      data={storesFilters?.languages || []}
                      disabled={isTrial || isBasicPlan}
                      view={view}
                      tooltip={'Select language'}
                      isMobile={isMobile}
                      count={countStores}
                      handleCount={handleCount}
                    />
                     <DropdownDateBlock innerLabel={'Creation date'}
                        id={'created_at'}
                        customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                        customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                        iconType={'database_calendar'}
                        data={dateOptions}
                        dropdownFilters={value}
                        setDropdownFilters={setValue}
                        hideTimeZone={true}
                        visibleModal={visible}
                        setVisibleModal={setVisible}
                        isMobile={isMobile}
                        withTitle={true}
                        position={'bottomLeft'}
                        disabledCalendar={isTrial}
                        disabledCalendarTooltip={'Feature disabled during Trial'}
                    />
                  </div>
                ) : (
                  <div className="main-filter-dropdown-wrapper">
                    <DropdownBarFilter
                      key={'Price'}
                      title={t('Price')}
                      tooltip={'The price products are sold for in USD.'}
                      id={'price'}
                      isStore={view === 'stores'}
                      min={productsFilters?.price?.[activePeriod?.filter]?.min}
                      max={productsFilters?.price?.[activePeriod?.filter]?.max}
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan || isStandardPlan}
                      isStandard={isStandardPlan}
                      countLoading={countLoading}
                      count={countProducts}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownBarFilter
                      key={'Revenue'}
                      title={t('Revenue')}
                      tooltip={
                        'Product listing revenue generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                      }
                      id={'revenue'}
                      isStore={view === 'stores'}
                      min={
                        productsFilters?.revenue?.[activePeriod?.filter]?.min
                      }
                      max={
                        productsFilters?.revenue?.[activePeriod?.filter]?.max
                      }
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan || isStandardPlan}
                      isStandard={isStandardPlan}
                      countLoading={countLoading}
                      count={countProducts}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownBarFilter
                      key={'Sales'}
                      title={t('Sales')}
                      tooltip={
                        'Number of sales a product listing generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                      }
                      id={'sales'}
                      isStore={view === 'stores'}
                      min={productsFilters?.sales?.[activePeriod?.filter]?.min}
                      max={productsFilters?.sales?.[activePeriod?.filter]?.max}
                      value={value}
                      setValue={setValue}
                      disabled={isTrial || isBasicPlan || isStandardPlan}
                      isStandard={isStandardPlan}
                      countLoading={countLoading}
                      count={countProducts}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownList
                      title={t('Language')}
                      id={'languages'}
                      value={value}
                      setValue={setValue}
                      data={productsFilters?.languages || []}
                      disabled={isTrial || isBasicPlan || isStandardPlan}
                      isStandard={isStandardPlan}
                      count={countProducts}
                      handleCount={handleCount}
                      isMobile={isMobile}
                    />
                    <DropdownDateBlock innerLabel={'Creation date'}
                        id={'created_at'}
                        customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                        customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                        iconType={'database_calendar'}
                        data={dateOptions}
                        dropdownFilters={value}
                        setDropdownFilters={setValue}
                        hideTimeZone={true}
                        visibleModal={visible}
                        setVisibleModal={setVisible}
                        isMobile={isMobile}
                        withTitle={true}
                        position={'bottomLeft'}
                        disabledCalendar={isTrial}
                        disabledCalendarTooltip={'Feature disabled during Trial'}
                    />
                  </div>
                )}
                <div className="vertical-divider" style={{ minWidth: 1 }} />
                <div
                  className="switch-wrapper"
                  style={{ order: isMobile && -1 }}
                >
                  <div className="switch-options-wrapper">
                    {switchFilterOptions.map((el) => (
                      <div key={el.key + 1} className="switch-option">
                        <Switch
                          name={el.key}
                          key={el.key}
                          checked={showAllCharts}
                          onChange={(state) => setShowAllCharts(state)}
                        />
                        <div
                          onClick={(state) => setShowAllCharts(state)}
                          className="switch-title"
                          style={{
                            cursor: 'pointer',
                            pointerEvents: el.disabled ? 'none' : 'unset',
                          }}
                        >
                          {t(el.text)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
                <div className={cls('product-database-table-wrapper sales-tracker-top-table-wrapper')}>
        {
            loading || filtersLoading || presetsLoading ?
              <PaginationSkeleton isMobile={isMobile}/>
              :
              (view === 'stores' ? topStores?.length : topProducts?.length) ?
              <DatabaseProductsPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                isTopStores={true}
                isTrial={isTrial}
                setPageNumber={setPageNumber}
                totalProducts={view === 'stores' ? storesCount || 0 : productsCount || 0}
                disabled={isTrial || isBasicPlan || (view === 'products' && isStandardPlan)}
                isTopProducts={view === 'products' && isStandardPlan}
              />
                :
                null
          }
        {showTags && !(loading || filtersLoading || presetsLoading) ? (
          <div className={'sales-tracker-top-page-tags'}>
            <div
              onClick={resetFilters}
              className="sales-tracker-top-page-tags-clear-all"
            >
              <span>
                <Icon role={'icon'} type="clear" />
              </span>
              {t('Clear all')}
            </div>
            <div className="vertical-divider" />
            {Object.keys(value)
              ?.filter((key) => {
                let currentFilters =
                  view === 'stores' ? storesFilters : productsFilters;
                if (key !== 'languages' && key !== 'created_at') {
                  return (
                    (value?.[key]?.min !==
                      currentFilters?.[key]?.[activePeriod?.filter]?.min &&
                      value?.[key]?.min !== null) ||
                    (value?.[key]?.max !==
                      currentFilters?.[key]?.[activePeriod?.filter]?.max &&
                      value?.[key]?.max !== null)
                  );
                } else {
                  if (key === 'languages') {
                    return value?.[key] !== null;
                  } else {
                    return (
                      value?.[key]?.min !== null || value?.[key]?.max !== null
                    );
                  }
                }
              })
              ?.map((el) => {
                let def = filterOptions.filter((elem) => elem?.id === el)?.[0];
                let data = {};
                let currentFilters =
                  view === 'stores' ? storesFilters : productsFilters;
                if (el !== 'languages' && el !== 'created_at') {
                  data = {
                    id: el,
                    title: def?.title,
                    value: value?.[el],
                    min: currentFilters?.[el]?.[activePeriod?.filter]?.min,
                    max: currentFilters?.[el]?.[activePeriod?.filter]?.max,
                  };
                } else {
                  data = {
                    id: el,
                    title: def?.title,
                    value: value?.[el],
                    min: null,
                    max: null,
                  };
                }
                return (
                  <FilterTag
                    key={el}
                    languages={
                      view === 'stores'
                        ? storesFilters?.languages?.length
                          ? [...storesFilters.languages]
                          : []
                        : productsFilters?.languages?.length
                        ? [...productsFilters.languages]
                        : []
                    }
                    data={data}
                    setValue={setValue}
                  />
                );
              })}
          </div>
        ) : null}

        {loading || filtersLoading || presetsLoading ? (
          <SalesTopTrackerPageTableSkeleton isMobile={isMobile} />
        ) : (
          <SalesTrackerTopTable
            view={view}
            expandedRowKeys={expandedRowKeys}
            setExpandedRowKeys={setExpandedRowKeys}
            activePeriod={activePeriod}
            isMobile={isMobile}
            setStoreProductsToShow={setStoreProductsToShow}
            setVisible={setVisible}
            setModal={setModal}
            showAllCharts={showAllCharts}
            topProducts={topProducts}
            topStores={topStores}
            loading={loading}
            setRecordToPriceHistory={setRecordToPriceHistory}
            setRecordToDelete={setRecordToDelete}
            period={activePeriod?.filter}
            isTrial={isTrial}
            isBasicPlan={isBasicPlan}
            isStandardPlan={isStandardPlan}
            isPremiumPlan={isPremiumPlan}
            connectStore={connectTopStore}
            connectProduct={connectTopProduct}
            visible={visible}
            onSort={handleSort}
            sortOrder={sortOrder}
          />
        )}
        </div>
        <Modal
          className="change-modal custom-modal video-modal"
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? { transitionName: '' } : null)}
          open={visibleVideo}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#225aea" opacity={1} />
          }
          footer={null}
          closable="true"
          onCancel={() => setVisibleVideo(false)}
          destroyOnClose
        >
          <ReactPlayer
            controls={true}
            width={'100%'}
            height={'100%'}
            playing={true}
            url={
              view === 'stores'
                ? 'https://www.youtube.com/watch?v=nqwt8lpe9cY'
                : 'https://www.youtube.com/watch?v=vZqzFerxSps'
            }
          />
        </Modal>
        <Modal
          className={cls('change-modal custom-modal ', {
            'store-products-opened': modal === 'storeProducts',
            'collection-list-modal collection-modal':
              modal === 'canNotRemoveTracking',
          })}
          getContainer={() => document.getElementById('global-wrap')}
          {...(isMobile ? { transitionName: '' } : null)}
          open={visible}
          centered={!isMobile}
          closeIcon={
            <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
          }
          width={modalBlocksWidth[modal]}
          footer={null}
          closable="true"
          onCancel={() => setVisible(false)}
          destroyOnClose
          title={
            modal === 'canNotRemoveTracking' && t('Can not remove tracking')
          }
        >
          <Spin size="large" spinning={false}>
            {modalBlocks[modal]}
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  limits: state.salesTracker.stores?.limits,
  loading: state.salesTracker.topPage?.loading,
  spinner: state.salesTracker.topPage?.spinner,
  trackingLoading: state.salesTracker.topPage?.trackingLoading,
  countLoading: state.salesTracker.topPage?.countLoading,
  countStores: state.salesTracker.topPage?.topStores?.count,
  countProducts: state.salesTracker.topPage?.topProducts?.count,
  fetching: state.salesTracker.loading,
  filtersLoading: state.salesTracker.topPage?.filtersLoading,
  presetsLoading: state.salesTracker.topPage?.presetsLoading,
  storesFilters: state?.salesTracker?.topPage?.topStores?.filters,
  productsFilters: state?.salesTracker?.topPage?.topProducts?.filters,
  storesPresets: state?.salesTracker?.topPage?.topStores?.presets?.quick_search,
  productsPresets:
    state?.salesTracker?.topPage?.topProducts?.presets?.quick_search,
  topStores: state?.salesTracker?.topPage?.topStores?.stores?.results,
  topProducts: state?.salesTracker?.topPage?.topProducts?.products?.results,
  storeProductsModalTableDataLoading:
    state.salesTracker.storeProductsModal.loading,
  storeProductsModalTableData: state.salesTracker.storeProductsModal,
  storesCount: state.salesTracker.topPage?.topStores?.stores?.count,
  productsCount:state.salesTracker.topPage?.topProducts?.products?.count,
});

const mapDispatchToProps = (dispatch) => ({
  getTopStoresFilters: () => dispatch(Creators.getTopStoresFiltersRequest()),
  getTopProductsFilters: () =>
    dispatch(Creators.getTopProductsFiltersRequest()),
  getTopStores: (data) => dispatch(Creators.getTopStoresRequest(data)),
  getTopProducts: (data) => dispatch(Creators.getTopProductsRequest(data)),
  getTopStoresCount: (data) =>
    dispatch(Creators.getTopStoresCountRequest(data)),
  getTopProductsCount: (data) =>
    dispatch(Creators.getTopProductsCountRequest(data)),
  getStoreProductsModal: (data) =>
    dispatch(Creators.getStoreProductsModalRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  connectTopStore: (data) =>
    dispatch(Creators.trackTopStoreByIdRequest(data)),
  deleteTopStore: (data) =>
    dispatch(Creators.unTrackTopStoreByIdRequest(data)),
  connectTopProduct: (data) =>
    dispatch(Creators.trackTopProductByIdRequest(data)),
  deleteTopProduct: (data) =>
    dispatch(Creators.unTrackTopProductByIdRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesTrackerTopPage);
