import React from 'react';
import { Divider } from 'antd';

const DatabaseProductsSkeleton = ({isMobile=false}) => (
  <div className="product-database-table-wrapper">
    <div className="product-database-table-header">
      {isMobile ? (
        <>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 68, height: 20 },
                { width: 70, height: 32 },
                { width: 64, height: 20 },
              ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                style={{ width: el.width, height: el.height }}/>))
            }
          </div>
          <div className={'product-database-table-header-skeleton'} style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="link skeleton" style={{ width: 32, height: 32 }}/>
            <div className={'product-database-table-header-skeleton-page-pagination'}>
              {
                [
                  { width: 53, height: 20 },
                  { width: 70, height: 32 },
                  { width: 53, height: 20 },
                ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                  style={{ width: el.width, height: el.height }}/>))
              }
            </div>
            <span className="link skeleton" style={{ width: 32, height: 32, margin: 0 }}/>
          </div>
        </>
      ) : (
        <>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 68, height: 20 },
                { width: 70, height: 32 },
                { width: 64, height: 20 },
              ].map(el => (<span key={el.width * el.height} className="link skeleton"
                                style={{ width: el.width, height: el.height }}/>))
            }
          </div>
          <div className={'product-database-table-header-skeleton'}>
            {
              [
                { width: 34, height: 20 },
                { width: 70, height: 32 },
                { width: 53, height: 20 },
                { width: 32, height: 32 },
                { width: 32, height: 32 },
              ].map((el, index) => (<span key={el.width * el.height * index}
                                          className="link skeleton" style={{ width: el.width, height: el.height }}/>))
            }
          </div>
        </>
      )}
    </div>

    <div className="product-database-table-subheader" style={{ marginTop: 20, }}>
      <div style={{ width: '32%', paddingLeft: 48 }}>
        <span className="link skeleton" style={{ width: 62, height: 24 }}/>
      </div>
      {
        isMobile ?
          null
          :
          <div style={{ width: '13%' }}>
            <span className="link skeleton" style={{ width: 62, height: 24 }}/>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '13.5%' }}>
            <span className="link skeleton" style={{ width: 109, height: 24 }}/>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '17.5%' }}>
            <span className="link skeleton" style={{ width: 120, height: 24, marginLeft: 24 }}/>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '10%' }}>
            <span className="link skeleton" style={{ width: 75, height: 24 }}/>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          </div>
      }
      {
        isMobile ?
          null
          :
          <div style={{ width: '10.5%' }}>
            <span className="link skeleton" style={{ width: 75, height: 24 }}/>
            <span className="link skeleton" style={{ width: 24, height: 24 }}/>
          </div>
      }
    </div>
    <Divider type={'horizontal'} style={{ margin: "12px auto 0px" }}/>

    <div style={{ marginTop: 0 }} className="product-database-table-body">
      {[
        { id: 1, last: 68, third: { first: 74 } },
        { id: 2, last: 79, third: { first: 54, second: 61 } },
        { id: 3, last: 70, third: { first: 48, second: 49 } },
        { id: 4, last: 70, third: { first: 111, second: 119 } },
        { id: 5, last: 75, third: { first: 27, second: 66 } },
        { id: 6, last: 75, third: { first: 48 } },
        { id: 7, last: 75, third: { first: 49 } },
      ].map(row => (
        <div key={row.id} className={'product-database-table-skeleton-row'}>
          <div className={'product-database-table-skeleton-cell-wrapper'}>
            <div key={row.id + 10} className={'product-database-table-skeleton-cell-1'}>
              <div style={{ position: 'relative', display: "flex", alignItems: "center", gap: isMobile ? 4 : 10 }}>
                <span className="link skeleton" style={{ width: 20, height: 20, marginRight: isMobile && 4 }}/>
                <span className="link skeleton" style={{ width: 40, height: 40 }}/>
              </div>

              <div style={{ marginLeft: isMobile ? 12 : 16, display: "flex", gap: isMobile && 10, marginRight: isMobile && 8, justifyContent: isMobile && "space-between", width: isMobile && "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 4, justifyContent: "center" }}>
                  <span className="link skeleton" style={{ width: 200, height: 20 }}/>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <span className="link skeleton" style={{ width: isMobile ? 100 : 120, height: 20 }}/>
                    <Divider type="vertical"/>
                    <span className="link skeleton" style={{ width: isMobile ? 60 : 80, height: 20 }}/>
                    <Divider type="vertical"/>
                    <span className="link skeleton" style={{ width: isMobile ? 60 : 80, height: 20 }}/>
                  </div>
                </div>
                {isMobile ? 
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                  <span className="link skeleton" style={{ width: 35, height: 10 }}/>
                </div> : null }
              </div>
            </div>
            {
              isMobile ?
                null
                :
                <div key={row.id + 20} className={'product-database-table-skeleton-cell-2'}>
                  <span className="link skeleton" style={{ width: 20, height: 20 }}/>
                  <span className="link skeleton" style={{ width: 20, height: 20, marginRight: 48 }}/>
                  <span className="link skeleton" style={{ width: 20, height: 20 }}/>
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 30} className={'product-database-table-skeleton-cell-3'}>
                  <span className="link skeleton" style={{ width: row.third.first, height: 20 }}/>
                  {row.third.second && <span className="link skeleton"
                                             style={{
                                               display: 'block',
                                               width: row.third.second,
                                               height: 20,
                                               marginTop: 10,
                                             }}
                  />}
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 40} className={'product-database-table-skeleton-cell-4'}>
                  <span className="link skeleton" style={{ width: 36, height: 20 }}/>
                </div>
            }
            {
              isMobile ?
                null
                :
                <div key={row.id + 50} className={'product-database-table-skeleton-cell-5'}>
                  <span className="link skeleton" style={{ width: row.last, height: 20 }}/>
                  <span className="link skeleton" style={{ width: row.last, height: 20 }}/>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: "center", gap: 10 }}>
                    <span className="link skeleton" style={{ width: 35, height: 10 }}/>
                  </div>
                </div>
            }
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default DatabaseProductsSkeleton;
