import { API, encryptedData } from '../../Services/Api';

const request = new API();

export const getProductPriceHistory = (data) =>
  data?.isPD ?
    request.api.get(`/product_database/products/chart/${data?.store_id}/${data?.product_id}/prices/`, null)
    :
    request.api.get(`/sales_tracker/products/chart/${data?.store_id}/${data?.product_id}/prices/`, null);
export const getProductDatabaseFilters = () => request.api.get(`product_database/choice_filters/`, null);
export const getProductDatabasePresets = (data) => request.api.get(`product_database/filter_presets/?q=${data}`, null);
export const createProductDatabasePreset = (data) => request.api.post(`product_database/filter_presets/`, encryptedData(data));
export const deleteProductDatabasePreset = (record) => request.api.delete(`product_database/filter_presets/${record.id}/`, null);
export const cancelDeleteProductDatabasePreset = (record) => request.api.delete(`product_database/filter_presets/${record.id}/`, null);
export const updateProductDatabasePreset = (data) => request.api.patch(`product_database/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));
export const getProductsDatabase = (data) =>
  data?.hasOwnProperty('filters') ?
  request.api.post(`product_database/search/`, encryptedData(data))
    :
  request.api.post(`product_database/search/default/`, encryptedData(data))
export const getProductChart = (data) => {
  return data?.raw_data ?
    request.api.get(`/product_database/products/chart/${data.store_id}/${data.product_id}/raw_data/`, null)
    :
    request.api.get(`/product_database/products/chart/${data.store_id}/${data.product_id}/`, null);
}

export const deleteTrackingStoreByID = (data) =>
  request.api.delete(`/v2/sales_tracker/stores/${data?.store?.internal_shop_id}/`);
export const createTrackingStore = (data) => {

  const url = `/v2/sales_tracker/stores/start_tracking/${data?.store?.internal_shop_id}/`;
  return request.api.post(url, encryptedData({full_data: false}));
}
export const deleteTrackingProductByID = (data) => request.api.delete(`/v2/sales_tracker/products/${data?.store?.internal_shop_id}/${data?.id}/`);
export const createTrackingProduct = (data) => {

  const url = `/v2/sales_tracker/products/start_tracking/${data?.store?.internal_shop_id}/${data?.id}/`;
  return request.api.post(url, encryptedData({full_data: false}));
}

