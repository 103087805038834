import React from 'react';
import Icon from '../Icon';
import { Tooltip } from 'antd';

export const titleSortFilters = ({ title, stateData, key, isTrial = false }) => {
  let sortImage;

  if (stateData?.sortColumn?.dataIndex === key) {
    if (stateData?.sortOrder === 'descend') {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" width={20} height={20} />
          <Icon type="table_arrow" role="icon" className="icon-descend" width={20} height={20} />
        </span>
      );
    } else if (stateData?.sortOrder === 'ascend') {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" className="icon-ascend" width={20} height={20} />
          <Icon type="table_arrow" role="icon" width={20} height={20} />
        </span>
      );
    } else {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" width={20} height={20} />
          <Icon type="table_arrow" role="icon" width={20} height={20} />
        </span>
      );
    }
  } else {
    sortImage = (
      <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" width={20} height={20} />
          <Icon type="table_arrow" role="icon" width={20} height={20} />
        </span>
    );
  }

  return (
    <Tooltip 
      placement="top"
      title={isTrial ? 'Feature disabled during Trial' : null}
      destroyTooltipOnHide={true}
    >
      <div className="table-column-title">
        <span>{title}</span>
        {sortImage}
      </div>
    </Tooltip>
  );
};

export const columnTitles = ({ sortOrder, title, key }) => {
  let sortImage;

  if (sortOrder?.includes(key)) {
    if (sortOrder === '-' + key) {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" width={20} height={20} />
          <Icon type="table_arrow" role="icon" className="icon-descend" width={20} height={20} />
        </span>
      );
    } else if (sortOrder === key) {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" className="icon-ascend" width={20} height={20} />
          <Icon type="table_arrow" role="icon" width={20} height={20} />
        </span>
      );
    } else {
      sortImage = (
        <span className="title-sort-header">
          <Icon type="table_arrow" role="icon" width={20} height={20} />
          <Icon type="table_arrow" role="icon" width={20} height={20} />
        </span>
      );
    }
  } else {
    sortImage = (
      <span className="title-sort-header">
        <Icon type="table_arrow" role="icon" width={20} height={20} />
        <Icon type="table_arrow" role="icon" width={20} height={20} />
      </span>
    );
  }

  return (
    <div className="table-column-title">
      <span>{title}</span>
      {sortImage}
    </div>
  );
};
