import React from 'react';
import { useSelector } from 'react-redux';

const Followers = () => {
  const theme = useSelector((state) => state?.nav?.theme);
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16671 0.333496C1.15419 0.333496 0.333374 1.15431 0.333374 2.16683V9.16683C0.333374 10.1794 1.15419 11.0002 2.16671 11.0002H3.00004V11.8335C3.00004 12.846 3.82085 13.6668 4.83337 13.6668H11.8334C12.8459 13.6668 13.6667 12.846 13.6667 11.8335V4.8335C13.6667 3.82097 12.8459 3.00016 11.8334 3.00016H11V2.16683C11 1.15431 10.1792 0.333496 9.16671 0.333496H2.16671ZM4.83337 3.00016H10V2.16683C10 1.70659 9.62695 1.3335 9.16671 1.3335H2.16671C1.70647 1.3335 1.33337 1.70659 1.33337 2.16683V9.16683C1.33337 9.62707 1.70647 10.0002 2.16671 10.0002H3.00004V4.8335C3.00004 3.82097 3.82085 3.00016 4.83337 3.00016ZM8.33337 10.0002C6.95635 10.0002 5.76148 10.9079 5.16635 12.2372C5.07358 12.4445 5.23382 12.6668 5.46086 12.6668H11.2059C11.4329 12.6668 11.5932 12.4445 11.5004 12.2372C10.9053 10.9079 9.7104 10.0002 8.33337 10.0002ZM8.33337 9.00016C9.25385 9.00016 10 8.25397 10 7.3335C10 6.41302 9.25385 5.66683 8.33337 5.66683C7.4129 5.66683 6.66671 6.41302 6.66671 7.3335C6.66671 8.25397 7.4129 9.00016 8.33337 9.00016Z"
        fill={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.259)'}
      />
    </svg>
  );
};


export default Followers;