import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { getDiff } from './AdLibraryItem';

const AdLibraryModalDemography = ({ insightsInfo, isMobile,insightsInfoLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="demography-block">
      <div className="demography-section">
        <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Countries')}</div>
        <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
          <div className="adLibrary-item-info-value">
            {Array.isArray(insightsInfo?.demographics?.country) ? (
              <div className={`country-flags ${insightsInfoLoading && "skeleton"}`}>
                {insightsInfo?.demographics.country
                  .slice(0, 4)
                  .map((country, index) => (
                    <Tooltip key={index} title={country.name}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: country.flag,
                        }}
                      />
                    </Tooltip>
                  ))}
                {insightsInfo?.demographics?.country?.length > 4 && (
                  <Tooltip
                    title={
                      <div style={{ whiteSpace: 'pre-line' }}>
                        {insightsInfo.demographics.country
                          .slice(4)
                          .map((country) => country.name)
                          .join('\n')}
                      </div>
                    }
                  >
                    <span className="extra-flags">
                      +{insightsInfo?.demographics.country?.length - 4}
                    </span>
                  </Tooltip>
                )}
              </div>
            ) : (
              insightsInfo?.demographics?.country || null
            )}
          </div>
        </div>
      </div>
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Language')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>{insightsInfo?.language}</div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Gender')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {Array.isArray(insightsInfo?.demographics?.gender)
              ? insightsInfo?.demographics?.gender.join(', ')
              : insightsInfo?.demographics?.gender}
          </div>
        </div>
      )}
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Status')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            <span
              className={`status ${insightsInfo?.is_active ? 'green' : ''} ${insightsInfoLoading && "skeleton"}`}
            >
              {insightsInfo?.is_active ? t('Running') : t('Not running')}
            </span>
          </div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Language')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>{insightsInfo?.language}</div>
        </div>
      )}
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Creation date')}</div>
          <div className={`demography-value-date ${insightsInfoLoading && "skeleton"}`}>
            <div>{dayjs(insightsInfo?.created_at, t).format('DD.MM.YYYY')}</div>
            <div className="demography-value-date-gray">
              {getDiff(insightsInfo?.created_at, t)}
            </div>
          </div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Age')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {typeof insightsInfo?.demographics?.age === 'string'
              ? insightsInfo?.demographics?.age || t('Any')
              : insightsInfo?.demographics?.age
              ? `${insightsInfo.demographics.age.min} - ${insightsInfo.demographics.age.max}`
              : t('Any')}
          </div>
        </div>
      )}
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Gender')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {Array.isArray(insightsInfo?.demographics?.gender)
              ? insightsInfo?.demographics?.gender.join(', ')
              : insightsInfo?.demographics?.gender}
          </div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Status')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            <span
              className={`status ${insightsInfo?.is_active ? 'green' : ''} ${insightsInfoLoading && "skeleton"}`}
            >
              {insightsInfo?.is_active ? t('Running') : t('Not running')}
            </span>
          </div>
        </div>
      )}
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Age')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {typeof insightsInfo?.demographics?.age === 'string'
              ? insightsInfo?.demographics?.age || t('Any')
              : insightsInfo?.demographics?.age
              ? `${insightsInfo.demographics.age.min} - ${insightsInfo.demographics.age.max}`
              : t('Any')}
          </div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Running for')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {`${insightsInfo?.running_days} ${t('days')}`}
          </div>
        </div>
      )}
      {!isMobile ? (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Running for')}</div>
          <div className={`demography-value ${insightsInfoLoading && "skeleton"}`}>
            {`${insightsInfo?.running_days} ${t('days')}`}
          </div>
        </div>
      ) : (
        <div className="demography-section">
          <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Creation date')}</div>
          <div className={`demography-value-date ${insightsInfoLoading && "skeleton"}`}>
            <div>{dayjs(insightsInfo?.created_at, t).format('DD.MM.YYYY')}</div>
            <div className="demography-value-date-gray">
              {getDiff(insightsInfo?.created_at, t)}
            </div>
          </div>
        </div>
      )}
      <div className="demography-section">
        <div className={`demography-title ${insightsInfoLoading && "skeleton"}`}>{t('Last seen')}</div>
        <div className={`demography-value-date ${insightsInfoLoading && "skeleton"}`}>
          <div>
            {dayjs(insightsInfo?.last_seen_date, t).format('DD.MM.YYYY')}
          </div>
          <div className="demography-value-date-gray">
            {getDiff(insightsInfo?.last_seen_date, t)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdLibraryModalDemography;
