import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import acc from 'accounting';
import { useLocation } from 'react-router-dom';
import { Dropdown, Tooltip } from 'antd';
import InputComponent from '../../../Components/Input';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import useDebounce from '../../../hooks/use-debounce';
import { useTranslation } from 'react-i18next';
import DatabasePaginationOptionsModal from './DatabasePaginationOptionsModal';

const DatabaseProductsPagination = (props) => {
  const {
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    totalProducts,
    disabled,
    isTopStores = false,
    isTopProducts = false,
    isTrial,
    isShort = false,
  } = props;

  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const { pathname } = useLocation();

  const debouncedValue = useDebounce( page, 600);
  const totalPages = Math.ceil(totalProducts / pageSize) || 0;
  const optionsSelect = [50, 100, 150];

  const isMobile = useSelector(state => state?.nav?.isMobile);
  const subscriptions = useSelector(store => store?.auth?.userInfo?.subscriptions);
  const isFreePlan = subscriptions?.[0]?.plan?.plan?.name === 'basic';
  const showTooltip = isTrial;

  useEffect(() => {
    setPageNumber(debouncedValue === 0 ? 1 : debouncedValue);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [debouncedValue]);
  useEffect(() => {
    if( pathname === '/competitor-research' || pathname === '/admin/shopify'){
      setPage(pageNumber)
      setPageNumber(pageNumber)
    }else{
      if (page !== pageNumber) setPage(pageNumber);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber]);

  const handleInnerVisibleChange = (value) => {
    setInnerVisible(value);
  };

  const pageNumberHandler = (e) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;

    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      if (+parsedValue > totalPages) {

          setPage(totalPages);

      } else {
          setPage(+parsedValue);

      }
    }
  };

  return (
    <div className="product-database-table-header">
        <span>
          {t('Displaying')}

          <Tooltip placement="top"
                   overlayClassName="product_name-tooltip details-tooltip"
                   getPopupContainer={(trigger) => trigger.parentNode}
                   title={showTooltip ? 'Feature disabled during Trial' : null}
                   destroyTooltipOnHide={true}
          >
            {
              isMobile ?
                <span className={'ant-dropdown-trigger'}
                      onClick={() => setModal(true)}
                >
                  {pageSize}
                  <Icon type="arrow_select_down" role="icon" />
                </span>
                :
                <Dropdown overlayClassName={'page-size-dropdown'}
                          destroyPopupOnHide={true}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onOpenChange={handleInnerVisibleChange}
                          trigger={['click']}
                          open={!isTrial && innerVisible}
                          dropdownRender={() => (
                            <ul className="page-size-dropdown-list">
                              {
                                optionsSelect.map(el => (
                                  <li className={'page-size-dropdown-list-item'}
                                      key={el}
                                      onClick={() => {
                                        setPageSize(el);
                                        setPageNumber(1);
                                        setInnerVisible(false);
                                      }}
                                  >
                                    {el}
                                    {
                                      pageSize === el ?
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="16"
                                             height="16"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             className={'checkmark'}
                                        >
                                          <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                stroke="#225AEA"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                          />
                                        </svg>
                                        :
                                        null
                                    }
                                  </li>
                                ))
                              }
                            </ul>
                          )}
                >
                  <span className={`${isTrial ? 'disabled' : ''}`}>
                    {pageSize}
                    <Icon type="arrow_select_down" role="icon" />
                  </span>
                </Dropdown>
            }
          </Tooltip>
          {t('of')} {acc.format(totalProducts, '0', ',', '')}
        </span>
      {
        isShort ?
          null
          :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
            {isMobile && (
              <Tooltip placement="top"
                       overlayClassName="product_name-tooltip details-tooltip"
                       getPopupContainer={(trigger) => trigger.parentNode}
                       title={showTooltip ? 'Feature disabled during Trial' : null}
                       destroyTooltipOnHide={true}
                       open={visible}
              >
                <div className="database-page-buttons" style={{ position: 'relative' }}>
                  {showTooltip && (
                    <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 100 }}
                          onMouseEnter={() => setVisible(true)}
                          onMouseLeave={() => setVisible(false)}
                    />
                  )}
                    <ButtonComponent className={'database-page-button database-page-button-prev'}
                                    text={''}
                                    disabled={pageNumber <= 1 || totalProducts === 0 || isTrial}
                                    onClick={() => setPageNumber(prev => prev - 1)}
                                    icon={<Icon type="arrow_select_down" role="icon" />}
                    />
                </div>
              </Tooltip>
            )}
            <div>
              {t('Page')}
              <Tooltip placement="top"
                      overlayClassName="product_name-tooltip details-tooltip"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      title={showTooltip ? 'Feature disabled during Trial' : null}
                      destroyTooltipOnHide={true}
              >
                <span>
                  <InputComponent onChange={(e) => pageNumberHandler(e)}
                                  className={'database-page-number'}
                                  placeholder={''}
                                  disabled={totalProducts === 0 || isTrial || (isFreePlan && isTopStores) || isTopProducts}
                                  value={acc.formatNumber(page, 0, ',', '')}
                  />
                </span>
              </Tooltip>

              {t('of')} {acc.format(totalPages, '0', ',', '')}
            </div>
            <Tooltip placement="top"
                     overlayClassName="product_name-tooltip details-tooltip"
                     getPopupContainer={(trigger) => trigger.parentNode}
                     title={showTooltip ? 'Feature disabled during Trial' : null}
                     destroyTooltipOnHide={true}
                     open={visible}
            >
              <div className="database-page-buttons" style={{ position: 'relative' }}>
                {showTooltip && (
                  <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 100 }}
                       onMouseEnter={() => setVisible(true)}
                       onMouseLeave={() => setVisible(false)}
                  />
                )}
                {!isMobile && (
                  <ButtonComponent className={'database-page-button database-page-button-prev'}
                                 text={''}
                                 disabled={pageNumber <= 1 || totalProducts === 0 || isTrial}
                                 onClick={() => setPageNumber(prev => prev - 1)}
                                 icon={<Icon type="arrow_select_down" role="icon" />}
                  />
                )}
                <ButtonComponent className={'database-page-button database-page-button-next'}
                                 text={''}
                                 disabled={pageNumber === totalPages || totalProducts === 0 || (disabled && !isFreePlan) || (disabled && isTopStores) || isTrial}
                                 onClick={() => setPageNumber(prev => prev + 1)}
                                 icon={<Icon type="arrow_select_down" role="icon" />}
                />
              </div>
            </Tooltip>
          </div>
      }
      <DatabasePaginationOptionsModal setModal={setModal}
                                      isMobile={isMobile}
                                      visible={modal}
                                      pageSize={pageSize}
                                      setPageSize={setPageSize}
                                      optionsSelect={optionsSelect}
                                      setPageNumber={setPageNumber}
      />
    </div>
  );
};

export default React.memo(DatabaseProductsPagination);
