import React, { useCallback, useEffect, useRef, useState } from 'react';
import Creators from '../reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Divider, Modal, Spin } from 'antd';
import DropdownList from '../pages/components/DropdownList';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import Icon from '../../../Icon';
import CascadingDropdown from '../../AdSpotPage/components/CascadingDropdown';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import AdItem from '../../AdSpotPage/components/AdItem';
import CascadingModal from '../../AdSpotPage/components/CascadingModal';


const dateOptions = [
  {id: 3, name: 'Last 7 days', value: 'Last 7 days', days: 7},
  {id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30},
  {id: 5, name: 'Last 90 days', value: 'Last 90 days', days: 90},
  {id: 6, name: 'Last 6 months', value: 'Last 6 months', days: 180},
  {id: 7, name: 'Last 12 months', value: 'Last 12 months', days: 365}
];

const creativeFiltersData = [
  {
    id: 'video',
    value: 'Video'
  },
  {
    id: 'photo',
    value: 'Photo'
  },
  {
    id: 'carousel',
    value: 'Carousel'
  },
]

const StoreTrackerPageAds = (
  {
    id,
    isMobile,
    storeInnerPage,
    getAds,
    getCount,
    getFilters,
    userInfo
  }
) => {

  const { t } = useTranslation();
  const tableRef = useRef(null);
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState('most_recent');
  const [visibleModal, setVisibleModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [filtersValue, setFiltersValue] = useState({
    call_to_action: null,
    languages: null,
    website_providers: null,
    creative_filters: null,
    created_at: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    call_to_action: null,
    languages: null,
    website_providers: null,
    creative_filters: null,
    created_at: null,
  });
  const [tags, setTags] = useState([]);

  const tagsValue = {
    call_to_action: {
      title: 'Call to action',
    },
    languages: {
      title: 'Language',
    },
    website_providers: {
      title: 'Website provider',
    },
    creative_filters: {
      title: 'Creative type',
    },
    created_at: {
      title: 'Creation date',
    },
  };

  function handleCount(key = {}) {
    let data = {
      internal_shop_id: id,
      ad_created_at__gte: selectedFilters?.created_at?.min,
      ad_created_at__lte: selectedFilters?.created_at?.max,
      ad_copy_language: selectedFilters?.languages?.length ?
        [...selectedFilters?.languages?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.languages?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.languages]?.map(el => el?.id)
        :
        null,
      call_to_action: selectedFilters?.call_to_action?.length ?
        [...selectedFilters?.call_to_action?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.call_to_action?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.call_to_action]?.map(el => el?.id)
        :
        null,
      website_provider: selectedFilters?.website_provider?.length ?
        [...selectedFilters?.website_provider?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.website_provider?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.website_provider]?.map(el => el?.id)
        :
        null,
      creative_filters: selectedFilters?.creative_filters?.length ?
        [...selectedFilters?.creative_filters?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.creative_filters?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.creative_filters]?.map(el => el?.id)
        :
        null,
    };
    data[key?.id] = key?.value?.length ?
      [...key?.value?.filter(el => typeof el === 'string')]?.length ?
        [...key?.value?.filter(el => typeof el === 'string')]
        :
        [...key?.value]?.map(el => el?.id)
      :
      null;
    getCount({...data});
  }

  function applyFilters() {
    let data = {
      internal_shop_id: id,
      page: pageNumber,
      page_size: 20,
      ordering: sortOrder,
      ad_created_at__gte: selectedFilters?.created_at?.min,
      ad_created_at__lte: selectedFilters?.created_at?.max,
      ad_copy_language: selectedFilters?.languages?.length ?
        [...selectedFilters?.languages?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.languages?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.languages]?.map(el => el?.id)
        :
        null,
      call_to_action: selectedFilters?.call_to_action?.length ?
        [...selectedFilters?.call_to_action?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.call_to_action?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.call_to_action]?.map(el => el?.id)
        :
        null,
      website_provider: selectedFilters?.website_provider?.length ?
        [...selectedFilters?.website_provider?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.website_provider?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.website_provider]?.map(el => el?.id)
        :
        null,
      creative_filters: selectedFilters?.creative_filters?.length ?
        [...selectedFilters?.creative_filters?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.creative_filters?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.creative_filters]?.map(el => el?.id)
        :
        null,
    };
    getAds({...data});
  }

  function resetFilters() {
    setSelectedFilters({ ...storeInnerPage?.full?.ads?.filters, created_at: null, creative_filters: null, });
  }

  function resetFilterByKey(key) {
    key === 'created_at' ?
    setSelectedFilters( prev => ({...prev, [key]: null }))
      :
    setSelectedFilters( prev => ({...prev, [key]: storeInnerPage?.full?.ads?.filters?.[key] }))
  }

  function checkIfChanged() {
    let result = [];
    let currentFilters = storeInnerPage?.full?.ads?.filters;
    if (
      Object.keys(selectedFilters).some((el) => {
        if (el === 'created_at') {
          if (selectedFilters?.[el] !== null) result.push(el);
        } else {
          if ((selectedFilters?.[el] !== null || selectedFilters?.[el]?.length) && JSON.stringify(selectedFilters?.[el]) !== JSON.stringify(currentFilters?.[el])) result.push(el);
        }
      })
    ) {
      return setTags(result);
    } else {
      return setTags(result);
    }
  }

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && storeInnerPage?.full?.ads?.results?.length < storeInnerPage?.full?.ads?.count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    getFilters({ internal_shop_id: id });
  }, []);

  useEffect(() => {
    if (Object.keys(storeInnerPage?.full?.ads?.filters)?.length) {
      setFiltersValue({ ...storeInnerPage?.full?.ads?.filters, created_at: null, creative_filters: null, });
      setSelectedFilters({ ...storeInnerPage?.full?.ads?.filters, created_at: null, creative_filters: null, });
    }
  }, [storeInnerPage?.full?.ads?.filters]);

  useEffect(() => {
    checkIfChanged();
    !storeInnerPage?.full?.ads?.loading && Object.keys(storeInnerPage?.full?.ads?.filters)?.length && applyFilters();
  }, [selectedFilters]);

  useEffect(() => {
    const tableContent = tableRef.current || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, storeInnerPage?.full?.ads?.count, storeInnerPage?.full?.ads?.results?.length]);

  useEffect(() => {
    !storeInnerPage?.full?.ads?.loading && Object.keys(storeInnerPage?.full?.ads?.filters)?.length && applyFilters();
  }, [pageNumber, sortOrder]);

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-competitors">
      <div className="report-main-block">
        <span className={'report-main-block-title'}>
          <span className="title">
            {t('Facebook ads')}
          </span>
        </span>
      </div>
      <Spin spinning={storeInnerPage?.full?.ads?.filtersLoading}>
        <div className='store-tracker-page-competitors-options-wrapper'>
          <div className="store-tracker-page-competitors-filter-wrapper">
            <DropdownList title={t('Creative type')}
                          id={'creative_filters'}
                          value={selectedFilters}
                          setValue={setSelectedFilters}
                          data={[...creativeFiltersData]}
                          disabled={false}
                          tooltip={'Select creative type'}
                          isMobile={isMobile}
                          count={storeInnerPage?.full?.ads?.count || 0}
                          countLoading={storeInnerPage?.full?.ads?.countLoading}
                          handleCount={handleCount}
                          accessor={'id'}
                          searchPlaceholder={'Search for creative type...'}
            />
            <DropdownDateBlock innerLabel={'Creation date'}
                               id={'created_at'}
                               customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                               customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                               iconType={'database_calendar'}
                               data={dateOptions}
                               dropdownFilters={selectedFilters}
                               setDropdownFilters={setSelectedFilters}
                               hideTimeZone={true}
                               visibleModal={visibleModal}
                               setVisibleModal={setVisibleModal}
                               isMobile={isMobile}
                               withTitle={true}
                               position={'bottomLeft'}
            />
            <DropdownList title={t('Language')}
                          id={'languages'}
                          value={selectedFilters}
                          setValue={setSelectedFilters}
                          data={filtersValue?.languages?.length ? [...filtersValue?.languages] : []}
                          disabled={false}
                          tooltip={'Select language'}
                          isMobile={isMobile}
                          count={storeInnerPage?.full?.ads?.count || 0}
                          countLoading={storeInnerPage?.full?.ads?.countLoading}
                          handleCount={handleCount}
                          accessor={'id'}
            />
            <DropdownList title={t('Call to action')}
                          id={'call_to_action'}
                          value={selectedFilters}
                          setValue={setSelectedFilters}
                          data={filtersValue?.call_to_action?.length ? [...filtersValue?.call_to_action] : []}
                          disabled={false}
                          tooltip={'Select call to action'}
                          isMobile={isMobile}
                          count={storeInnerPage?.full?.ads?.count || 0}
                          countLoading={storeInnerPage?.full?.ads?.countLoading}
                          handleCount={handleCount}
                          accessor={'id'}
                          searchPlaceholder={'Search for call to action...'}
            />
            <DropdownList title={t('Website provider')}
                          id={'website_providers'}
                          value={selectedFilters}
                          setValue={setSelectedFilters}
                          data={filtersValue?.website_providers?.length ? [...filtersValue?.website_providers] : []}
                          disabled={false}
                          tooltip={'Select website provider'}
                          isMobile={isMobile}
                          count={storeInnerPage?.full?.ads?.count || 0}
                          countLoading={storeInnerPage?.full?.ads?.countLoading}
                          handleCount={handleCount}
                          accessor={'id'}
                          searchPlaceholder={'Search for website provider...'}
            />
          </div>
          <CascadingDropdown
            value={sortOrder}
            setValue={setSortOrder}
            isMobile={isMobile}
            disabled={false}
            isTrial={isTrial}
            setModal={setModal}
            setVisible={() => null}
          />
        </div>
        {
          tags?.length && Object.keys(storeInnerPage?.full?.ads?.filters)?.length ?
            <div className='store-tracker-page-competitors-tags-wrapper'>
              <div className='store-tracker-page-competitors-tag'
                   onClick={resetFilters}
              >
                <Icon role={'icon'} type="clear" />
                <span className='main'>
                  {t('Clear all')}
                </span>
              </div>
              <Divider type={'vertical'} style={{ height: 16 }} />
              {
                tags.map((tag) => (
                  <div className='store-tracker-page-competitors-tag'
                       onClick={() => resetFilterByKey(tag)}
                  >
                    {t(tagsValue?.[tag]?.title)}:
                    <span className={cls('', { 'main': tag !== 'created_at' && selectedFilters?.[tag]?.length > 1 } )}>
                      {` ${tag !== 'created_at' ?
                        selectedFilters?.[tag]?.length > 1 ? 'Mixed values' : selectedFilters?.[tag]?.[0]?.id
                        :
                        `${selectedFilters?.[tag]?.min || storeInnerPage?.full?.competitors?.filters?.[tag]?.min} - ${selectedFilters?.[tag]?.max || storeInnerPage?.full?.competitors?.filters?.[tag]?.max}` }`}
                    </span>
                    <Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />
                  </div>
                ))
              }
            </div>
            :
            null
        }
      </Spin>
      <Spin spinning={storeInnerPage?.full?.ads?.loading}>
        {
          storeInnerPage?.full?.ads?.results?.length ?
            <div className="product-database-table-body store-tracker-page-sales-table-wrapper cards"
                 id={'product-database-table-body'}
                 ref={tableRef}
            >
                {
                  storeInnerPage?.full?.ads?.results?.map((el) => (
                    <AdItem data={{ ...el }}
                            key={el?.id}
                            isMobile={isMobile}
                            isAlive={el?.status}
                            disabledNavigate={false}
                    />
                  ))
                }
              </div>
            :
            <StoreTrackerPageOverviewEmpty tags={tags}
                                           type={'facebook_ads'}
                                           clearFilters={resetFilters}
            />
        }
      </Spin>
      <Modal
        className="change-modal custom-modal"
        {...(isMobile ? { transitionName: '' } : null)}
        getContainer={() => document.getElementById('global-wrap')}
        open={modal}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
        }
        width={450}
        footer={null}
        closable="true"
        onCancel={() => setModal(false)}
        destroyOnClose
      >
        <CascadingModal value={sortOrder} setValue={setSortOrder} setVisible={setModal} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  getAds: (data) => dispatch(Creators.getStoreAdsRequest(data)),
  getCount: (data) => dispatch(Creators.getStoreAdsCountRequest(data)),
  getFilters: (data) => dispatch(Creators.getStoreAdsFiltersRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageAds);
