import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import cls from 'classname';
import Creators from './reducer';
import Icon from '../../Icon';
import FiltersBlock from './components/FiltersBlock';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import DatabaseProducts from './components/DatabaseProducts';
import { openNotificationWithIcon } from '../../Components/Notification/index';
import { DefaultMsgSaga } from '../../Components/Notification/notification-message';
import BannerLimited from '../../Components/Banners/BannerLimited';
import ReactPlayer from "react-player";
import { Modal } from "antd";
import './styles.less';
import DatabaseProductsCards from './components/DatabaseProductsCards';
import EmptyTable from '../ShopifySearch/components/EmptyTable';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';

export const ProductDatabasePage = (
  {
    userInfo,
    isMobile,
    isAdmin,
    error,
    theme,
    activeSubscriptions,
    productDatabase,
    ...props
  }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //state to display skeleton on render
  const [firsLoading, setFirsLoading] = useState(true);
  //state check if filters were changed
  const [enabled, setEnabled] = useState(true);
  const [isChanged, setIsChanged] = useState(true);
  //state to toggle banner/table
  const [showTable, setShowTable] = useState(false);
  const [view, setView] = useState('details');
  const [productsAdd, setProductsAdd] = useState(false);
  //show modal
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const currentScroll = useRef(null);
  const firstLoading = useRef(true);
  const rerenderKey = useRef(new Date().getTime());

  //just deleted filter preset to call undo
  const deleteResult = useSelector(store => store['productDatabase'].deleteResult);
  const tableData = useSelector(store => store['productDatabase'].products.results) || [];
  const { fetching, productsLoading, loading, filtersLoading, attemptsLeft } = productDatabase;
  const chartLoading = productDatabase.chartLoading;

  const plainOptions = useSelector(store => store.productDatabase.filters.categories) || [];
  const isVisibleLimitRequest = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [loadedPreset, setLoadedPreset] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [switchFilters, setSwitchFilters] = useState({
    exclude_top_brands: false,
    exclude_unavailable: true,
    only_dropshipping: false,
    only_print: false
  });
  const [inputFilters, setInputFilters] = useState({
    title: { include: null, exclude: null },
    description: { include: null, exclude: null },
    domain: { include: null, exclude: null },
  });
  const [minMaxFilters, setMinMaxFilters] = useState({
    price: { min: null, max: null },
    sales: { min: null, max: null },
    revenue: { min: null, max: null },
    products: { min: null, max: null },
    images: { min: null, max: null },
    variants: { min: null, max: null },
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    product_created_at: { min: null, max: null, id: null },
    store_created_at: { min: null, max: null, id: null },
    language: 'All',
    currency: 'All',
    domain_tld: 'All',
  });
  const [inputText, setInputText] = useState('');

  //show notification after delete to undo
  useEffect(() => {
    if (deleteResult) {
      openNotificationWithIcon({
        deleteResult,
        className: 'notification preset-delete-notification',
        style: { minWidth: '716px' },
        message: (
          <DefaultMsgSaga
            text={
              <span className="preset-notification-block">
                Preset successfully removed from your list.
              </span>
            }
            title={t('Preset was successfully removed')}
            iconOutline={true}
            icon="trash_paper"
            preset={deleteResult.name}
            withTranslate={true}
          />
        ),
      });
      setTimeout(() => dispatch(Creators.resetDeleteResult()), 1);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [dispatch, deleteResult]);

  useEffect(() => {
    document.title = `Product Database - Dropship`;
    if (firsLoading) setTimeout(() => setFirsLoading(false), 1);
    props.getFilters();
    props.getPresets('');
    props.getShopifyStore();
    props.getAutoStore();
    dispatch(Creators.resetCreateError());
    dispatch(Creators.resetDeleteResult());
    props.resetProducts();

    return () => {
      props.setCompetitor(false);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if(isMobile) setVisibleVideo(false)
  }, [isMobile])

  useEffect(() => {
    if (showTable && !isChanged) setEnabled(false);
  }, [showTable, isChanged]);

  const handleChangePageSize = useCallback(value =>
      setPageSize(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);
  const handleChangePageNumber = useCallback(value =>
      setPageNumber(value),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);
  const handleSetCompetitor = useCallback(data =>
      props.setCompetitor(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [])
  const handleGetProductChart = useCallback(data =>
      props.getProductChart(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    [])

  const handleSort = useCallback(({ order, columnKey }) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const resetFilters = () => {
    rerenderKey.current = new Date().getTime();
    Object.keys(switchFilters).forEach((key) => {
      if (key === 'exclude_unavailable') setSwitchFilters(state => ({...state, [key]: true}));
      else setSwitchFilters(state => ({...state, [key]: false}));
    });
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({ ...state, [key]: { include: null, exclude: null } })));
    Object.keys(minMaxFilters).forEach((key) => setMinMaxFilters(state => ({ ...state, [key]: { min: null, max: null } })));
    setCheckedList(plainOptions.map(el => el.id));
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'product_created_at' || key === 'store_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({ ...state, [key]: 'All' }));
    });
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setLoadedPreset(null);
    setInputText('');
  };

  const headerSkeleton = () => isMobile ? (
    <div className="sales-tracker-top-page-header">
      <div className="sales-tracker-top-page-subheader">
        <h3 className="sales-tracker-top-page-title">
          <span className={'link skeleton'} style={{width: 160, height: 20}}/>
        </h3>
      </div>
    </div>
  ) : (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <span className={'link skeleton'} style={{width: 40, height: 40}}/>
        <div className="sales-tracker-top-page-subheader">
          <span className={'link skeleton'} style={{width: 241, height: 16}}/>
          <span className={'link skeleton'} style={{width: 337, height: 16, marginTop: 4}}/>
        </div>
      </div>
      <div className={'sales-tracker-top-page-limits-wrapper'}>
        <span className={'link skeleton'} style={{width: 137, height: 40}}/>
        <span className={'link skeleton'} style={{width: 168, height: 40}}/>
      </div>
    </div>
  )


  return (
    //custom scrollbars
    <div className={cls("product-database-page productDatabase-page", {
      'product-database-page-no_scroll': visibleVideo
    })}>
      <TopPageHeader 
        icon={'product_database_dashboard'}
        title={'Product Database'}
        subtitle={'Search millions of products and see their revenue'}
        takeTourHandler={() => window.Intercom('startTour', 433081)}
        watchTutorialHandler={() => setVisibleVideo(true)}
        skeleton={firsLoading || filtersLoading}
        isMobile={isMobile}
        modalText={'Take a tour through Competitor research to understand how it works better or watch a quick tutorial.'}
        modalTitle={'Competitors Research'}
        withButton
      />
      {isTrial || isVisibleLimitRequest ? (
        <BannerLimited 
          skeleton={firsLoading || filtersLoading}
          className={cls({ 'limited-banner_error': attemptsLeft === 0 })}
          text={`${t('Daily Searches Remaining')}: ${attemptsLeft}`}
          btnText={t('Upgrade For Unlimited')}
          onClick={() => navigate('/setting/plan')}
          isTrial={isTrial}
        />
      ) : null}
      <FiltersBlock
        skeleton={firsLoading || filtersLoading}
        isMobile={isMobile}
        theme={theme}
        productsLoading={productsLoading}
        fetching={firsLoading || loading || filtersLoading}
        savePreset={props.savePreset}
        deletePreset={props.deletePreset}
        updatePreset={props.updatePreset}
        getProducts={props.getProducts}
        setShowTable={setShowTable}
        showTable={showTable}
        setView={setView}
        view={view}
        pageSize={pageSize}
        setPageSize={handleChangePageSize}
        pageNumber={pageNumber}
        setPageNumber={handleChangePageNumber}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isChanged={(isTrial || isVisibleLimitRequest) ? (attemptsLeft >= 0 && isChanged) : isChanged}
        disabledSearch={((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0])}
        setIsChanged={setIsChanged}
        setEnabled={setEnabled}
        currentScroll={currentScroll}
        getPresets={props.getPresets}
        setProductsAdd={setProductsAdd}
        productsAdd={productsAdd}
        resetProducts={props.resetProducts}
        rerenderKey={rerenderKey}
        resetFilters={resetFilters}
        firstLoading={firstLoading}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        switchFilters={switchFilters}
        setSwitchFilters={setSwitchFilters}
        inputFilters={inputFilters}
        setInputFilters={setInputFilters}
        minMaxFilters={minMaxFilters}
        setMinMaxFilters={setMinMaxFilters}
        dropdownFilters={dropdownFilters}
        setDropdownFilters={setDropdownFilters}
        loadedPreset={loadedPreset}
        setLoadedPreset={setLoadedPreset}
        inputText={inputText}
        setInputText={setInputText}
      />

      {showTable ?
        //condition toggle table/empty table
        !tableData.length && !fetching && !productsLoading ?
          <div className={cls('product-database-table-wrapper', {
            'product-database-table-wrapper-empty': !tableData?.length,
          })}>
            <EmptyTable resetFilters={() => resetFilters()}/>
          </div>
          :
          view === 'details' ?
          <DatabaseProducts fetching={fetching || productsLoading}
                            loading={loading}
                            theme={theme}
                            onSort={handleSort}
                            pageSize={pageSize}
                            setPageSize={handleChangePageSize}
                            pageNumber={pageNumber}
                            setPageNumber={handleChangePageNumber}
                            data={tableData}
                            sortOrder={sortOrder}
                            isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                            enabled={enabled}
                            setCompetitor={handleSetCompetitor}
                            getChart={handleGetProductChart}
                            chartLoading={chartLoading}
                            currentScroll={currentScroll}
                            daysFromLaunch={productDatabase?.products?.['days_from_launch']}
                            isTrial={isTrial}
                            isMobile={isMobile}
                            view={view}
          />
        :
        <DatabaseProductsCards loading={loading}
                               fetching={fetching || productsLoading}
                               data={tableData}
                               sortOrder={sortOrder}
                               pageSize={pageSize}
                               setPageNumber={setPageNumber}
                               isTrial={isTrial}
                               isMobile={isMobile}
                               isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                               enabled={enabled}
                               isProductDatabaseCard={true}
                               setCompetitor={handleSetCompetitor}
                               view={view}
        />
        :
        null
      }

      <Modal
        className="change-modal custom-modal video-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? {transitionName: ''} : null)}
        open={visibleVideo}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#225aea" opacity={1}/>
        }
        footer={null}
        closable="true"
        onCancel={() => setVisibleVideo(false)}
        destroyOnClose
      >
        <ReactPlayer controls={true}
                     width={'100%'}
                     height={'100%'}
                     playing={true}
                     url={'https://www.youtube.com/watch?v=OftbjjbL0tc'}
        />
      </Modal>
    </div>
  );
};

ProductDatabasePage.defaultProps = {};

ProductDatabasePage.propTypes = {
  isAdmin: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  productDatabase: state.productDatabase,
});

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => dispatch(Creators.getProductDatabaseFiltersRequest()),
  getPresets: (data) => dispatch(Creators.getProductDatabasePresetsRequest(data)),
  savePreset: (data) => dispatch(Creators.createProductDatabasePresetRequest(data)),
  deletePreset: (id) => dispatch(Creators.deleteProductDatabasePresetRequest(id)),
  updatePreset: (data) => dispatch(Creators.updateProductDatabasePresetRequest(data)),
  getProducts: (data) => dispatch(Creators.getProductsDatabaseRequest(data)),
  resetProducts: () => dispatch(Creators.resetProductsDatabase()),
  getProductChart: (data) => dispatch(Creators.getProductChartRequest(data)),
  setCompetitor: (data) => dispatch(Creators.setCompetitorResearch(data)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDatabasePage);
