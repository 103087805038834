import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Icon from '../../../../Icon';
import './AccessLimited.less';

const AccessLimited = ({ view, isTrial, isBasicPlan, isStandardPlan, isPremiumPlan }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getDescription = () => {
    if (view === 'stores') {
      if (isTrial) {
        if (isBasicPlan) return t('Upgrade to Standard or Premium plan to unlock Top Stores');
        if (isStandardPlan || isPremiumPlan) return t('Skip trial and pay to unlock Top Stores');
      }
      return t('Upgrade to Standard or Premium plan to unlock Top Stores');
    }
    if (view === 'products') {
      if (isTrial) {
        if (isBasicPlan || isStandardPlan) return t('Upgrade to Premium plan to unlock Top Products');
        if (isPremiumPlan) return t('Skip trial and pay to unlock Top Products');
      }
      return 'Upgrade to Premium plan to unlock Top Products';
    }
  };

  return (
    <div className="access-limited-body">
      <div className="empty-image-table">
        <Icon role="icon" type="lock_table_item" width={40} height={40} />
      </div>
      <div className="access-limited-title">{t('Access limited')}</div>
      <div className="access-limited-description">{getDescription()}</div>
      <div className={'redirect-btn'} onClick={() => navigate('/setting/plan')}>
        {t('Upgrade Plan')}
      </div>
    </div>
  );
};

export default AccessLimited;
