import React from 'react';
import { Divider, Spin } from 'antd';
import Icon from '../../../Icon';
import AddCompetitorsModalContent from './Competitors/AddCompetitorsModalContent';
import BDModal from '../components/BenefitsDrawbacks/BDModal';

export function DeleteModal() {
  return (
    <>
      <p key="text" style={{ margin: 0 }}>
        Are you sure you want to delete this?
      </p>
      <Divider style={{ margin: '24px 0 0' }} />
    </>
  );
}

export const modalData = (
  modal,
  addLinks,
  handleChangeLink,
  bdModalData,
  spinning,
) => {
  if (
    modal === 'product_delete' ||
    modal === 'image_delete' ||
    modal === 'competitors_delete' ||
    modal === 'suppliers_delete' ||
    modal === 'bd_delete'
  ) {
    return (
      <Spin size="large" spinning={spinning}>
        <p key="text" style={{ margin: 0 }}>
          Are you sure you want to delete this?
        </p>
        <Divider style={{ margin: '24px 0 0' }} />
      </Spin>
    );
  }
  if (modal === 'competitors_add') {
    return (
      <Spin size="large" spinning={spinning}>
        <AddCompetitorsModalContent value={addLinks} onChange={handleChangeLink} />
      </Spin>
    );
  }
  if (modal === 'bd_import') {
    return <BDModal {...bdModalData} fetching={spinning} />;
  }
};

export const modalBtnCancel = {
  product_delete: 'Back',
  image_delete: 'Back',
  bd_delete: 'Back',
  bd_import: 'Cancel',
  competitors_add: 'Cancel',
  competitors_delete: 'Back',
  suppliers_delete: 'Back',
};

export const modalTitle = {
  product_delete: 'Are you sure?',
  image_delete: 'Are you sure?',
  bd_delete: 'Are you sure?',
  bd_import: 'Benefits & Drawbacks',
  competitors_add: 'Add Competitors',
  competitors_delete: 'Are you sure?',
  suppliers_delete: 'Are you sure?',
};

export const modalBtnOk = (modal, addLinks) => {
  if (
    modal === 'product_delete' ||
    modal === 'image_delete' ||
    modal === 'competitors_delete' ||
    modal === 'suppliers_delete' ||
    modal === 'bd_delete'
  ) {
    return 'Delete';
  }
  if (modal === 'bd_import') {
    return 'Import';
  }
  if (modal === 'competitors_add') {
    return (
      <>
        <Icon
          role="icon"
          type="arrow_add"
          color={!addLinks ? '#6E7DAE' : null}
          style={{ marginRight: 8 }}
        />{' '}
        Add
      </>
    );
  }
};
