import React, { useEffect, useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utils from '../../../../Utils/utils';
import Image from '../../../../Components/Image';
import Images from '../../../../Images';
import Icon from '../../../../Icon';
import { Divider } from 'antd';
import AppUtils from '../../../../Utils/utils';
import Chart from '../../../../Components/Charts/Chart';
import './PriceHistory.less';
import SalesTrackerCreators from '../../reducer';

const PriceHistory = ({ product, salesTopProducts }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector((store) => store?.nav?.isMobile);
  const loading = useSelector(
    (store) => store?.salesTracker?.productPriceLoading,
  );
  const data =
    useSelector((store) => store?.salesTracker?.productPriceData) ||
    [];
  const [checkedList, setCheckedList] = useState({ id: 0, name: 'All Time' });
  const filterData = [
    { id: 0, name: 'All Time' },
    { id: 1, name: 'Last 7 days' },
    { id: 2, name: 'Last 30 days' },
  ];

  const fetchPriceHistory = () => {
    let dateParams = {};
    if (checkedList.id === 1) {
      dateParams.date_gte = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      dateParams.date_lte = dayjs().format('YYYY-MM-DD');
    } else if (checkedList.id === 2) {
      dateParams.date_gte = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
      dateParams.date_lte = dayjs().format('YYYY-MM-DD');
    }

    dispatch(
      SalesTrackerCreators.getProductPriceHistorySalesTrackerRequest({
        internal_shop_id: product?.store?.id,
        product_id: product?.id,
        ...dateParams,
      }),
    );
  };

  useEffect(() => {
    fetchPriceHistory();
    return () => {
      dispatch(SalesTrackerCreators.resetProductPriceSalesTracker());
    };
  }, [checkedList]);

  return (
    <div className={'load-filter-preset-wrapper price-modal-wrapper'}>
      <div className="modal-filter-preset-title">{t('Price Dynamics')}</div>
      <div className="modal-filter-preset-description">
        {t(`This chart displays the listing's historical price changes.`)}
      </div>
      <Divider type={'horizontal'} style={{ margin: '24px 0' }} />
      <div className={'price-modal-info'}>
        <div className={'price-modal-info-logo'}>
          {product?.main_image ? (
            <img
              src={product?.main_image}
              alt=""
              onError={utils.addDefaultSrc}
              width="62"
              height="62"
            />
          ) : (
            <Image src={Images.defaultImg} small={true} />
          )}
        </div>
        <div className="price-modal-info-wrapper">
          <div className="price-modal-info-title">{product?.title}</div>
          {salesTopProducts && (
            <div className="price-modal-info-additional-info">
              <div className="price-modal-info-link">{product.link}</div>
              <div className="vertical-divider-small" />
              <div className="price-modal-info-item"><span>{product.images}</span><span>{t('images')}</span></div>
              <div className="vertical-divider-small" />
              <div className="price-modal-info-item"><span>{product.variants}</span><span>{t('variants')}</span></div>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className={'price-modal-chart-wrapper'}>
          <div className={'price-modal-period'}>
            <span
              className="link skeleton"
              style={{ width: 192, height: 20, display: 'block' }}
            />
            <span
              className="link skeleton"
              style={{ width: 238, height: 20, display: 'block' }}
            />
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 6,
                }}
              >
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
              </div>
              <div style={{ width: 'calc(100% - 39px)', height: 214 }}>
                <span
                  className="link skeleton"
                  style={{ width: '100%', height: '100%', display: 'block' }}
                />
              </div>
            </div>
            <div
              style={{
                width: 'calc(100% - 39px)',
                marginLeft: 39,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
                <span
                  key={el * index}
                  className="link skeleton"
                  style={{ width: el, height: 16 }}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={'price-modal-chart-wrapper'}>
          <div className="price-modal-chart-main-wrapper">
            {!salesTopProducts ? (
              <div className={'price-modal-period'}>
                <p>
                  {dayjs(data?.[0]?.date).format('MMM DD, YYYY')} -{' '}
                  {dayjs(data?.[data?.length - 1]?.date).format('MMM DD, YYYY')}
                </p>
                <p>
                  <span style={{ marginRight: 8 }}>
                    {t('Updated')}{' '}
                    {AppUtils.durationAsString(
                      new Date().toISOString(),
                      data?.last_updated,
                      t,
                    )}
                  </span>
                  <Icon
                    type={'attention_outline'}
                    role={'button'}
                    width={16}
                    height={16}
                    tooltipProps={{
                      overlayClassName: 'expandable-chart-info-tooltip-wrapper',
                      placement: 'top',
                      title: () => (
                        <div className={'expandable-chart-info-tooltip'}>
                          <div
                            className={'expandable-chart-info-tooltip-title'}
                          >
                            {t(
                              'Dashboard data syncs every 2-3 hours. However, delays may occur when high volumes are being processed.',
                            )}
                          </div>
                          <Divider style={{ margin: '12px 0' }} />
                          <div className={'expandable-chart-info-tooltip-text'}>
                            <p>{t('Last updated on')}</p>
                            <p>
                              {dayjs(data?.last_updated).format(
                                'lll',
                              )}
                              UTC
                            </p>
                          </div>
                        </div>
                      ),
                      destroyTooltipOnHide: true,
                      getPopupContainer: (trigger) => trigger.parentNode,
                    }}
                  />
                </p>
              </div>
            ) : (
              <div className="price-modal-chart-top-block">
                <div className="price-modal-chart-price-block">
                  <div className="price-modal-chart-price">{t('Price')}</div>
                  <div className="price-modal-chart-second-block">
                    <div className="price-modal-chart-price-value">
                      {`$`}
                      {product.price}
                    </div>
                    <div className="vertical-divider-small" />
                    <div className="price-modal-chart-last-updated">
                    <Icon
                    type={'double_arrows'}
                    width={16}
                    height={16}/>
                      {t('Updated')}{' '}
                      {AppUtils.durationAsString(
                        new Date().toISOString(),
                        data?.last_updated,
                        t,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="main-filter-options">
              {filterData.map((el) => (
                <span
                  key={el.id}
                  onClick={() => setCheckedList(el)}
                  className={cls('filter-option', {
                    selected: checkedList.id === el.id,
                  })}
                >
                  {el.name}
                </span>
              ))}
            </div>
          </div>
          <Chart
            type={'price_dynamics'}
            data={data.chart?.length ? [...data?.chart] : []}
            loading={false}
            isMobile={isMobile}
          />
        </div>
      )}
    </div>
  );
};

export default PriceHistory;
