import React, {useEffect, useRef, useState} from 'react';
import cls from "classname";
import acc from "accounting";
import ArrowSelectDown from "../../../../Icon/img/ArrowSelectDown";
import { Divider, Dropdown, Spin, Tooltip } from 'antd';
import ButtonComponent from "../../../../Components/Button";
import {useTranslation} from "react-i18next";
import Icon from "../../../../Icon";
import InputComponent from "../../../../Components/Input";
import Modal from '../../../../Components/Modal';


const DropdownList = (
  {
    title,
    id,
    value,
    setValue,
    data,
    disabled,
    isStandard=false,
    count,
    handleCount,
    countLoading = false,
    accessor='code',
    isMobile=false,
    tooltip,
    searchPlaceholder = 'Search for language...'
  }) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [search, setSearch] = useState('');
  const localData = useRef([]);

  useEffect(() => {
    if (!value[id] || JSON.stringify(value?.[id]) === JSON.stringify(data)) {
      setCheckedList(data);
      setCheckAll(true);
      localData.current = [...data];
    } else {
      setCheckAll(false);
      setCheckedList([...value[id]]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible, modal]);

  useEffect(() => {
    if (visible || modal) {
        handleCount({
          id: id,
          value: checkedList,
        });
    }
  }, [visible, modal, checkedList]);

  useEffect(() => {
    localData.current = [...data];
  }, [data])

  useEffect(() => {
    if (checkedList.length === data.length) {
      localData.current = [...data];
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkedList])

  const handleVisibleChange = (value) => {
    setVisible(value);
    setSearch('');
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  //checkbox click handle
  const clickHandler = (val, e) => {
    e.target.classList.toggle('dropdown-database-list-item--active');
    if (checkedList.some(el => el?.[accessor] === val?.[accessor])) {
      let res = [...checkedList.filter(el => el?.[accessor] !== val?.[accessor])]
      setCheckedList(res)
      setCheckAll(res.length === data.length);
    } else {
      let res = [...checkedList, val]
      setCheckedList(res)
      setCheckAll(res.length === data.length);
    }
  }

  //main checkbox handler
  const allClickHandler = e => {
    let checked;
    if (e.target.classList.contains('dropdown-database-list-item-checkbox')) {
      e.target.classList.toggle('dropdown-database-list-item-checkbox--active');
      checked = e.target.classList.contains('dropdown-database-list-item-checkbox--active')
    } else {
      e.target.classList.toggle('dropdown-database-list-item--active')
      checked = e.target.classList.contains('dropdown-database-list-item--active')
    }
    setCheckedList(checked ? [...data] : []);
    setCheckAll(checked);
    localData.current = [...data];
  }

  return (
    isMobile ?
      <>
        <div className='main-filter-dropdown'
             onClick={() => setModal(true)}>
          {title}
          <ArrowSelectDown/>
        </div>
        <Modal handleOk={null}
               handleClose={() => {
                 setModal(false);
               }}
               title={t(tooltip)}
               isMobile={isMobile}
               width={450}
               className="collection-list-modal collection-modal products-filter-modal"
               open={modal}
               destroyOnClose
        >
          <Spin spinning={countLoading}>
            <div className={'collection-modal-content'}>
              <div className="dropdown-database-menu">
                <InputComponent
                  className={'dropdown-search'}
                  prefix={<Icon type="database_search" role="icon"/>}
                  placeholder={t(searchPlaceholder)}
                  value={search}
                  onChange={handleChangeSearch}
                />
                <ul className="dropdown-database-list"
                    style={{ maxHeight: 304, overflowY: 'auto' }}
                >
                  {
                    search ?
                      null
                      :
                      (
                        <div className={cls("dropdown-database-list-item  test-item", {
                          'dropdown-database-list-item--active': checkAll
                        })}
                             onClick={allClickHandler}
                        >
                          <div className={cls('dropdown-database-list-item-checkbox', {
                            'dropdown-database-list-item-checkbox--active': checkAll
                          })}/>
                          All
                        </div>
                      )
                  }
                  {
                    !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                      ?
                      localData.current.map(el => (
                        <div key={el?.id}
                             onClick={(e) => clickHandler(el, e)}
                             className={cls('dropdown-database-list-item  test-item', {
                               'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                               'dropdown-database-list-item--active': checkedList.some(elem => el?.[accessor] === elem?.[accessor]) || checkAll,
                             })}>

                          <div className={cls('dropdown-database-list-item-checkbox', {
                            'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el?.[accessor] === elem?.[accessor]) || checkAll,
                          })} />

                          {
                            el?.value
                          }
                        </div>
                      ))
                      :
                      <div className={'dropdown-database-list empty'}>
                        <div className="dropdown-database-list-item"
                             style={{ display: 'flex', alignItems: 'center' }}>
                      <span className={'link skeleton'}
                            style={{ width: 20, height: 20 }}
                      />
                          <span className={'link skeleton'}
                                style={{ width: 120, height: 20, marginLeft: 8 }}
                          />
                        </div>
                        <div className="empty-state_wrapper-select">
                          {t('No options found')}
                        </div>
                        <p className={"empty-state_wrapper-text"}>
                          {t('Try changing your search query or try again.')}
                        </p>
                      </div>
                  }
                </ul>
                {
                  !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length ?
                    <Divider style={{margin: '7px auto'}} type={"horizontal"} />
                    :
                    null
                }
                {
                  !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length ?
                    <div className="main-filter-dropdown-content-button-wrapper">
                      {
                        disabled ?
                          <Tooltip placement='top'
                                   title={isStandard ? 'To preform this action, you would need to upgrade to Premium plan.' : 'To perform this action, you would need to upgrade to Standard or Premium plan.'}
                                   overlayClassName={'subscription-tooltip scheduled skip'}
                                   arrow={true}
                                   trigger={'hover'}
                                   getPopupContainer={(trigger) => trigger.parentNode}
                                   destroyTooltipOnHide={true}
                          >
                            <ButtonComponent text={t('Apply Filter')}
                                             className={cls('button-apply', {
                                               'button-apply-disabled': disabled
                                             })}
                                             onClick={() => null}
                            />
                          </Tooltip>
                          :
                          <ButtonComponent text={t(`Show _name_ results`, {name: acc.formatNumber(count, 0, ',', '.')})}
                                           className={cls('button-apply', {
                                             'button-apply-disabled': disabled
                                           })}
                                           onClick={() => {
                                             setVisible(false);
                                             setValue(state => ({...state, [id]: checkedList?.length ? checkedList?.length === data?.length ? null : checkedList : null}))
                                           }}
                                           disabled={false}
                          />
                      }
                    </div>
                    :
                    null
                }
              </div>
            </div>
            <div style={{height: 16}} />
          </Spin>
        </Modal>
      </>
      :
      <div className={cls('main-filter-dropdown')} style={{position: "relative"}}>
      <Dropdown
        overlayClassName={'main-filter-dropdown-content-wrapper languages-dropdown-content-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottom'}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
            <Spin spinning={countLoading}>
              <div>
                <InputComponent
                  className={'dropdown-search'}
                  prefix={<Icon type="database_search" role="icon"/>}
                  placeholder={t(searchPlaceholder)}
                  value={search}
                  onChange={handleChangeSearch}
                />
                <ul className="dropdown-database-list">
                  {
                    search ?
                      null
                      :
                      (
                        <div className={cls("dropdown-database-list-item  test-item", {
                          'dropdown-database-list-item--active': checkAll
                        })}
                             onClick={allClickHandler}
                        >
                          <div className={cls('dropdown-database-list-item-checkbox', {
                            'dropdown-database-list-item-checkbox--active': checkAll
                          })}/>
                          All
                        </div>
                      )
                  }
                  {
                    !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                      ?
                      localData.current.map(el => (
                        <div key={el?.id}
                             onClick={(e) => clickHandler(el, e)}
                             className={cls('dropdown-database-list-item  test-item', {
                               'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                               'dropdown-database-list-item--active': checkedList.some(elem => el?.[accessor] === elem?.[accessor]) || checkAll,
                             })}>

                          <div className={cls('dropdown-database-list-item-checkbox', {
                            'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el?.[accessor] === elem?.[accessor]) || checkAll,
                          })} />

                          {
                            el?.value
                          }
                        </div>
                      ))
                      :
                      <div className={'dropdown-database-list empty'}>
                        <div className="dropdown-database-list-item"
                             style={{ display: 'flex', alignItems: 'center' }}>
                      <span className={'link skeleton'}
                            style={{ width: 20, height: 20 }}
                      />
                          <span className={'link skeleton'}
                                style={{ width: 120, height: 20, marginLeft: 8 }}
                          />
                        </div>
                        <div className="empty-state_wrapper-select">
                          {t('No options found')}
                        </div>
                        <p className={"empty-state_wrapper-text"}>
                          {t('Try changing your search query or try again.')}
                        </p>
                      </div>
                  }
                </ul>
                {
                  !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length ?
                    <Divider style={{margin: '7px auto'}} type={"horizontal"} />
                    :
                    null
                }
                {
                  !search || data.filter(el => [el?.name, el?.value, el?.[accessor]].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length ?
                    <div className="main-filter-dropdown-content-button-wrapper">
                      {
                        disabled ?
                          <Tooltip placement='top'
                                   title={isStandard ? 'To preform this action, you would need to upgrade to Premium plan.' : 'To perform this action, you would need to upgrade to Standard or Premium plan.'}
                                   overlayClassName={'subscription-tooltip scheduled skip'}
                                   arrow={true}
                                   trigger={'hover'}
                                   getPopupContainer={(trigger) => trigger.parentNode}
                                   destroyTooltipOnHide={true}
                          >
                            <ButtonComponent text={t('Apply Filter')}
                                             className={cls('button-apply', {
                                               'button-apply-disabled': disabled
                                             })}
                                             onClick={() => null}
                            />
                          </Tooltip>
                          :
                          <ButtonComponent text={t(`Show _name_ results`, {name: acc.formatNumber(count, 0, ',', '.')})}
                                           className={cls('button-apply', {
                                             'button-apply-disabled': disabled
                                           })}
                                           onClick={() => {
                                             setVisible(false);
                                             setValue(state => ({...state, [id]: checkedList?.length ? checkedList?.length === data?.length ? null : checkedList : null}))
                                           }}
                                           disabled={false}
                          />
                      }
                    </div>
                    :
                    null
                }
              </div>
            </Spin>
        )}
      >
        <div className='main-filter-dropdown-inner'>
          {title}
          <ArrowSelectDown/>
        </div>
      </Dropdown>

    </div>
  );
}

export default DropdownList;
