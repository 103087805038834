import React, {useState} from 'react';
import cls from "classname";
import {Collapse} from "antd";
import Icon from "../../../Icon";
const {Panel} = Collapse;

const TooltipColumnIcon = ({record, isST = false}) => {

  const [openTooltipText, setOpenTooltipText] = useState(false);

  return (
    <Icon type={'attention_outline'}
          role={'button'}
          width={13}
          height={13}
          tooltipProps={{
            placement: 'right',
            trigger: 'hover',
            arrow: true,
            destroyTooltipOnHide: true,
            overlayClassName: cls('details-tooltip', 'domains-tooltip'),
            onOpenChange: (isOpened) => !isOpened && setOpenTooltipText(false),
            getPopupContainer: (trigger) => trigger.parentNode,
          }}
          titleText={() => {
            if ((isST ? record?.all_domains : record?.store?.all_domains) !== null) {
              if (isST ? record?.all_domains?.length <= 6 : record?.store?.all_domains?.length <= 6) {
                return isST ?
                  record?.all_domains.map(el => (
                      <p key={el}>
                        {el}
                      </p>
                    )
                  )
                  :
                  record?.store?.all_domains.map(el => (
                      <p key={el}>
                        {el}
                      </p>
                    )
                  )
              } else {
                let [a,b,c,d,e,f, ...rest] = isST ?  record?.all_domains : record?.store?.all_domains;
                let main = [a,b,c,d,e,f];
                let others = [...rest];
                return (
                  <>
                    {
                      main?.map(el => (
                        <p key={el}>
                          {el}
                        </p>
                      ))
                    }
                    {
                      <Collapse defaultActiveKey={[openTooltipText ? '1' : null]} collapsible={'header'} ghost={true}>
                        <Panel key='1'
                               showArrow={false}
                               header={
                                 <span onClick={() => setOpenTooltipText(!openTooltipText)}>
                                          {openTooltipText ? 'See less' : 'See more'}
                                        </span>}
                        >
                          {
                            others?.map(el => (
                              <p key={el}>
                                {el}
                              </p>
                            ))
                          }
                        </Panel>
                      </Collapse>
                    }
                  </>
                )
              }
            }
          }}
    />
  );
};

export default TooltipColumnIcon;
