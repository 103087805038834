import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import Modal from '../../../Components/Modal';
import Icon from '../../../Icon';

const AdLibraryItemActionModal = ({
  visible,
  setModal,
  handleOpenModalShopifyConnect,
  isNotModal,
  setVisibleModalMobileId,
  advertiserId,
  shopifyMetaData,
  domainFld,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      handleClose={() => {
        setVisibleModalMobileId(null);
        setModal(null);
      }}
      title={t('Ad actions')}
      isMobile={true}
      width={450}
      className="collection-options-modal collection-modal product-actions-modal"
      open={visible}
      destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <div className="adLibrary-item-header-action-dropdown">
            {isNotModal && (
              <div
                className="adLibrary-item-header-action-dropdown-option"
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&is_targeted_country=false&media_type=all&search_type=page&view_all_page_id=${advertiserId}`,
                    '_blank',
                  )
                }
              >
                <Icon role={'icon'} type={'search_facebook'} isDark={false} />
                <span>{t('View Facebook Ad Library')}</span>
              </div>
            )}
            {shopifyMetaData[0]?.shopify_product_id && (
              <div
                className="adLibrary-item-header-action-dropdown-option"
                onClick={() => {
                  setModal(null);
                  handleOpenModalShopifyConnect(
                    `${shopifyMetaData[0]?.internal_shop_id}_${shopifyMetaData[0]?.shopify_product_id}`,
                  );
                }}
              >
                <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />
                <span>{t('Import to Shopify store')}</span>
              </div>
            )}
            {domainFld && <div
              className="adLibrary-item-header-action-dropdown-option"
              onClick={() =>
                window.open(
                  `https://www.similarweb.com/website/${domainFld}`,
                  '_blank',
                )
              }
            >
              <Icon role={'icon'} type={'similar_web'} isDark={false} />
              <span>{t('View with Similarweb')}</span>
            </div>}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AdLibraryItemActionModal;
