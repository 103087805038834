import React, { Fragment, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import cls from 'classname';
import actions from './actions';
import { navIconsArr, navIconsAdmin, NavLinkWithIcon } from './navigation';
import ButtonComponent from '../../Components/Button';
import { sendExtensionMessage } from '../../Utils/extension';
import SidebarHeader from './SidebarHeader';
import Images from '../../Images';
import Image from '../../Components/Image';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Icon from '../../Icon';
import './styles.less';
import LogoutModal from '../User/components/LogoutModal';

const SidebarComponent = (
  {
    nav,
    isAdmin,
    changeSizeSidebar,
    setAdSpotFilters,
    setAdSpotQuickPreset,
    setAdSpotSort,
    setAdSpotDefaultSearch,
    openSidebar,
    showLogout,
    ...props
  }) => {

  const { isShowMenu, isShowSideBar, theme, isMobile, lastNotSettingUrl } = nav;
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState([]);
  const [menu, setMenu] = useState('desktop');
  const [transition, setTransition] = useState(false);
  const [showExtensionBtn, setShowExtensionBtn] = useState(false);
  const [flag, setFlag] = useState(false);
  const subscription = props.userInfo?.subscriptions?.[0];

  const isFreePlan = subscription?.payment_status?.id === 6;

  const isActive = (path) => path.includes('sales-tracker') ?
    !location.pathname.includes(path.split('/')[1])
    :
    path.includes('portfolio') ?
      !(location.pathname.includes('collections') || location.pathname.includes('portfolio'))
      :
      !location.pathname.includes(path);

  const menuIcons = isAdmin
    ? navIconsAdmin
    : (props?.userInfo?.need_block_pages && location.pathname.includes('setting')) 
    ? navIconsArr['settingsDemo']
    : props?.userInfo?.need_block_pages
    ? navIconsArr['demo'] : navIconsArr[menu];

  useEffect(() => {
    let page = document.querySelector('.main-content');
    page?.scrollTo({ top: 0 });
    if (location?.pathname.includes('sales-tracker')) setActiveKey(['salesTracker']);
    else if (location?.pathname.includes('collections') || location?.pathname.includes('portfolio')) setActiveKey(['portfolio']);
    else setActiveKey([]);
    if (!location?.pathname.includes('ad-spot')) {
      setAdSpotFilters({});
      setAdSpotQuickPreset({});
      setAdSpotSort('most_recent');
      setAdSpotDefaultSearch(true);
    }
    if (location.pathname.includes('setting')) {
      if (menu === 'desktop') {
        setTransition(true);
        setTimeout(() => setMenu('setting'), 150);
        setTimeout(() => setTransition(false), 310);
      }
      if (!isShowSideBar) {
        changeSizeSidebar(true);
        setFlag(true);
      }
    } else {
      if (menu === 'setting') {
        setTransition(true);
        setTimeout(() => setMenu('desktop'), 150);
        setTimeout(() => setTransition(false), 310);
      }
      props.setLastNotSettingUrl(location.pathname);
    }
    sendExtensionMessage(
      { ping: true },
      (response) => {
        if (response) {
          setShowExtensionBtn(false);
        } else {
          setShowExtensionBtn(true);
        }
      });
  }, [location]);

  useEffect(() => {
    if(isMobile) changeSizeSidebar(true);
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => setFlag(false), 300)
  }, [isShowSideBar]);

  return (
    <>
      {
        menu === 'desktop' ?
          <aside className={cls('main-sidebar', {
            'closed-sidebar': !isShowMenu,
            'opened-sidebar': isShowMenu,
            'big-sidebar': isShowSideBar && !isMobile,
            'hidden': location.pathname.includes('shopify/app'),
            'transition': menu === 'desktop' && transition,
          })}
                 style={isMobile ? null : { overflow: flag ? 'hidden' : 'unset' }}
          >
            <div className="main-sidebar_wrap">
              <SidebarHeader changeSizeSidebar={changeSizeSidebar}
                             isShowSideBar={isShowSideBar}
                             setFlag={setFlag}
                             isMobile={isMobile}
                             openSidebar={openSidebar}
              />
              {
                isShowSideBar ?
                  null
                  :
                  <Divider type={'horizontal'} style={{ margin: '0 auto 12px' }} />
              }
              {menuIcons.map(({
                                url,
                                iconDesk,
                                text,
                                comingSoon,
                                markText,
                                withDivider,
                                markTextBordered,
                                dropdown,
                                options,
                                flag,
                                isNew,
                              }) => {
                const navIcon = (
                  <NavLinkWithIcon key={url}
                                   url={url}
                                   theme={theme}
                                   text={t(text)}
                                   active={isActive(url)}
                                   iconType={iconDesk}
                                   isOpened={isShowSideBar}
                                   isSoon={comingSoon}
                                   isNew={isNew}
                                   markText={markText ? t(markText) : null}
                                   markTextBordered={markTextBordered}
                                   openSidebar={() => openSidebar(!isShowMenu)}
                                   location={location.pathname}
                                   isDropdown={dropdown}
                                   options={options}
                                   activeKey={activeKey}
                                   setActiveKey={setActiveKey}
                                   flag={flag}
                                   t={t}
                  />
                );
                const divider = <Divider className="main-sidebar_divider" style={{ margin: '12px 0' }} />;
                return withDivider ? (
                    <Fragment key={url}>
                      {navIcon} {divider}
                    </Fragment>
                  )
                  :
                  (
                    navIcon
                  );
              })}
            </div>
            <div className="main-sidebar_bottom">
              {
                (isMobile || !showExtensionBtn || isAdmin || !isShowSideBar || (activeKey.length && window.innerHeight < 900)) ?
                  null
                  :
                  <div className={'chrome-extension-link-wrapper'}
                       onClick={() => window.open(`https://chrome.google.com/webstore/detail/dropship-product-research/${process.env.REACT_APP_EXTENSION_ID}`, '_blank')}
                  >
                    <div className="chrome-extension-link-icon">
                      <Image src={Images.Extension} small={false} />
                    </div>
                    <div className="chrome-extension-link-title-wrapper">
                      <div className="chrome-extension-link-title">
                        {
                          t('Introducing Dropship extension')
                        }
                      </div>
                      <div className="chrome-extension-link-text">
                        {
                          t('Perform real-time product research with our extension.')
                        }
                      </div>
                    </div>
                  </div>
              }
              {props.children}
              {
                isShowSideBar ?
                  <ButtonComponent className={'main-sidebar-free-button'}
                                   onClick={() => {
                                     if (isMobile && isShowMenu) openSidebar(false);
                                     navigate('/setting/plan');
                                   }}
                  >
                <span>
                  {t('Upgrade')}
                </span>
                    {
                      isFreePlan ?
                        theme === 'light' ?
                          <svg xmlns="http://www.w3.org/2000/svg"
                               width="21"
                               height="20"
                               viewBox="0 0 21 20"
                               fill="none"
                          >
                            <path fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.53007 2.7142C9.41836 1.22027 11.5813 1.22026 12.4696 2.7142L18.7063 13.2031C19.6146 14.7307 18.5137 16.666 16.7365 16.666H4.2632C2.48597 16.666 1.38513 14.7307 2.29343 13.2031L8.53007 2.7142ZM10.4999 6.66597C10.8451 6.66597 11.1249 6.9458 11.1249 7.29097V10.6243C11.1249 10.9695 10.8451 11.2493 10.4999 11.2493C10.1547 11.2493 9.87492 10.9695 9.87492 10.6243V7.29097C9.87492 6.9458 10.1547 6.66597 10.4999 6.66597ZM11.3333 12.916C11.3333 13.3762 10.9602 13.7493 10.4999 13.7493C10.0397 13.7493 9.66659 13.3762 9.66659 12.916C9.66659 12.4557 10.0397 12.0826 10.4999 12.0826C10.9602 12.0826 11.3333 12.4557 11.3333 12.916Z"
                                  fill="#F6B60E"
                            />
                          </svg>
                          :
                          <svg xmlns="http://www.w3.org/2000/svg"
                               width="21"
                               height="20"
                               viewBox="0 0 21 20"
                               fill="none"
                          >
                            <path fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.53032 2.7142C9.4186 1.22027 11.5816 1.22026 12.4699 2.7142L18.7065 13.2031C19.6148 14.7307 18.514 16.666 16.7367 16.666H4.26344C2.48622 16.666 1.38537 14.7307 2.29367 13.2031L8.53032 2.7142ZM10.5002 6.66597C10.8453 6.66597 11.1252 6.9458 11.1252 7.29097V10.6243C11.1252 10.9695 10.8453 11.2493 10.5002 11.2493C10.155 11.2493 9.87516 10.9695 9.87516 10.6243V7.29097C9.87516 6.9458 10.155 6.66597 10.5002 6.66597ZM11.3335 12.916C11.3335 13.3762 10.9604 13.7493 10.5002 13.7493C10.0399 13.7493 9.66683 13.3762 9.66683 12.916C9.66683 12.4557 10.0399 12.0826 10.5002 12.0826C10.9604 12.0826 11.3335 12.4557 11.3335 12.916Z"
                                  fill="#F6B60E"
                            />
                          </svg>
                        :
                        null
                    }
                  </ButtonComponent>
                  :
                  null
              }
            </div>
          </aside>
          :
          <aside className={cls('main-sidebar', {
            'closed-sidebar': !isShowMenu,
            'opened-sidebar': isShowMenu,
            'big-sidebar': isShowSideBar && !isMobile,
            'hidden': location.pathname.includes('shopify/app'),
            'transition': menu === 'setting' && transition,
          })}
                 style={isMobile ? null : { overflow: flag ? 'hidden' : 'unset' }}
          >
            <div className="main-sidebar_wrap">
              <div className={'main-sidebar-header-settings'}
                   onClick={() => navigate(lastNotSettingUrl || '/dashboard')}
              >
                <ArrowSelectDown />
                <span>
                  {t('Settings')}
                </span>
                {
                  isMobile ?
                    <span onClick={(e) => {
                      e.stopPropagation();
                      openSidebar(false);
                    }}
                          style={{ marginTop: -4, marginLeft: 'auto' }}
                    >
                      <Icon role={'icon'} type={'close_modal'} />
                    </span>
                    :
                    null
                }
              </div>
              {
                isMobile ?
                  <Divider type="horizontal"
                           style={{margin: '0 0 16px'}}
                  />
                  :
                  null
              }
              {menuIcons.map(({
                                url,
                                iconDesk,
                                text,
                                comingSoon,
                                markText,
                                withDivider,
                                markTextBordered,
                                dropdown,
                                options,
                                flag,
                              }) => {
                const navIcon = (
                  <NavLinkWithIcon key={url}
                                   url={url}
                                   theme={theme}
                                   text={t(text)}
                                   active={isActive(url)}
                                   iconType={iconDesk}
                                   isOpened={isShowSideBar}
                                   isSoon={comingSoon}
                                   markText={markText ? t(markText) : null}
                                   markTextBordered={markTextBordered}
                                   openSidebar={() => openSidebar(!isShowMenu)}
                                   location={location.pathname}
                                   isDropdown={dropdown}
                                   options={options}
                                   activeKey={activeKey}
                                   setActiveKey={setActiveKey}
                                   flag={flag}
                                   t={t}
                                   isSetting={true}
                                   isMobile={isMobile}
                  />
                );
                const divider = <Divider className="main-sidebar_divider" style={{ margin: '12px 0' }} />;
                return withDivider ? (
                    <Fragment key={url}>
                      {navIcon} {divider}
                    </Fragment>
                  )
                  :
                  (
                    navIcon
                  );
              })}
            </div>
            <div  className={`${'main-sidebar_bottom'} logout`}>
              <span className='sidebar-link link-logout' onClick={() => {
                  showLogout(true);
                }}>
                <Icon className="sidebar-link_icon icon icon-integrations icon-integrations-bold" role="icon" type="logout"/>
                <span className="sidebar-link-text">{t('Logout')}</span>
              </span>
            </div>
          </aside>
      }
      {
        isMobile ?
          <div className={cls('main-sidebar_shadow', {
            active: isShowMenu
          })}
               onClick={() => openSidebar(false)}
          />
          :
          null
      }
      <LogoutModal/>
    </>
  );
};

const mapStateToProps = (state) => ({
  nav: state.nav,
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  changeSizeSidebar(value) {
    dispatch(actions.changeSizeSidebar('isShowSideBar', value));
  },
  setLastNotSettingUrl(value) {
    dispatch(actions.changeSizeSidebar('lastNotSettingUrl', value))
  },
  setAdSpotFilters(data) {
    dispatch(actions.setAdSpotFilters(data));
  },
  setAdSpotQuickPreset(data) {
    dispatch(actions.setAdSpotQuickPreset(data));
  },
  setAdSpotSort(data) {
    dispatch(actions.setAdSpotSort(data));
  },
  setAdSpotDefaultSearch(data) {
    dispatch(actions.setAdSpotDefaultSearch(data));
  },
  showLogout: value => dispatch(actions.changeTheme('showLogout', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
