import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { openNotificationWithIcon } from '../../Components/Notification';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AdLibraryCreators from './reducer';
import acc from 'accounting';
import { DefaultMsgSaga } from '../../Components/Notification/notification-message';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import QuickSearch from './components/QuickSearch';
import FiltersBlock from './components/FiltersBlock';
import LoadPresetModal from '../ShopifySearch/components/LoadPresetModal';
import LoadPresetOptionsModal from '../ShopifySearch/components/LoadPresetOptionsModal';
import DeletePresetModal from '../ShopifySearch/components/DeletePresetModal';
import RenamePresetModal from '../ShopifySearch/components/RenamePresetModal';
import SaveFilterPresetModal from '../ShopifySearch/components/SaveFilterPresetModal';
import VideoTutorialModal from '../ShopifySearch/components/VideoTutorialModal';
import AdLibraryItemActionModal from './components/AdLibraryItemActionModal';
import AdLibraryItem from './components/AdLibraryItem';
import AdLibraryItemSkeleton from './components/AdLibraryItemSkeleton';
import Images from '../../Images';
import { checkboxOptions } from '../../Utils/adLibraryMockedData';
import dayjs from 'dayjs';
import './styles.less';

const AdLibraryPage = ({
  isMobile,
  userInfo,
  getShopifyStores,
  shopifyStores,
  changeVisibleModalShopify,
  getFilters,
  adLibrary,
  getAdLibraryAds,
  getAdLibraryAdsDefault,
  getAdLibraryPresets,
  creditsLeft,
  presets,
  updatePreset,
  deleteAdLibraryPreset,
  saveFilterPreset,
  setSelectedFilters,
  theme,
  quickSearch,
  selectedQuickPreset,
  getAdLibraryAdsCollation,
  collations,
  getCollationInsightsCharts,
  getCollationInsightsInfo,
  getCollationInsightsBreakDown,
  getAdLibraryStatusChart,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, adsLoading, filtersLoading, filters, ads, loadingAds } =
    adLibrary;
  const skeleton = loading || adsLoading || filtersLoading;
  const credits = 500;
  const initialUrl =
    document?.location?.pathname + (document?.location?.search || '');
  const isYearly =
    userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const [isChanged, setIsChanged] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [loadedPreset, setLoadedPreset] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterToPresets, setFilterToPresets] = useState({});
  const [modal, setModal] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [disableShowFilters, setDisableShowFilters] = useState(false);
  const [inputText, setInputText] = useState('');
  const tableRef = useRef();
  const [searchForFilters, setSearchForFilters] = useState(false);
  const [isNotModal, setIsNotModal] = useState(true);
  const [mobileModal, setMobileModal] = useState(null);
  const [visibleModalMobileId, setVisibleModalMobileId] = useState(null);

  const [creativeType, setCreativeType] = useState('all');
  const [checkedList, setCheckedList] = useState([
    ...checkboxOptions?.map((el) => el?.id),
  ]);
  const [inputFilters, setInputFilters] = useState({
    ad_spend: { min: '', max: '' },
    ad_sets_count: { min: '', max: '' },
    total_reaches_count: { min: '', max: '' },
    running_days: { min: '', max: '' },
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    created_at__gte: { min: null, max: null, id: null },
    last_seen_date__gte: { min: null, max: null, id: null },
    product_created_at__gte: { min: null, max: null, id: null },
    language: 'All',
    country: 'All',
    domain_tld: 'All',
    status: 'All',
    cta_types: 'All',
  });

  const setFiltersHandler = () => {
    setSelectedFilters({
      inputFilters: inputFilters,
      checkedList: checkedList,
      creativeType: creativeType,
      dropdownFilters: dropdownFilters,
    });
  };

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setDropdownFilters({
        ...filters,
        language: filters.languages || 'All',
        country: filters.countries || 'All',
        domain_tld: filters.domain_tld || 'All',
        cta_types: filters.call_to_action || 'All',
      });
      setTimeout(() => {
        setDropdownFilters({
          ...filters,
          language: 'All',
          country: 'All',
          domain_tld: 'All',
          cta_types: 'All',
          status: 'All',
        });
      }, [500]);
    }
  }, [filters]);

  const getClassForCreditsTotal = (creditsTotal, creditsLeft) => {
    const percentageLeft = (creditsLeft / creditsTotal) * 100;

    if (percentageLeft > 50) {
      return 'green';
    } else if (percentageLeft > 25 && percentageLeft <= 50) {
      return 'orange';
    } else if (percentageLeft >= 0 && percentageLeft <= 25) {
      return 'red';
    }
    return '';
  };

  const applyFilters = () => {
    setFiltersHandler();
    // Create an object to store the applied filters
    const appliedFilters = {};

    // Check inputFilters
    for (const key in inputFilters) {
      if (inputFilters[key].min || inputFilters[key].max) {
        const minValue = inputFilters[key].min || null;
        const maxValue = inputFilters[key].max || null;

        if (minValue !== null) {
          appliedFilters[`${key}__gte`] = minValue;
        }
        if (maxValue !== null) {
          appliedFilters[`${key}__lte`] = maxValue;
        }
      }
    }

    // Check dropdownFilters
    for (const key in dropdownFilters) {
      const value = dropdownFilters[key];
      if (key === 'status') {
        if (value === 'All' || value === null) {
          continue;
        }
        if (Array.isArray(value)) {
          appliedFilters[key] = value.includes('active');
        } else {
          appliedFilters[key] = value === 'active';
        }
        continue;
      }

      if (
        key === 'cta_types' ||
        key === 'language' ||
        key === 'country' ||
        key === 'domain_tld'
      ) {
        if (value !== 'All' && value !== null) {
          appliedFilters[key] = value;
        }
        continue;
      }

      if (
        key === 'created_at__gte' ||
        key === 'last_seen_date__gte' ||
        key === 'product_created_at__gte'
      ) {
        if (value.min || value.max) {
          const minDate = value.min
            ? dayjs(value.min).format('YYYY-MM-DD')
            : null;
          const maxDate = value.max
            ? dayjs(value.max).format('YYYY-MM-DD')
            : null;

          if (minDate) {
            appliedFilters[`${key}`] = minDate;
          }
          if (maxDate) {
            appliedFilters[`${key.replace('__gte', '__lte')}`] = maxDate;
          }
        }
        continue;
      }
    }

    if (
      checkedList.length > 0 &&
      checkedList.length !== checkboxOptions.length
    ) {
      appliedFilters.category = checkedList;
    }

    if (inputText.trim().length)
      appliedFilters.global_search = inputText.trim();

    if (creativeType !== 'all') {
      if (Array.isArray(creativeType) && creativeType.includes('photo')) {
        appliedFilters.creative_type = creativeType.map((type) =>
          type === 'photo' ? 'image' : type,
        );
      } else if (Array.isArray(creativeType)) {
        appliedFilters.creative_type = creativeType;
      } else {
        appliedFilters.creative_type =
          creativeType === 'photo' ? 'image' : creativeType;
      }
    }
    setAppliedFilters(appliedFilters);
    setFilterToPresets({
      creativeType,
      checkedList,
      inputFilters,
      dropdownFilters,
      global_search: inputText.trim(),
    });
    setPageNumber(1);
    setSearchForFilters(true);
  };

  useEffect(() => {
    document.title = `Ad Library - Dropship`;
    getShopifyStores();
    getFilters();
    getAdLibraryPresets();
  }, []);

  const onScrollHandlerFunc = (event) => {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (
      currentTopScroll >= maxTopScroll * 0.95 &&
      !adsLoading &&
      ads?.results.length < +ads?.count
    ) {
      setPageNumber((prev) => prev + 1);
      const currentScrollPos = event.target.scrollTop;
      event.target.dataset.scrollPos = currentScrollPos;
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  };
  useEffect(() => {
    // Fetch ads whenever pageNumber changes
    if (Object.keys(appliedFilters).length > 0 || searchForFilters) {
      if (creditsLeft !== 0) {
        getAdLibraryAds({
          ...appliedFilters,
          page_number: pageNumber,
          page_size: pageSize,
          ordering: sortOrder,
        });
      }
    } else {
      getAdLibraryAdsDefault({
        page_number: pageNumber,
        page_size: pageSize,
        ordering: sortOrder,
      });
    }
  }, [pageNumber, pageSize, appliedFilters, sortOrder, searchForFilters]);

  useEffect(() => {
    const tableContent = tableRef.current;

    if (tableContent && ads?.results?.length < ads?.count) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (tableContent) {
        tableContent.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
  }, [ads.results?.length, ads?.count, adsLoading]);

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && tableContent.dataset.scrollPos) {
      tableContent.scrollTop = tableContent.dataset.scrollPos;
    }
  }, [ads.results]);
  const loadPreset = (data) => {
    if (credits === 0 || !Boolean(userInfo?.subscriptions?.[0])) {
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={null}
            title={t(
              'You have reached your search limit, upgrade to unlock unlimited searches',
            )}
            icon="notification_error"
            iconOutline={true}
            withTranslate={true}
          />
        ),
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach((val) => {
        if (val === 'creativeType') setCreativeType(data.value[val]);
        if (val === 'inputFilters')
          setInputFilters((state) => ({ ...state, ...data.value[val] }));
        if (val === 'checkedList') setCheckedList(data.value[val]);
        if (val === 'dropdownFilters')
          setDropdownFilters((state) => ({ ...state, ...data.value[val] }));
      });
      applyFilters();
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            text={null}
            title={t('_name_ preset was applied', { name: name })}
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (loadedPreset !== null) {
      applyFilters();
      if (!loadedPreset?.key?.includes('defined')) setLoadedPreset(null);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      changeVisibleModalShopify({
        isVisibleModal: 'import',
        initialUrl,
        importProductId,
      });
    } else {
      changeVisibleModalShopify({
        isVisibleModal: 'connect',
        initialUrl,
        importProductId: null,
      });
    }
  };
  return (
    <div className={'product-database-page adlibrary-page'}>
      <TopPageHeader
        icon={'ad_library_dashboard'}
        title={'Ad Library'}
        subtitle={'Find winning shopify products based on ad spent'}
        takeTourHandler={() => window.Intercom('startTour', 584956)}
        increaseLimitsHandler={
          userInfo?.subscriptions?.[0]?.plan?.plan?.name === 'premium'
            ? null
            : () => navigate('/setting/plan')
        }
        skeleton={filtersLoading}
        isMobile={isMobile}
        withCredits={true}
        isYearly={isYearly}
        creditsLeft={creditsLeft}
        creditsTotal={presets.total_credits}
        getClassForCreditsTotal={getClassForCreditsTotal}
        isTrippleMenuMobile={false}
        isTrippleMenu={true}
        isTrialPremium={
          isTrial &&
          userInfo?.subscriptions?.[0]?.plan?.plan?.name === 'premium'
        }
        isTrialStandard={
          isTrial &&
          userInfo?.subscriptions?.[0]?.plan?.plan?.name === 'standard'
        }
        isTrialBasic={
          isTrial && userInfo?.subscriptions?.[0]?.plan?.plan?.name === 'basic'
        }
      />
      {isMobile ? (
        skeleton ? (
          <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              <span
                className={'link skeleton'}
                style={{ width: 92, height: 20 }}
              />
            </span>
            <span className="sales-tracker-top-page-credits-value">
              <span className={'current'}>
                <span
                  className={'link skeleton'}
                  style={{ width: 62, height: 20, display: 'block' }}
                />
              </span>
              <span className={'separator'}>/</span>
              <span className={'maximum'}>
                <span
                  className={'link skeleton'}
                  style={{ width: 62, height: 20, display: 'block' }}
                />
              </span>
            </span>
          </div>
        ) : (
          <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              {t('Search credits')}
            </span>
            <span className="sales-tracker-top-page-credits-value">
              <span
                className={`total-${getClassForCreditsTotal(
                  presets.total_credits,
                  creditsLeft,
                )}`}
              >
                {acc.formatNumber(creditsLeft, 0, ',')}
              </span>
              <span className={'separator'}>/</span>
              <span className={'maximum'}>
                {acc.formatNumber(presets.total_credits, 0, ',')}
              </span>
            </span>
          </div>
        )
      ) : null}
      <PageOptions
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        setModal={setModal}
        isChanged={isChanged}
        inputText={inputText}
        setInputText={setInputText}
        skeleton={filtersLoading}
        isMobile={isMobile}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setAppliedFilters={setAppliedFilters}
        disabled={creditsLeft === 0}
        adsLoading={adsLoading}
        applyFilters={applyFilters}
        isTrial={isTrial}
      />
      {quickSearch?.length ? (
        <QuickSearch
          loadPreset={loadPreset}
          setLoadedPreset={setLoadedPreset}
          loadedPreset={loadedPreset || selectedQuickPreset}
          skeleton={filtersLoading}
          isMobile={isMobile}
          data={quickSearch || []}
          disabled={creditsLeft === 0}
        />
      ) : null}
      <FiltersBlock
        showFilters={showFilters}
        isMobile={isMobile}
        setIsChanged={setIsChanged}
        setInputText={setInputText}
        disableShowFilters={disableShowFilters || creditsLeft === 0}
        filters={filters}
        inputText={inputText}
        setPageNumber={setPageNumber}
        skeleton={filtersLoading}
        setFilterToPresets={setFilterToPresets}
        recordToDelete={recordToDelete}
        creativeType={creativeType}
        setCreativeType={setCreativeType}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        inputFilters={inputFilters}
        setInputFilters={setInputFilters}
        dropdownFilters={dropdownFilters}
        setDropdownFilters={setDropdownFilters}
        applyFilters={applyFilters}
        setLoadedPreset={setLoadedPreset}
      />

      <div className="adlibrary-page-items-wrapper" ref={tableRef}>
        {skeleton ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((el) => (
            <AdLibraryItemSkeleton key={el} />
          ))
        ) : (
          <>
            {!ads.results?.length && !adsLoading ? (
              <div className="adLibrary-items-empty">
                <div className="adLibrary-items-empty-wrapper">
                  <img
                    src={
                      theme === 'light'
                        ? Images.adsEmptyLight
                        : Images.adsEmptyDark
                    }
                    width={208}
                  />
                  <div className="adLibrary-items-empty-sup">
                    {t('No ads found')}
                  </div>
                  <div className="adLibrary-items-empty-sub">
                    {t('Try changing your search query and try again.')}
                  </div>
                </div>
              </div>
            ) : (
              ads.results?.map((el) => {
                return(
                <>
                  <AdLibraryItem
                    key={el?.collation_id}
                    id={el?.collation_id}
                    handleOpenModalShopifyConnect={
                      handleOpenModalShopifyConnect
                    }
                    setModal={setModal}
                    modal={modal}
                    data={el}
                    getAdLibraryAdsCollation={getAdLibraryAdsCollation}
                    collations={collations}
                    skeleton={loadingAds}
                    theme={theme}
                    adsLoading={adsLoading}
                    getCollationInsightsCharts={getCollationInsightsCharts}
                    getCollationInsightsInfo={getCollationInsightsInfo}
                    getCollationInsightsBreakDown={
                      getCollationInsightsBreakDown
                    }
                    getAdLibraryStatusChart={getAdLibraryStatusChart}
                    setIsNotModal={setIsNotModal}
                    setMobileModal={setMobileModal}
                    visibleModalMobileId={visibleModalMobileId}
                    setVisibleModalMobileId={setVisibleModalMobileId}
                  />
                  <AdLibraryItemActionModal
                    setModal={setMobileModal}
                    visible={mobileModal === 'ad_item_action' && visibleModalMobileId === el.collation_id}
                    handleOpenModalShopifyConnect={
                      handleOpenModalShopifyConnect
                    }
                    isNotModal={isNotModal}
                    setVisibleModalMobileId={setVisibleModalMobileId}
                    advertiserId={el?.advertiser?.id}
                    shopifyMetaData={el?.shopify_metadata}
                    domainFld={el?.domain_fld}
                  />
                </>
              )})
            )}
          </>
        )}
      </div>

      <LoadPresetModal
        visible={modal === 'load_preset'}
        setModal={setModal}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        loadPreset={loadPreset}
        setLoadedPreset={setLoadedPreset}
        setSortOrder={setSortOrder}
        category={'adLibrary'}
        loading={skeleton}
        isMobile={isMobile}
        setRecordToDelete={setRecordToDelete}
        setSelectedFilters={setSelectedFilters}
      />
      <LoadPresetOptionsModal
        setModal={setModal}
        isMobile={isMobile}
        visible={modal === 'load_preset_options'}
        selected={recordToDelete}
      />
      <DeletePresetModal
        setModal={setModal}
        visible={modal === 'delete_preset'}
        deleteFilter={deleteAdLibraryPreset}
        record={recordToDelete}
        loading={skeleton}
        isMobile={isMobile}
      />
      <RenamePresetModal
        setModal={setModal}
        visible={modal === 'rename_preset'}
        record={recordToDelete}
        isMobile={isMobile}
        modal={modal}
        updatePreset={updatePreset}
      />
      <SaveFilterPresetModal
        setModal={setModal}
        isMobile={isMobile}
        visible={modal === 'save_preset'}
        saveFilterPreset={saveFilterPreset}
        filters={filterToPresets}
        category={'adLibrary'}
        loading={skeleton}
      />
      <VideoTutorialModal
        visible={modal === 'tutorial'}
        setModal={setModal}
        isMobile={isMobile}
        link={'https://www.youtube.com/watch?v=h-rOBAPwptI'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userInfo: state.auth.userInfo,
  shopifyStores: state?.shopifyStore?.results,
  adLibrary: state?.adLibrary,
  creditsLeft: state.adLibrary.creditsLeft,
  filtersLoading: state.adLibrary.filtersLoading,
  presets: state.adLibrary.presets,
  theme: state.nav.theme,
  quickSearch: state?.adLibrary?.presets?.quick_search,
  selectedQuickPreset: state.adLibrary.selectedQuickPreset,
  collations: state.adLibrary.collations,
});

const mapDispatchToProps = (dispatch) => ({
  getShopifyStores: () =>
    dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  changeVisibleModalShopify: (data) =>
    dispatch(ShopifyStoreCreators.changeVisibleModalShopify(data)),
  getFilters: () => dispatch(AdLibraryCreators.getAdLibraryFiltersRequest()),
  getAdLibraryAds: (data) =>
    dispatch(AdLibraryCreators.getAdLibraryAdsRequest(data)),
  getAdLibraryAdsDefault: (data) =>
    dispatch(AdLibraryCreators.getAdLibraryAdsDefaultRequest(data)),
  updatePreset: (data) =>
    dispatch(AdLibraryCreators.updateAdLibraryPresetRequest(data)),
  getAdLibraryPresets: () =>
    dispatch(AdLibraryCreators.getAdLibraryPresetsRequest()),
  deleteAdLibraryPreset: (id) =>
    dispatch(AdLibraryCreators.deleteAdLibraryPresetRequest(id)),
  saveFilterPreset: (data) =>
    dispatch(AdLibraryCreators.createAdLibraryPresetRequest(data)),
  setSelectedFilters: (data) =>
    dispatch(AdLibraryCreators.setSelectedFiltersAdLibrary(data)),
  getAdLibraryAdsCollation: (data) =>
    dispatch(AdLibraryCreators.getAdLibraryAdsCollationRequest(data)),
  getCollationInsightsCharts: (data) =>
    dispatch(AdLibraryCreators.getCollationInsightsChartsRequest(data)),
  getCollationInsightsInfo: (data) =>
    dispatch(AdLibraryCreators.getCollationInsightsInfoRequest(data)),
  getCollationInsightsBreakDown: (data) =>
    dispatch(AdLibraryCreators.getCollationInsightsBreakDownRequest(data)),
  getAdLibraryStatusChart: (data) =>
    dispatch(AdLibraryCreators.getAdLibraryStatusChartRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdLibraryPage);
