import React, { useCallback, useEffect, useRef, useState } from 'react';
import Creators from '../reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import DropdownBarFilter from '../pages/components/DropdownBarFilter';
import DropdownList from '../pages/components/DropdownList';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import ProductView from '../../ProductsPage/components/product-view';
import { Divider, Spin, Table } from 'antd';
import Icon from '../../../Icon';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import ProductCard from './ProductCard';
import { getColumns } from '../../ShopifySearch/components/DatabaseProductsColumns';

const dateOptions = [
  {id: 3, name: 'Last 7 days', value: 'Last 7 days', days: 7},
  {id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30},
  {id: 5, name: 'Last 90 days', value: 'Last 90 days', days: 90},
  {id: 6, name: 'Last 6 months', value: 'Last 6 months', days: 180},
  {id: 7, name: 'Last 12 months', value: 'Last 12 months', days: 365}
];

const LocalTable = (props) => <Table {...props} />;

const MemoTable = React.memo(LocalTable, (prev, next) =>
  JSON.stringify(prev?.dataSource) === JSON.stringify(next?.dataSource) && prev?.loading === next?.loading && prev?.sortOrder === next?.sortOrder);

const StoreTrackerPageCompetitors = (
  {
    setModal,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setRecordToCalc,
    setDeleteType,
    id,
    trackCompetitor,
    unTrackCompetitor,
    getCompetitors,
    getCount,
    getFilters,
    userInfo,
    isMobile,
    storeInnerPage,
  },
) => {

  const { t } = useTranslation();
  const tableRef = useRef(null);

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [displayFormat, setDisplayFormat] = useState('overview');
  const [filtersValue, setFiltersValue] = useState({
    revenue: {min: 0, max: 0},
    sales: {min: 0, max: 0},
    products_count: {min: 0, max: 0},
    languages: null,
    created_at: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    revenue: {min: 0, max: 0},
    sales: {min: 0, max: 0},
    products_count: {min: 0, max: 0},
    languages: null,
    created_at: null,
  });
  const [tags, setTags] = useState([]);

  const tagsValue = {
    revenue: {
      title: 'Revenue',
    },
    sales: {
      title: 'Sales',
    },
    products_count: {
      title: 'Products',
    },
    languages: {
      title: 'Language',
    },
    created_at: {
      title: 'Creation date',
    },
  }

  function handleCount(key = {}) {
    let data = {
      internal_shop_id: id,
      page: 1,
      page_size: 20,
      created_at__gte: selectedFilters?.created_at?.min,
      created_at__lte: selectedFilters?.created_at?.max,
      languages: selectedFilters?.languages?.length ?
        [...selectedFilters?.languages?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.languages?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.languages]?.map(el => el?.code)
        :
        null,
      products_count__gte: selectedFilters?.products_count?.min,
      products_count__lte: selectedFilters?.products_count?.max,
      revenue__gte: selectedFilters?.revenue?.min,
      revenue__lte: selectedFilters?.revenue?.max,
      sales__gte: selectedFilters?.sales?.min,
      sales__lte: selectedFilters?.sales?.max,
    };
    if (key?.id === 'languages') {
      data[key?.id] = key?.value?.length ?
        [...key?.value?.filter(el => typeof el === 'string')]?.length ?
          [...key?.value?.filter(el => typeof el === 'string')]
          :
          [...key?.value]?.map(el => el?.code)
        :
        null
    } else if (key?.id === 'products') {
      data.products_count__gte = key?.value?.min;
      data.products_count__lte = key?.value?.max;
    } else {
      data[`${key?.id}__gte`] = key?.value?.min;
      data[`${key?.id}__lte`] = key?.value?.max;
    }
    getCount({...data});
  }

  function applyFilters() {
    let data = {
      internal_shop_id: id,
      page: pageNumber,
      page_size: 20,
      ordering: sortOrder,
      created_at__gte: selectedFilters?.created_at?.min || storeInnerPage?.full?.competitors?.filters?.created_at?.min,
      created_at__lte: selectedFilters?.created_at?.max || storeInnerPage?.full?.competitors?.filters?.created_at?.max,
      languages: selectedFilters?.languages?.length ?
        [...selectedFilters?.languages?.filter(el => typeof el === 'string')]?.length ?
          [...selectedFilters?.languages?.filter(el => typeof el === 'string')]
          :
          [...selectedFilters?.languages]?.map(el => el?.code)
        :
        null,
      products_count__gte: selectedFilters?.products_count?.min || storeInnerPage?.full?.competitors?.filters?.products_count?.min,
      products_count__lte: selectedFilters?.products_count?.max || storeInnerPage?.full?.competitors?.filters?.products_count?.max,
      revenue__gte: selectedFilters?.revenue?.min || storeInnerPage?.full?.competitors?.filters?.revenue?.min,
      revenue__lte: selectedFilters?.revenue?.max || storeInnerPage?.full?.competitors?.filters?.revenue?.max,
      sales__gte: selectedFilters?.sales?.min || storeInnerPage?.full?.competitors?.filters?.sales?.min,
      sales__lte: selectedFilters?.sales?.max || storeInnerPage?.full?.competitors?.filters?.sales?.max,
    };
    getCompetitors({...data});
  }

  function resetFilters() {
    setSelectedFilters({ ...storeInnerPage?.full?.competitors?.filters });
  }

  function resetFilterByKey(key) {
    setSelectedFilters( prev => ({...prev, [key]: storeInnerPage?.full?.competitors?.filters?.[key] }));
  }

  function checkIfChanged() {
    let result = [];
    let currentFilters = storeInnerPage?.full?.competitors?.filters;
    if (
      Object.keys(selectedFilters).some((el) => {
        if (el !== 'languages' && el !== 'created_at') {
          if (
            (selectedFilters?.[el]?.min !==
              currentFilters?.[el]?.min &&
              selectedFilters?.[el]?.min !== null) ||
            (selectedFilters?.[el]?.max !==
              currentFilters?.[el]?.max &&
              selectedFilters?.[el]?.max !== null)
          ) result.push(el);
        } else {
          if (el === 'languages') {
            if (selectedFilters?.[el] !== null && JSON.stringify(selectedFilters?.[el]) !== JSON.stringify(currentFilters?.[el])) result.push(el);
          } else {
            if ((selectedFilters?.[el]?.min !== null || selectedFilters?.[el]?.max !== null) && JSON.stringify(selectedFilters?.[el]) !== JSON.stringify(currentFilters?.[el])) result.push(el);
          }
        }
      })
    ) {
      return setTags(result);
    } else {
      return setTags(result);
    }
  };

  const toggleConnectProduct = (record) => {
    if (record?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            internal_shop_id: record?.store?.internal_shop_id,
            product_id: record?.id,
            title: record?.title,
          },
          cb: unTrackCompetitor
        });
        setModal('remove_tracking');
      }
    } else {
      trackCompetitor({
        internal_shop_id: record?.store?.internal_shop_id,
        product_id: record?.id,
        full_data: false,
      });
    }
  };

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && storeInnerPage?.full?.competitors?.results?.length < storeInnerPage?.full?.competitors?.count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  useEffect(() => {
    getFilters({ internal_shop_id: id });
  }, []);

  useEffect(() => {
    setSortOrder(null);
  }, [displayFormat]);

  useEffect(() => {
    if (Object.keys(storeInnerPage?.full?.competitors?.filters)?.length) {
      setFiltersValue({ ...storeInnerPage?.full?.competitors?.filters });
      setSelectedFilters({ ...storeInnerPage?.full?.competitors?.filters });
    }
  }, [storeInnerPage?.full?.competitors?.filters]);

  useEffect(() => {
    checkIfChanged();
    !storeInnerPage?.full?.competitors?.loading && Object.keys(storeInnerPage?.full?.competitors?.filters)?.length && applyFilters();
  }, [selectedFilters]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, storeInnerPage?.full?.competitors?.count, storeInnerPage?.full?.competitors?.results?.length]);

  useEffect(() => {
    !storeInnerPage?.full?.competitors?.loading && Object.keys(storeInnerPage?.full?.competitors?.filters)?.length && applyFilters();
  }, [pageNumber, sortOrder]);

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-competitors">
      <div className="report-main-block">
        <span className={'report-main-block-title'}>
          <span className="title">
            {t('Competitors')}
          </span>
        </span>
      </div>
      <Spin spinning={storeInnerPage?.full?.competitors?.filtersLoading}>
        <div className='store-tracker-page-competitors-options-wrapper'>
          <div className="store-tracker-page-competitors-filter-wrapper">
            <DropdownBarFilter key={'Revenue'}
                               title={t('Revenue')}
                               tooltip={
                                 'Store revenue generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                               }
                               id={'revenue'}
                               isStore={true}
                               min={filtersValue?.revenue?.min}
                               max={filtersValue?.revenue?.max}
                               value={selectedFilters}
                               setValue={setSelectedFilters}
                               disabled={false}
                               countLoading={storeInnerPage?.full?.competitors?.countLoading}
                               count={storeInnerPage?.full?.competitors?.count || 0}
                               handleCount={handleCount}
                               isMobile={isMobile}
            />
            <DropdownBarFilter key={'Sales'}
                               title={t('Sales')}
                               tooltip={
                                 'Number of sales the store generated based on the period you select either 7 Days, 30 Days or 90 Days.'
                               }
                               id={'sales'}
                               isStore={true}
                               min={filtersValue?.sales?.min}
                               max={filtersValue?.sales?.max}
                               value={selectedFilters}
                               setValue={setSelectedFilters}
                               disabled={false}
                               countLoading={storeInnerPage?.full?.competitors?.countLoading}
                               count={storeInnerPage?.full?.competitors?.count || 0}
                               handleCount={handleCount}
                               isMobile={isMobile}
                               suffix={''}
            />
            <DropdownBarFilter key={'Products'}
                               title={t('Products')}
                               tooltip={'Amount of product listings in the store.'}
                               id={'products_count'}
                               isStore={true}
                               min={filtersValue?.products_count?.min}
                               max={filtersValue?.products_count?.max}
                               value={selectedFilters}
                               setValue={setSelectedFilters}
                               disabled={false}
                               countLoading={storeInnerPage?.full?.competitors?.countLoading}
                               count={storeInnerPage?.full?.competitors?.count || 0}
                               handleCount={handleCount}
                               isMobile={isMobile}
                               suffix={''}
            />
            <DropdownList title={t('Language')}
                          id={'languages'}
                          value={selectedFilters}
                          setValue={setSelectedFilters}
                          data={filtersValue?.languages?.length ? [...filtersValue?.languages] : []}
                          disabled={false}
                          tooltip={'Select language'}
                          isMobile={isMobile}
                          count={storeInnerPage?.full?.competitors?.count || 0}
                          countLoading={storeInnerPage?.full?.competitors?.countLoading}
                          handleCount={handleCount}
            />
            <DropdownDateBlock innerLabel={'Creation date'}
                               id={'created_at'}
                               customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                               customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                               iconType={'database_calendar'}
                               data={dateOptions}
                               dropdownFilters={selectedFilters}
                               setDropdownFilters={setSelectedFilters}
                               hideTimeZone={true}
                               visibleModal={visibleModal}
                               setVisibleModal={setVisibleModal}
                               isMobile={isMobile}
                               withTitle={true}
                               position={'bottomLeft'}
            />
          </div>
          <ProductView view={displayFormat}
                       setView={(val) => setDisplayFormat(val)}
          />
        </div>
        {
          tags?.length && Object.keys(storeInnerPage?.full?.competitors?.filters)?.length ?
            <div className='store-tracker-page-competitors-tags-wrapper'>
              <div className='store-tracker-page-competitors-tag'
                   onClick={resetFilters}
              >
                <Icon role={'icon'} type="clear" />
                <span className='main'>
                  {t('Clear all')}
                </span>
              </div>
              <Divider type={'vertical'} style={{ height: 16 }} />
              {
                tags.map((tag) => (
                  <div className='store-tracker-page-competitors-tag'
                       onClick={() => resetFilterByKey(tag)}
                  >
                    {t(tagsValue?.[tag]?.title)}:
                    <span className={cls('', { 'main': tag === 'languages' && selectedFilters?.[tag]?.length > 1 } )}>
                      {` ${tag === 'languages' ?
                          selectedFilters?.[tag]?.length > 1 ? 'Mixed values' : selectedFilters?.[tag]?.[0]?.value
                          :
                          `${tag === 'revenue' ? '$' : ''}${selectedFilters?.[tag]?.min || storeInnerPage?.full?.competitors?.filters?.[tag]?.min} - ${tag === 'revenue' ? '$' : ''}${selectedFilters?.[tag]?.max || storeInnerPage?.full?.competitors?.filters?.[tag]?.max}` }`}
                    </span>
                    <Icon role="icon" type="close_modal" color="#225aea" width={8} height={8} opacity={1} />
                  </div>
                ))
              }
            </div>
            :
            null
        }
      </Spin>
      <Spin spinning={storeInnerPage?.full?.competitors?.loading || storeInnerPage?.full?.competitors?.trackingLoading}>
        {
          storeInnerPage?.full?.competitors?.results?.length ?
            displayFormat === 'overview' ?
              <div className="product-database-table-body store-tracker-page-sales-table-wrapper cards"
                   id={'product-database-table-body'}
              >
                {
                  storeInnerPage?.full?.competitors?.results?.map((el, index) => (
                    <ProductCard key={el?.id}
                                 data={el}
                                 setRecordToCalc={setRecordToCalc}
                                 setModal={setModal}
                                 handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                 handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                 trackingHandler={toggleConnectProduct}
                                 isMobile={isMobile}
                                 id={id}
                                 rank={index}
                    />
                  ))
                }
              </div>
              :
              <div className="product-database-table-body store-tracker-page-sales-table-wrapper"
                   id={'product-database-table-body'}
              >
                <MemoTable components={{
                  // eslint-disable-next-line react/prop-types
                  table: ({ className, children, ...other }) => (
                    <table  {...other}
                            ref={tableRef}
                            className={cls(className, 'other-wrapper')}
                    >
                      {children}
                    </table>
                  ),
                }}
                           className="list-table"
                           loading={false}
                           rowClassName={'product-database-table-row'}
                           rowKey={(record) => record.id}
                           dataSource={
                             storeInnerPage?.full?.competitors?.results?.length ?
                               [...storeInnerPage?.full?.competitors?.results]
                               :
                               []
                           }
                           sortOrder={sortOrder}
                           columns={getColumns({
                             sortOrder,
                             setRecordToCalc,
                             setModal,
                             disabled: false,
                             handleOpenModalShopifyConnect,
                             handleOpenModalAutoDSConnect,
                             toggleConnectStore: null,
                             toggleConnectProduct,
                             t,
                             isAdmin: false,
                             isMobile,
                           })
                           }
                           pagination={false}
                           locale={{ emptyText: '-' }}
                           scroll={{
                             x: 1600, //'max-content',
                             y: 'calc(100dvh - 165px)',
                           }}
                           sticky={{
                             offsetHeader: 0,
                             offsetScroll: 0,
                             getContainer: () => document.querySelector('.product-database-table-body'),
                           }}
                           sortDirections={['descend', 'ascend', null]}
                           onChange={(pagination, filters, sorter) => {
                             handleSort(sorter);
                           }}
                />
              </div>
            :
            <StoreTrackerPageOverviewEmpty tags={tags}
                                           type={'competitors'}
                                           clearFilters={resetFilters}
            />
        }
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  trackCompetitor: (data) => dispatch(Creators.trackCompetitorStoreInnerPageRequest(data)),
  unTrackCompetitor: (data) => dispatch(Creators.unTrackCompetitorStoreInnerPageRequest(data)),
  getCompetitors: (data) => dispatch(Creators.getStoreCompetitorsRequest(data)),
  getCount: (data) => dispatch(Creators.getStoreCompetitorsCountRequest(data)),
  getFilters: (data) => dispatch(Creators.getStoreCompetitorsFiltersRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageCompetitors);
