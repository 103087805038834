import React from 'react';
import {useTranslation} from "react-i18next";
import { Divider, Spin } from 'antd';
import ButtonComponent from "../../../Components/Button";
import Modal from '../../../Components/Modal';

const DeleteTrackingModal = (
  {
    setModal,
    visible,
    record,
    disconnectHandler,
    view,
    isMobile,
  }
) => {
  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={t(`Stop tracking this ${view === 'stores' ? 'store' : 'product'}`)}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal delete-tracking-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              `${t('Are you sure you want to stop tracking')}`
            }
            <span className="bold"
                  style={{margin: '0 0 0 4px'}}
            >
              {record?.data?.title}
            </span>
            <span style={{margin: '0 4px 0 0'}}>
              ?
            </span>
            {
              t('You are always able to start tracking it again.')
            }
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className={'collection-modal-btn collection-modal-btn-cancel'}
                           text={t('Cancel')}
                           onClick={() => setModal(null)}
          />
          <ButtonComponent className={'collection-modal-btn collection-modal-btn-save red'}
                           text={t('Stop tracking')}
                           onClick={() => {
                             disconnectHandler(record?.data)
                             setModal(null);
                           }}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default DeleteTrackingModal;
