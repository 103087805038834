import React from 'react';
import { useSelector } from 'react-redux';


const PieChart = ({ className = '' }) => {

  const theme = useSelector(state => state?.nav?.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
    <path d="M10.0001 2.29166C5.74289 2.29166 2.29175 5.74279 2.29175 9.99999C2.29175 14.2572 5.74289 17.7083 10.0001 17.7083C13.3563 17.7083 16.2116 15.5633 17.2698 12.5694M10.0001 2.29166C14.2573 2.29166 17.7084 5.74279 17.7084 9.99999C17.7084 10.9009 17.5539 11.7658 17.2698 12.5694M10.0001 2.29166V9.99999L17.2698 12.5694" stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.36)'} strokeWidth="1.5" strokeLinejoin="round"/>
    </svg>
  ); 
};

export default PieChart;