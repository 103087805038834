import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cls from 'classname';
import acc from 'accounting';
import { Tooltip } from 'antd';
import './StoreTrackerPageTabs.less';

const StoreTrackerPageTabs = (
  {
    active,
    setActive,
    isTracked,
    storeInnerPage
  }) => {

  const {t} = useTranslation();

  const tabs = [
    {
      title: 'Overview',
      key: 'overview',
      count: false,
      disabled: false
    },
    {
      title: 'Sales & Revenue',
      key: 'sales_&_revenue',
      count: false,
      disabled: false
    },
    {
      title: 'Competitors',
      key: 'competitors',
      count: acc.formatNumber(storeInnerPage?.short?.competitors?.count || storeInnerPage?.full?.competitors?.count, 0, ','),
      disabled: !isTracked
    },
    {
      title: 'Facebook ads',
      key: 'facebook_ads',
      count: acc.formatNumber(storeInnerPage?.short?.ads?.count || storeInnerPage?.full?.ads?.count, 0, ','),
      disabled: !isTracked
    },
    {
      title: 'Products',
      key: 'products',
      count: acc.formatNumber(storeInnerPage?.short?.products?.count || storeInnerPage?.full?.products?.count, 0, ','),
      disabled: !isTracked
    },
    {
      title: 'Apps',
      key: 'apps',
      count: acc.formatNumber(storeInnerPage?.short?.apps?.count || storeInnerPage?.full?.apps?.count, 0, ','),
      disabled: !isTracked
    },
  ]

  return (
    <div className={'store-tracker-page-tabs'}>
      {
        tabs.map(el => (
          <span key={el.key}
                className={cls('store-tracker-page-tab', {
                  active: active === el?.key,
                  disabled: el?.disabled,
                })}
                onClick={() => !el?.disabled && setActive(el?.key)}
          >
            {
              el?.disabled ?
                <Tooltip placement="top"
                         title={t('To see this data you need to start tracking this store')}
                         arrow={false}
                         trigger={['hover']}
                         destroyTooltipOnHide={true}
                         overlayClassName={'disabled-calendar_tooltip'}
                         getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {t(el?.title)}
                </Tooltip>
                :
                t(el?.title)
            }
            {
              (el?.count || el?.count === 0) && !el?.disabled  ?
                <span className="count">
                  {acc.formatNumber(el?.count, 0, ',')}
                </span>
                :
                null
            }
          </span>
        ))
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTracked: state?.salesTracker?.storeInnerPage?.header?.is_tracked,
  storeInnerPage: state?.salesTracker?.storeInnerPage,
});

export default connect(mapStateToProps, null)(StoreTrackerPageTabs);
