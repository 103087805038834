import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import acc from 'accounting';
import dayjs from 'dayjs';
import cls from 'classname';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import { Dropdown, Tooltip } from 'antd';
import IconWithText from '../../../Components/Text';
import './ProductCard.less';

const DropdownRender = (
  {
    data,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
  }) => {

  const { t } = useTranslation();

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return (
    <Dropdown open={visibleDropdown}
              onOpenChange={handleInnerVisibleChange}
              dropdownRender={() => (
                <ul className={'inner-page-title-action-dropdown'}>
                  <li className="inner-page-title-action-dropdown-item"
                      onClick={() => {
                        setVisibleDropdown(false);
                        handleOpenModalShopifyConnect(`${data?.store?.id}_${data?.id}`);
                      }}
                  >
                    <IconWithText icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />}
                                  text={t('to Shopify')}
                    />
                  </li>
                  <li className="inner-page-title-action-dropdown-item"
                      onClick={() => {
                        setVisibleDropdown(false);
                        handleOpenModalAutoDSConnect({
                          'source': 'shopify',
                          'product_id': `${data?.store?.id}_${data?.id}`,
                        });
                      }}
                  >
                    <IconWithText icon={() => <Icon type={'auto_ds'} role={'icon'} />}
                                  text={t('to AutoDS')}
                    />
                  </li>
                </ul>
              )}
              destroyPopupOnHide={true}
              trigger={['click']}
              placement="bottomRight"
              getPopupContainer={(triggerNode) => triggerNode}
              overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
        <span className={'store-tracker-product-card-btn'}>
          {t('Import')}
        </span>
    </Dropdown>
  );
};


const ProductCard = (
  {
    id,
    isMobile,
    data,
    setRecordToCalc,
    setModal,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    trackingHandler,
    rank = null
  }) => {

  const { t } = useTranslation();

  return (
    <div className="store-tracker-product-card">
      <div className="store-tracker-product-card-image">
        {
          data?.main_image ?
            <img src={utils.changeImageSize(data?.main_image, 496)}
                 alt=""
                 onError={utils.addDefaultSrc}
            />
            :
            <Image src={Images.defaultImg} small={false} />
        }
        {
          rank !== null ?
            <span className="rank">
              {rank + 1 < 10 ? `0${rank + 1}` : rank + 1}
            </span>
            :
            null
        }
        <div className="store-tracker-product-card-image-count">
          <Icon type={'pictures'} role={'icon'} />
          <span>
            {data?.images}
          </span>
        </div>
      </div>
      <h3 className="store-tracker-product-card-title">
        <Tooltip overlayClassName="product_name-tooltip details-tooltip"
                 title={data?.title}
                 trigger={isMobile ? 'click' : 'hover'}
                 destroyTooltipOnHide={true}
                 getPopupContainer={(triggerNode) => triggerNode}
        >
          <span>
            {data?.title}
          </span>
        </Tooltip>
      </h3>
      <div className="store-tracker-product-card-price">
          <span className={'main'}
                onClick={() => {
                  setRecordToCalc(data);
                  setModal('numbers_breakdown');
                }}
          >
            ${acc.format(data?.usd_price, 2, ',', '.')}
            {
              data?.usd_price_max && data?.usd_price_max !== data?.usd_price &&
              <span>
                - ${acc.format(data?.usd_price_max, 2, ',', '.')}
              </span>
            }
          </span>
        <span className={'original'}>
            {
              (data?.original_price > 0 || data?.original_price_max > 0) && data?.store?.currency?.toLowerCase() !== "usd" ?
                <span>
                  {
                    acc.format(data?.original_price, 2, ',', '.')}
                  {` `}
                  {
                    data?.original_price_max > 0 &&
                    <span>
                      - {acc.format(data?.original_price_max, 2, ',', '.')}
                    </span>
                  }
                  {
                    (data?.original_price > 0 || data?.original_price_max > 0) &&
                    data?.store?.currency
                  }
                </span>
                :
                null
            }
          </span>
      </div>
      <div className="store-tracker-product-card-stat">
        <span className={'title'}>
          {
            t('Revenue')
          }
        </span>
        <span className={'value'}>
          {
            `$${acc.format(data?.revenue || 0, 2, ',', '.')}`
          }
        </span>
      </div>
      <div className="store-tracker-product-card-stat">
        <span className={'title'}>
          {
            t('Sales')
          }
        </span>
        <span className={'value'}>
          {acc.format(data?.sales || 0, 0, ',', '.')}
        </span>
      </div>
      <div className="store-tracker-product-card-stat">
        <span className={'title'}>
          {
            t('Creation date')
          }
        </span>
        <span className={'value'}>
          {dayjs(data?.created_at).format('DD MMM, YYYY')}
        </span>
      </div>
      <div className="store-tracker-product-card-btn-wrapper">
        {
          isMobile ?
            null
            :
            <DropdownRender data={{...data, store: {id: id}}}
                            handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                            handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
            />
        }
        <ButtonComponent
          className={cls('store-tracker-product-card-btn store-tracker-product-card-btn--track', { 'tracked': data?.is_tracked })}
          onClick={() => trackingHandler(data)}
          text={t(data?.is_tracked ? 'Stop tracking' : 'Start tracking')}
        />
      </div>
    </div>
  );
};

export default ProductCard;
