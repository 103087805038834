import React from 'react';
import './AdItemNavigation.less';
import { useSelector } from 'react-redux';
import Icon from '../../../Icon';
import { useNavigate } from 'react-router';
import utils from '../../../Utils/utils';
import Images from '../../../Images';
import Image from '../../../Components/Image';
import cls from 'classname';
import { useTranslation } from 'react-i18next';

const AdItemNavigation = ({ mainInfo, mode, isMobile }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const product = useSelector(
    (state) => state?.adSpot?.innerPagesInfo?.product,
  );
  const skeleton = mainInfo?.mainInfoLoading;

  if (isMobile) {
    return null;
  }
  if (skeleton) {
    const getWidths = (mode) => {
      return mode === 'detailed' ? [55, 170, 250] : [55, 170];
    };
    const widths = getWidths(mode);

    return (
      <div className="ad-item-nav">
        <div className="ad-item-nav-wrapper">
          <span className="ad-item-nav-tab" style={{ gap: 10 }}>
            {widths.map((el) => (
              <span
                key={el}
                className="link skeleton"
                style={{ width: el, height: 20 }}
              />
            ))}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="ad-item-nav">
      <div className="ad-item-nav-wrapper">
        <span className="ad-item-nav-tab">
          <span
            className="ad-item-nav-name"
            onClick={() => navigate('/ad-spot')}
          >
            {t('Ad spot')}
          </span>
        </span>
        <span
          className={cls('ad-item-nav-tab', {
            active: mode !== 'detailed',
          })}
        >
          <Icon role={'icon'} type={'arrow_slider_next'} />
          <div className={'ad-item-nav-logo'}>
            {mainInfo?.advertiser?.icon ? (
              <img
                src={mainInfo?.advertiser?.icon}
                alt=""
                onError={utils.addDefaultSrc}
                width="36"
                height="36"
              />
            ) : (
              <Image src={Images.defaultImg} small={true} />
            )}
          </div>
          <span
            className="ad-item-nav-name"
            onClick={mode === 'detailed' ? () => navigate(-1) : null}
          >
            {mainInfo?.advertiser?.name}
          </span>
        </span>
        {mode === 'detailed' && product.productInfo && (
          <span className="ad-item-nav-tab active">
            <Icon role={'icon'} type={'arrow_slider_next'} />
            <div className={'ad-item-nav-logo'}>
              {product?.productInfo?.main_image ? (
                <img
                  src={product?.productInfo?.main_image}
                  alt=""
                  onError={utils.addDefaultSrc}
                  width="36"
                  height="36"
                />
              ) : (
                <Image src={Images.defaultImg} small={true} />
              )}
            </div>
            <span className="ad-item-nav-name">
              {product?.productInfo?.title}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default AdItemNavigation;
