import React, { useState } from 'react';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from '../../../Components/Image';
import { columnTitles } from '../../../Components/titleSortFilters';
import Images from '../../../Images';
import TooltipColumnIcon from '../../ProductDatabasePage/components/TooltipColumnIcon';
import cls from 'classname';
import { Dropdown } from 'antd';
import IconWithText from '../../../Components/Text';
import UserTrackingsActionsModal from './UserTrackingsActionsModal';

const CellWrapper = ({
  setModal,
  children,
  style = {},
  record,
  setRecordToDelete,
}) => {
  return record?.scraping_status === 'untrackable' &&
    !record?.month_revenue &&
    !record?.week_revenue &&
    !record?.yesterday_revenue &&
    !record?.day_revenue ? (
    <div
      onClick={() => {
        setRecordToDelete({ type: 'store', data: { ...record } });
        setModal('untrackable_delete_tracking');
      }}
      style={{ cursor: 'pointer', ...style }}
    >
      {children}
    </div>
  ) : record?.scraping_status === 'gathering' ? (
    <div
      onClick={() => {
        setModal('gathering_data');
      }}
      style={{ cursor: 'pointer', ...style }}
    >
      {children}
    </div>
  ) : (
    <a
      href={`/sales-tracker/stores/store/${record?.internal_shop_id}`}
      target="_self"
      style={{ ...style }}
    >
      {children}
    </a>
  );
};

export const getColumns = ({
  sortOrder,
  setRecordToDelete,
  setModal,
  t,
  isMobile = false,
  isTrial,
  pinHandler
}) => [
  {
    title: () => 'Store',
    dataIndex: 'title',
    key: 'name',
    width: isMobile ? 'calc(100vw - 48px)' : 355,
    fixed: isMobile ? false : 'left',
    sorter: false,
    render: (text, record) => (
      <div className={'product-database-table-cell'}>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            pinHandler({view: 'stores', record: record});
          }}
          style={{ cursor: 'pointer', marginRight: 16 }}
        >
          <Icon type={'pin_icon'} role={'icon'} active={record?.pin_time} />
        </span>
        <CellWrapper
          style={{ flex: 'unset' }}
          setModal={setModal}
          record={record}
          setRecordToDelete={setRecordToDelete}
        >
          <div
            className={'product-database-table-image sales-store-table-image'}
          >
            {record?.logo || record?.favicon ? (
              <img
                src={record?.logo || record?.favicon}
                alt=""
                onError={utils.addDefaultSrc}
              />
            ) : (
              <Image src={Images.defaultImg} small={true} />
            )}
          </div>
        </CellWrapper>
        <div className={'product-info-wrapper'}>
          <CellWrapper
            setModal={setModal}
            record={record}
            setRecordToDelete={setRecordToDelete}
          >
            <p className={'product-name'}>
              {record?.title}
              {record?.is_demo && (
                <span className="product-name-tag">{t('example')}</span>
              )}
              {record?.scraping_status === 'untrackable' ? (
                <span
                  className="product-name-tag untrackable"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setRecordToDelete({ type: 'store', data: { ...record } });
                    setModal('untrackable_delete_tracking');
                  }}
                >
                  {t('untrackable')}
                </span>
              ) : record?.scraping_status === 'gathering' ? (
                <span className="product-name-tag gathering">
                  {t('gathering data')}
                </span>
              ) : null}
              <span className="product-is-deleted">
                {record?.is_deleted_from_st ? (
                  <Icon
                    role="button"
                    type={`unavailable_store_triangle`}
                    className={`unavailable-store-triangle`}
                    width={18}
                    height={18}
                    titleText={t('This store has been closed or paused.')}
                    tooltipProps={{
                      placement: 'right',
                      trigger: 'hover',
                      destroyTooltipOnHide: true,
                      overlayClassName: cls(
                        'details-tooltip',
                        `unavailable-store-triangle-tooltip`,
                      ),
                      getPopupContainer: () =>
                        document.getElementById('product-database-table-body'),
                    }}
                  />
                ) : null}
              </span>
            </p>
          </CellWrapper>
          <p>
            <a
              href={`https://${record?.custom_domain}`}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <span className={'product-url'}>
                {record?.all_domains?.length > 0 && !isMobile ? (
                  <>
                    {record?.user_domain
                      ? record?.user_domain
                      : record?.custom_domain}
                    <TooltipColumnIcon record={record} isST={true} />
                  </>
                ) : record?.user_domain ? (
                  record?.user_domain
                ) : (
                  record?.custom_domain
                )}
              </span>
            </a>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Links',
    fixed: false,
    dataIndex: 'quick_search',
    key: 'suppliers',
    width: 65,
    sorter: false,
    render: (text, record) => (
      <div className={'product-database-table-cell'}>
        <div className="product-suppliers">
          {/*{record?.quick_search?.map(el => (*/}
          <a
            key={'facebook'}
            href={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&q=${
              record?.custom_domain?.includes('www')
                ? record?.custom_domain
                    ?.split('.')
                    ?.filter((el) => el !== 'www')
                    .join('.')
                : record?.custom_domain
            }&search_type=keyword_unordered&media_type=all`}
            target="_blank"
            rel="noopener noreferrer"
            className="link with-img"
          >
            <Icon
              role="button"
              type={`search_facebook`}
              className={`facebook-icon`}
              width={20}
              height={20}
              titleText={() => (
                <>
                  Search on{' '}
                  <span style={{ textTransform: 'capitalize' }}>Facebook</span>
                </>
              )}
              tooltipProps={{
                placement: 'bottom',
                trigger: 'hover',
                destroyTooltipOnHide: true,
                overlayClassName: cls('details-tooltip', `facebook-tooltip`),
                getPopupContainer: () =>
                  document.getElementById('product-database-table-body'),
              }}
            />
          </a>
          {/*))}*/}
        </div>
      </div>
    ),
  },
  {
    title: () => {
      let result = 'Sales Today';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'day_revenue',
      });
    },
    dataIndex: 'day_revenue',
    key: 'day_revenue',
    width: 150,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['day_revenue'] || 0) - (b['day_revenue'] || 0),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="product-store-info">
            {record?.day_revenue ? (
              <p>${acc.format(record?.day_revenue, 2, ',', '.')}</p>
            ) : (
              <p>-</p>
            )}
            {record?.day_sales ? (
              <p>
                {acc.format(record?.day_sales, 0, ',', '.')}{' '}
                {record?.day_sales === 1 ? t('Sale') : t('Sales')}
              </p>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: () => {
      let result = 'Sales Yesterday';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'yesterday_revenue',
      });
    },
    dataIndex: 'yesterday_revenue',
    key: 'yesterday_revenue',
    width: 180,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 :
      (a['yesterday_revenue'] || 0) - (b['yesterday_revenue'] || 0),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="product-store-info">
            {record?.yesterday_revenue ? (
              <p>${acc.format(record?.yesterday_revenue, 2, ',', '.')}</p>
            ) : (
              <p>-</p>
            )}
            {record?.yesterday_sales ? (
              <p>
                {acc.format(record?.yesterday_sales, 0, ',', '.')}{' '}
                {record?.yesterday_sales === 1 ? t('Sale') : t('Sales')}
              </p>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: () => {
      let result = 'Sales 7 Days';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'week_revenue',
      });
    },
    dataIndex: 'week_revenue',
    key: 'week_revenue',
    width: 180,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['week_revenue'] || 0) - (b['week_revenue'] || 0),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="product-store-info">
            {record?.week_revenue ? (
              <p>${acc.format(record?.week_revenue, 2, ',', '.')}</p>
            ) : (
              <p>-</p>
            )}
            {record?.week_sales ? (
              <p>
                {acc.format(record?.week_sales, 0, ',', '.')}{' '}
                {record?.week_sales === 1 ? t('Sale') : t('Sales')}
              </p>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: () => {
      let result = 'Sales 30 Days';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'month_revenue',
      });
    },
    dataIndex: 'month_revenue',
    key: 'month_revenue',
    width: 180,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['month_revenue'] || 0) - (b['month_revenue'] || 0),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="product-store-info">
            {record?.month_revenue ? (
              <p>${acc.format(record?.month_revenue, 2, ',', '.')}</p>
            ) : (
              <p>-</p>
            )}
            {record?.month_sales ? (
              <p>
                {acc.format(record?.month_sales, 0, ',', '.')}{' '}
                {record?.month_sales === 1 ? t('Sale') : t('Sales')}
              </p>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: () => {
      let result = 'Store Info';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'products_count',
      });
    },
    dataIndex: 'products_count',
    key: 'products_count',
    width: 200,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['products_count'] || 0) - (b['products_count'] || 0),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="product-store-info">
            <p>
              {acc.format(record?.products_count, 0, ',', '.')} {t('Products')}
            </p>
            <p style={{ textTransform: 'capitalize' }}>{record?.language}</p>
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: () => {
      let result = 'Store creation date';
      return columnTitles({
        sortOrder,
        title: result,
        key: 'created_at',
      });
    },
    dataIndex: 'created_at',
    key: 'created_at',
    width: 200,
    sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 :
      dayjs(a['created_at']).valueOf() - dayjs(b['created_at']).valueOf(),
    render: (text, record) => (
      <CellWrapper
        style={{ flex: 'unset' }}
        setModal={setModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
      >
        <div
          className={
            'product-database-table-cell product-database-table-cell_clickable'
          }
        >
          <div className="store-creation-date">
            {record?.created_at
              ? dayjs(record?.created_at).format('MMMM DD, YYYY')
              : 'No Data'}
          </div>
        </div>
      </CellWrapper>
    ),
  },
  {
    title: '',
    key: 'actions',
    width: 40,
    sorter: false,
    fixed: 'right',
    render: (text, record) => (
      <div
        className={
          'product-database-table-cell sales-tracker-store-table-cell cell-actions'
        }
      >
        <div className="product-actions">
          <span>
            <DropdownRender
              record={record}
              t={t}
              isMobile={isMobile}
              isTrial={isTrial}
              setModal={setModal}
              setRecordToDelete={setRecordToDelete}
            />
          </span>
        </div>
      </div>
    ),
  },
];

export const DropdownRender = ({
  record,
  t,
  isMobile,
  isTrial,
  setModal,
  setRecordToDelete,
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [innerModal, setInnerModal] = useState(false);

  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return isMobile ? (
    <>
      <span
        className={'product-actions-options'}
        onClick={() => setInnerModal(true)}
      >
        <Icon type="three_dots" role="button" />
      </span>
      <UserTrackingsActionsModal
        setInnerModal={setInnerModal}
        view={'Store'}
        visible={innerModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
        setModal={setModal}
        isTrial={isTrial}
      />
    </>
  ) : (
    <Dropdown
      open={visibleDropdown}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul
          className={'filter-preset-action-dropdown database-action-dropdown'}
        >
          <li
            className={'action-dropdown_item active'}
            onClick={() => {
              setVisibleDropdown(false);
              if (
                record?.scraping_status === 'untrackable' &&
                !record?.month_revenue &&
                !record?.week_revenue &&
                !record?.yesterday_revenue &&
                !record?.day_revenue
              ) {
                setRecordToDelete({ type: 'store', data: { ...record } });
                setModal('untrackable_delete_tracking');
              } else if (record?.scraping_status === 'gathering') {
                setModal('gathering_data');
              } else {
                window.open(
                  `/sales-tracker/stores/store/${record?.internal_shop_id}`,
                  '_self',
                );
              }
            }}
          >
            <IconWithText
              size={24}
              icon={() => <Icon type={'product_insights'} role={'icon'} />}
              text={t(`Store insights`)}
            />
          </li>
          <li
            className={'action-dropdown_item active tracked'}
            onClick={() => {
              setVisibleDropdown(false);
              if (isTrial && record?.scraping_status !== 'untrackable') {
                setModal('can_not_remove_tracking');
              } else {
                setRecordToDelete({ type: 'store', data: { ...record } });
                setModal('delete_tracking');
              }
            }}
          >
            <IconWithText
              size={24}
              icon={() => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                    stroke="#D71313"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              )}
              text={t(`Stop tracking store`)}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() =>
        document.getElementById('product-database-table-body') ||
        document.getElementById('product-database-table-cards')
      }
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" />
      </span>
    </Dropdown>
  );
};
