import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from "react-i18next";
import ButtonComponent from "../../../Components/Button";
import utils from "../../../Utils/utils";
import { Divider, Spin } from 'antd';
import './StoreAppsModal.less';
import Icon from "../../../Icon";
import {useSelector} from "react-redux";

const StoreAppModal = ({name, average_rating, link, icon, review_count}) => {

  const {t} = useTranslation();

  return (
    <div className={'store-app-wrapper'}>
      <div className={'store-app-info'}>
        <div className={'store-app-image'}
        >
          <img src={utils.changeImageSize(icon, 124)}
               alt=""
               onError={utils.addDefaultSrc}
               width="40" height="40"
          />
        </div>
        <div className={'product-info-wrapper'} style={{flex: 1}}>
          <p className={'store-app-name'}>{name}</p>
          <div className={'store-app-item'}>
            <div className={'store-app-item-text'}>
              <Icon role="icon" type="rating"/>
              <span className={'store-app-rating'}>
                <span>
                  {average_rating}
                </span>
                <span> {t('rating')}</span>
              </span>
            </div>
            <Divider className="store-app-item-divider" type={'vertical'} />
            <div className={'store-app-item-text'}>
              <Icon role="icon" type="ad_spot_chart_comments" width={16} height={16}/>
              <span className={'store-app-review'}>
                <span>
                  {review_count}
                </span>
                <span> {t('reviews')}</span>
              </span>
            </div>
          </div>
        </div>

      </div>

      <ButtonComponent text={t('View app')}
                       onClick={() => window.open(link, '_blank')}
                       className={'store-app-button'}
      />

    </div>
  )

}

const StoreAppsModal = (
  {
    data = [],
    getApps = null,
    count = 0,
    loading = false,
    id = null
  }) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);

  const theme = useSelector(state => state?.nav?.theme);

  const [pageNumber, setPageNumber] = useState(1);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && data?.length < count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    getApps && getApps({ internal_shop_id: id, page: pageNumber, page_size: 20 });
  }, [pageNumber]);

  useEffect(() => {
    const tableContent = tableRef.current || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, count, data?.length]);

  return (
    <div className={'load-filter-preset-wrapper store-apps-modal-wrapper'}>
      <div className="load-filter-preset-title">
          {t('Store apps')}
        <p className="load-filter-preset-subtitle">
          {t('Here is a list of application store is using.')}
        </p>
      </div>
      <Spin spinning={loading}>
        <div className="store-apps-wrapper"
             id={'product-database-table-body'}
             ref={tableRef}
        >
          {
            data?.length ?
              data.map((el, i) => (
                <StoreAppModal key={i} {...el} />
              ))
              :
              <div className="empty-wrapper">
                <Icon width={40} height={40}
                      role="icon"
                      type="empty_warning"
                      fill={theme === 'dark' ? '#2B3450' : '#C5CCE3'}
                />
                <p>
                  {t('Currently, no apps have been identified for this store.')}
                </p>
                <p>
                  {t('There may be no apps installed, or please revisit at a later time for potential updates.')}
                </p>
              </div>
          }
        </div>
      </Spin>
    </div>
  );
};

export default StoreAppsModal;
