import React, { useEffect, useRef, useState } from 'react';
import cls from 'classname';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Creators from './reducer';
import NavActions from '../../Redux/NavRedux';
import { Spin, Row, Modal } from 'antd';
import ReactPlayer from 'react-player';
import Icon from '../../Icon';
import { imageBig } from './tabs-data';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import Drops from './components/drops';
import SubUnSubComponent from '../../Components/Subscribed';
import Panel from '../../Components/Tabs/panel';
import Skeleton from './components';
import CollectionsActions from '../CollectionsPage/reducer';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import './styles.less';
import './styles-subscribed.less';

export const getClassName = (other, category) => {
  let className = '';

  switch (true) {
    case category === 'random':
      className = 'theme-default';
      break;
    case category === 'household':
      className = 'theme-red';
      break;
    case ['fashion', 'fashion_men'].includes(category):
      className = 'theme-yellow';
      break;
    case ['baby', 'fashion_women'].includes(category):
      className = 'theme-pink';
      break;
    case category === 'electronics':
      className = 'theme-purple';
      break;
    case category === 'beauty':
      className = 'theme-green';
      break;
    case category === 'pets':
      className = 'theme-brown';
      break;
    default:
      className = 'theme-default';
      break;
  }

  return cls(className, other);
};

const Subscribed = (
  {
    loading,
    skeletonLoading,
    userCategoryDataLoading,
    dropsLoading,
    categories,
    subscriptions,
    getStatus,
    getUserCategoryData,
    dispatch,
    isMobile,
    tabPosition,
    activeTab,
    userInfo,
    setLastLanguage,
  }) => {

  const scrollRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.title = 'Portfolio - Dropship';
    setLastLanguage(userInfo?.language);
    dispatch(CollectionsActions.removeCollection());
    dispatch(CollectionsActions.getCollectionsRequest({ page_size: 40 }));
    dispatch(BreadcrumbsActions.clearBreadCrumbs());
    dispatch(
      BreadcrumbsActions.addBreadCrumb({
        name: 'Portfolio',
        link: '/portfolio',
      }),
    );

    // GET ALL CATEGORY
    if (!categories?.length) getStatus();
    getUserCategoryData({ id: categories[activeTab]?.id || 2 });

    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  // SCROLL TABS
  useEffect(() => {
    if (scrollRef.current && isMobile && activeTab) {
      scrollRef.current.osInstance().scroll({ x: tabPosition?.x || 0 });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [skeletonLoading]);

  // SUBSCRIBE TO CATEGORY - ROUTE TO UPCOMING DROPS PAGE
  const onSubscribeHandler = () => {
    dispatch(push('/setting/plan'));
  };

  // GET NEXT DROP ACTION HANDLER
  const getNextList = () => {
    if (subscriptions?.next) {
      dispatch(
        Creators.getNextDropsRequest({
          link: subscriptions.next,
        }),
      );
    }
  };

  // SHOW SKELETON
  if (skeletonLoading || userCategoryDataLoading) return <Skeleton isMobile={isMobile} />

  return (
    <div className="portfolio-page">
      <TopPageHeader icon={'portfolio_dashboard'}
                     title={'Portfolio'}
                     subtitle={'Receive winning products on a weekly basis'}
                     watchTutorialHandler={() => setVisible(true)}
                     withButton
                     isMobile={isMobile}
      />
      <div className="portfolio_wrap tabs_wrapper">
        <Spin spinning={loading || !categories.length}>
          <Panel className={getClassName(
            `panel--${categories[activeTab]?.name || 'random'}`,
            categories[activeTab]?.name || 'random',
          )}
          >
            <Row gutter={{ xs: 8, sm: 0 }}>
              {
                !categories[activeTab]?.subs_status && !subscriptions?.count ?
                  (
                    <SubUnSubComponent imageName={imageBig[categories[activeTab]?.name] || 'random'}
                                       categoryText={subscriptions?.message || ''}
                                       categoryName={categories[activeTab]?.name || 'random'}
                                       categoryTitle={categories[activeTab]?.title || 'random'}
                                       active={categories[activeTab]?.status || false}
                                       subscribeFunc={onSubscribeHandler}
                    />
                  )
                  :
                  (
                    <Drops {...subscriptions}
                           getNextList={getNextList}
                           dropsLoading={dropsLoading}
                           drops={subscriptions?.results || []}
                           isMobile={isMobile}
                           category={categories[activeTab]}
                    />
                  )}
            </Row>
          </Panel>
        </Spin>
      </div>
      <div className={cls('portfolio_empty-footer', {
        'portfolio_empty-footer--with-btn':
          !categories[activeTab]?.subs_status,
      })} />
      <Modal className="change-modal custom-modal video-modal"
             {...(isMobile ? { transitionName: '' } : null)}
             getContainer={() => document.getElementById('global-wrap')}
             centered={!isMobile}
             open={visible}
             closeIcon={
               <Icon role="icon" type="close_modal" color="#225aea" opacity={1} />
             }
             destroyOnClose
             closable="true"
             onCancel={() => setVisible(false)}
             footer={null}
      >
        <ReactPlayer controls={true}
                     url={'https://www.youtube.com/watch?v=SaL0W9Lq1To'}
                     height={'100%'}
                     playing={true}
                     width={'100%'}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.portfolio.loading,
  skeletonLoading: state.portfolio.skeletonLoading,
  userCategoryDataLoading: state.portfolio.userCategoryDataLoading,
  dropsLoading: state.portfolio.dropsLoading,
  errors: state.portfolio.errors,
  categories: state.portfolio.categories,
  subscriptions: state.portfolio.subscriptions,
  isMobile: state.nav.isMobile,
  tabPosition: state.nav.tabPosition,
  activeTab: state.portfolio.activeTab,
  lastLanguage: state.portfolio.lastLanguage,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getStatus: () => dispatch(Creators.getCategoryStatusRequest()),
  setLastLanguage: (data) => dispatch(Creators.setLastLanguage(data)),
  setTabPosition: (value) =>
    dispatch(NavActions.navSetProp('tabPosition', value)),
  getUserCategoryData: ({ id }) =>
    dispatch(Creators.getUserCategoryDataRequest({ id })),
  setActiveTabIndex: ({ activeTab }) =>
    dispatch(Creators.setActiveTabIndex({ activeTab })),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribed);
