import React from 'react';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import ButtonComponent from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import './AppCard.less';

const AppCard = (
  {
    data
  }) => {

  const {t} = useTranslation();

  return (
    <div className="store-tracker-app-card">
      <div className="store-tracker-app-card-header">
        <div className="image">
          {
            data?.icon ?
              <img src={utils.changeImageSize(data?.icon, 496)}
                   alt=""
                   onError={utils.addDefaultSrc}
              />
              :
              <Image src={Images.defaultImg} small={false} />
          }
        </div>
        <ButtonComponent className='store-tracker-app-card-btn'
          onClick={() => window.open(data?.link, '_blank')}
          text={t('View app')}
        />
      </div>
      <h3 className="store-tracker-app-card-title">
        {data?.name}
      </h3>
      <p className="store-tracker-app-card-description">
        Placeholder
      </p>
      <div className="store-tracker-app-card-footer">
        <div className="rating">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.90688 1.23595C8.54178 0.476038 7.45747 0.476039 7.09238 1.23595L5.51332 4.52259C5.51332 4.52259 5.51146 4.52513 5.50851 4.52551L1.87544 5.00116C1.03824 5.11077 0.696867 6.14314 1.31533 6.72623L3.97142 9.23043C3.97261 9.23155 3.97251 9.23285 3.97251 9.23285L3.30547 12.8106C3.14983 13.6454 4.03334 14.2761 4.77283 13.8775L7.99591 12.1401C7.99815 12.1389 8.0011 12.1389 8.00334 12.1401L11.2264 13.8775C11.9659 14.2761 12.8494 13.6454 12.6938 12.8106L12.0267 9.23285C12.0267 9.23285 12.0266 9.23155 12.0278 9.23043L14.6839 6.72623C15.3024 6.14314 14.961 5.11077 14.1238 5.00116L10.4907 4.52551C10.4878 4.52513 10.4859 4.52259 10.4859 4.52259L8.90688 1.23595Z" fill="#F6B60E" />
          </svg>
          <span>
            {`${data?.average_rating || '0.0'} ${t('rating')}`}
          </span>
        </div>
        <span className="dot" />
        <div className="reviews">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M11.4999 9.5H12.8347C13.5711 9.5 14.1681 8.90305 14.1681 8.16667V3.83333C14.1681 3.09695 13.5711 2.5 12.8347 2.5H6.00138C5.265 2.5 4.66805 3.09695 4.66805 3.83333V5.16667M10.1681 5.16667H3.16805C2.43167 5.16667 1.83472 5.76362 1.83472 6.5V10.8333C1.83472 11.5697 2.43167 12.1667 3.16805 12.1667H4.00138V13.8333L7.00138 12.1667H10.1681C10.9044 12.1667 11.5014 11.5697 11.5014 10.8333V6.5C11.5014 5.76362 10.9044 5.16667 10.1681 5.16667Z"
              stroke="#A1AAC8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span>
            {`${acc.formatNumber(data?.review_count || 0, 0, ',')} ${t('reviews')}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppCard;
