import React from 'react';
import { useSelector } from 'react-redux';

const Likes = () => {
  const theme = useSelector((state) => state?.nav?.theme);
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7408 1.3335C7.30464 1.3335 6.90322 1.57693 6.70285 1.96588L4.28115 6.66683H2.50016C1.85583 6.66683 1.3335 7.18916 1.3335 7.8335V12.8335C1.3335 13.4778 1.85583 14.0002 2.50016 14.0002H11.6148C12.8503 14.0002 13.9005 13.0977 14.0864 11.8763L14.6443 8.2096C14.8746 6.69646 13.7034 5.3335 12.1728 5.3335H9.26158L9.55536 3.44926C9.72894 2.33597 8.8643 1.3335 7.7408 1.3335ZM4.00016 7.66683V13.0002H2.50016C2.40812 13.0002 2.3335 12.9255 2.3335 12.8335V7.8335C2.3335 7.74145 2.40812 7.66683 2.50016 7.66683H4.00016Z"
        fill={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.259)'}
      />
    </svg>
  );
};

export default Likes;
