import React, {useRef} from 'react';
import cls from "classname";
import {Table} from "antd";
import {getColumns} from "./DataTableColumns";
import { useTranslation } from 'react-i18next';

const DataTable = (
  {
    loading,
    data,
    setModal,
    setVisible,
    setRecordToDelete,
    setFlag
  }
) => {
  const tableRef = useRef();
  const { t } = useTranslation();

  return (
    <div className={'products-database-table-wrapper sales-tracker-stores-table-wrapper'}>
      <div id={'product-database-table-body'} className="product-database-table-body sales-tracker-stores-table-body sales-tracker-stores-table-body-admin">
        <Table
          components={{
            // eslint-disable-next-line react/prop-types
            table: ({className, children, ...other}) => (
              <table
                {...other}
                ref={tableRef}
                className={cls(className, 'other-wrapper')}
              >
                {children}
              </table>
            ),
          }}
          className="list-table"
          loading={loading}
          rowClassName={'product-database-table-row'}
          rowKey={(record) => record.title + record['store_created_at']}
          dataSource={data?.length ? [...data] : []}
          columns={getColumns({
            setRecordToDelete,
            setVisible,
            setModal,
            setFlag,
            t,
          })}
          pagination={false}
          scroll={{
            x: 1600, //'max-content',
            y: 'calc(100dvh - 96px - 54px)',
          }}
          sticky={true}
          sortDirections={['descend', 'ascend', null]}
        />
      </div>
    </div>
  );
};

export default React.memo(DataTable, (prev, next) => prev.loading === next.loading && prev?.data === next?.data);
