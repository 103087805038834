import { API, encryptedData } from '../../Services/Api';
const request = new API();

export const getAdLibraryFilters = () =>
  request.api.get(`ad_library/choice_filters/`, null);

export const getAdLibraryPresets = () =>
  request.api.get(`ad_library/filter_presets/`, null);

export const createAdLibraryPreset = (data) =>
  request.api.post(`ad_library/filter_presets/`, encryptedData(data));

export const deleteAdLibraryPreset = (record) =>
  request.api.delete(`ad_library/filter_presets/${record.id}/`, null);

export const updateAdLibraryPreset = (data) =>
  request.api.patch(
    `ad_library/filter_presets/${data.record.id}/`,
    encryptedData({ name: data.name }),
  );

export const getAdLibraryAds = (data) => {
  const buildQueryParams = (params) =>
    Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) =>
        Array.isArray(value)
          ? value
              .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
              .join('&')
          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join('&');

  const queryString = buildQueryParams(data);

  return request.api.get(`ad_library/ads/search/?${queryString}`);
};

export const getAdLibraryAdsDefault = (data) => {
  const { page_size, page_number, ordering } = data;
  let url = `ad_library/ads/search/default/?page_size=${page_size}&page_number=${page_number}`;

  if (ordering) {
    url += `&ordering=${ordering}`;
  }

  return request.api.get(url);
};

export const getAdLibraryAdsCollation = (data) => {
  const { page_size, page_number, collation_id, advertiser_id } = data;

  return request.api.get(
    `ad_library/collations/${advertiser_id}/${collation_id}/adsets/?page_size=${page_size}&page_number=${page_number}`,
  );
};

export const getCollationInsightsCharts = (data) => {
  const { advertiser_id, collation_id, view, isAdsetsRequest, ad_archive_id } =
    data;

  if (!advertiser_id || (!collation_id && !ad_archive_id) || !view) {
    return;
  }

  const baseUrl = isAdsetsRequest
    ? `ad_library/adsets/${advertiser_id}/${ad_archive_id}/insights/charts/`
    : `ad_library/collations/${advertiser_id}/${collation_id}/insights/charts/`;

  return request.api.get(`${baseUrl}?view=${view}`);
};

export const getCollationInsightsInfo = (data) => {
  const { advertiser_id, collation_id, isAdsetsRequest, ad_archive_id } = data;
  if (!advertiser_id || (!collation_id && !ad_archive_id)) {
    return;
  }
  const baseUrl = isAdsetsRequest
    ? `ad_library/adsets/${advertiser_id}/${ad_archive_id}/insights/info/`
    : `ad_library/collations/${advertiser_id}/${collation_id}/insights/info/`;

  return request.api.get(baseUrl);
};

export const getCollationInsightsBreakDown = (data) => {
  const { advertiser_id, collation_id, isAdsetsRequest, ad_archive_id,view } = data;
  if (!advertiser_id || (!collation_id && !ad_archive_id)) {
    return;
  }
  const baseUrl = isAdsetsRequest
    ? `ad_library/adsets/${advertiser_id}/${ad_archive_id}/insights/breakdown/`
    : `ad_library/collations/${advertiser_id}/${collation_id}/insights/breakdown/`;

  return request.api.get(`${baseUrl}?view=${view}`);
};
export const getAdLibraryStatusChart = (data) =>
  request.api.get(`/ad_library/advertiser/${data.advertiser_id}/ads/status_charts/`, null);

