import React,{useState,useEffect} from 'react';
import cls from "classname";
import { useTranslation } from 'react-i18next';
import {Table} from "antd";
import {getColumns} from "./TableColumns";
import "./Table.less"
import TableSkeleton from './TableSkeleton';


const AdLibraryTable = (
  {
    data,
    isMobile=false,
    insightsBreakDownLoading={insightsBreakDownLoading}
  }
) => {
  const {t} = useTranslation();

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      const sorted = [...data].sort((a, b) => {
        const valA = a[sorter.field];
        const valB = b[sorter.field];

        if (typeof valA === 'number' && typeof valB === 'number') {
          return sorter.order === 'ascend' ? valA - valB : valB - valA;
        }
        if (typeof valA === 'string' && typeof valB === 'string') {
          return sorter.order === 'ascend'
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA);
        }
        return 0;
      });
      setSortedData(sorted);
    } else {
      setSortedData([...data]);
    }
  };
  return (
    
    <>
      {insightsBreakDownLoading || (data?.length === 0 || !Object.keys(data).length || !data) ? <TableSkeleton isMobile={isMobile} /> : 
      <div className='ad-library-modal-table'>
          <div className="product-database-table-body adLibrary-modal-table">
          <Table
          components={{
          // eslint-disable-next-line react/prop-types
          table: ({className, children, ...other}) => (
          <table
        {...other}
          className={cls(className, 'other-wrapper')}
          >
        {children}
          </table>
          ),
        }}
          className="list-table"
          rowClassName={'adLibrary-table-row'}
          rowKey={(record) => record.id}
          dataSource={sortedData || []}
          columns={getColumns({ t })}
          pagination={false}
          locale={{ emptyText: '-' }}
          scroll={{
          y: '65vh',
        }}
          sticky={true}
          sortDirections={['descend', 'ascend', null]}
          onChange={handleTableChange}
          />
          </div>
      </div>
    }
    </>
  );
};

export default AdLibraryTable;
