import React from 'react';
import cls from 'classname';
import InnerPageTitleBlockSkeleton from './InnerPageTitleBlockSkeleton';
import InnerPageInfoBlockSkeleton from './InnerPageInfoBlockSkeleton';
import MainBlockSkeleton from './MainBlockSkeleton';
import { Divider } from 'antd';

const StoreTrackerPage = (
  {
    isMobile = false
  }) => {

  return (
    <div className="product-database-page sales-tracker-page sales-tracker-store-page">
      <div className={'custom-breadcrumbs skeleton'}
           style={{ alignItems: 'center' }}
      >
        <span className={'link skeleton'}
              style={{ width: 110, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 16, height: 16 }}
        />
        <span className={'link skeleton'}
              style={{ width: 200, height: 20 }}
        />
      </div>
      <InnerPageTitleBlockSkeleton isMobile={isMobile} type={'store'} />
      <div className={'store-tracker-page-tabs'}>
        {
          [1, 2, 3, 4, 5, 6].map(el => (
            <span key={el}
                  className={cls('store-tracker-page-tab')}
            >
            <span className={'link skeleton'}
                  style={{ width: 60, height: 20 }}
            />
          </span>
          ))
        }
      </div>
      <div className="store-tracker-page-tab-content store-tracker-page-overview">
        <InnerPageInfoBlockSkeleton isMobile={isMobile} />
        <MainBlockSkeleton isMobile={isMobile} />
        <h4 className="store-tracker-page-tab-subtitle">
        <span className={'link skeleton'}
              style={{ width: 70, height: 20 }}
        />
        </h4>
        <div className="chart-block">
          <div className="chart-block_header">
            <div className="chart-block_title"
                 style={{ display: 'flex', alignItems: 'center' }}>
            <span className={'link skeleton'}
                  style={{ width: isMobile ? 50 : 200, height: 20 }}
            />
              <Divider type={'vertical'} style={{ margin: '0 12px' }} />
              <span className={'link skeleton'}
                    style={{ width: isMobile ? 30 : 157, height: 16 }}
              />
            </div>
            <span className={'link skeleton'}
                  style={{ width: isMobile ? '100%' : 72, height: 40, marginTop: isMobile ? 16 : 0 }}
            />
          </div>
          <div className="chart-block_total-count-block"
               style={{
                 display: 'flex',
                 alignItems: isMobile ? 'flex-start' : 'center',
                 gap: isMobile ? 16 : 30,
                 marginTop: 16,
                 flexDirection: isMobile ? 'column' : 'row',
               }}
          >
            <div className="chart-block_total-count"
                 style={{ marginLeft: 0 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
              />
                <span className={'link skeleton'}
                      style={{ width: 88, height: 20 }}
                />
              </div>
              <span className={'link skeleton'}
                    style={{ width: 123, height: 28, marginTop: 4, marginLeft: 20 }}
              />
            </div>
            <div className="chart-block_total-count"
                 style={{ marginLeft: 0 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
              />
                <span className={'link skeleton'}
                      style={{ width: 88, height: 20 }}
                />
              </div>
              <span className={'link skeleton'}
                    style={{ width: 123, height: 28, marginTop: 4, marginLeft: 20 }}
              />
            </div>
          </div>
          <span className={'link skeleton'}
                style={{ width: '100%', height: 308, marginTop: 16 }}
          />
        </div>
        <h4 className="store-tracker-page-tab-subtitle">
          <span className={'link skeleton'}
                style={{ width: 70, height: 20 }}
          />
        </h4>
        <div className="store-tracker-page-overview-blocked">
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: 24 }}
          />
        </div>
        <h4 className="store-tracker-page-tab-subtitle">
          <span className={'link skeleton'}
                style={{ width: 70, height: 20 }}
          />
        </h4>
        <div className="store-tracker-page-overview-blocked">
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: 24 }}
          />
        </div>
        <h4 className="store-tracker-page-tab-subtitle">
          <span className={'link skeleton'}
                style={{ width: 70, height: 20 }}
          />
        </h4>
        <div className="store-tracker-page-overview-blocked">
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: 24 }}
          />
        </div>
        <h4 className="store-tracker-page-tab-subtitle">
          <span className={'link skeleton'}
                style={{ width: 70, height: 20 }}
          />
        </h4>
        <div className="store-tracker-page-overview-blocked">
          <span className={'link skeleton'}
                style={{ width: '100%', height: '100%', borderRadius: 24 }}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreTrackerPage;
