import { takeLatest, put, call, delay } from 'redux-saga/effects';

import { push } from 'connected-react-router';
import * as services from './services';
import Creators, { SalesTrackerTypes as constants } from './reducer';
import { openNotificationWithIcon } from '../../Components/Notification/index';
import { DefaultMsg } from '../../Components/Notification/notification-message';
import React from 'react';
import { sendExtensionMessage } from '../../Utils/extension';
import FileDownload from 'js-file-download';

const showNotification = (data) => openNotificationWithIcon({
  style: { width: data.width },
  className: data.className,
  message: (
    <DefaultMsg text={data.text}
                icon={data.icon}
                title={data.title}
                textIsWide={data.textIsWide}
    />
  )});

const actions = {
  getProductReportActions: {
    request: Creators.getProductReportRequest,
    success: Creators.getProductReportSuccess,
    errors: Creators.getProductReportFailure,
  },
  getStoreReportActions: {
    request: Creators.getStoreReportRequest,
    success: Creators.getStoreReportSuccess,
    errors: Creators.getStoreReportFailure,
  },
  trackCompetitorStoreInnerPageActions: {
    request: Creators.trackCompetitorStoreInnerPageRequest,
    success: Creators.trackCompetitorStoreInnerPageSuccess,
    errors: Creators.trackCompetitorStoreInnerPageFailure,
  },
  unTrackCompetitorStoreInnerPageActions: {
    request: Creators.unTrackCompetitorStoreInnerPageRequest,
    success: Creators.unTrackCompetitorStoreInnerPageSuccess,
    errors: Creators.unTrackCompetitorStoreInnerPageFailure,
  },
  trackCompetitorShortStoreInnerPageActions: {
    request: Creators.trackCompetitorShortStoreInnerPageRequest,
    success: Creators.trackCompetitorShortStoreInnerPageSuccess,
    errors: Creators.trackCompetitorShortStoreInnerPageFailure,
  },
  unTrackCompetitorShortStoreInnerPageActions: {
    request: Creators.unTrackCompetitorShortStoreInnerPageRequest,
    success: Creators.unTrackCompetitorShortStoreInnerPageSuccess,
    errors: Creators.unTrackCompetitorShortStoreInnerPageFailure,
  },
  trackProductShortStoreInnerPageActions: {
    request: Creators.trackProductShortStoreInnerPageRequest,
    success: Creators.trackProductShortStoreInnerPageSuccess,
    errors: Creators.trackProductShortStoreInnerPageFailure,
  },
  unTrackProductShortStoreInnerPageActions: {
    request: Creators.unTrackProductShortStoreInnerPageRequest,
    success: Creators.unTrackProductShortStoreInnerPageSuccess,
    errors: Creators.unTrackProductShortStoreInnerPageFailure,
  },
  trackProductStoreInnerPageActions: {
    request: Creators.trackProductStoreInnerPageRequest,
    success: Creators.trackProductStoreInnerPageSuccess,
    errors: Creators.trackProductStoreInnerPageFailure,
  },
  unTrackProductStoreInnerPageActions: {
    request: Creators.unTrackProductStoreInnerPageRequest,
    success: Creators.unTrackProductStoreInnerPageSuccess,
    errors: Creators.unTrackProductStoreInnerPageFailure,
  },
  trackStoreInnerPageActions: {
    request: Creators.trackStoreInnerPageRequest,
    success: Creators.trackStoreInnerPageSuccess,
    errors: Creators.trackStoreInnerPageFailure,
  },
  unTrackStoreInnerPageActions: {
    request: Creators.unTrackStoreInnerPageRequest,
    success: Creators.unTrackStoreInnerPageSuccess,
    errors: Creators.unTrackStoreInnerPageFailure,
  },
  trackProductInnerPageActions: {
    request: Creators.trackProductInnerPageRequest,
    success: Creators.trackProductInnerPageSuccess,
    errors: Creators.trackProductInnerPageFailure,
  },
  unTrackProductInnerPageActions: {
    request: Creators.unTrackProductInnerPageRequest,
    success: Creators.unTrackProductInnerPageSuccess,
    errors: Creators.unTrackProductInnerPageFailure,
  },
  getStoreProductsModalActions: {
    request: Creators.getStoreProductsModalRequest,
    success: Creators.getStoreProductsModalSuccess,
    errors: Creators.getStoreProductsModalFailure,
  },
  trackTopStoreByIdActions: {
    request: Creators.trackTopStoreByIdRequest,
    success: Creators.trackTopStoreByIdSuccess,
    errors: Creators.trackTopStoreByIdFailure,
  },
  unTrackTopStoreByIdActions: {
    request: Creators.unTrackTopStoreByIdRequest,
    success: Creators.unTrackTopStoreByIdSuccess,
    errors: Creators.unTrackTopStoreByIdFailure,
  },
  trackTopProductByIdActions: {
    request: Creators.trackTopProductByIdRequest,
    success: Creators.trackTopProductByIdSuccess,
    errors: Creators.trackTopProductByIdFailure,
  },
  unTrackTopProductByIdActions: {
    request: Creators.unTrackTopProductByIdRequest,
    success: Creators.unTrackTopProductByIdSuccess,
    errors: Creators.unTrackTopProductByIdFailure,
  },
  getPopularStoresActions: {
    request: Creators.getPopularStoresRequest,
    success: Creators.getPopularStoresSuccess,
    errors: Creators.getPopularStoresFailure,
  },
  getTrackedStoresActions: {
    request: Creators.getTrackedStoresRequest,
    success: Creators.getTrackedStoresSuccess,
    errors: Creators.getTrackedStoresFailure,
  },
  trackStoreByIdActions: {
    request: Creators.trackStoreByIdRequest,
    success: Creators.trackStoreByIdSuccess,
    errors: Creators.trackStoreByIdFailure,
  },
  trackStoreByUrlActions: {
    request: Creators.trackStoreByUrlRequest,
    success: Creators.trackStoreByUrlSuccess,
    errors: Creators.trackStoreByUrlFailure,
  },
  unTrackStoreByIdActions: {
    request: Creators.unTrackStoreByIdRequest,
    success: Creators.unTrackStoreByIdSuccess,
    errors: Creators.unTrackStoreByIdFailure,
  },
  pinStoreByIdActions: {
    request: Creators.pinStoreByIdRequest,
    success: Creators.pinStoreByIdSuccess,
    errors: Creators.pinStoreByIdFailure,
  },
  unPinStoreByIdActions: {
    request: Creators.unPinStoreByIdRequest,
    success: Creators.unPinStoreByIdSuccess,
    errors: Creators.unPinStoreByIdFailure,
  },
  getTrackSuggestionsActions: {
    request: Creators.getTrackSuggestionsRequest,
    success: Creators.getTrackSuggestionsSuccess,
    errors: Creators.getTrackSuggestionsFailure,
  },
  getStoreHeaderActions: {
    request: Creators.getStoreHeaderRequest,
    success: Creators.getStoreHeaderSuccess,
    errors: Creators.getStoreHeaderFailure,
  },
  getStoreSalesAndRevenueShortActions: {
    request: Creators.getStoreSalesAndRevenueShortRequest,
    success: Creators.getStoreSalesAndRevenueShortSuccess,
    errors: Creators.getStoreSalesAndRevenueShortFailure,
  },
  getStoreSalesAndRevenueActions: {
    request: Creators.getStoreSalesAndRevenueRequest,
    success: Creators.getStoreSalesAndRevenueSuccess,
    errors: Creators.getStoreSalesAndRevenueFailure,
  },
  getStoreProductsShortActions: {
    request: Creators.getStoreProductsShortRequest,
    success: Creators.getStoreProductsShortSuccess,
    errors: Creators.getStoreProductsShortFailure,
  },
  getStoreProductsActions: {
    request: Creators.getStoreProductsRequest,
    success: Creators.getStoreProductsSuccess,
    errors: Creators.getStoreProductsFailure,
  },
  getStoreCompetitorsShortActions: {
    request: Creators.getStoreCompetitorsShortRequest,
    success: Creators.getStoreCompetitorsShortSuccess,
    errors: Creators.getStoreCompetitorsShortFailure,
  },
  getStoreCompetitorsActions: {
    request: Creators.getStoreCompetitorsRequest,
    success: Creators.getStoreCompetitorsSuccess,
    errors: Creators.getStoreCompetitorsFailure,
  },
  getStoreCompetitorsCountActions: {
    request: Creators.getStoreCompetitorsCountRequest,
    success: Creators.getStoreCompetitorsCountSuccess,
    errors: Creators.getStoreCompetitorsCountFailure,
  },
  getStoreCompetitorsFiltersActions: {
    request: Creators.getStoreCompetitorsFiltersRequest,
    success: Creators.getStoreCompetitorsFiltersSuccess,
    errors: Creators.getStoreCompetitorsFiltersFailure,
  },
  getStoreAdsShortActions: {
    request: Creators.getStoreAdsShortRequest,
    success: Creators.getStoreAdsShortSuccess,
    errors: Creators.getStoreAdsShortFailure,
  },
  getStoreAdsActions: {
    request: Creators.getStoreAdsRequest,
    success: Creators.getStoreAdsSuccess,
    errors: Creators.getStoreAdsFailure,
  },
  getStoreAdsCountActions: {
    request: Creators.getStoreAdsCountRequest,
    success: Creators.getStoreAdsCountSuccess,
    errors: Creators.getStoreAdsCountFailure,
  },
  getStoreAdsFiltersActions: {
    request: Creators.getStoreAdsFiltersRequest,
    success: Creators.getStoreAdsFiltersSuccess,
    errors: Creators.getStoreAdsFiltersFailure,
  },
  getStoreAppsShortActions: {
    request: Creators.getStoreAppsShortRequest,
    success: Creators.getStoreAppsShortSuccess,
    errors: Creators.getStoreAppsShortFailure,
  },
  getStoreAppsActions: {
    request: Creators.getStoreAppsRequest,
    success: Creators.getStoreAppsSuccess,
    errors: Creators.getStoreAppsFailure,
  },
  getPopularProductsActions: {
    request: Creators.getPopularProductsRequest,
    success: Creators.getPopularProductsSuccess,
    errors: Creators.getPopularProductsFailure,
  },
  getTrackedProductsActions: {
    request: Creators.getTrackedProductsRequest,
    success: Creators.getTrackedProductsSuccess,
    errors: Creators.getTrackedProductsFailure,
  },
  trackProductByIdActions: {
    request: Creators.trackProductByIdRequest,
    success: Creators.trackProductByIdSuccess,
    errors: Creators.trackProductByIdFailure,
  },
  trackProductByUrlActions: {
    request: Creators.trackProductByUrlRequest,
    success: Creators.trackProductByUrlSuccess,
    errors: Creators.trackProductByUrlFailure,
  },
  unTrackProductByIdActions: {
    request: Creators.unTrackProductByIdRequest,
    success: Creators.unTrackProductByIdSuccess,
    errors: Creators.unTrackProductByIdFailure,
  },
  pinProductByIdActions: {
    request: Creators.pinProductByIdRequest,
    success: Creators.pinProductByIdSuccess,
    errors: Creators.pinProductByIdFailure,
  },
  unPinProductByIdActions: {
    request: Creators.unPinProductByIdRequest,
    success: Creators.unPinProductByIdSuccess,
    errors: Creators.unPinProductByIdFailure,
  },
  getProductHeaderActions: {
    request: Creators.getProductHeaderRequest,
    success: Creators.getProductHeaderSuccess,
    errors: Creators.getProductHeaderFailure,
  },
  getProductReturnAndRevenueActions: {
    request: Creators.getProductReturnAndRevenueRequest,
    success: Creators.getProductReturnAndRevenueSuccess,
    errors: Creators.getProductReturnAndRevenueFailure,
  },
  getTopStoresFiltersActions: {
    request: Creators.getTopStoresFiltersRequest,
    success: Creators.getTopStoresFiltersSuccess,
    errors: Creators.getTopStoresFiltersFailure,
  },
  getTopProductsFiltersActions: {
    request: Creators.getTopProductsFiltersRequest,
    success: Creators.getTopProductsFiltersSuccess,
    errors: Creators.getTopProductsFiltersFailure,
  },
  getTopStoresPresetsActions: {
    request: Creators.getTopStoresPresetsRequest,
    success: Creators.getTopStoresPresetsSuccess,
    errors: Creators.getTopStoresPresetsFailure,
  },
  getTopProductsPresetsActions: {
    request: Creators.getTopProductsPresetsRequest,
    success: Creators.getTopProductsPresetsSuccess,
    errors: Creators.getTopProductsPresetsFailure,
  },
  getTopStoresActions: {
    request: Creators.getTopStoresRequest,
    success: Creators.getTopStoresSuccess,
    errors: Creators.getTopStoresFailure,
  },
  getTopProductsActions: {
    request: Creators.getTopProductsRequest,
    success: Creators.getTopProductsSuccess,
    errors: Creators.getTopProductsFailure,
  },
  getTopStoresCountActions: {
    request: Creators.getTopStoresCountRequest,
    success: Creators.getTopStoresCountSuccess,
    errors: Creators.getTopStoresCountFailure,
  },
  getTopProductsCountActions: {
    request: Creators.getTopProductsCountRequest,
    success: Creators.getTopProductsCountSuccess,
    errors: Creators.getTopProductsCountFailure,
  },
  getProductPriceHistorySalesTrackerActions: {
    success: Creators.getProductPriceHistorySalesTrackerSuccess,
    errors: Creators.getProductPriceHistorySalesTrackerFailure,
    request: Creators.getProductPriceHistorySalesTrackerRequest,
  },
};

const eventsOptions = {
  [constants.GET_STORE_REPORT_REQUEST]: {
    api: services.getStoreReport,
    actions: actions.getStoreReportActions,
  },
  [constants.GET_PRODUCT_REPORT_REQUEST]: {
    api: services.getProductReport,
    actions: actions.getProductReportActions,
  },
  [constants.TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackCompetitorStoreInnerPageActions,
  },
  [constants.UN_TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackCompetitorStoreInnerPageActions,
  },
  [constants.TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackCompetitorShortStoreInnerPageActions,
  },
  [constants.UN_TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackCompetitorShortStoreInnerPageActions,
  },
  [constants.TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackProductShortStoreInnerPageActions,
  },
  [constants.UN_TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackProductShortStoreInnerPageActions,
  },
  [constants.TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackProductStoreInnerPageActions,
  },
  [constants.UN_TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackProductStoreInnerPageActions,
  },
  [constants.TRACK_STORE_INNER_PAGE_REQUEST]: {
    api: services.trackStoreById,
    actions: actions.trackStoreInnerPageActions,
  },
  [constants.UN_TRACK_STORE_INNER_PAGE_REQUEST]: {
    api: services.unTrackStoreById,
    actions: actions.unTrackStoreInnerPageActions,
  },
  [constants.TRACK_PRODUCT_INNER_PAGE_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackProductInnerPageActions,
  },
  [constants.UN_TRACK_PRODUCT_INNER_PAGE_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackProductInnerPageActions,
  },
  [constants.GET_STORE_PRODUCTS_MODAL_REQUEST]: {
    api: services.getStoreProductsQuickView,
    actions: actions.getStoreProductsModalActions,
  },
  [constants.TRACK_TOP_STORE_BY_ID_REQUEST]: {
    api: services.trackStoreById,
    actions: actions.trackTopStoreByIdActions,
  },
  [constants.UN_TRACK_TOP_STORE_BY_ID_REQUEST]: {
    api: services.unTrackStoreById,
    actions: actions.unTrackTopStoreByIdActions,
  },
  [constants.TRACK_TOP_PRODUCT_BY_ID_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackTopProductByIdActions,
  },
  [constants.UN_TRACK_TOP_PRODUCT_BY_ID_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackTopProductByIdActions,
  },
  [constants.GET_POPULAR_STORES_REQUEST]: {
    api: services.getPopularStores,
    actions: actions.getPopularStoresActions,
  },
  [constants.GET_TRACKED_STORES_REQUEST]: {
    api: services.getTrackedStores,
    actions: actions.getTrackedStoresActions,
  },
  [constants.TRACK_STORE_BY_ID_REQUEST]: {
    api: services.trackStoreById,
    actions: actions.trackStoreByIdActions,
  },
  [constants.TRACK_STORE_BY_URL_REQUEST]: {
    api: services.trackStoreByUrl,
    actions: actions.trackStoreByUrlActions,
  },
  [constants.UN_TRACK_STORE_BY_ID_REQUEST]: {
    api: services.unTrackStoreById,
    actions: actions.unTrackStoreByIdActions,
  },
  [constants.PIN_STORE_BY_ID_REQUEST]: {
    api: services.pinStoreById,
    actions: actions.pinStoreByIdActions,
  },
  [constants.UN_PIN_STORE_BY_ID_REQUEST]: {
    api: services.unPinStoreById,
    actions: actions.unPinStoreByIdActions,
  },
  [constants.GET_TRACK_SUGGESTIONS_REQUEST]: {
    api: services.getTrackSuggestions,
    actions: actions.getTrackSuggestionsActions,
  },
  [constants.GET_STORE_HEADER_REQUEST]: {
    api: services.getStoreHeader,
    actions: actions.getStoreHeaderActions,
  },
  [constants.GET_STORE_SALES_AND_REVENUE_SHORT_REQUEST]: {
    api: services.getStoreSalesAndRevenueShort,
    actions: actions.getStoreSalesAndRevenueShortActions,
  },
  [constants.GET_STORE_SALES_AND_REVENUE_REQUEST]: {
    api: services.getStoreSalesAndRevenue,
    actions: actions.getStoreSalesAndRevenueActions,
  },
  [constants.GET_STORE_PRODUCTS_SHORT_REQUEST]: {
    api: services.getStoreProductsShort,
    actions: actions.getStoreProductsShortActions,
  },
  [constants.GET_STORE_PRODUCTS_REQUEST]: {
    api: services.getStoreProducts,
    actions: actions.getStoreProductsActions,
  },
  [constants.GET_STORE_COMPETITORS_SHORT_REQUEST]: {
    api: services.getStoreCompetitorsShort,
    actions: actions.getStoreCompetitorsShortActions,
  },
  [constants.GET_STORE_COMPETITORS_REQUEST]: {
    api: services.getStoreCompetitors,
    actions: actions.getStoreCompetitorsActions,
  },
  [constants.GET_STORE_COMPETITORS_COUNT_REQUEST]: {
    api: services.getStoreCompetitorsCount,
    actions: actions.getStoreCompetitorsCountActions,
  },
  [constants.GET_STORE_COMPETITORS_FILTERS_REQUEST]: {
    api: services.getStoreCompetitorsFilters,
    actions: actions.getStoreCompetitorsFiltersActions,
  },
  [constants.GET_STORE_ADS_SHORT_REQUEST]: {
    api: services.getStoreAdsShort,
    actions: actions.getStoreAdsShortActions,
  },
  [constants.GET_STORE_ADS_REQUEST]: {
    api: services.getStoreAds,
    actions: actions.getStoreAdsActions,
  },
  [constants.GET_STORE_ADS_COUNT_REQUEST]: {
    api: services.getStoreAdsCount,
    actions: actions.getStoreAdsCountActions,
  },
  [constants.GET_STORE_ADS_FILTERS_REQUEST]: {
    api: services.getStoreAdsFilters,
    actions: actions.getStoreAdsFiltersActions,
  },
  [constants.GET_STORE_APPS_SHORT_REQUEST]: {
    api: services.getStoreAppsShort,
    actions: actions.getStoreAppsShortActions,
  },
  [constants.GET_STORE_APPS_REQUEST]: {
    api: services.getStoreApps,
    actions: actions.getStoreAppsActions,
  },
  [constants.GET_POPULAR_PRODUCTS_REQUEST]: {
    api: services.getPopularProducts,
    actions: actions.getPopularProductsActions,
  },
  [constants.GET_TRACKED_PRODUCTS_REQUEST]: {
    api: services.getTrackedProducts,
    actions: actions.getTrackedProductsActions,
  },
  [constants.TRACK_PRODUCT_BY_ID_REQUEST]: {
    api: services.trackProductById,
    actions: actions.trackProductByIdActions,
  },
  [constants.TRACK_PRODUCT_BY_URL_REQUEST]: {
    api: services.trackProductByUrl,
    actions: actions.trackProductByUrlActions,
  },
  [constants.UN_TRACK_PRODUCT_BY_ID_REQUEST]: {
    api: services.unTrackProductById,
    actions: actions.unTrackProductByIdActions,
  },
  [constants.PIN_PRODUCT_BY_ID_REQUEST]: {
    api: services.pinProductById,
    actions: actions.pinProductByIdActions,
  },
  [constants.UN_PIN_PRODUCT_BY_ID_REQUEST]: {
    api: services.unPinProductById,
    actions: actions.unPinProductByIdActions,
  },
  [constants.GET_PRODUCT_HEADER_REQUEST]: {
    api: services.getProductHeader,
    actions: actions.getProductHeaderActions,
  },
  [constants.GET_PRODUCT_RETURN_AND_REVENUE_REQUEST]: {
    api: services.getProductReturnAndRevenue,
    actions: actions.getProductReturnAndRevenueActions,
  },
  [constants.GET_TOP_STORES_FILTERS_REQUEST]: {
    api: services.getTopStoresFilters,
    actions: actions.getTopStoresFiltersActions,
  },
  [constants.GET_TOP_PRODUCTS_FILTERS_REQUEST]: {
    api: services.getTopProductsFilters,
    actions: actions.getTopProductsFiltersActions,
  },
  [constants.GET_TOP_STORES_PRESETS_REQUEST]: {
    api: services.getTopStoresPresets,
    actions: actions.getTopStoresPresetsActions,
  },
  [constants.GET_TOP_PRODUCTS_PRESETS_REQUEST]: {
    api: services.getTopProductsPresets,
    actions: actions.getTopProductsPresetsActions,
  },
  [constants.GET_TOP_STORES_REQUEST]: {
    api: services.getTopStores,
    actions: actions.getTopStoresActions,
  },
  [constants.GET_TOP_PRODUCTS_REQUEST]: {
    api: services.getTopProducts,
    actions: actions.getTopProductsActions,
  },
  [constants.GET_TOP_STORES_COUNT_REQUEST]: {
    api: services.getTopStoresCount,
    actions: actions.getTopStoresCountActions,
  },
  [constants.GET_TOP_PRODUCTS_COUNT_REQUEST]: {
    api: services.getTopProductsCount,
    actions: actions.getTopProductsCountActions,
  },
  [constants.GET_PRODUCT_PRICE_HISTORY_SALES_TRACKER_REQUEST]: {
    api: services.getProductPriceHistorySalesTracker,
    actions: actions.getProductPriceHistorySalesTrackerActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      yield put(provider.actions.success({ ...response.data, ...params }));
      if (action.type.includes('TRACK_') && !action.type.includes('SUGGESTIONS')) {
        showNotification({
          width: 400,
          className: action.type.includes('UN_TRACK_') ? 'notification notification_delete' : 'notification notification--create-collection',
          text: action.type.includes('UN_TRACK_') ? `You've successfully stopped tracking ${params?.title}` : `You've successfully started tracking this ${action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? 'product' : 'store'}.`,
          icon: action.type.includes('UN_TRACK_') ? 'notification_delete' : 'start_tracking',
          title: action.type.includes('UN_TRACK_') ? `${action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? 'Product' : 'Store'} was removed from tracking list` : `${action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? 'Product' : 'Store'} is now being tracked...`,
          textIsWide: true,
        });

        sendExtensionMessage({
          action: action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? 'setProductsLimits' : 'setStoresLimits',
          data: { isIncrease: !action.type.includes('UN_TRACK_') },
        });

        sendExtensionMessage({
          action: `${action.type.includes('UN_TRACK_') ? 'delete' : 'create'}Tracking${action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? 'Product' : 'Store'}`,
          data: { id: action.type.includes('TRACK_PRODUCT') || action.type.includes('TRACK_TOP_PRODUCT') ? params.product_id : params.internal_shop_id },
        });
      }
      if (action.type.includes('PIN_')) {
        showNotification({
          width: 400,
          className: 'notification notification--create-collection',
          text: null,
          icon: 'pin_icon_notification',
          title: `${params?.title} ${action.type.includes('_STORE') ? 'store' : 'product'} was ${action.type.includes('UN_') ? 'unpinned' : 'pinned'}`,
          textIsWide: true,
        });
      }
    } else {
      const error = Object.values(response.data).length
        ? Object.values(response.data)[0].detail
        : null;
      yield put(provider.actions.errors({ errors: error }));
      showNotification({
        width: 400,
        className: 'notification notification--save notification-rename-error',
        text: action?.type === 'TRACK_STORE_BY_URL_REQUEST' ? 'Try pasting another link or try again later.' : error,
        icon: action.type.includes('TRACK_') && !action.type.includes('SUGGESTIONS') ? 'notification_tracking_error' : 'notification_warning',
        title: action?.type === 'TRACK_STORE_BY_URL_REQUEST' ?
          'Store link entered is invalid'
          :
          action?.type === 'TRACK_PRODUCT_BY_URL_REQUEST' ?
            'Product link entered is invalid'
            :
            'Something went wrong',
        textIsWide: true,
      });
      if (action.type.includes('_INNER_PAGE') || action.type.includes('HEADER')) {
        yield delay(500);
        action.type.includes('STORE') ?
          yield put(push('/sales-tracker/stores'))
          :
          yield put(push('/sales-tracker/products'))
      }
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

function* exportFileGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.data && response.ok) {
      let result = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
      FileDownload(result, `${params.name}.xlsx`);
      yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
      showNotification({
        width: 400,
        className: 'notification notification--save notification-rename-error',
        text: 'Data is no longer available on Shopify',
        icon: 'notification_warning',
        title: 'Something went wrong',
        textIsWide: true,
      });
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_STORE_REPORT_REQUEST, exportFileGenerator);
  yield takeLatest(constants.GET_PRODUCT_REPORT_REQUEST, exportFileGenerator);
  yield takeLatest(constants.TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_STORE_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_PRODUCT_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_PRODUCT_INNER_PAGE_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_PRODUCTS_MODAL_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_TOP_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_TOP_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_TOP_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_TOP_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_POPULAR_STORES_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TRACKED_STORES_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_STORE_BY_URL_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.PIN_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_PIN_STORE_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TRACK_SUGGESTIONS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_HEADER_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_SALES_AND_REVENUE_SHORT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_SALES_AND_REVENUE_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_PRODUCTS_SHORT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_PRODUCTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_COMPETITORS_SHORT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_COMPETITORS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_COMPETITORS_COUNT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_COMPETITORS_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_ADS_SHORT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_ADS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_ADS_COUNT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_ADS_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_APPS_SHORT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_STORE_APPS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_POPULAR_PRODUCTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TRACKED_PRODUCTS_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.TRACK_PRODUCT_BY_URL_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_TRACK_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.PIN_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.UN_PIN_PRODUCT_BY_ID_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_PRODUCT_HEADER_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_PRODUCT_RETURN_AND_REVENUE_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_STORES_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_PRODUCTS_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_STORES_PRESETS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_PRODUCTS_PRESETS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_STORES_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_PRODUCTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_STORES_COUNT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_TOP_PRODUCTS_COUNT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_PRODUCT_PRICE_HISTORY_SALES_TRACKER_REQUEST, apiGenerator);
}
