import React from 'react';
import { useTranslation } from 'react-i18next';
import acc from 'accounting';
import cls from 'classname';
import dayjs from 'dayjs';
import { Divider, Tooltip } from 'antd';
import Icon from '../../../Icon';
import './InnerPageInfoBlock.less';

const blocks = {
  'store': [
    {
      label: 'Tracked by',
      key: 'tracked_by',
      onClick: false,
    },
    {
      label: 'Creation date',
      key: 'created_at',
      onClick: false,
    },
    {
      label: 'Theme',
      key: 'theme',
      onClick: false,
    },
    {
      label: 'Language',
      key: 'language',
      onClick: false,
    },
    {
      label: 'Total products',
      key: 'store_products',
      onClick: true,
    },
    {
      label: 'Apps',
      key: 'store_apps',
      onClick: true,
    },
    {
      label: 'Socials',
      key: 'social_channels',
      onClick: false,
    },
  ],
  'product': [
    {
      label: 'Tracked by',
      key: 'tracked_by',
      onClick: false,
    },
    {
      label: 'Creation date',
      key: 'created_at',
      onClick: false,
    },
    {
      label: 'Store theme',
      key: 'theme',
      onClick: false,
    },
    {
      label: 'Language',
      key: 'language',
      onClick: false,
    },
    {
      label: 'Store products',
      key: 'store_products',
      onClick: true,
    },
    {
      label: 'Store apps',
      key: 'store_apps',
      onClick: true,
    },
    {
      label: 'Socials',
      key: 'social_channels',
      onClick: false,
    },
  ],
};

const InnerPageInfoBlock = (
  {
    data,
    setModal,
    isTracked = true
  }) => {

  const { t } = useTranslation();

  return (
    <div className="inner-page-info">
      {
        blocks?.[data?.type]?.map(block => (
          <div className="inner-page-info-block" key={block.key}>
            <div className="title">
              {t(block.label)}
            </div>
            <div className="value">
              {
                block.key === 'social_channels' ?
                  data?.[block.key]?.length ?
                    data?.[block.key]?.map(el => (
                      <a key={el?.platform}
                         href={el?.link ? el?.link : '#'}
                         target="_blank"
                         rel="noopener noreferrer"
                      >
                        <Icon role={'button'} type={`search_${el?.platform}`} />
                      </a>
                    ))
                    :
                    t('No data')
                  :
                  null
              }
              {
                block.key === 'social_channels' ?
                  null
                  :
                  typeof data?.[block.key] === 'object' ?
                    acc.formatNumber(data?.[block.key]?.length || 0, 0, ',')
                    :
                    block.key === 'created_at' ?
                      dayjs(data?.[block.key]).format('MMM DD, YYYY')
                      :
                      typeof data?.[block.key] === 'string' ?
                        data?.[block.key]
                        :
                        `${acc.formatNumber(data?.[block.key] || 0, 0, ',')} ${block?.key === 'tracked_by' ? 'Others' : ''}`
              }
              {
                block?.onClick && +(data?.[block.key]?.replaceAll(',', '')) > 0 ?
                  <Divider type={'vertical'} />
                  :
                  null
              }
              {
                block?.onClick && +(data?.[block.key]?.replaceAll(',', '')) > 0?
                  <span onClick={() => isTracked && setModal(block.key)}
                        className={cls('', {
                          disabled: !isTracked
                        })}
                  >
                    {
                      !isTracked ?
                        <Tooltip placement="top"
                                 title={t('To see this data you need to start tracking this store')}
                                 arrow={false}
                                 trigger={['hover']}
                                 destroyTooltipOnHide={true}
                                 overlayClassName={'disabled-calendar_tooltip'}
                                 getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {t('View')}
                        </Tooltip>
                        :
                        t('View')
                    }
                  </span>
                  :
                  null
              }
            </div>
          </div>
          )
        )
      }
    </div>
  );
};

export default InnerPageInfoBlock;
