import Creators, {AdLibraryTypes as constants} from './reducer';
import {openNotificationWithIcon} from "../../Components/Notification/index";
import {DefaultMsg} from "../../Components/Notification/notification-message";
import React from "react";
import * as services from "./services";
import {call, delay, put, takeLatest} from "redux-saga/effects";

const actions = {
  getAdLibraryFiltersActions: {
    request: Creators.getAdLibraryFiltersRequest,
    success: Creators.getAdLibraryFiltersSuccess,
    errors: Creators.getAdLibraryFiltersFailure,
  },
  getAdLibraryPresetsActions: {
    request: Creators.getAdLibraryPresetsRequest,
    success: Creators.getAdLibraryPresetsSuccess,
    errors: Creators.getAdLibraryPresetsFailure,
  },
  createAdLibraryPresetActions: {
    request: Creators.createAdLibraryPresetRequest,
    success: Creators.createAdLibraryPresetSuccess,
    errors: Creators.createAdLibraryPresetFailure,
  },
  deleteAdLibraryPresetActions: {
    request: Creators.deleteAdLibraryPresetRequest,
    success: Creators.deleteAdLibraryPresetSuccess,
    errors: Creators.deleteAdLibraryPresetFailure,
  },
  updateAdLibraryPresetActions: {
    request: Creators.updateAdLibraryPresetRequest,
    success: Creators.updateAdLibraryPresetSuccess,
    errors: Creators.updateAdLibraryPresetFailure,
  },
  getAdLibraryAdsActions: {
    request: Creators.getAdLibraryAdsRequest,
    success: Creators.getAdLibraryAdsSuccess,
    errors: Creators.getAdLibraryAdsFailure,
  },
  getAdLibraryAdDefaultsActions: {
    request: Creators.getAdLibraryAdsDefaultRequest,
    success: Creators.getAdLibraryAdsDefaultSuccess,
    errors: Creators.getAdLibraryAdsDefaultFailure,
  },
  getAdLibraryCollationsActions: {
    request: Creators.getAdLibraryAdsCollationRequest,
    success: Creators.getAdLibraryAdsCollationSuccess,
    errors: Creators.getAdLibraryAdsCollationFailure,
  },
  getCollationInsightsChartsActions: {
    request: Creators.getCollationInsightsChartsRequest,
    success: Creators.getCollationInsightsChartsSuccess,
    errors: Creators.getCollationInsightsChartsFailure,
  },
  getCollationInsightsInfoActions: {
    request: Creators.getCollationInsightsInfoRequest,
    success: Creators.getCollationInsightsInfoSuccess,
    errors: Creators.getCollationInsightsInfoFailure,
  },
  getCollationInsightsBreakDownActions: {
    request: Creators.getCollationInsightsBreakDownRequest,
    success: Creators.getCollationInsightsBreakDownSuccess,
    errors: Creators.getCollationInsightsBreakDownFailure,
  },
  getAdLibraryStatusChartActions: {
    request: Creators.getAdLibraryStatusChartRequest,
    success: Creators.getAdLibraryStatusChartSuccess,
    errors: Creators.getAdLibraryStatusChartFailure,
  },
}

const notification = (error) => openNotificationWithIcon({
  style: { width: '716px' },
  className: 'notification notification--save notification-rename-error',
  message: (
    <DefaultMsg text={error}
                icon="notification_warning"
                title={'Something went wrong'}
    />
  ),
});

const eventsOptions = {
  [constants.GET_AD_LIBRARY_FILTERS_REQUEST]: {
    api: services.getAdLibraryFilters,
    actions: actions.getAdLibraryFiltersActions,
  },
  [constants.GET_AD_LIBRARY_PRESETS_REQUEST]: {
    api: services.getAdLibraryPresets,
    actions: actions.getAdLibraryPresetsActions,
  },
  [constants.CREATE_AD_LIBRARY_PRESET_REQUEST]: {
    api: services.createAdLibraryPreset,
    actions: actions.createAdLibraryPresetActions,
    openNotification: (name) => {
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsg text={"You've created a new preset for this search."}
                      icon="create_preset"
                      title={'New preset was created'}
          />
        ),
      });
    },
  },
  [constants.DELETE_AD_LIBRARY_PRESET_REQUEST]: {
    api: services.deleteAdLibraryPreset,
    actions: actions.deleteAdLibraryPresetActions,
  },
  [constants.UPDATE_AD_LIBRARY_PRESET_REQUEST]: {
    api: services.updateAdLibraryPreset,
    actions: actions.updateAdLibraryPresetActions,
    openNotification: (name) => {
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsg text={null}
                      icon="notification_success"
                      title={'Preset was successfully renamed'}
          />
        ),
      });
    },
    openErrorNotification: (error) => {
      notification(error)
    }
  },
  [constants.GET_AD_LIBRARY_ADS_REQUEST]: {
    api: services.getAdLibraryAds,
    actions: actions.getAdLibraryAdsActions,
    openErrorNotification: (error) => {
      notification(error)
    }
  },
  [constants.GET_AD_LIBRARY_ADS_DEFAULT_REQUEST]: {
    api: services.getAdLibraryAdsDefault,
    actions: actions.getAdLibraryAdDefaultsActions,
    openErrorNotification: (error) => {
      notification(error)
    }
  },
  [constants.GET_AD_LIBRARY_ADS_COLLATION_REQUEST]: {
    api: services.getAdLibraryAdsCollation,
    actions: actions.getAdLibraryCollationsActions,
  },
  [constants.GET_COLLATION_INSIGHTS_CHARTS_REQUEST]: {
    api: services.getCollationInsightsCharts,
    actions: actions.getCollationInsightsChartsActions,
  },
  [constants.GET_COLLATION_INSIGHTS_INFO_REQUEST]: {
    api: services.getCollationInsightsInfo,
    actions: actions.getCollationInsightsInfoActions,
  },
  [constants.GET_COLLATION_INSIGHTS_BREAK_DOWN_REQUEST]: {
    api: services.getCollationInsightsBreakDown,
    actions: actions.getCollationInsightsBreakDownActions,
  },
  [constants.GET_AD_LIBRARY_STATUS_CHART_REQUEST]: {
    api: services.getAdLibraryStatusChart,
    actions: actions.getAdLibraryStatusChartActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if ((response?.data || response.status === 204) && response.ok) {
      if (action.type === 'DELETE_AD_LIBRARY_PRESET_REQUEST') {
        yield put(provider.actions.success(params.id));
      } else if(action.type === 'GET_AD_LIBRARY_ADS_DEFAULT_REQUEST') {
        yield put(provider.actions.success({...response.data, page_number: params?.page_number}));
      }else if(action.type === 'GET_AD_LIBRARY_ADS_REQUEST'){
        yield put(provider.actions.success({...response.data, page_number: params?.page_number}));
      }else if(action.type === 'GET_AD_LIBRARY_ADS_COLLATION_REQUEST'){
        yield put(provider.actions.success({...response.data, page_number: params?.page_number}));
      }else yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

function* apiGeneratorWithNotification(action) {
  const provider = eventsOptions[action.type];
  const params = action.payload;

  try {
    const response = yield call(provider.api, params);

    if ((response?.data || response.status === 204) && response.ok) {
      yield delay(500);
      if (action.type === 'UPDATE_AD_LIBRARY_PRESET_REQUEST') {
        yield put(provider.actions.success(response?.data || true));
        yield call(provider.openNotification, response?.data?.name);
      }
      else {
        yield put(provider.actions.success(response?.data || true));
        yield call(provider.openNotification, response?.data?.name);
      }
    } else {
      const error = Object.values(response.data).length
        ? Object.values(response.data)[0].detail
        : null;
      yield put(provider.actions.errors({ errors: error }));
      if (action.type === 'UPDATE_AD_LIBRARY_PRESET_REQUEST' || action.type === 'GET_AD_LIBRARY_ADS_DEFAULT_REQUEST' || action.type === 'GET_AD_LIBRARY_ADS_REQUEST') {
        yield call(provider.openErrorNotification, error);
      }
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_AD_LIBRARY_FILTERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AD_LIBRARY_PRESETS_REQUEST, apiGenerator);
  yield takeLatest(constants.CREATE_AD_LIBRARY_PRESET_REQUEST, apiGeneratorWithNotification);
  yield takeLatest(constants.DELETE_AD_LIBRARY_PRESET_REQUEST, apiGenerator);
  yield takeLatest(constants.UPDATE_AD_LIBRARY_PRESET_REQUEST, apiGeneratorWithNotification);
  yield takeLatest(constants.GET_AD_LIBRARY_ADS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AD_LIBRARY_ADS_DEFAULT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_AD_LIBRARY_ADS_COLLATION_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_COLLATION_INSIGHTS_CHARTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_COLLATION_INSIGHTS_INFO_REQUEST, apiGenerator)
  yield takeLatest(constants.GET_COLLATION_INSIGHTS_BREAK_DOWN_REQUEST, apiGenerator)
  yield takeLatest(constants.GET_AD_LIBRARY_STATUS_CHART_REQUEST, apiGenerator)
};
