import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import Icon from '../../../Icon';
import Creators from '../reducer';
import utils from "../../../Utils/utils";
import Image from "../../../Components/Image";
import Images from "../../../Images";
import ButtonComponent from "../../../Components/Button";
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import './AdminMainBlock.less';

export const AdminMainBlock = ({ data, downloadSalesReport, downloadRevenueReport, downloadProductReport, titleLink, dateOptions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownFilters = useSelector(store => store.adminStoreTable.filters);
  const checkedList = useSelector(store => store.adminStoreTable.checkedList);
  const [visibleModal, setVisibleModal] = useState(false);

  const fileLoading = useSelector(state => state?.adminStoreTable?.fileLoading);

  const setDropdownFilters = (prop) => {
    let result = prop();
    dispatch(Creators.setAdminFilters(result))
  }

  const setMemoCheckedList = (data) => {
    dispatch(Creators.setAdminCheckedList(data))
  }

  const handleDownload = () => {
    if (data.report === 'sales') downloadSalesReport({id: data.id, name: `${data.title} - ${data.reportName}`, date_range: data.dates})
    else if (data.report === 'product') downloadProductReport({id: data.id, name: `${data.title} - ${data.reportName}`, date_range: data.dates})
    else downloadRevenueReport({id: data.id, name: `${data.title} - ${data.reportName}`, date_range: data.dates})
  }

  return (
    <div className="report-main-block">

      <div className={'report-main-block-logo'}>
        {
          data?.logo
            ? <img src={data?.logo}
                   alt=""
                   onError={utils.addDefaultSrc}
                   width="62" height="62"
            />
            : <Image src={Images.defaultImg} small={true}/>
        }
      </div>

      {titleLink ?
        <a href={titleLink}
           target={'_blank'}
           rel="noopener noreferrer"
        >
          <span className="report-main-block_title">{data?.title}</span>
        </a>
        :
        <span className="report-main-block_title">{data?.title}</span>
      }

      <Divider type={'vertical'} style={{ height: '24px', margin: '0 16px' }}/>

      <a className={'report-main-block_link chart-block_link'}
         target={'_blank'}
         href={data?.link ? `https://${data?.link}` : '#'}
         rel="noopener noreferrer"
      >
        {data?.link}

        <Icon type="external_link" role="icon" width={16} height={16}/>

      </a>

      <ButtonComponent type={'primary'}
              loading={fileLoading}
              className={'btn-primary white download-report'}
              onClick={handleDownload}
      >
        {t('Download Report')}
      </ButtonComponent>
      <DropdownDateBlock
        limit={data?.timeInfo}
        timeZone={data?.timeZone}
        label={null}
        customOverlayClassName={'dropdown-sales_tracker-wrapper'}
        customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
        id={'report_period'}
        iconType={'database_calendar'}
        data={dateOptions}
        dropdownFilters={dropdownFilters}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        minDate={data.timeInfo}
        setDropdownFilters={setDropdownFilters}
        memoCheckedList={checkedList}
        setMemoCheckedList={setMemoCheckedList}
      />
    </div>
  );
};

export default AdminMainBlock;
