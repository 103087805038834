import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import ShopifyCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import dayjs from 'dayjs';
import cls from 'classname';
import { Spin, Modal } from 'antd';
import StoreTrackerPageSkeleton from './components/StoreTrackerPageSkeleton';
import Icon from '../../Icon';
import NumbersBreakdownDatabaseBlock from '../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock';
import { CanNotRemoveTrackingContent } from '../ProductDatabasePage/components/CanNotRemoveTracking';
import StoreProductsModal from './components/StoreProductsModal';
import StoreAppsModal from './components/StoreAppsModal';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import InnerPageTitleBlock from './components/InnerPageTitleBlock';
import StoreTrackerPageTabs from './components/StoreTrackerPageTabs';
import StoreTrackerPageOverview from './components/StoreTrackerPageOverview';
import StoreTrackerPageSales from './components/StoreTrackerPageSales';
import StoreTrackerPageCompetitors from './components/StoreTrackerPageCompetitors';
import StoreTrackerPageAds from './components/StoreTrackerPageAds';
import StoreTrackerPageProducts from './components/StoreTrackerPageProducts';
import StoreTrackerPageApps from './components/StoreTrackerPageApps';
import StopTrackingModal from '../ShopifySearch/components/StopTrackingModal';
import './styles.less';


const StoreTrackerPage = (
  {
    userInfo,
    isMobile,
    breadcrumbs,
    shopifyStore,
    autoDS,
    storeInnerPage,
    storeProductsModal,
    addBreadCrumb,
    removeBreadCrumb,
    clearBreadCrumbs,
    changeVisibleModalShopify,
    changeVisibleModalAuto,
    trackStoreById,
    unTrackStoreById,
    getStoreHeader,
    getStoreSalesAndRevenueShort,
    getStoreProductsShort,
    getStoreCompetitorsShort,
    getStoreAdsShort,
    getStoreAppsShort,
    getStoreApps,
    getStoreProductsModal,
    resetStoreProductsModal,
  }) => {

  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const { state } = location;
  const isVisibleModal = shopifyStore?.isVisibleModal;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [activeTab, setActiveTab] = useState('overview');
  const [dateParams, setDateParams] = useState({
    'report_period': {
      'min': dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      'max': dayjs().format('YYYY-MM-DD'),
      'id': {
        'id': 0,
        'name': 'Last 7 days',
      },
    },
  });
  const [showRawData, setShowRawData] = useState(false);
  const [modal, setModal] = useState('');
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const skeleton = storeInnerPage?.header?.loading ||
    storeInnerPage?.short?.products?.loading ||
    storeInnerPage?.short?.competitors?.loading ||
    storeInnerPage?.short?.ads?.loading ||
    storeInnerPage?.short?.ads?.loading

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    if (isVisibleModal) changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    getStoreHeader({ internal_shop_id: id });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (storeInnerPage?.header?.is_tracked && activeTab === 'overview') {
      getStoreProductsShort({
        internal_shop_id: id,
        page: 1,
        page_size: 4,
      });
      getStoreCompetitorsShort({ internal_shop_id: id });
      getStoreAdsShort({
        internal_shop_id: id,
        page: 1,
        page_size: 3,
      });
      getStoreAppsShort({ internal_shop_id: id });
    }
    if (!storeInnerPage?.header?.is_tracked && !['overview', 'sales_&_revenue']?.includes(activeTab)) setActiveTab('overview');
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [activeTab, storeInnerPage?.header?.is_tracked]);

  useEffect(() => {
    activeTab === 'overview' && getStoreSalesAndRevenueShort({
      internal_shop_id: id,
      date_gte: dateParams?.report_period?.min,
      date_lte: dateParams?.report_period?.max,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [activeTab, dateParams?.report_period?.min, dateParams?.report_period?.max, showRawData]);

  //breadcrumbs display management
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else if (breadCrumbs?.length < 2) {
      addBreadCrumb({
        name: storeInnerPage?.header?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    } else {
      clearBreadCrumbs();
      addBreadCrumb({
        name: 'Sales tracker',
        link: `/sales-tracker/stores`,
      });
      addBreadCrumb({
        name: storeInnerPage?.header?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state, storeInnerPage?.header?.title]);

  const modalBlocks = {
    canNotRemoveTracking: <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile} />,
    store_products: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                        storeProductsModalTableDataLoading={storeProductsModal?.loading}
                                        storeProductsModalTableData={storeProductsModal}
                                        storeId={id}
                                        resetStoreProductsModal={resetStoreProductsModal}
                                        minDate={dateParams?.report_period?.min}
                                        maxDate={dateParams?.report_period?.max}
                                        isMobile={isMobile}
    />,
    store_apps: <StoreAppsModal data={storeInnerPage?.full?.apps?.results?.length ? [...storeInnerPage?.full?.apps?.results] : []}
                                getApps={getStoreApps}
                                count={storeInnerPage?.short?.apps?.count || 0}
                                loading={storeInnerPage?.full?.apps?.loading}
                                id={id}
    />,
  };

  const modalBlocksWidth = {
    canNotRemoveTracking: 450,
    store_products: 828,
    store_apps: 450,
    priceHistory: 1040,
  };

  const handleOpenModalShopifyConnect = (importProductId) => {
    changeVisibleModalShopify({
      isVisibleModal: shopifyStore?.results?.length ? 'import' : 'connect',
      initialUrl,
      importProductId: shopifyStore?.results?.length ? importProductId : null,
    });
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) changeVisibleModalAuto({
        isVisibleModal: 'import',
        importProductId,
      });
      else changeVisibleModalAuto({
        isVisibleModal: 'import_unavailable',
        importProductId: null,
      });
    } else changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId });
  };

  const trackingHandler = () => {
    if (storeInnerPage?.header?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'store',
          record: {
            internal_shop_id: id,
            title: storeInnerPage?.header?.title,
          },
          cb: unTrackStoreById
        });
        setModal('remove_tracking');
      }
    } else {
      trackStoreById({
        internal_shop_id: id,
        full_data: false,
      });
    }
  }

  const tabContent = {
    'overview': <StoreTrackerPageOverview id={id}
                                          minDate={dateParams?.report_period?.min}
                                          maxDate={dateParams?.report_period?.max}
                                          showRawData={showRawData}
                                          setShowRawData={setShowRawData}
                                          dateParams={dateParams}
                                          setDateParams={setDateParams}
                                          setTab={setActiveTab}
                                          setModal={setModal}
                                          handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                          handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                          setRecordToCalc={setRecordToCalc}
                                          setDeleteType={setDeleteType}
    />,
    'sales_&_revenue': <StoreTrackerPageSales setModal={setModal}
                                              handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                              handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                              setRecordToCalc={setRecordToCalc}
                                              setDeleteType={setDeleteType}
                                              id={id}
    />,
    'competitors': <StoreTrackerPageCompetitors setModal={setModal}
                                                handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                                handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                                setRecordToCalc={setRecordToCalc}
                                                setDeleteType={setDeleteType}
                                                id={id}
    />,
    'facebook_ads': <StoreTrackerPageAds id={id} />,
    'products': <StoreTrackerPageProducts setModal={setModal}
                                          handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                          handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                          setRecordToCalc={setRecordToCalc}
                                          setDeleteType={setDeleteType}
                                          id={id}
    />,
    'apps': <StoreTrackerPageApps id={id} />,
  };

  if (skeleton) return <StoreTrackerPageSkeleton isMobile={isMobile} />;

  return (
    <div className={'product-database-page sales-tracker-page sales-tracker-store-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
                            hideTime={true}
                            timeInfo={null}
      />
      <InnerPageTitleBlock data={{
        title: storeInnerPage?.header?.title,
        link: storeInnerPage?.header?.custom_domain,
        fbLink: storeInnerPage?.header?.best_ranked_domain,
        logo: storeInnerPage?.header?.logo || storeInnerPage?.header?.favicon,
        record: storeInnerPage?.header,
      }}
                           handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                           handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                           productPage={false}
                           isMobile={isMobile}
                           trackingHandler={trackingHandler}
                           trackingLoading={storeInnerPage?.header?.trackingLoading}
      />
      <StoreTrackerPageTabs active={activeTab}
                            setActive={setActiveTab}
      />
      {
        tabContent[activeTab]
      }
      <NumbersBreakdownDatabaseBlock product={recordToCalc}
                                     loading={false}
                                     visible={modal === 'numbers_breakdown'}
                                     setModal={setModal}
      />
      <StopTrackingModal recordToDelete={deleteType?.record}
                         type={deleteType?.type}
                         loading={storeInnerPage?.header?.trackingLoading || storeInnerPage?.short?.products?.trackingLoading || storeInnerPage?.full?.products?.trackingLoading}
                         visible={modal === 'remove_tracking'}
                         setModal={setModal}
                         disconnect={deleteType?.cb}
                         isMobile={isMobile}
      />
      <Modal
        className={cls('change-modal custom-modal', {
          'store-products-opened': modal === 'storeProducts' || modal === 'storeApps',
          'collection-list-modal collection-modal': modal === 'canNotRemoveTracking',
        })}
        getContainer={() => document.getElementById('global-wrap')}
        open={Boolean(modal) && modal !== 'numbers_breakdown' && modal !== 'remove_tracking'}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => setModal(null)}
        destroyOnClose
        title={modal === 'canNotRemoveTracking' && t('Can not remove tracking')}
      >
        <Spin size="large" spinning={false}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  shopifyStore: state.shopifyStore,
  autoDS: state.autoDS,
  storeInnerPage: state.salesTracker.storeInnerPage,
  storeProductsModal: state.salesTracker.storeProductsModal,
});

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
  changeVisibleModalAuto: (data) => dispatch(AutoDSCreators.changeVisibleModalAuto(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  trackStoreById: (data) => dispatch(Creators.trackStoreInnerPageRequest(data)),
  unTrackStoreById: (data) => dispatch(Creators.unTrackStoreInnerPageRequest(data)),
  getStoreHeader: (data) => dispatch(Creators.getStoreHeaderRequest(data)),
  getStoreSalesAndRevenueShort: (data) => dispatch(Creators.getStoreSalesAndRevenueShortRequest(data)),
  getStoreProductsShort: (data) => dispatch(Creators.getStoreProductsShortRequest(data)),
  getStoreCompetitorsShort: (data) => dispatch(Creators.getStoreCompetitorsShortRequest(data)),
  getStoreAdsShort: (data) => dispatch(Creators.getStoreAdsShortRequest(data)),
  getStoreAppsShort: (data) => dispatch(Creators.getStoreAppsShortRequest(data)),
  getStoreApps: (data) => dispatch(Creators.getStoreAppsRequest(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(StoreTrackerPage));
