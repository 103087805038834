import React, { useState,useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Icon from '../../../Icon';
import { Dropdown, Modal, Spin, Tooltip } from 'antd';
import { FadspotComponent } from './AddLiibraryContent';
import { getDiff } from './AdLibraryItem';
import AdLibraryItemAdSpendModal from './AdLibraryItemAdSpendModal';

import './AdLibraryItem.less';

const AdLibraryModalItem = ({
  id,
  setModal,
  handleOpenModalShopifyConnect,
  data,
  setVisibleModal,
  getCollationInsightsCharts,
  getCollationInsightsInfo,
  getCollationInsightsBreakDown,
  newModal,
  setNewModal,
  setIsNotModal,
  setMobileModal,
  visibleModalMobileId,
  setVisibleModalMobileId,
  collationId
}) => {
  const { t } = useTranslation();
  const isExtended = data.is_eu_targeted;
  const isMobile = useSelector((state) => state?.nav?.isMobile);

  const [visible, setVisible] = useState(false);
  const [visibleTooltipAction, setVisibleTooltipAction] = useState(false);
  const [visibleTooltipHeadline, setVisibleTooltipHeadline] = useState(false);
  const [adLibrarySlide, setAdLibrarySlide] = useState(0);
  const [visibleModalId,setVisibleModalId] = useState(null)
  const handleVisibleChange = (value) => setVisible(value);
  
  return (
    <div
      className={cls('adLibrary-item adLibrary-item-modal', {
        extended: isExtended,
      })}
    >
      <div className="adLibrary-item-header">
        <div className="adLibrary-item-header-info-wrapper">
          <div className="adLibrary-item-header-logo-wrapper">
            <img
              src={data?.collation?.advertiser?.profile_photo}
              alt=""
              className={'adLibrary-item-header-logo'}
              onError={utils.addDefaultSrc}
            />
          </div>
          <div className="adLibrary-item-header-title-wrapper">
            <span className="adLibrary-item-header-title">
              {`${data?.collation?.advertiser?.page_name}`}
              {data?.collation?.advertiser?.is_verified && (
                <Icon role={'icon'} type={'ad_spot_checkmark'} />
              )}
            </span>
            <div className="adLibrary-item-header-section">
              <div
                className={cls('adLibrary-item-header-status', {
                  red: !data?.is_active,
                })}
              >
                <div
                  className={cls('adLibrary-item-header-status-circle', {
                    red: !data?.is_active,
                  })}
                />
                {!data?.is_active ? t('Inactive') : t('Active')}
              </div>
              <div className="adLibrary-item-header-items">
                {data?.collation?.advertiser?.followers ? (
                  <span className="adLibrary-item-header-item">
                    <div className="vertical-divider" />
                    <Icon role={'icon'} type={'followers'} />
                    {utils.convertNumber(
                      data?.collation?.advertiser?.followers,
                      2,
                    )}
                  </span>
                ) : null}
                {data?.collation?.advertiser?.likes ? (
                  <span className="adLibrary-item-header-item">
                    <div className="vertical-divider" />
                    <Icon role={'icon'} type={'likes'} />
                    {utils.convertNumber(data?.collation?.advertiser?.likes, 2)}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isMobile ? (
          <span
            className="adLibrary-item-header-action"
            onClick={() => {
              setMobileModal('ad_item_action');
              setVisibleModalMobileId(collationId)
              setIsNotModal(true)
            }}
          >
            <Icon type={'three_dots'} role={'icon'} />
          </span>
        ) : (
          <Dropdown
            destroyPopupOnHide={true}
            getPopupContainer={(trigger) => trigger.parentNode}
            onOpenChange={handleVisibleChange}
            placement="bottomRight"
            dropdownRender={() => (
              <div className="adLibrary-item-header-action-dropdown">
                <div
                  className="adLibrary-item-header-action-dropdown-option"
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/ads/library/?id=${data?.ad_archive_id}`,
                      '_blank',
                    )
                  }
                >
                  <Icon role={'icon'} type={'search_facebook'} isDark={false} />
                  <span>{t('View Facebook Ad Library')}</span>
                </div>
                {data?.collation?.shopify_metadata.length ? (
                  <div
                    className="adLibrary-item-header-action-dropdown-option"
                    onClick={() => {
                      setVisible(false);
                      handleOpenModalShopifyConnect(
                        `${data?.collation?.shopify_metadata[0]?.internal_shop_id}_${data?.collation?.shopify_metadata[0]?.shopify_product_id}`,
                      );
                    }}
                  >
                    <Icon
                      type={'shopify_btn_icon'}
                      role={'icon'}
                      isLogo={true}
                    />
                    <span>{t('Import to Shopify store')}</span>
                  </div>
                ) : null}
                <div
                  className="adLibrary-item-header-action-dropdown-option"
                  onClick={() =>
                    window.open(
                      `https://www.similarweb.com/website/${data?.collation?.domain_fld}`,
                      '_blank',
                    )
                  }
                >
                  <Icon role={'icon'} type={'similar_web'} isDark={false} />
                  <span>{t('View with Similarweb')}</span>
                </div>
              </div>
            )}
            trigger={['click']}
            open={visible}
          >
            <span className="adLibrary-item-header-action">
              <Icon type={'three_dots'} role={'icon'} />
            </span>
          </Dropdown>
        )}
      </div>
      <p className="adLibrary-item-description">
        {data?.collation?.creative_type === 'carousel'
          ? data?.collation?.snapshot?.cards?.[adLibrarySlide]?.body
          : data?.collation?.snapshot?.body?.text}
      </p>
      {isExtended ? (
        <div className="adLibrary-item-target-wrapper">
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Region')}</span>
            <span className="adLibrary-item-target-value-with-flag">
              <Icon type={'e_u_flag'} />
              <span>EU</span>
            </span>
          </div>
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Ad spend')}</span>
            <span className="adLibrary-item-target-value">
              {data?.ad_spend
                ? `$${acc.format(data.ad_spend, 2, ',', '.')}`
                : 'No Data'}
            </span>
          </div>
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Reach')}</span>
            <span className="adLibrary-item-target-value">
              {`${utils.convertNumber(data?.total_reaches_count, 2)}`}
            </span>
          </div>
        </div>
      ) : null}
      {isExtended ? (
        <div className="adLibrary-item-insights">
          <span
            onClick={() => {
              setVisibleModalId(id)
              setVisibleModal(true);
              setNewModal('ad_item_ad_spend_modal');
            }}
          >
            {t('View ad insights')}
          </span>
        </div>
      ) : null}
      <div className={cls('fadspot-page-item-wrapper', { addLibrary: true })}>
        <div className="fadspot-page-item">
          <FadspotComponent
            data={data.collation}
            visibleTooltipAction={visibleTooltipAction}
            visibleTooltipHeadline={visibleTooltipHeadline}
            setAdLibrarySlide={setAdLibrarySlide}
          />
        </div>
      </div>

      <div className="adLibrary-item-info-wrapper">
        {isExtended && data?.demographics?.country.length > 0 && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Countries')}</span>
            <div className="adLibrary-item-info-value">
              {Array.isArray(data?.demographics?.country) ? (
                <div className="country-flags">
                  {data.demographics.country
                    .slice(0, 4)
                    .map((country, index) => {
                      return (
                        <Tooltip key={index} title={country.name}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: country.flag,
                            }}
                          />
                        </Tooltip>
                      );
                    })}
                  {data?.demographics?.country?.length > 4 && (
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {data.demographics.country
                            .slice(4)
                            .map((country) => country.name)
                            .join('\n')}
                        </div>
                      }
                    >
                      <span className="extra-flags">
                        +{data?.demographics.country?.length - 4}
                      </span>
                    </Tooltip>
                  )}
                </div>
              ) : (
                data?.demographics?.country || null
              )}
            </div>
          </div>
        )}
        {isExtended && data?.demographics?.gender?.length > 0 && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Gender')}</span>
            <div className="adLibrary-item-info-value">
              {Array.isArray(data.demographics.gender)
                ? data.demographics.gender.join(', ')
                : data.demographics.gender}
            </div>
          </div>
        )}
        {isExtended && data?.demographics?.age && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Age')}</span>

            {typeof data?.demographics?.age === 'string' ? (
              <div className="adLibrary-item-info-value">
                {data?.demographics?.age || t('any')}
              </div>
            ) : (
              <div className="adLibrary-item-info-value">
                {`${data?.demographics?.age?.min} - ${data?.demographics?.age?.max}`}
              </div>
            )}
          </div>
        )}
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Creation date')}
          </span>
          <div className="adLibrary-item-info-value-date">
            <div>{dayjs(data?.created_at).format('DD.MM.YYYY')}</div>
            <div className="adLibrary-item-info-value-date-gray">
              {getDiff(data?.created_at, t)}
            </div>
          </div>
        </div>
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">{t('Last seen')}</span>
          <div className="adLibrary-item-info-value-date">
            <div>{dayjs(data?.last_seen_date).format('DD.MM.YYYY')}</div>
            <div className="adLibrary-item-info-value-date-gray">
              {getDiff(data?.last_seen_date, t)}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={cls(
          `change-modal custom-modal ${isMobile && 'full-screen'} ${isMobile && newModal === 'ad_item_ad_spend_modal' ? 'scrolable' : ''}`,    
        )}
        getContainer={() => document.getElementById('global-wrap')}
        open={newModal === 'ad_item_ad_spend_modal' && visibleModalId === id}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={828}
        footer={null}
        closable="true"
        onCancel={() => {
          setNewModal(null);
          setVisibleModalId(null)
        }}
        destroyOnClose
      >
        <Spin size="large" spinning={false}>
        <AdLibraryItemAdSpendModal 
          advertiserId={data?.collation?.advertiser?.id} 
          getCollationInsightsCharts={getCollationInsightsCharts}
          getCollationInsightsInfo={getCollationInsightsInfo}
          getCollationInsightsBreakDown={getCollationInsightsBreakDown}
          isMobile={isMobile}
          archivedId={id}
          isAdsetsRequest={true}
          handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
          domainFld={data?.collation.domain_fld}
          setModal={setModal}
          setIsNotModal={setIsNotModal}
          setMobileModal={setMobileModal}
          visibleModalMobileId={collationId}
          setVisibleModalMobileId={setVisibleModalMobileId}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default AdLibraryModalItem;
