import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import cls from 'classname';
import Creators from './reducer';
import ShopifyCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import { Spin, Modal } from 'antd';
import ChartBlock from './components/ChartBlock';
import MainBlock from './components/MainBlock';
import Chart from '../../Components/Charts/Chart';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import TrackerTable from './components/TrackerTable';
import SalesTrackerNote from './components/SalesTrackerNote';
import Icon from '../../Icon';
import StoreProductsModal from './components/StoreProductsModal';
import StoreAppsModal from './components/StoreAppsModal';
import InnerPageTitleBlock from './components/InnerPageTitleBlock';
import InnerPageInfoBlock from './components/InnerPageInfoBlock';
import ProductTrackerPageSkeleton from './components/ProductTrackerPageSkeleton';
import StopTrackingModal from '../ShopifySearch/components/StopTrackingModal';
import { CanNotRemoveTrackingContent } from '../ProductDatabasePage/components/CanNotRemoveTracking';
import './styles.less';
import acc from 'accounting';

const ProductTrackerPage = (
  {
    userInfo,
    isMobile,
    breadcrumbs,
    shopifyStore,
    autoDS,
    productInnerPage,
    addBreadCrumb,
    removeBreadCrumb,
    clearBreadCrumbs,
    changeVisibleModalShopify,
    changeVisibleModalAuto,
    resetStoreProductsModal,
    getStoreProducts,
    trackProduct,
    unTrackProduct,
    getProductHeader,
    getProductReturnAndRevenue,
    storeProductsModal,
    getProductReport
  }) => {

  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const [dateParams, setDateParams] = useState({
    "report_period": {
      "min": dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      "max": dayjs().format('YYYY-MM-DD'),
      "id": {
        "id": 0,
        "name": "Last 7 days"
      }
    }
  });
  const [firsLoading, setFirsLoading] = useState(true);
  const [showRawData, setShowRawData] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);
  const [deleteType, setDeleteType] = useState(null);

  const timeZone = productInnerPage?.header?.store?.store_timezone ? productInnerPage?.header?.store?.store_timezone.split('UTC')[1] : null;

  const skeleton = productInnerPage?.header?.loading && productInnerPage?.return_and_revenue?.loading;

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    getProductHeader({
      internal_shop_id: id.split('_')?.[0],
      product_id: id.split('_')?.[1],
    });
    getProductReturnAndRevenue({
      internal_shop_id: id.split('_')?.[0],
      product_id: id.split('_')?.[1],
      date_gte: dateParams?.report_period?.min,
      date_lte: dateParams?.report_period?.max,
    });
  }, []);

  useEffect(() => {
    if (firsLoading) setFirsLoading(false)
    else {
      getProductReturnAndRevenue({
        internal_shop_id: id.split('_')?.[0],
        product_id: id.split('_')?.[1],
        date_gte: dateParams?.report_period?.min,
        date_lte: dateParams?.report_period?.max,
      });
    }
  }, [dateParams?.report_period?.min, dateParams?.report_period?.max, showRawData])

  useEffect(() => {
    setData(productInnerPage?.return_and_revenue?.table?.length ? [...productInnerPage?.return_and_revenue?.table.slice(0,49)] : [])
    setSortOrder(null)
  }, [productInnerPage?.header?.title])

  useEffect(() => {
    if (sortOrder) {
      let newData;
      let key = sortOrder?.includes('-') ? sortOrder?.split('-')?.[1] : sortOrder
      key = key === 'monthly_sales' ? 'sales' : key;
      if (sortOrder?.includes('-')) newData = [...productInnerPage?.return_and_revenue?.table].sort((a, b) => b?.[key] - a?.[key]);
      else newData = [...productInnerPage?.return_and_revenue?.table].sort((a, b) => a?.[key] - b?.[key]);
      setData([...newData.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)]);
    } else {
      setData(productInnerPage?.return_and_revenue?.table ? [...productInnerPage?.return_and_revenue?.table.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)] : []);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [sortOrder, pageSize, pageNumber])

  //breadcrumbs display handler
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else {
      clearBreadCrumbs();
      addBreadCrumb({
        name: 'Sales tracker',
        link: state?.link || `/sales-tracker/products`,
      });
      addBreadCrumb({
        name: productInnerPage?.header?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/products/product/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state, productInnerPage?.header?.title]);

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const modalBlocks = {
    store_products: <StoreProductsModal getStoreProductsModal={getStoreProducts}
                                        storeProductsModalTableDataLoading={storeProductsModal?.loading}
                                        storeProductsModalTableData={storeProductsModal}
                                        storeId={id.split('_')?.[0]}
                                        resetStoreProductsModal={resetStoreProductsModal}
                                        minDate={dateParams?.report_period?.min}
                                        maxDate={dateParams?.report_period?.max}
                                        isMobile={isMobile}
    />,
    store_apps: <StoreAppsModal data={productInnerPage?.header?.store?.apps?.length ? [...productInnerPage?.header?.store?.apps] : []} />,
    canNotRemoveTracking: <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile} />,
  };

  const modalBlocksWidth = {
    canNotRemoveTracking: 450,
    store_products: 828,
    store_apps: 450,
  };

  const handleOpenModalShopifyConnect = (importProductId) => {
    changeVisibleModalShopify({ isVisibleModal: shopifyStore.results?.length ? 'import' : 'connect', initialUrl, importProductId: shopifyStore.results?.length ? importProductId : null });
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) changeVisibleModalAuto({ isVisibleModal: 'import', importProductId });
      else changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null });
    } else changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId });
  };

  const trackingHandler = () => {
    if (productInnerPage?.header?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            internal_shop_id: id.split('_')?.[0],
            product_id: id.split('_')?.[1],
            title: productInnerPage?.header?.title,
          },
          cb: unTrackProduct
        });
        setModal('remove_tracking');
      }
    } else {
      trackProduct({
        internal_shop_id: id.split('_')?.[0],
        product_id: id.split('_')?.[1],
        full_data: false,
      });
    }
  }

  if (skeleton) return <ProductTrackerPageSkeleton isMobile={isMobile} />

  return (
    <div className={'product-database-page sales-tracker-page sales-tracker-product-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
                            hideTime={true}
                            timeInfo={null}
      />
      <SalesTrackerNote />
      <InnerPageTitleBlock data={{
        title: productInnerPage?.header?.title,
        link: productInnerPage?.header?.store?.custom_domain,
        fbLink: productInnerPage?.header?.store?.best_ranked_domain,
        logo: productInnerPage?.header?.main_image,
        record: productInnerPage?.header,
      }}
                           handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                           handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                           productPage={true}
                           isMobile={isMobile}
                           trackingHandler={trackingHandler}
                           trackingLoading={productInnerPage?.header?.trackingLoading}
      />
      <InnerPageInfoBlock data={{
        type: 'product',
        tracked_by: productInnerPage?.header?.tracked_by,
        created_at: productInnerPage?.header?.created_at,
        theme: productInnerPage?.header?.store?.theme?.name,
        language: productInnerPage?.header?.store?.language?.value || 'English',
        store_products: acc.formatNumber(productInnerPage?.header?.store?.products_count, 0, ','),
        store_apps: acc.formatNumber(productInnerPage?.header?.store?.apps?.length || 0, 0, ','),
        social_channels: productInnerPage?.header?.store?.social_channels,
      }}
                          setModal={setModal}
                          isTracked={productInnerPage?.header?.is_tracked}
      />
      <MainBlock data={{
        title: productInnerPage?.header?.title,
        link: productInnerPage?.header?.store?.custom_domain,
        logo: productInnerPage?.header?.main_image,
        report: 'product',
        id: id,
        reportName: 'Product Report',
        dates: { min: dateParams?.report_period?.min, max: dateParams?.report_period?.max },
        dateId: dateParams?.report_period?.id,
        setDateParams: setDateParams,
        timeInfo: null,
        timeZone: timeZone,
      }}
                 downloadReport={getProductReport}
                 showRawData={showRawData}
                 setShowRawData={setShowRawData}
                 rawDataAccess={userInfo?.raw_data_access}
                 isMobile={isMobile}
                 disabledCalendar={!productInnerPage?.header?.is_tracked}
                 disabledCalendarTooltip={!productInnerPage?.header?.is_tracked ? 'To change timeframe you need to start tracking this product' : ''}
      />
      <Spin spinning={productInnerPage?.return_and_revenue?.loading}>
        <ChartBlock type={'revenue'}
                    subTitle={dateParams?.report_period?.min && dateParams?.report_period?.max ?
                      `${dayjs(dateParams?.report_period?.min).format('MMM DD, YYYY')} - ${dayjs(dateParams?.report_period?.max).format('MMM DD, YYYY')}`
                      :
                      `${dayjs(productInnerPage?.return_and_revenue?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(productInnerPage?.return_and_revenue?.charts?.revenue[productInnerPage?.return_and_revenue?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`}
                    totalCount={Object.keys(productInnerPage?.return_and_revenue?.total || {}).length ? productInnerPage?.return_and_revenue?.total?.revenue : 0}
                    rawTotalCount={Object.keys(productInnerPage?.return_and_revenue?.raw_total || {}).length ? productInnerPage?.return_and_revenue?.raw_total?.revenue : 0}
                    returnsCount={Object.keys(productInnerPage?.return_and_revenue?.total || {})?.length ? productInnerPage?.return_and_revenue?.total?.returns_revenue : 0}
                    rawReturnsCount={Object.keys(productInnerPage?.return_and_revenue?.raw_total || {})?.length ? productInnerPage?.return_and_revenue?.raw_total?.returns_revenue : 0}
                    link={false}
                    isMobile={isMobile}
                    showRawData={showRawData}
                    withReturns={true}
                    isLineChart={isLineChartRevenue}
                    setIsLineChart={setIsLineChartRevenue}
                    className={'st-inner-chart'}
        >
          <Chart data={
            productInnerPage?.return_and_revenue?.charts ?
              Object.keys(productInnerPage?.return_and_revenue?.charts).length ?
                [...productInnerPage?.return_and_revenue?.charts?.revenue]
                :
                []
              :
              []
          }
                 type={'revenue'}
                 animation={false}
                 isMobile={isMobile}
                 withRawData={showRawData}
                 withReturns={true}
                 returnsData={productInnerPage?.return_and_revenue?.charts?.returns_revenue?.length ?
                   [...productInnerPage?.return_and_revenue?.charts?.returns_revenue]
                   :
                   []}
                 rawReturnsData={productInnerPage?.return_and_revenue?.raw_total?.returns_revenue?.length ?
                   [...productInnerPage?.return_and_revenue?.raw_total?.returns_revenue]
                   :
                   []}
                 rawData={productInnerPage?.return_and_revenue?.charts?.revenue?.raw_data_chart?.length ?
                   [...productInnerPage?.return_and_revenue?.charts?.revenue?.raw_data_chart]
                   :
                   []}
                 chartType={!isLineChartRevenue && 'bar'}
          />
        </ChartBlock>
      </Spin>
      <TrackerTable fetching={productInnerPage?.return_and_revenue?.loading}
                    onSort={handleSort}
                    pageSize={pageSize}
                    setPageSize={handleChangePageSize}
                    pageNumber={pageNumber}
                    setPageNumber={handleChangePageNumber}
                    totalProducts={productInnerPage?.return_and_revenue?.table?.length}
                    data={productInnerPage?.return_and_revenue?.table || []}
                    localData={data || []}
                    sortOrder={sortOrder}
                    isChanged={true}
                    withSummary={true}
                    report={'product'}
      />
      <StopTrackingModal recordToDelete={deleteType?.record}
                         type={deleteType?.type}
                         loading={productInnerPage?.header?.trackingLoading}
                         visible={modal === 'remove_tracking'}
                         setModal={setModal}
                         disconnect={deleteType?.cb}
                         isMobile={isMobile}
      />
      <Modal
        className={cls('change-modal custom-modal', {
          'store-products-opened': modal === 'storeProducts' || modal === 'storeApps',
        })}
        getContainer={() => document.getElementById('global-wrap')}
        open={Boolean(modal) && modal !== 'remove_tracking'}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => {
          setModal(null);
        }}
        destroyOnClose
      >
        <Spin size="large" spinning={false}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  shopifyStore: state.shopifyStore,
  autoDS: state.autoDS,
  productInnerPage: state.salesTracker.productInnerPage,
  storeProductsModal: state.salesTracker.storeProductsModal,
});

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
  changeVisibleModalAuto: (data) => dispatch(AutoDSCreators.changeVisibleModalAuto(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  getStoreProducts: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
  trackProduct: (data) => dispatch(Creators.trackProductInnerPageRequest(data)),
  unTrackProduct: (data) => dispatch(Creators.unTrackProductInnerPageRequest(data)),
  getProductHeader: (data) => dispatch(Creators.getProductHeaderRequest(data)),
  getProductReturnAndRevenue: (data) => dispatch(Creators.getProductReturnAndRevenueRequest(data)),
  getProductReport: (data) => dispatch(Creators.getProductReportRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTrackerPage);
