import React from 'react';
import { NavLink } from 'react-router-dom';
import cls from 'classname';
import Icon from '../../Icon';
import ArrowSelectDown from "../../Icon/img/ArrowSelectDown";
import {Collapse} from "antd";

const {Panel} = Collapse;

export const NavLinkWithIcon = ({
  url,
  iconType,
  text,
  active,
  theme,
  isOpened,
  isSoon,
  markText,
  markTextBordered,
  openSidebar,
  location,
  isDropdown,
  options,
  activeKey,
  setActiveKey,
  flag,
  t,
  isSetting = false,
  isMobile = false,
  isNew = false,
  ...props
}) => {
  const mouseOver = e => {
    let target = e.target;
    const {scrollWidth, clientWidth} = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing')
  }

  const mouseLeave = e => {
    let target = e.target;
    target.classList.remove('overflowing')
  }
  return (
    isDropdown && isOpened ?
      <CollapsibleNavLink url={url}
                          iconType={iconType}
                          text={text}
                          active={active}
                          theme={theme}
                          isOpened={isOpened}
                          isSoon={isSoon}
                          markTextBordered={markTextBordered}
                          openSidebar={openSidebar}
                          location={location}
                          options={options}
                          activeKey={activeKey}
                          setActiveKey={setActiveKey}
                          flag={flag}
                          t={t}
      />
      :
      <NavLink
        to={url}
        onClick={(e) => {
          setActiveKey([]);
          if (isSoon) {
            return (e.preventDefault())
          } else {
            if (openSidebar)
              openSidebar()
            return (e)
          }
        }}
        className={cls('sidebar-link', {
          disabled: isSoon,
          selected: (url === '/sales-tracker/stores' && location?.includes('sales-tracker')) || (url === '/setting/account' && location?.includes('setting'))
        })}
      >
        <Icon
          className="sidebar-link_icon"
          type={iconType}
          role="icon"
          outline={active}
          theme={theme}
          {...props}
        />
        {
          isOpened ?
            <span data-text={text} 
              onMouseOver={mouseOver}
              onMouseLeave={mouseLeave}
              className={cls('sidebar-link-text', {
              setting: isSetting,
            })}>
              {text}
              {
                isSetting && isMobile ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M8.33398 13.3337L11.0781 10.5896C11.4035 10.2641 11.4035 9.73651 11.0781 9.41107L8.33398 6.66699"
                      stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  :
                  null
              }
              {isNew ? <span className="dashboard-page-block-title-tag">new</span> : null}
              {!!markText ? (
                <span className={cls('sidebar-link_mark', {
                  'mark-text-bordered': markTextBordered,
                })}>{markText}</span>
              ) : null}
            </span>
            :
            null
        }
      </NavLink>
  );
}

const CollapsibleNavLink = (
  {
    url,
    iconType,
    text,
    active,
    theme,
    isOpened,
    isSoon,
    markTextBordered,
    openSidebar,
    location,
    options,
    activeKey,
    setActiveKey,
    flag,
    t
  }
) => {

  return (
    <Collapse collapsible={'header'}
              ghost={true}
              activeKey={activeKey}
    >
      <Panel key={flag} showArrow={false} header={
        <NavLink
          to={url}
          onClick={(e) => {
            setActiveKey([`${flag}`]);
            if (isSoon) {
              return (e.preventDefault())
            } else {
              return (e)
            }
          }}
          className={cls('sidebar-link sidebar-dropdown-link-main', {
            disabled: isSoon,
            'sidebar-dropdown-link-main-selected': flag === 'salesTracker' && ((location.includes('sales-tracker/stores') || location.includes('sales-tracker/products'))) || options?.some(option => location?.includes(option?.url)) || location.includes(url)
          })}
        >
          <Icon
            className="sidebar-link_icon"
            type={iconType}
            role="icon"
            outline={active}
            theme={theme}
          />
          {
            isOpened ?
              <span className={'sidebar-link_text'}>
                {t(text)}
                <ArrowSelectDown />
              </span>
              :
              null
          }
        </NavLink>
      }>
        {
          options.map(el => (
            <NavLink
              to={el?.url}
              key={el?.url}
              onClick={(e) => {
                if (el?.isSoon) {
                  return (e.preventDefault())
                } else {
                  if (openSidebar)
                    openSidebar()
                  return (e)
                }
              }}
              className={cls('sidebar-link sidebar-dropdown-link', {
                disabled: isSoon,
                active: el?.key === 'general' && flag === 'salesTracker' ? (location.includes('sales-tracker/stores') || location.includes('sales-tracker/products')) : location === el?.url
              })}
            >
              <span className={cls('sidebar-link_text')}>
      {t(el?.text)}
                {!!el?.markText ? (
                  <span className={cls("sidebar-link_mark", {
                    'mark-text-bordered': markTextBordered
                  })}>{t(el?.markText)}</span>
                ) : null}
    </span>
            </NavLink>
          ))
        }
      </Panel>
    </Collapse>
  )
}

export const navIconsArr = {
  demo: [
    {
      url: '/dashboard',
      iconDesk: 'home',
      text: 'Dashboard',
    },
    {
      url: '/portfolio',
      iconDesk: 'portfolio',
      text: 'Portfolio',
      comingSoon: false,
      flag: 'portfolio',
      markTextBordered: true,
      withDivider: true,
      dropdown: true,
      options: [
        {text: 'My Portfolio', url: '/portfolio', key: 'general'},
        {text: 'Collections', url: '/collections', markText: null},
      ]
    },
    {
      url: '/suppliers',
      iconDesk: 'suppliers',
      text: 'Suppliers',
    },
  ],
  settingsDemo: [
    {
      url: '/setting/account',
      iconDesk: 'account',
      text: 'Account',
    },
    {
      url: '/setting/integrations',
      iconDesk: 'integrations',
      text: 'Integrations',
    },
  ],
  desktop: [
    {
      url: '/dashboard',
      iconDesk: 'home',
      text: 'Dashboard',
    },
    {
      url: '/ad-library',
      iconDesk: 'ad_library',
      text: 'Ad Library',
      isNew: true,
    },
    {
      url: '/ad-spot',
      iconDesk: 'speaker',
      text: 'Ad Spot',
    },
    {
      url: '/product-database',
      iconDesk: 'database',
      text: 'Product Database',
    },
    {
      url: '/sales-tracker/stores',
      iconDesk: 'gps',
      text: 'Sales Tracker',
      flag: 'salesTracker',
      dropdown: true,
      options: [
        {text: 'My Trackings', url: '/sales-tracker/stores', key: 'general'},
        {text: 'Top Stores', url: '/sales-tracker/top/stores', markText: null},
        {text: 'Top Products', url: '/sales-tracker/top/products', markText: null}
      ]
    },
    {
      url: '/competitor-research',
      iconDesk: 'competitor',
      text: 'Competitor Research',
      withDivider: true
    },
    {
      url: '/portfolio',
      iconDesk: 'portfolio',
      text: 'Portfolio',
      comingSoon: false,
      flag: 'portfolio',
      markTextBordered: true,
      withDivider: true,
      dropdown: true,
      options: [
        {text: 'My Portfolio', url: '/portfolio', key: 'general'},
        {text: 'Collections', url: '/collections', markText: null},
      ]
    },
    {
      url: '/dropship-university',
      iconDesk: 'university',
      text: 'Dropship University',
    },
    {
      url: '/suppliers',
      iconDesk: 'suppliers',
      text: 'Suppliers',
    },
  ],
  setting: [
    {
      url: '/setting/account',
      iconDesk: 'account',
      text: 'Account',
    },
    {
      url: '/setting/billing',
      iconDesk: 'billing',
      text: 'Billing',
    },
    {
      url: '/setting/plan',
      iconDesk: 'pln',
      text: 'Plan',
    },
    {
      url: '/setting/integrations',
      iconDesk: 'integrations',
      text: 'Integrations',
    },
  ],
};

export const navIconsAdmin = [
  {
    url: '/admin/portfolio',
    iconDesk: 'portfolio',
    text: 'Portfolio',
  },
  {
    url: '/admin/database',
    iconDesk: 'database',
    text: 'Database',
    comingSoon: true,
    markText: 'soon',
  },
  {
    url: '/admin/droptimes',
    iconDesk: 'calendar',
    text: 'Drop times',
  },
  {
    url: '/admin/bd',
    iconDesk: 'b_d',
    text: 'Benefits & Drawbacks',
  },
  {
    url: '/admin/shopify',
    iconDesk: 'ali_drops',
    text: 'Shopify',
  },
  {
    url: '/admin/store-manager',
    iconDesk: 'settings',
    text: 'Store manager',
  },
  {
    url: '/admin/store-table',
    iconDesk: 'gps',
    text: 'Store table',
  },
];
