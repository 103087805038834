import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import '../../SalesTrackerPage/components/MainBlockNew.less';
import MainBlockStat from '../../SalesTrackerPage/components/MainBlockStat';
import dayjs from 'dayjs';
import acc from 'accounting';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import './BlockStatistics.less';

const BlockStatistics = ({
  data,
  isMobile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const statBlocks = [
    {
      title: 'Total ads',
      value: data?.ads_count
        ? acc.formatNumber(data?.ads_count, 0, ',')
        : t('No data'),
    },
    {
      title: 'Unique ads',
      value: data?.ads_count
        ? acc.formatNumber(data?.ads_count, 0, ',')
        : t('No data'),
    },
    {
      title: 'Active ads',
      value: data?.ads_count
        ? acc.formatNumber(data?.ads_count, 0, ',')
        : t('No data'),
    },
    {
      title: 'Inactive ads',
      value: data?.ads_count
        ? acc.formatNumber(data?.ads_count, 0, ',')
        : t('No data'),
    },
    {
      title: 'Ad spend',
      value: `$${acc.formatNumber(118500 / 1000, 1, ' ', '.')}K - $${acc.formatNumber(325946 / 1000, 1, ' ', '.')}K`
    },
    {
      title: 'EU reach',
      value: `${acc.formatNumber(111520, 2, ' ', '.')}K`
    },
  ];

  return (
    <>
        <div className="main-stats">
          <div className="main-stats-blocks-wrapper">
            {statBlocks.map((el) => (
              <MainBlockStat
                key={el?.title}
                {...el}
                t={t}
                isMobile={isMobile}
              />
            ))}
            {data?.social_channels?.length > 0 && (
              <div className="main-block-stat-wrapper">
                <div className="channels-wrapper">
                  <h4 className="main-block-stat-title">
                    {data?.social_channels?.length === 1
                      ? t('Social')
                      : t('Socials')}
                  </h4>
                  <div className="main-block-stat-value">
                    <div className="main-block-stat-value-socials">
                      {data?.social_channels?.length > 0
                        ? data?.social_channels?.map((el) => (
                            <a
                              key={el?.platform}
                              href={el?.link ? el?.link : '#'}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link with-img"
                            >
                              <Icon
                                role={'button'}
                                width={15}
                                height={15}
                                type={`search_${el?.platform}`}
                              />
                            </a>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
    </>
  );
};

export default BlockStatistics;
