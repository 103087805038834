import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Icon from "../../../Icon";
import { connect } from "react-redux";
import dayjs from "dayjs";
import Creators from '../reducer';
import BlockStatistics from "../components/BlockStatistics";
import BlockStatisticsSkeleton from "../components/BlockStatisticsSkeleton";

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const AdLibraryItemAds = (
  {
    isMobile,
    mainInfo,
  }) => {
  const { search } = useLocation();
  let params = new URLSearchParams(search);
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) setTimeout(() => setFirstLoading(false), 1000);
  }, [search]);

  return (
    <div className='fadspot-page-inner-content fadspot-page-overview'>
      {
          mainInfo?.mainInfoLoading || firstLoading ?
          <BlockStatisticsSkeleton isMobile={isMobile} />
          :
          <BlockStatistics
            skeleton={mainInfo?.mainInfoLoading || firstLoading}
            data={mainInfo}
            isMobile={isMobile}
          />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  // isMobile: state?.nav?.isMobile,
  // userInfo: state.auth.userInfo,
  // overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  // innerPagesInfo: state?.adSpot?.innerPagesInfo,
  // creditsLeft: state.adSpot.creditsLeft,
});

const mapDispatchToProps = (dispatch) => ({
  // getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
  // getOverviewCardInfo: (data) => dispatch(Creators.getOverviewCardInfoRequest(data)),
  // getOverviewChartInfo: (data) => dispatch(Creators.getOverviewChartInfoRequest(data)),
  // getOverviewReport: (data) => dispatch(Creators.getOverviewReportRequest(data)),
  // getOverviewSimilar: (data) => dispatch(Creators.getOverviewSimilarRequest(data)),
  // setInnerPagesDateFilters: (data) => dispatch(Creators.setInnerPagesDateFilters(data)),
  // setInnerPagesDateCheckedList: (data) => dispatch(Creators.setInnerPagesDateCheckedList(data)),
  // getStoreProductsModal: (data) => dispatch(Creators.getOverviewStoreProductsModalRequest(data)),
  // getStoreProductsModalNext: (data) => dispatch(Creators.getOverviewStoreProductsModalNextRequest(data)),
  // resetStoreProductsModal: () => dispatch(Creators.resetOverviewStoreProductsModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdLibraryItemAds);
