import React, { useState } from 'react';
import Icon from '../../../../Icon';
import { useTranslation } from 'react-i18next';
import { Dropdown, Tooltip } from 'antd';
import HeaderModal from './HeaderModal';
import acc from 'accounting';
import cls from 'classname';
import TopPageLimits from '../../components/TopPageLimits';
import ButtonComponent from '../../../../Components/Button';

const TopPageHeader = (
  {
    icon,
    title,
    subtitle,
    takeTourHandler = null,
    watchTutorialHandler = null,
    increaseLimitsHandler = null,
    skeleton,
    isMobile,
    modalTitle = '',
    modalText = '',
    withCredits = false,
    withLimits = false,
    isYearly,
    isTrial,
    creditsLeft,
    creditsTotal,
    getClassForCreditsTotal,
    withButton = false,
    isTrippleMenuMobile = false,
    isTrippleMenu = false,
    isTrialPremium,
    isTrialStandard,
    isTrialBasic,
  }) => {

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const handleVisibleChange = (value) => setVisible(value);

  if (skeleton) return (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        {
          isMobile ?
            null
            :
            <span className={'link skeleton'}
              style={{ width: 40, height: 40 }}
            />
        }
        <div className="sales-tracker-top-page-subheader sales-tracker-top-page-mobile" style={{ padding: isMobile && 12 }}>
          <span className={'link skeleton'}
            style={{ width: isMobile ? 160 : 70, height: 20 }}
          />
          {isMobile ? null : (
            <span className={'link skeleton'}
              style={{ width: 252, height: 20 }}
            />
          )}
        </div>
      </div>
      {
        withCredits && !isMobile ?
          <span className={'link skeleton'}
            style={{ width: 245, height: 36, margin: '0 16px 0 auto' }}
          />
          :
          null
      }
      {
        withLimits && !isMobile ?
          <span className={'link skeleton'}
            style={{ width: 345, height: 36, margin: '0 16px 0 auto' }}
          />
          :
          null
      }
      {
        withLimits && !isMobile ?
          <span className={'link skeleton'}
                style={{ width: 169, height: 36, margin: '0 16px 0 0' }}
          />
          :
          null
      }
      {isMobile ? null : (
        <span className={'link skeleton'}
          style={{ width: 40, height: 10 }}
        />
      )}
      {isMobile && watchTutorialHandler ? (
        <span className={'link skeleton'}
          style={{ width: 150, height: 36, marginRight: 16 }}
        />
      ) : null}
    </div>
  );

  return (
    <div className="sales-tracker-top-page-header">
      {isMobile ? (
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            {t(title)}
            <Icon
              type={'attention_outline'}
              role="button"
              width={16}
              height={16}
              tooltipProps={{
                trigger: 'hover',
                placement: 'bottomRight',
                overlayClassName: 'subscription-tooltip dropdown-bar-tooltip',
                getPopupContainer: (trigger) => trigger.parentNode,
              }}
              titleText={t(subtitle)}
            />
          </h3>
          {watchTutorialHandler && withButton ? (
            <ButtonComponent className={'sales-tracker-top-page-watch-tutorial'}
                  onClick={() => {
                  watchTutorialHandler();
                  setVisible(false);
                  }}
            >
              <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
              <span>{t('Watch tutorial')}</span>
            </ButtonComponent>
          ) : null}
          {increaseLimitsHandler && !isTrippleMenuMobile ? (
            <div className={cls('sales-tracker-top-page-watch-tutorial', {
              disabled: isTrial,
            })}
              onClick={() => {
                if (isTrial) return;
                increaseLimitsHandler();
                setVisible(false);
              }}
            >
              <Icon type={'speedometer'}
                role={'icon'}
              />
              <span>
                {t('Increase limits')}
              </span>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="title-wrapper">
          <div className="title-wrapper-icon">
            <Icon type={icon} role={'icon'} />
          </div>
          <div className="sales-tracker-top-page-subheader">
            <h3 className="sales-tracker-top-page-title">
              {t(title)}
            </h3>
            <h4 className="sales-tracker-top-page-subtitle">
              {t(subtitle)}
            </h4>
          </div>
        </div>
      )}
      {
        withCredits && !isMobile ?
          <div className="sales-tracker-top-page-credits">
            <span className='sales-tracker-top-page-credits-title'>
              {
                t('Search credits')
              }
              <Icon
                type={'attention_outline'}
                role="button"
                width={16}
                height={16}
                tooltipProps={{
                  trigger: 'hover',
                  placement: 'bottom',
                  overlayClassName: 'subscription-tooltip scheduled credit-tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(
                  isYearly 
                    ? 'Each ad seen is 1 credit used. Credits reset every 30 days.'
                  : isTrialPremium 
                    ? 'Each ad seen is 1 credit used. Credits reset on your next billing cycle. Skip trial and pay to get access to the 50 000 credits' 
                  : isTrialStandard 
                    ? 'Each ad seen is 1 credit used. Credits reset on your next billing cycle. Skip trial and pay to get access to the 25 000 credits'
                  : isTrialBasic 
                    ? 'Each ad seen is 1 credit used. Credits reset on your next billing cycle. Skip trial and pay to get access to the 10 000 credits'
                  : 'Each ad seen is 1 credit used. Credits reset on your next billing cycle.'
                )}
              />
            </span>
            <span className="sales-tracker-top-page-credits-value">
              <span
                className={`total-${getClassForCreditsTotal(
                  creditsTotal,
                  creditsLeft,
                )}`}>
                {acc.formatNumber(creditsLeft, 0, ',')}
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                {acc.formatNumber(creditsTotal, 0, ',')}
              </span>
            </span>
          </div>
          :
          null
      }
      {withLimits && !isMobile ? (
          <div className="sales-tracker-top-page-credits">
            <TopPageLimits
              view="both"
              t={t}
              limits={{
                 stores: { used: withLimits?.stores?.used, allowed: withLimits?.stores?.allowed },
                products: { used: withLimits?.products?.used, allowed: withLimits?.products?.allowed }
              }}
            />
          </div>
          ) : null
      }
      {!isMobile && watchTutorialHandler && !withButton ? <div className={'sales-tracker-top-page-watch-tutorial'}
                       onClick={() => {
                        watchTutorialHandler();
                        setVisible(false);
                       }}
                  >
                    <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24}/>
                    <span>{t('Watch tutorial')}</span>
                  </div> : null
  }
      {
        (takeTourHandler || increaseLimitsHandler) && !withButton ?
          <>
          {(isMobile && isTrippleMenuMobile) ? (
            <span className={'sales-tracker-top-page-header-btn'}
              onClick={() => {
                setVisibleModal('header_modal');
              }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
          ) : (!isMobile && isTrippleMenu) ? (
            <Dropdown destroyPopupOnHide={true}
              getPopupContainer={(trigger) => trigger.parentNode}
              onOpenChange={handleVisibleChange}
              placement="bottomRight"
              dropdownRender={() => (
                <div className={'sales-tracker-top-page-header-dropdown'}>
                  {
                    takeTourHandler ?
                      <div className="sales-tracker-top-page-header-dropdown-option tour"
                        onClick={() => {
                          takeTourHandler();
                          setVisible(false);
                        }}
                      >
                        <Icon type="take_tour" role="icon" />
                        <span>
                          {t('Take tour')}
                        </span>
                      </div>
                      :
                      null
                  }
                  {
                    increaseLimitsHandler ?
                      <Tooltip
                        placement="top"
                        title={isTrial ? t('Feature disabled during Trial') : null}
                        destroyTooltipOnHide={true}
                      >
                        <div className={cls('sales-tracker-top-page-header-dropdown-option tutorial', {
                          disabled: isTrial,
                        })}
                          onClick={() => {
                            if (isTrial) return;
                            increaseLimitsHandler();
                            setVisible(false);
                          }}
                        >
                          <Icon type={'speedometer'}
                            role={'icon'}
                          />
                          <span>
                            {t('Increase limits')}
                          </span>
                        </div>
                      </Tooltip>
                      :
                      null
                  }
                </div>
              )}
              trigger={['click']}
              open={visible}
            >
              <span className={'sales-tracker-top-page-header-btn'}>
                <Icon type={'three_dots'} role={'icon'} />
              </span>
            </Dropdown>
          ) : null}
          </>
          :
          null
      }
      {isMobile ? null : (
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          {
            (withButton && takeTourHandler) && (
              <div className={'sales-tracker-top-page-watch-tutorial tour'}
                onClick={takeTourHandler}
                style={{ width: 138 }}
              >
                <Icon type={'take_tour'} role={'icon'} />
                <span>{t('Take Tour')}</span>
              </div>
            )
          }
          {
            (withButton && watchTutorialHandler) && (
              <div className={'sales-tracker-top-page-watch-tutorial'}
                onClick={watchTutorialHandler}
              >
                <Icon type={'watch_tutorial'} role={'icon'} width={24} height={24} />
                <span>{t('Watch Tutorial')}</span>
              </div>
            )
          }
        </div>
      )}
      <HeaderModal setModal={setVisibleModal}
        isMobile={isMobile}
        visible={visibleModal}
        takeTourHandler={takeTourHandler}
        watchTutorialHandler={watchTutorialHandler}
        increaseLimitsHandler={increaseLimitsHandler}
        title={modalTitle}
        text={modalText}
        isTrial={isTrial}
      />
    </div>
  );
};

export default TopPageHeader;
