import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import {useLocation} from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import openNotification from '../../Components/Notification';
import cls from 'classname';
import { OverlayScrollbar } from '../../Components/ScrollBar';
import TopLine from './components/TopLine';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import { debounce } from '../../Utils/utils';
import { footerModal } from '../UpcomingDropPage/modal-footer';
import Modal from '../../Components/Modal';
import ImagesBlock from './components/ImagesBlock';
import ProductMainInfo from './components/ProductMainInfo';
import Description from './components/Description';
import Competitors from './components/Competitors';
import Suppliers from './components/Suppliers';
import BenefitsDrawbacks from './components/BenefitsDrawbacks';
import InterestTargeting from './components/InterestTargeting';
import CompetitorsShopify from './components/CompetitorsShopify';
import Footer from './components/Footer';
import './styles.less';

import {
  modalBtnCancel,
  modalTitle,
  modalBtnOk,
  modalData,
} from './components/Modals';

export const ProductDetailsPageAdmin = (
  {
    product,
    isMobile,
    theme,
    error,
    fetching,
    updateLoading,
    deleteFetching,
    changeStatusFetching,
    userInfo,
    breadcrumbs,
    saveResult,
    deleteImage,
    infoData,
    benefitsDrawbacks,
    interestTarget,
    interestTargetLoading,
    shopifyTarget,
    shopifyLoading,
    ...props
  }) => {
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState('product_delete');
  const [data, setData] = useState(null);
  const [addLinks, setAddLinks] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [iconsBtn, setIconsBtn] = useState('benefits');
  const [searchTextShopify, setSearchTextShopify] = useState('');
  const [selectValueShopify, setSelectValueShopify] = useState('Title');

  const location = useLocation();

  const {state} = location;

  const scrollRef = useRef(null);
  const { product_id } = useParams();
  const drop_id = [...breadcrumbs]?.filter((el) =>
    el?.link?.includes('drop_id'),
  )?.[0]?.name;

  const handleChangeLink = debounce((e) => {
    const value = e.target.value.trim();
    setAddLinks(value);
  }, 500);

  useEffect(() => {
    document.title = `Portfolio - Dropship`;
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (error) {
      handleCloseModal();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error]);

  useEffect(() => {
    setData((data) => ({
      ...product,
      ...data,
      score: product?.score,
      competitors: product?.competitors,
      status: product?.status,
      interests: interestTarget?.results,
    }));
  }, [product, interestTarget]);

  useEffect(() => {
    props.clearProductData();
    props.clearInfoData();
    props.getProductDetails({ drop_id, product_id });
    props.getProductDetailsShopify({ drop_id, product_id });
    props.getInterestTarget({
      product_id,
      drop_id,
      page_size: 20,
    });

    if (breadcrumbs?.length <= 2) {
      props.addBreadCrumb({
        name: state?.title || product_id,
      });
    }
    if (breadcrumbs?.length === 3) {
      props.removeBreadCrumb();
      props.addBreadCrumb({
        name: state?.title || product_id,
      });
    }
    return setData(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (data?.description?.length > 2500) setDisabled(true)
    else setDisabled(false)
  }, [data?.description]);

  useEffect(() => {
    if (saveResult) {
      if (saveResult?.removed) {
        handleCloseModal();
      }
      if (saveResult?.interestTarget) {
      }
      if (saveResult?.deleteImage || saveResult?.uploadImage) {
        handleChangeProduct({ images: saveResult?.images });
        if (saveResult?.deleteImage) {
          showNotification('Image', 'delete');
        }
      }
      if (saveResult?.deleteBd || saveResult?.addBd) {
        handleChangeProduct({ b_and_d: saveResult?.data?.b_and_d });
        if (saveResult?.deleteBd) {
          showNotification('Benefits&Drawbacks item', 'delete');
        }
      }
      if (saveResult?.addSupplier || saveResult?.deleteSupplier) {
        handleChangeProduct({ suppliers: saveResult?.data?.suppliers });
        if (saveResult?.deleteSupplier) {
          showNotification('Supplier', 'delete');
        }
      }
      if (saveResult?.addCompetitors || saveResult?.deleteCompetitors) {
        handleChangeProduct({ competitors: saveResult?.data?.competitors });
        setAddLinks(null);
        if (saveResult?.deleteCompetitors) {
          showNotification('Competitor', 'delete');
        }
      }
      if (saveResult?.changed_status) {
        openNotification({
          type: 'success',
          style: { minWidth: '716px' },
          className:
            product?.status?.name === 'In Progress'
              ? 'custom-notification_purple'
              : '',
          message: (
            <div className="ant-notification-notice-message-wrapper">
              <div>{`Product status has been successfully `}</div>
              <div>{`changed to ${product?.status?.name}`}</div>
            </div>
          ),
        });
      }
      props.clearSaveResult();
      handleCloseModal();
    } else {
      handleCloseModal();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  function handleOpenModal(name) {
    setModal(name);
    setVisible(true);
    setSearchText('');
  }

  const handleCloseModal = () => {
    if (visible) setVisible(false);
    setModal('');
    setSearchText('');
    props.clearInfoData();
  };

  const handleOk = () => {
    if (modal === 'product_delete') {
      props.deleteProductDetails({ drop_id, product_id });
    }
    if (modal === 'image_delete' && infoData) {
      const newImagesList = data?.images
        ?.filter((img) => img?.url !== infoData?.url)
        .map(({ url, name }) => ({ url, name }));
      deleteImage({
        drop_id,
        product_id,
        data: {
          images: newImagesList,
          description: data?.description || '',
          status: data?.status?.id,
        },
      });
    }
    if (modal === 'competitors_add' && addLinks) {
      props.addCompetitors({ drop_id, product_id, urls: addLinks });
    }
    if (modal === 'competitors_delete' && infoData) {
      props.deleteCompetitors({
        drop_id,
        product_id,
        id: infoData?.id,
      });
    }
    if (modal === 'suppliers_delete' && infoData) {
      props.deleteSupplier({
        drop_id,
        product_id,
        data: { id: infoData?.id, type: infoData?.type },
      });
    }

    if (modal === 'bd_delete' && infoData) {
      props.deleteBd({
        drop_id,
        product_id,
        data: { id: infoData?.id },
      });
    }
    if (modal === 'bd_import' && infoData) {
      props.addBd({
        drop_id,
        product_id,
        data: { benefits_drawbacks: infoData },
      });
    }
  };

  const handleChangeStatus = (newStatus) => {
    const filledInfo = [
      data?.images.length,
      data?.cost_min,
      data?.price,
      data?.score,
      data?.title,
      data?.description,
      data?.suppliers?.alibaba?.length || data?.suppliers?.aliexpress?.length,
      data?.interests?.length,
    ];

    const fieldNames = {
      0: 'Images',
      1: 'MinCost',
      2: 'Price',
      3: 'Score',
      4: 'Title',
      5: 'Description',
      6: 'Suppliers',
      7: 'Interests',
    };

    const requiredFields = filledInfo
      .map((el, i) => {
        if (!!el === false) {
          return i;
        }
        return null;
      })
      .filter((el) => el !== null);

    const checkFilledInfo = filledInfo.every((el) => !!el);

    const nameOfFields = requiredFields?.length
      ? requiredFields?.map((el) => fieldNames[el])?.join(', ')
      : null;

    if (newStatus === 2 && !checkFilledInfo) {
      openNotification({
        type: 'error',
        style: { minWidth: '716px' },
        className: 'status-notification',
        message: (
          <div className="status-notification-block detailed">
            You have not filled all the required information in such fields
            <br/>
            <span style={{ color: '#707BA0', fontStyle: 'italic' }}>
              {nameOfFields}
            </span>
          </div>
        ),
      });
      return;
    }
    if (newStatus !== product?.status?.id) {
      const videosLists = {
        facebook: [...data?.videos?.facebook?.filter((link) => !!link?.url)],
        tiktok: [...data?.videos?.tiktok?.filter((link) => !!link?.url)],
      };
      const { interests, ...newDate } = data;

      props.changeStatusProductDetails({
        drop_id,
        product_id,
        data: {
          ...newDate,
          videos: { ...videosLists },
          status: newStatus,
        },
      });
    }
  };

  function showNotification(text, type) {
    return openNotification({
      type,
      style: { minWidth: '716px' },
      className: `${type}-notification`,
      message: (
        <div className={`${type}-notification-block`}>
          {text} has been {type === 'delete' ? 'removed' : 'changed'}!
        </div>
      ),
    });
  }

  const handleSaveProduct = () => {
    const videosLists = {
      facebook: [...data?.videos?.facebook?.filter((link) => !!link?.url)],
      tiktok: [...data?.videos?.tiktok?.filter((link) => !!link?.url)],
    };
    const { interests, ...newDate } = data;

    props.saveProductDetails({
      drop_id,
      product_id,
      data: {
        ...newDate,
        videos: { ...videosLists },
        status: newDate?.status?.id,
      },
    });
  };

  const handleChangeProduct = (value) => {
    setData(data =>({ ...data, ...value }));
  };

  function handleUploadImage(images) {
    props.uploadImage({ drop_id, product_id, images });
  }

  function handleImportSupplier({ type, url }) {
    props.addSupplier({ drop_id, product_id, data: { type, url } });
  }

  function handleGetBdList() {
    props.getBd({
      drop_id,
      product_id,
      ...(iconsBtn && { _type: iconsBtn === 'benefits' ? '1' : '2' }),
      page_size: 20,
    });
  }

  function handleScrollToTop() {
    if (scrollRef) scrollRef.current['_osInstance'].scroll(0, 500);
  }

  // for interest block
  const handleSearch = debounce((e, order) => {
    const search = e.target?.value ? e.target?.value.trim() : null;
    props.saveInfoData(null);
    if (modal === 'bd_import') {
      props.getBd({
        drop_id,
        product_id,
        ...(search && { q: search }),
        ...(iconsBtn && { _type: iconsBtn === 'benefits' ? '1' : '2' }),
        page_size: 20,
      });
      return;
    }
    props.getInterestTarget({
      drop_id,
      product_id,
      page_size: 20,
      ...(order && { ordering: order }),
      ...(search && { q: search }),
    });
  }, 500);

  function handleChangeInterestTargeting(saved) {
    props.getInterestTarget({
      product_id,
      drop_id,
      page_size: 20,
      ...(saved && { saved: true }),
    });
  }

  function handleChangeApprovedInterests(type, interestId) {
    props.changeInterestTarget({
      drop_id,
      product_id,
      type,
      data: { id: interestId },
    });
  }

  // for competitors shopify block
  const handleSearchShopify = debounce((e, order) => {
    props.getProductDetailsShopify({
      drop_id,
      product_id,
      field: selectValueShopify,
      ...(order && { ordering: order }),
      ...(searchTextShopify && { q: searchTextShopify }),
      page_size: 20,
    });
  }, 500);

  function handleChangeShopifyTargeting(saved) {
    props.getInterestTarget({
      product_id,
      drop_id,
      page_size: 20,
      ...(saved && { saved: true }),
    });
  }

  function handleChangeApprovedShopify(type, productData) {
    props.changeShopifyTarget({
      drop_id,
      product_id,
      type,
      productData,
    });
  }

  const bdModalData = {
    b_and_d: data?.b_and_d,
    handleSearch,
    icons: benefitsDrawbacks?.results || [],
    next: benefitsDrawbacks?.next,
    setSearchText,
    searchText,
    iconsBtn,
    setIconsBtn,
    saveInfoData: props.saveInfoData,
    getBd: props.getBd,
    getBdNext: props.getBdNext,
    drop_id,
    product_id,
  };
  const spinning =
    fetching ||
    shopifyLoading ||
    deleteFetching ||
    updateLoading ||
    props.addCompetitorsLoading ||
    props.benefitsDrawbacksLoading;

  return (
    <OverlayScrollbar visibility="hidden" ref={scrollRef}>
      <div className="product-details-page product-details-page_admin">
        <Modal
          className={cls('product-details-page_admin-modal', {
            small: true,
            'small-top': false,
          })}
          title={modalTitle[modal]}
          open={visible}
          width={512}
          handleOk={handleOk}
          handleClose={handleCloseModal}
          isMobile={isMobile}
          destroyOnClose
          footer={footerModal({
            isMobile,
            handleCancel: handleCloseModal,
            textBtnCancel: modalBtnCancel[modal],
            handleOk,
            textBtnOk: modalBtnOk(modal, addLinks),
            isDisabledBtnOk: modal === 'competitors_add' && !addLinks,
            deleteType: modal !== 'competitors_add' && modal !== 'bd_import',
            ...(modal === 'competitors_add' && { width: '354px' }),
            swipeBtn: false,
            textSwipeBtn: null,
          })}
        >
          {modalData(
            modal,
            addLinks,
            handleChangeLink,
            bdModalData,
            spinning,
          )}
        </Modal>

        <BreadcrumbsComponent
          breadCrumbs={breadcrumbs}
          isMobile={isMobile}
          isAdmin={true}
        />

        <TopLine
          product={product}
          statusLoading={changeStatusFetching}
          onChangeStatus={handleChangeStatus}
          openModal={handleOpenModal}
          onSave={handleSaveProduct}
          disabledSave={disabled}
        />
        <Divider style={{ margin: "0px 0px 24px" }}/>
        <ImagesBlock
          images={data?.images}
          isMobile={isMobile}
          onUploadImage={handleUploadImage}
          openModal={handleOpenModal}
          updateLoading={updateLoading}
          onChange={handleChangeProduct}
          saveInfo={props.saveInfoData}
        />
        <strong className="site-back-top-basic" />
        <ProductMainInfo product={data} onChange={handleChangeProduct}/>
        <Description product={data} onChange={handleChangeProduct} disabled={disabled}/>
        <Divider/>
        <BenefitsDrawbacks
          b_and_d={data?.b_and_d}
          onChange={handleChangeProduct}
          openModal={handleOpenModal}
          saveInfo={props.saveInfoData}
          getBdList={handleGetBdList}
          benefitsDrawbacks={benefitsDrawbacks}
        />
        <Divider/>
        <CompetitorsShopify
          products={shopifyTarget?.results}
          count={shopifyTarget?.selected_count}
          onSearch={handleSearchShopify}
          getShopifyTargetNext={props.getShopifyTargetNext}
          getShopifyTarget={props.getInterestTarget}
          changeShopifyTarget={handleChangeShopifyTargeting}
          next={shopifyTarget?.next}
          fetching={shopifyLoading}
          drop_id={drop_id}
          product_id={product_id}
          setSearchTextShopify={setSearchTextShopify}
          searchTextShopify={searchTextShopify}
          setSelectValueShopify={setSelectValueShopify}
          approvedIds={shopifyTarget?.approved?.approved_ids}
          changeApprovedShopify={handleChangeApprovedShopify}
        />
        <Divider/>
        <Competitors
          product={data}
          openModal={handleOpenModal}
          onChangeValue={handleChangeProduct}
          isMobile={isMobile}
          addCompetitorsLoading={props.addCompetitorsLoading}
          saveInfo={props.saveInfoData}
        />
        <Divider/>
        <Suppliers
          product={data}
          onChange={handleChangeProduct}
          openModal={handleOpenModal}
          saveInfo={props.saveInfoData}
          onImportSupplier={handleImportSupplier}
          fetching={props.addSupplierLoading}
        />
        <Divider/>
        <InterestTargeting
          interests={interestTarget?.results}
          onSearch={handleSearch}
          getInterestTargetNext={props.getInterestTargetNext}
          getInterestTarget={props.getInterestTarget}
          changeInterestTarget={handleChangeInterestTargeting}
          next={interestTarget?.next}
          fetching={interestTargetLoading}
          drop_id={drop_id}
          product_id={product_id}
          approved={interestTarget?.approved?.approved_count}
          approvedIds={interestTarget?.approved?.approved_ids}
          changeApprovedInterests={handleChangeApprovedInterests}
        />
        <Footer scrollToTop={handleScrollToTop}/>
      </div>
    </OverlayScrollbar>
  );
};

ProductDetailsPageAdmin.propTypes = {
  product: PropTypes.shape({
    BECPA: PropTypes.number,
    BEROAS: PropTypes.number,
    benefits: PropTypes.array,
    category: PropTypes.string,
    competitors: PropTypes.array,
    competitors_count: PropTypes.number,
    cost: PropTypes.string,
    description: PropTypes.string,
    drawbacks: PropTypes.array,
    extra_contents: PropTypes.array,
    fees: PropTypes.string,
    images: PropTypes.array,
    interests: PropTypes.array,
    more_products: PropTypes.array,
    profit_margin: PropTypes.number,
    p_c_ratio: PropTypes.number,
    pot_profit: PropTypes.number,
    price: PropTypes.number,
    product_id: PropTypes.string,
    quick_search: PropTypes.object,
    saturation: PropTypes.number,
    saved: PropTypes.bool,
    score: PropTypes.number,
    shipping: PropTypes.number,
    status: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    suppliers: PropTypes.object,
    title: PropTypes.string,
    variants: PropTypes.object,
    videos: PropTypes.shape({
      facebook: PropTypes.array,
      tiktok: PropTypes.array,
    }),
  }),
  fetching: PropTypes.bool,
  shopifyLoading: PropTypes.bool,
  deleteFetching: PropTypes.bool,
  changeStatusFetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  fetching: state.productDetailsAdmin.loading,
  infoData: state.productDetailsAdmin.info,
  interestTarget: state.productDetailsAdmin.interestTarget,
  shopifyTarget: state.productDetailsAdmin.shopifyTarget,
  benefitsDrawbacks: state.productDetailsAdmin.benefitsDrawbacks,
  benefitsDrawbacksLoading: state.productDetailsAdmin.benefitsDrawbacksLoading,
  updateLoading: state.productDetailsAdmin.updateLoading,
  addSupplierLoading: state.productDetailsAdmin.addSupplierLoading,
  addCompetitorsLoading: state.productDetailsAdmin.addCompetitorsLoading,
  deleteFetching: state.productDetailsAdmin.deleteFetching,
  changeStatusFetching: state.productDetailsAdmin.changeStatusFetching,
  interestTargetLoading: state.productDetailsAdmin.interestTargetLoading,
  shopifyLoading: state.productDetailsAdmin.shopifyLoading,
  saveResult: state.productDetailsAdmin.saveResult,
  error: state.productDetailsAdmin.errors,
  product: state.productDetailsAdmin?.productDetails?.current,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (params) =>
    dispatch(Creators.getProductDetailsAdminRequest(params)),
  getProductDetailsShopify: (params) =>
    dispatch(Creators.getProductDetailsAdminShopifyRequest(params)),
  deleteProductDetails: (params) =>
    dispatch(Creators.deleteProductDetailsAdminRequest(params)),
  changeStatusProductDetails: (params) =>
    dispatch(Creators.changeStatusProductDetailsAdminRequest(params)),
  saveProductDetails: (params) =>
    dispatch(Creators.updateProductDetailsAdminRequest(params)),
  clearSaveResult: () => dispatch(Creators.clearSaveResult()),
  addBreadCrumb: (params) => dispatch(BreadcrumbsActions.addBreadCrumb(params)),
  removeBreadCrumb: (params) =>
    dispatch(BreadcrumbsActions.removeBreadCrumb(params)),
  deleteImage: (params) => dispatch(Creators.deleteImageRequest(params)),
  uploadImage: (params) => dispatch(Creators.uploadImageRequest(params)),
  addCompetitors: (params) => dispatch(Creators.addCompetitorsRequest(params)),
  deleteCompetitors: (params) =>
    dispatch(Creators.deleteCompetitorsRequest(params)),
  clearProductData: () => dispatch(Creators.clearProductData()),
  saveInfoData: (data) => dispatch(Creators.saveInfoData(data)),
  clearInfoData: () => dispatch(Creators.clearInfoData()),
  addSupplier: (params) => dispatch(Creators.addSupplierRequest(params)),
  deleteSupplier: (params) => dispatch(Creators.deleteSupplierRequest(params)),
  getBd: (params) => dispatch(Creators.getBdRequest(params)),
  getBdNext: (link) => dispatch(Creators.getBdNextRequest(link)),
  addBd: (params) => dispatch(Creators.addBdRequest(params)),
  deleteBd: (params) => dispatch(Creators.deleteBdRequest(params)),
  getCompetitorsShopifyTarget: (params) =>
    dispatch(Creators.getCompetitorsShopifyTargetRequest(params)),
  getInterestTarget: (params) =>
    dispatch(Creators.getInterestTargetRequest(params)),
  getInterestTargetNext: (link) =>
    dispatch(Creators.getInterestTargetNextRequest(link)),
  getShopifyTargetNext: (link) =>
    dispatch(Creators.getShopifyTargetNextRequest(link)),
  changeInterestTarget: (params) =>
    dispatch(Creators.changeInterestTargetRequest(params)),
  changeShopifyTarget: (params) =>
    dispatch(Creators.changeShopifyTargetRequest(params)),
  changeApprovedInterests: (params) =>
    dispatch(Creators.changeApprovedInterests(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductDetailsPageAdmin);
