import React, { useEffect } from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import './ActiveAdsModal.less';
import Chart from '../../../Components/Charts/Chart';

const ActiveAdsModal = ({
  getAdLibraryStatusChart,
  advertiserId,
  isMobile,
}) => {
  const { t } = useTranslation();
  const statusChart = useSelector((state) => state?.adLibrary?.statusChart);
  const statusChartLoading = useSelector(
    (state) => state?.adLibrary?.statusChartLoading,
  );
  useEffect(() => {
    getAdLibraryStatusChart({ advertiser_id: advertiserId });
  }, []);
  return (
    <>
      {statusChartLoading ? (
        <div className="load-filter-preset-wrapper breakdown-database-wrapper price-modal-wrapper">
          <div className={'price-modal-chart-wrapper'}>
            <div
              className={'price-modal-period'}
              style={{
                flexDirection: isMobile && 'column',
                alignItems: isMobile && 'flex-start',
                marginBottom: 16,
              }}
            >
              {isMobile ? (
                <>
                  <span
                    className="link skeleton"
                    style={{ width: 68, height: 15, display: 'block' }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 61, height: 20, display: 'block' }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 169, height: 15, display: 'block' }}
                  />
                </>
              ) : null}
              {isMobile ? null : (
                <>
                  <span
                    className="link skeleton"
                    style={{
                      width: 120,
                      height: 28,
                      display: 'block',
                      marginBottom: 8,
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <span
                      className="link skeleton"
                      style={{ width: 280, height: 40, display: 'block' }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 6,
                  }}
                >
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 33, height: 16, marginTop: 10 }}
                  />
                </div>
                <div style={{ width: 'calc(100% - 39px)', height: 250 }}>
                  <span
                    className="link skeleton"
                    style={{ width: '100%', height: '100%', display: 'block' }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 'calc(100% - 39px)',
                  marginLeft: 39,
                  marginTop: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map(
                  (el, index) => (
                    <span
                      key={el * index}
                      className="link skeleton"
                      style={{ width: el, height: 16 }}
                    />
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={'load-filter-preset-wrapper store-products-modal-wrapper'}
        >
          <div className="load-filter-preset-title">{t('Active ads')}</div>
          <p className="load-filter-preset-subtitle">
            {t('View the amount of active ads this advertiser has.')}
          </p>
          <Divider style={{ margin: 0 }} />
          <div className="active-ads-modal-info-wrapper">
            <div className="active-ads-info-modal-info-title">
              {t('Active ads')}
            </div>
            <div className="active-ads-info-modal-info-text">
              <span className="value">{statusChart?.total}</span>
              <div className="vertical-divider-small" />
              <span className="status">
                <Icon type={'refresh'} role={'icon'} />
                {`${t('Updated')} ${utils.durationAsString(
                  new Date().toISOString(),
                  new Date().toISOString(),
                  t,
                )}`}
              </span>
            </div>
            <Chart
              type={'active_ads'}
              data={statusChart?.chart?.length ? [...statusChart?.chart] : []}
              loading={false}
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveAdsModal;
