import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getCompetitorsFilters = () => request.api.get(`product_database/choice_filters/`, null);
export const getCompetitorsPresets = () => request.api.get(`product_database/competitors/filter_presets/`, null);
export const createCompetitorsPreset = (data) => request.api.post(`product_database/competitors/filter_presets/`, encryptedData({ ...data }));
export const deleteCompetitorsPreset = (record) => request.api.delete(`product_database/competitors/filter_presets/${record.id}/`, null);
export const cancelDeleteCompetitorsPreset = (record) => request.api.delete(`product_database/competitors/filter_presets/${record.id}/`, null);
export const updateCompetitorsPreset = (data) => request.api.patch(`product_database/competitors/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));
export const getCompetitors = (data) =>
  data?.hasOwnProperty('filters') ?
    request.api.post(`product_database/competitors/search/`, encryptedData({...data}))
    :
    request.api.post(`product_database/competitors/search/default/`, encryptedData({...data}));
export const getShopifyCount = () => request.api.get(`/admin-panel/competitors_count/`);

export const deleteTrackingStoreByID = (data) =>
  request.api.delete(`/v2/sales_tracker/stores/${data?.store?.internal_shop_id}/`);
export const createTrackingStore = (data) => {

  const url = `/v2/sales_tracker/stores/start_tracking/${data?.store?.internal_shop_id}/`;
  return request.api.post(url);
}
export const deleteTrackingProductByID = (data) => request.api.delete(`/v2/sales_tracker/products/${data?.store?.internal_shop_id}/${data?.id}/`);
export const createTrackingProduct = (data) => {

  const url = `/v2/sales_tracker/products/start_tracking/${data?.store?.internal_shop_id}/${data?.id}/`;
  return request.api.post(url);
}
