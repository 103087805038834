import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import { columnTitles } from '../../../Components/titleSortFilters'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

export const getColumns = (
  {
    sortOrder,
    t,
    report,
    setVisible,
    setModal,
    setRecordToCalc,
    handleExpandRow,
  }) => {
  if(report === 'sales'){
    return (
      [
        /*Table.EXPAND_COLUMN,*/
        {
          title: () => t('Product'),
          dataIndex: 'title',
          key: 'name',
          width: 400,
          fixed: 'left',
          sorter: false,
          render: (text, record) => (
            <div className={'product-database-table-cell'}
                 onClick={(e) => {
                   e.persist();
                   if (!e.target.classList.contains('product-url')) window.open(`https://${record?.store?.original_domain}/products/${record?.handle}`,
                     '_blank')
                 }}
            >
                <div className={'product-database-table-image'}
                >
                  <img src={utils.changeImageSize(record?.main_image, 124)}
                       alt=""
                       onError={utils.addDefaultSrc}
                       width="62" height="62"
                  />
                </div>
                <div className="product-image-quantity">
                  <Icon type={'database_product_images'}
                        role={'icon'}
                        color={'#707ba0'}
                        width={16}
                        height={16}
                  />
                  <span>{record.images}</span>
                </div>
              <div className={'product-info-wrapper'} style={{flex: 1}}>
                <p className={'product-name'}>{record.title}</p>
                <p>
                    <span className={'product-url'} style={{color: '#225AEA', cursor: 'pointer' }}
                          onClick={(e) => {
                            setVisible(true);
                            setModal('breakdown');
                            setRecordToCalc(record);
                          }}>
                      {`$${record?.usd_price ? record?.usd_price : 0}`}

                      <span style={{paddingLeft: 4, color: '#707ba0'}}>
                        {record?.store?.currency !== 'USD' && `(${record?.original_price ? record?.original_price : 0} ${record?.store?.currency})`}
                      </span>
                    </span>
                  <span className={'product-variants'}>{record.variants} {t(record.variants === 1 ? 'Variant' : 'Variants')}</span>
                </p>
              </div>
            </div>
          ),
        },
        {
          title: ' ',
          fixed: 'left',
          dataIndex: 'quick_search',
          key: 'suppliers',
          width: 40,
          sorter: false,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-suppliers">
                {record.quick_search.map(el => (
                  <a
                    key={el.platform}
                    href={el.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link with-img"
                  >
                    <Icon role="button"
                          type={`search_${el.platform}`}
                          className={`${el.platform}-icon`}
                          width={el.platform === 'alibaba' ? 24 : 16}
                          height={el.platform === 'alibaba' ? 24 : 16}
                          titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>{el.platform}</span></>}
                          tooltipProps={{
                            placement: 'bottom',
                            trigger: 'hover',
                            destroyTooltipOnHide: true,
                            overlayClassName: cls('details-tooltip', `${el.platform}-tooltip`),
                            getPopupContainer: () =>
                              document.getElementById('product-database-table-body'),
                          }}
                    />
                  </a>
                ))}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Sales')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'sales',
            })
          },
          dataIndex: 'sales',
          key: 'sales',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-monthly-sales">
                {acc.format(record.sales, 0, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Revenue')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'revenue',
            })
          },
          dataIndex: 'revenue',
          key: 'revenue',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-monthly-revenue">
                ${acc.format(record.revenue, 2, ',', '.')}
              </div>
            </div>
          ),
        },
        {
          title: () => {
            let result = t('Product creation date')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'created_at',
            })
          },
          dataIndex: 'created_at',
          key: 'created_at',
          width: 216,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell product-database-table-cell_clickable'}
                 onClick={()=> handleExpandRow(true, record)}
            >
              <div className="product-creation-date">
                {
                  record.created_at ?  dayjs(record.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMM DD, YYYY') : 'No Data'
                }
              </div>
            </div>
          ),
        },
      ]
    )
  } else {
    let baseColumn = [
      {
        title: () => t('Date'),
        dataIndex: 'date',
        key: 'date',
        width: 170,
        sorter: false,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-creation-date">
              {
                record.date ?  dayjs(record.date).format('MMM DD, YYYY') : 'No Data'
              }
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = t('Sales')
          return columnTitles({
            sortOrder,
            title: result,
            key: 'monthly_sales',
          })
        },
        dataIndex: 'sales',
        key: 'monthly_sales',
        width: 170,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-monthly-sales">
              {acc.format(record.sales, 0, ',', '.')}
            </div>
          </div>
        ),
      },
      {
        title: () => {
          let result = t('Revenue')
          return columnTitles({
            sortOrder,
            title: result,
            key: 'revenue',
          })
        },
        dataIndex: 'revenue',
        key: 'revenue',
        width: 170,
        sorter: true,
        render: (text, record) => (
          <div className={'product-database-table-cell'}>
            <div className="product-monthly-revenue">
              ${acc.format(record.revenue, 2, ',', '.')}
            </div>
          </div>
        ),
      },
    ]
    if(report === 'product'){
      return baseColumn
    } else {
      return [
        ...baseColumn,
        {
          title: () => {
            let result = t('Unique Products Sold')
            return columnTitles({
              sortOrder,
              title: result,
              key: 'unique',
            })
          },
          dataIndex: 'unique',
          key: 'unique',
          width: 170,
          sorter: true,
          render: (text, record) => (
            <div className={'product-database-table-cell'}>
              <div className="product-monthly-revenue">
                {acc.format(record.unique, 0, ',', '.')}
              </div>
            </div>
          ),
        },
      ]
    }
  }
};
