import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    getStoreReportRequest: ['payload'],
    getStoreReportSuccess: ['payload'],
    getStoreReportFailure: ['payload'],

    getProductReportRequest: ['payload'],
    getProductReportSuccess: ['payload'],
    getProductReportFailure: ['payload'],

    trackCompetitorStoreInnerPageRequest: ['payload'],
    trackCompetitorStoreInnerPageSuccess: ['payload'],
    trackCompetitorStoreInnerPageFailure: ['payload'],

    unTrackCompetitorStoreInnerPageRequest: ['payload'],
    unTrackCompetitorStoreInnerPageSuccess: ['payload'],
    unTrackCompetitorStoreInnerPageFailure: ['payload'],

    trackCompetitorShortStoreInnerPageRequest: ['payload'],
    trackCompetitorShortStoreInnerPageSuccess: ['payload'],
    trackCompetitorShortStoreInnerPageFailure: ['payload'],

    unTrackCompetitorShortStoreInnerPageRequest: ['payload'],
    unTrackCompetitorShortStoreInnerPageSuccess: ['payload'],
    unTrackCompetitorShortStoreInnerPageFailure: ['payload'],

    trackProductShortStoreInnerPageRequest: ['payload'],
    trackProductShortStoreInnerPageSuccess: ['payload'],
    trackProductShortStoreInnerPageFailure: ['payload'],

    unTrackProductShortStoreInnerPageRequest: ['payload'],
    unTrackProductShortStoreInnerPageSuccess: ['payload'],
    unTrackProductShortStoreInnerPageFailure: ['payload'],

    trackProductStoreInnerPageRequest: ['payload'],
    trackProductStoreInnerPageSuccess: ['payload'],
    trackProductStoreInnerPageFailure: ['payload'],

    unTrackProductStoreInnerPageRequest: ['payload'],
    unTrackProductStoreInnerPageSuccess: ['payload'],
    unTrackProductStoreInnerPageFailure: ['payload'],

    trackStoreInnerPageRequest: ['payload'],
    trackStoreInnerPageSuccess: ['payload'],
    trackStoreInnerPageFailure: ['payload'],

    unTrackStoreInnerPageRequest: ['payload'],
    unTrackStoreInnerPageSuccess: ['payload'],
    unTrackStoreInnerPageFailure: ['payload'],

    trackProductInnerPageRequest: ['payload'],
    trackProductInnerPageSuccess: ['payload'],
    trackProductInnerPageFailure: ['payload'],

    unTrackProductInnerPageRequest: ['payload'],
    unTrackProductInnerPageSuccess: ['payload'],
    unTrackProductInnerPageFailure: ['payload'],

    getStoreProductsModalRequest: ['payload'],
    getStoreProductsModalSuccess: ['payload'],
    getStoreProductsModalFailure: ['payload'],

    trackTopStoreByIdRequest: ['payload'],
    trackTopStoreByIdSuccess: ['payload'],
    trackTopStoreByIdFailure: ['payload'],

    unTrackTopStoreByIdRequest: ['payload'],
    unTrackTopStoreByIdSuccess: ['payload'],
    unTrackTopStoreByIdFailure: ['payload'],

    trackTopProductByIdRequest: ['payload'],
    trackTopProductByIdSuccess: ['payload'],
    trackTopProductByIdFailure: ['payload'],

    unTrackTopProductByIdRequest: ['payload'],
    unTrackTopProductByIdSuccess: ['payload'],
    unTrackTopProductByIdFailure: ['payload'],

    getTopStoresFiltersRequest: null,
    getTopStoresFiltersSuccess: ['payload'],
    getTopStoresFiltersFailure: ['payload'],

    getTopProductsFiltersRequest: null,
    getTopProductsFiltersSuccess: ['payload'],
    getTopProductsFiltersFailure: ['payload'],

    getTopStoresPresetsRequest: null,
    getTopStoresPresetsSuccess: ['payload'],
    getTopStoresPresetsFailure: ['payload'],

    getTopProductsPresetsRequest: null,
    getTopProductsPresetsSuccess: ['payload'],
    getTopProductsPresetsFailure: ['payload'],

    getTopStoresRequest: ['payload'],
    getTopStoresSuccess: ['payload'],
    getTopStoresFailure: ['payload'],

    getTopProductsRequest: ['payload'],
    getTopProductsSuccess: ['payload'],
    getTopProductsFailure: ['payload'],

    getTopStoresCountRequest: ['payload'],
    getTopStoresCountSuccess: ['payload'],
    getTopStoresCountFailure: ['payload'],

    getTopProductsCountRequest: ['payload'],
    getTopProductsCountSuccess: ['payload'],
    getTopProductsCountFailure: ['payload'],

    getProductPriceHistorySalesTrackerRequest: ['payload'],
    getProductPriceHistorySalesTrackerSuccess: ['payload'],
    getProductPriceHistorySalesTrackerFailure: ['payload'],

    getPopularStoresRequest: null,
    getPopularStoresSuccess: ['payload'],
    getPopularStoresFailure: ['payload'],

    getTrackedStoresRequest: ['payload'],
    getTrackedStoresSuccess: ['payload'],
    getTrackedStoresFailure: ['payload'],

    trackStoreByIdRequest: ['payload'],
    trackStoreByIdSuccess: ['payload'],
    trackStoreByIdFailure: ['payload'],

    trackStoreByUrlRequest: ['payload'],
    trackStoreByUrlSuccess: ['payload'],
    trackStoreByUrlFailure: ['payload'],

    unTrackStoreByIdRequest: ['payload'],
    unTrackStoreByIdSuccess: ['payload'],
    unTrackStoreByIdFailure: ['payload'],

    pinStoreByIdRequest: ['payload'],
    pinStoreByIdSuccess: ['payload'],
    pinStoreByIdFailure: ['payload'],

    unPinStoreByIdRequest: ['payload'],
    unPinStoreByIdSuccess: ['payload'],
    unPinStoreByIdFailure: ['payload'],

    getTrackSuggestionsRequest: ['payload'],
    getTrackSuggestionsSuccess: ['payload'],
    getTrackSuggestionsFailure: ['payload'],

    getStoreHeaderRequest: ['payload'],
    getStoreHeaderSuccess: ['payload'],
    getStoreHeaderFailure: ['payload'],

    getStoreSalesAndRevenueShortRequest: ['payload'],
    getStoreSalesAndRevenueShortSuccess: ['payload'],
    getStoreSalesAndRevenueShortFailure: ['payload'],

    getStoreSalesAndRevenueRequest: ['payload'],
    getStoreSalesAndRevenueSuccess: ['payload'],
    getStoreSalesAndRevenueFailure: ['payload'],

    getStoreProductsShortRequest: ['payload'],
    getStoreProductsShortSuccess: ['payload'],
    getStoreProductsShortFailure: ['payload'],

    getStoreProductsRequest: ['payload'],
    getStoreProductsSuccess: ['payload'],
    getStoreProductsFailure: ['payload'],

    getStoreCompetitorsShortRequest: ['payload'],
    getStoreCompetitorsShortSuccess: ['payload'],
    getStoreCompetitorsShortFailure: ['payload'],

    getStoreCompetitorsRequest: ['payload'],
    getStoreCompetitorsSuccess: ['payload'],
    getStoreCompetitorsFailure: ['payload'],

    getStoreCompetitorsCountRequest: ['payload'],
    getStoreCompetitorsCountSuccess: ['payload'],
    getStoreCompetitorsCountFailure: ['payload'],

    getStoreCompetitorsFiltersRequest: ['payload'],
    getStoreCompetitorsFiltersSuccess: ['payload'],
    getStoreCompetitorsFiltersFailure: ['payload'],

    getStoreAdsShortRequest: ['payload'],
    getStoreAdsShortSuccess: ['payload'],
    getStoreAdsShortFailure: ['payload'],

    getStoreAdsRequest: ['payload'],
    getStoreAdsSuccess: ['payload'],
    getStoreAdsFailure: ['payload'],

    getStoreAdsCountRequest: ['payload'],
    getStoreAdsCountSuccess: ['payload'],
    getStoreAdsCountFailure: ['payload'],

    getStoreAdsFiltersRequest: ['payload'],
    getStoreAdsFiltersSuccess: ['payload'],
    getStoreAdsFiltersFailure: ['payload'],

    getStoreAppsShortRequest: ['payload'],
    getStoreAppsShortSuccess: ['payload'],
    getStoreAppsShortFailure: ['payload'],

    getStoreAppsRequest: ['payload'],
    getStoreAppsSuccess: ['payload'],
    getStoreAppsFailure: ['payload'],

    getPopularProductsRequest: null,
    getPopularProductsSuccess: ['payload'],
    getPopularProductsFailure: ['payload'],

    getTrackedProductsRequest: ['payload'],
    getTrackedProductsSuccess: ['payload'],
    getTrackedProductsFailure: ['payload'],

    trackProductByIdRequest: ['payload'],
    trackProductByIdSuccess: ['payload'],
    trackProductByIdFailure: ['payload'],

    trackProductByUrlRequest: ['payload'],
    trackProductByUrlSuccess: ['payload'],
    trackProductByUrlFailure: ['payload'],

    unTrackProductByIdRequest: ['payload'],
    unTrackProductByIdSuccess: ['payload'],
    unTrackProductByIdFailure: ['payload'],

    pinProductByIdRequest: ['payload'],
    pinProductByIdSuccess: ['payload'],
    pinProductByIdFailure: ['payload'],

    unPinProductByIdRequest: ['payload'],
    unPinProductByIdSuccess: ['payload'],
    unPinProductByIdFailure: ['payload'],

    getProductHeaderRequest: ['payload'],
    getProductHeaderSuccess: ['payload'],
    getProductHeaderFailure: ['payload'],

    getProductReturnAndRevenueRequest: ['payload'],
    getProductReturnAndRevenueSuccess: ['payload'],
    getProductReturnAndRevenueFailure: ['payload'],

    resetSuggestions: null,
    setView: ['payload'],
    resetProductPriceSalesTracker: null,
    resetStoreProductsModal: null,
    resetInnerStore: null,
    resetInnerProduct: null,
  },
);

export const SalesTrackerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  view: 'stores',
  productPriceLoading: true,
  productPriceData: {},
  storeProductsModal: {
    loading: false,
    results: [],
  },
  storeInnerPage: {
    downloadReportLoading: false,
    header: {
      loading: false,
      trackingLoading: false,
    },
    short: {
      sales_and_revenue: {
        loading: false,
      },
      products: {
        loading: false,
        trackingLoading: false,
        results: [],
      },
      competitors: {
        loading: false,
        trackingLoading: false,
      },
      ads: {
        loading: false,
      },
      apps: {
        loading: false,
      },
    },
    full: {
      sales_and_revenue: {
        loading: false,
      },
      products: {
        loading: false,
        trackingLoading: false,
        results: [],
      },
      competitors: {
        loading: false,
        trackingLoading: false,
        countLoading: false,
        filtersLoading: false,
        count: 0,
        filters: {},
      },
      ads: {
        loading: false,
        countLoading: false,
        filtersLoading: false,
        count: 0,
        filters: {},
      },
      apps: {
        loading: false,
      },
    },
  },
  productInnerPage: {
    downloadReportLoading: false,
    header: {
      loading: false,
      trackingLoading: false,
    },
    return_and_revenue: {
      loading: false,
    },
  },
  products: {
    loading: false,
    trackingLoading: false,
    pinLoading: false,
    popular: {
      loading: false,
      results: [],
    },
    count: 0,
    next: null,
    previous: null,
    results: [],
    limits: {
      stores: {
        used: 0,
        allowed: 0,
      },
      products: {
        used: 0,
        allowed: 0,
      },
    },
  },
  stores: {
    loading: false,
    trackingLoading: false,
    pinLoading: false,
    popular: {
      loading: false,
      results: [],
    },
    suggestions: {
      loading: false,
      results: [],
    },
    count: 0,
    next: null,
    previous: null,
    results: [],
    limits: {
      stores: {
        used: 0,
        allowed: 0,
      },
      products: {
        used: 0,
        allowed: 0,
      },
    },
  },
  topPage: {
    loading: false,
    filtersLoading: false,
    trackingLoading: false,
    presetsLoading: false,
    countLoading: false,
    spinner: false,
    topStores: {
      filters: {},
      presets: {},
      count: 0,
      errors: false,
      stores: {},
    },
    topProducts: {
      filters: {},
      presets: {},
      count: 0,
      errors: false,
      products: {},
    },
  },
});

/* ------------- Functions for reducer cases ------------- */
const getStoreReportRequest = (state, {payload: data}) =>
  state.merge({ ...state, storeInnerPage: { ...state?.storeInnerPage, downloadReportLoading: true } });
const getStoreReportSuccess = (state, {payload: data}) =>
  state.merge({ ...state, storeInnerPage: { ...state?.storeInnerPage, downloadReportLoading: false } });
const getStoreReportFailure = (state, {payload: data}) =>
  state.merge({ ...state, storeInnerPage: { ...state?.storeInnerPage, downloadReportLoading: false } });

const getProductReportRequest = (state, {payload: data}) =>
  state.merge({ ...state, productInnerPage: { ...state?.productInnerPage, downloadReportLoading: true } });
const getProductReportSuccess = (state, {payload: data}) =>
  state.merge({ ...state, productInnerPage: { ...state?.productInnerPage, downloadReportLoading: false } });
const getProductReportFailure = (state, {payload: data}) =>
  state.merge({ ...state, productInnerPage: { ...state?.productInnerPage, downloadReportLoading: false } });

const trackCompetitorStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: true,
        },
      },
    },
  });
const trackCompetitorStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.full.competitors.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: true,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const trackCompetitorStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: false,
        },
      },
    },
  });

const unTrackCompetitorStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: true,
        },
      },
    },
  });
const unTrackCompetitorStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.full.competitors.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: false,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const unTrackCompetitorStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: {
          ...state.storeInnerPage.full.competitors,
          trackingLoading: false,
        },
      },
    },
  });

const trackCompetitorShortStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: true,
        },
      },
    },
  });
const trackCompetitorShortStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.short.competitors.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: true,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const trackCompetitorShortStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: false,
        },
      },
    },
  });

const unTrackCompetitorShortStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: true,
        },
      },
    },
  });
const unTrackCompetitorShortStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.short.competitors.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: false,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const unTrackCompetitorShortStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: {
          ...state.storeInnerPage.short.competitors,
          trackingLoading: false,
        },
      },
    },
  });

const trackProductShortStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: true,
        },
      },
    },
  });
const trackProductShortStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.short.products.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: true,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const trackProductShortStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: false,
        },
      },
    },
  });

const unTrackProductShortStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: true,
        },
      },
    },
  });
const unTrackProductShortStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.short.products.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: false,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const unTrackProductShortStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: {
          ...state.storeInnerPage.short.products,
          trackingLoading: false,
        },
      },
    },
  });

const trackProductStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: true,
        },
      },
    },
  });
const trackProductStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.full.products.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: true,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const trackProductStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: false,
        },
      },
    },
  });

const unTrackProductStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: true,
        },
      },
    },
  });
const unTrackProductStoreInnerPageSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.storeInnerPage.full.products.results] || [];
  const newProducts = [...currentProducts].map(el => el?.id === data?.product_id ? {
    ...el,
    is_tracked: false,
  } : { ...el });
  return state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: false,
          results: [...newProducts],
        },
      },
    },
  });
};
const unTrackProductStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        products: {
          ...state.storeInnerPage.full.products,
          trackingLoading: false,
        },
      },
    },
  });

const trackStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: true,
      },
    },
  });
const trackStoreInnerPageSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: false,
        is_tracked: true,
      },
    },
  });
const trackStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: false,
      },
    },
  });

const unTrackStoreInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: true,
      },
    },
  });
const unTrackStoreInnerPageSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: false,
        is_tracked: false,
      },
    },
  });
const unTrackStoreInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      header: {
        ...state.storeInnerPage.header,
        trackingLoading: false,
      },
    },
  });

const trackProductInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: true,
      },
    },
  });
const trackProductInnerPageSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: false,
        is_tracked: true,
      },
    },
  });
const trackProductInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: false,
      },
    },
  });

const unTrackProductInnerPageRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: true,
      },
    },
  });
const unTrackProductInnerPageSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: false,
        is_tracked: false,
      },
    },
  });
const unTrackProductInnerPageFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      header: {
        ...state.productInnerPage.header,
        trackingLoading: false,
      },
    },
  });

const getStoreProductsModalRequest = (state, { payload: data }) =>
  state.merge({ ...state, storeProductsModal: { ...state.storeProductsModal, loading: true } });
const getStoreProductsModalSuccess = (state, { payload: data }) => {
  const { page, results, ...rest } = data;
  if (page === 1) {
    return state.merge({
      ...state,
      storeProductsModal: { ...state.storeProductsModal, loading: false, ...rest, results: [...results] },
    });
  } else return state.merge({
    ...state,
    storeProductsModal: {
      ...state.storeProductsModal,
      loading: false, ...rest,
      results: [...state.storeProductsModal.results, ...results],
    },
  });
};
const getStoreProductsModalFailure = (state, { payload: data }) =>
  state.merge({ ...state, storeProductsModal: { ...state.storeProductsModal, loading: false } });


const trackTopStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: true } });
const trackTopStoreByIdSuccess = (state, { payload: data }) => {
  let newStores = [...state.topPage.topStores.stores.results].map(el => el?.internal_shop_id === data?.internal_shop_id ? {
    ...el,
    is_tracked: true,
  } : el);
  return state.merge({
    ...state,
    topPage: {
      ...state.topPage,
      trackingLoading: false,
      topStores: { ...state.topPage.topStores, stores: { ...state.topPage.topStores.stores, results: [...newStores] } },
    },
  });
};
const trackTopStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: false } });

const unTrackTopStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: true } });
const unTrackTopStoreByIdSuccess = (state, { payload: data }) => {
  let newStores = [...state.topPage.topStores.stores.results].map(el => el?.internal_shop_id === data?.internal_shop_id ? {
    ...el,
    is_tracked: false,
  } : el);
  return state.merge({
    ...state,
    topPage: {
      ...state.topPage,
      trackingLoading: false,
      topStores: { ...state.topPage.topStores, stores: { ...state.topPage.topStores.stores, results: [...newStores] } },
    },
  });
};
const unTrackTopStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: false } });

const trackTopProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: true } });
const trackTopProductByIdSuccess = (state, { payload: data }) => {
  let newProducts = [...state.topPage.topProducts.products.results].map(el => el?.product_id === data?.product_id ? {
    ...el,
    is_tracked: true,
  } : el);
  return state.merge({
    ...state,
    topPage: {
      ...state.topPage,
      trackingLoading: false,
      topProducts: {
        ...state.topPage.topProducts,
        products: { ...state.topPage.topProducts.products, results: [...newProducts] },
      },
    },
  });
};
const trackTopProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: false } });

const unTrackTopProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: true } });
const unTrackTopProductByIdSuccess = (state, { payload: data }) => {
  let newProducts = [...state.topPage.topProducts.products.results].map(el => el?.product_id === data?.product_id ? {
    ...el,
    is_tracked: false,
  } : el);
  return state.merge({
    ...state,
    topPage: {
      ...state.topPage,
      trackingLoading: false,
      topProducts: {
        ...state.topPage.topProducts,
        products: { ...state.topPage.topProducts.products, results: [...newProducts] },
      },
    },
  });
};
const unTrackTopProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, topPage: { ...state.topPage, trackingLoading: false } });


const getProductReturnAndRevenueRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      return_and_revenue: { ...state.productInnerPage?.return_and_revenue, loading: true },
    },
  });
const getProductReturnAndRevenueSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      return_and_revenue: { ...state.productInnerPage?.return_and_revenue, loading: false, ...data },
    },
  });
const getProductReturnAndRevenueFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    productInnerPage: {
      ...state.productInnerPage,
      return_and_revenue: { ...state.productInnerPage?.return_and_revenue, loading: false },
    },
  });

const getProductHeaderRequest = (state, { payload: data }) =>
  state.merge({ ...state, productInnerPage: { ...state.productInnerPage, header: { ...state.productInnerPage?.header, loading: true } } });
const getProductHeaderSuccess = (state, { payload: data }) =>
  state.merge({ ...state, productInnerPage: { ...state.productInnerPage, header: { ...state.productInnerPage?.header, loading: false, ...data } } });
const getProductHeaderFailure = (state, { payload: data }) =>
  state.merge({ ...state, productInnerPage: { ...state.productInnerPage, header: { ...state.productInnerPage?.header, loading: false } } });

const unPinProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, pinLoading: true } });
const unPinProductByIdSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.products.results];
  const otherProducts = [...currentProducts].filter(el => el?.product_id !== data?.product_id);
  const currentProduct = {
    ...[...currentProducts].filter(el => el?.product_id === data?.product_id)?.[0],
    pin_time: false,
  };
  return state.merge({
    ...state,
    products: { ...state.products, pinLoading: false, results: [...otherProducts, currentProduct] },
  });
};
const unPinProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, pinLoading: false } });

const pinProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, pinLoading: true } });
const pinProductByIdSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.products.results];
  const otherProducts = [...currentProducts].filter(el => el?.product_id !== data?.product_id);
  const currentProduct = {
    ...[...currentProducts].filter(el => el?.product_id === data?.product_id)?.[0],
    pin_time: true,
  };
  return state.merge({
    ...state,
    products: { ...state.products, pinLoading: false, results: [currentProduct, ...otherProducts] },
  });
};
const pinProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, pinLoading: false } });

const unTrackProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, trackingLoading: true } });
const unTrackProductByIdSuccess = (state, { payload: data }) => {
  const currentProducts = [...state.products.results];
  const newProducts = [...currentProducts].filter(el => el?.product_id !== data?.product_id);
  const newLimits = state?.stores?.limits?.products?.used - 1;
  return state.merge({
    ...state,
    stores: {
      ...state.stores,
      limits: {
        ...state.stores.limits,
        products: {
          ...state.stores.limits.products,
          used: newLimits,
        }
      },
    },
    products: {
      ...state.products,
      trackingLoading: false,
      results: [...newProducts],
      limits: {
        ...state.products.limits,
        products: {
          ...state.products.limits.products,
          used: newLimits,
        }
      },
    } });
};
const unTrackProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, trackingLoading: false } });

const trackProductByUrlRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, trackingLoading: true } });
const trackProductByUrlSuccess = (state, { payload: data }) => {
  const newLimits = state?.stores?.limits?.products?.used + 1;
  return state.merge({
    ...state,
    stores: {
      ...state.stores,
      limits: {
        ...state.stores.limits,
        products: {
          ...state.stores.limits.products,
          used: newLimits,
        }
      },
    },
    products: {
      ...state.products,
      limits: {
        ...state.products.limits,
        products: {
          ...state.products.limits.products,
          used: newLimits,
        }
      },
      trackingLoading: false, results: [...state.products.results, { ...data }]
    },
  });
};
const trackProductByUrlFailure = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, trackingLoading: false } });

const trackProductByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, trackingLoading: true } });
const trackProductByIdSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    products: { ...state.products, trackingLoading: false, results: [...state.products.results, { ...data }] },
  });
const trackProductByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: false } });

const getTrackedProductsRequest = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, loading: true } });
const getTrackedProductsSuccess = (state, { payload: data }) => {
  const { page, results, ...rest } = data;
  if (page === 1) {
    return state.merge({ ...state, products: { ...state.products, loading: false, ...rest, results: results } });
  } else return state.merge({
    ...state,
    products: { ...state.products, loading: false, ...rest, results: [...state.products.results, results] },
  });
};
const getTrackedProductsFailure = (state, { payload: data }) =>
  state.merge({ ...state, products: { ...state.products, loading: false } });

const getPopularProductsRequest = (state) =>
  state.merge({ ...state, products: { ...state.products, popular: { ...state.products.popular, loading: true } } });
const getPopularProductsSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    products: { ...state.products, popular: { ...state.products.popular, loading: false, results: [...data?.results] } },
  });
const getPopularProductsFailure = (state) =>
  state.merge({
    ...state,
    products: { ...state.products, popular: { ...state.products.popular, loading: false, results: [] } },
  });


const getStoreAppsRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, apps: { ...state.storeInnerPage.full.apps, loading: true } },
    },
  });
const getStoreAppsSuccess = (state, { payload: data }) =>
  data?.page === 1 ?
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: { ...state.storeInnerPage.full, apps: { ...state.storeInnerPage.full.apps, loading: false, ...data } },
      },
    })
    :
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: { ...state.storeInnerPage.full, apps: { ...state.storeInnerPage.full.apps, loading: false, results: [...state.storeInnerPage.full.apps?.results, ...data?.results] } },
      },
    })
const getStoreAppsFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, apps: { ...state.storeInnerPage.full.apps, loading: false } },
    },
  });

const getStoreAppsShortRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, apps: { ...state.storeInnerPage.short.apps, loading: true } },
    },
  });
const getStoreAppsShortSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, apps: { ...state.storeInnerPage.short.apps, loading: false, ...data } },
    },
  });
const getStoreAppsShortFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, apps: { ...state.storeInnerPage.short.apps, loading: false } },
    },
  });

const getStoreAdsFiltersRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, filtersLoading: true } },
    },
  });
const getStoreAdsFiltersSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        ads: { ...state.storeInnerPage.full.ads, filtersLoading: false, filters: { ...data } },
      },
    },
  });
const getStoreAdsFiltersFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, filtersLoading: false } },
    },
  });

const getStoreAdsCountRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, countLoading: true } },
    },
  });
const getStoreAdsCountSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, countLoading: false, ...data } },
    },
  });
const getStoreAdsCountFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, countLoading: false } },
    },
  });

const getStoreAdsRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, loading: true } },
    },
  });
const getStoreAdsSuccess = (state, { payload: data }) =>
  data?.page === 1 ?
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, loading: false, ...data } },
      },
    })
    :
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: {
          ...state.storeInnerPage.full,
          ads: { ...state.storeInnerPage.full.ads, loading: false, results: [ ...state.storeInnerPage.full.ads?.results, ...data?.results ] },
        },
      },
    });
const getStoreAdsFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, ads: { ...state.storeInnerPage.full.ads, loading: false } },
    },
  });

const getStoreAdsShortRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, ads: { ...state.storeInnerPage.short.ads, loading: true } },
    },
  });
const getStoreAdsShortSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, ads: { ...state.storeInnerPage.short.ads, loading: false, ...data } },
    },
  });
const getStoreAdsShortFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, ads: { ...state.storeInnerPage.short.ads, loading: false } },
    },
  });

const getStoreCompetitorsFiltersRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, filtersLoading: true },
      },
    },
  });
const getStoreCompetitorsFiltersSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, filtersLoading: false, filters: { ...data } },
      },
    },
  });
const getStoreCompetitorsFiltersFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, filtersLoading: false },
      },
    },
  });

const getStoreCompetitorsCountRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, countLoading: true },
      },
    },
  });
const getStoreCompetitorsCountSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, countLoading: false, ...data },
      },
    },
  });
const getStoreCompetitorsCountFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        competitors: { ...state.storeInnerPage.full.competitors, countLoading: false },
      },
    },
  });

const getStoreCompetitorsRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, competitors: { ...state.storeInnerPage.full.competitors, loading: true } },
    },
  });
const getStoreCompetitorsSuccess = (state, { payload: data }) =>
  data?.page === 1 ?
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: {
          ...state.storeInnerPage.full,
          competitors: { ...state.storeInnerPage.full.competitors, loading: false, ...data },
        },
      },
    })
    :
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: {
          ...state.storeInnerPage.full,
          competitors: { ...state.storeInnerPage.full.competitors, loading: false, results: [ ...state.storeInnerPage.full.competitors?.results, ...data?.results ] },
        },
      },
    });
const getStoreCompetitorsFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, competitors: { ...state.storeInnerPage.full.competitors, loading: false } },
    },
  });

const getStoreCompetitorsShortRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: { ...state.storeInnerPage.short.competitors, loading: true },
      },
    },
  });
const getStoreCompetitorsShortSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: { ...state.storeInnerPage.short.competitors, loading: false, ...data },
      },
    },
  });
const getStoreCompetitorsShortFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        competitors: { ...state.storeInnerPage.short.competitors, loading: false },
      },
    },
  });

const getStoreProductsRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, products: { ...state.storeInnerPage.full.products, loading: true } },
    },
  });
const getStoreProductsSuccess = (state, { payload: data }) =>
  data?.page === 1 ?
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: {
          ...state.storeInnerPage.full,
          products: { ...state.storeInnerPage.full.products, loading: false, ...data },
        },
      },
    })
    :
    state.merge({
      ...state,
      storeInnerPage: {
        ...state.storeInnerPage,
        full: {
          ...state.storeInnerPage.full,
          products: { ...state.storeInnerPage.full.products, loading: false, results: [ ...state.storeInnerPage?.full?.products?.results, ...data?.results ] },
        },
      },
    });
const getStoreProductsFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: { ...state.storeInnerPage.full, products: { ...state.storeInnerPage.full.products, loading: false } },
    },
  });

const getStoreProductsShortRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, products: { ...state.storeInnerPage.short.products, loading: true } },
    },
  });
const getStoreProductsShortSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        products: { ...state.storeInnerPage.short.products, loading: false, ...data },
      },
    },
  });
const getStoreProductsShortFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: { ...state.storeInnerPage.short, products: { ...state.storeInnerPage.short.products, loading: false } },
    },
  });

const getStoreSalesAndRevenueRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        sales_and_revenue: { ...state.storeInnerPage.full.sales_and_revenue, loading: true },
      },
    },
  });
const getStoreSalesAndRevenueSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        sales_and_revenue: { ...state.storeInnerPage.full.sales_and_revenue, loading: false, ...data },
      },
    },
  });
const getStoreSalesAndRevenueFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      full: {
        ...state.storeInnerPage.full,
        sales_and_revenue: { ...state.storeInnerPage.full.sales_and_revenue, loading: false },
      },
    },
  });

const getStoreSalesAndRevenueShortRequest = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        sales_and_revenue: { ...state.storeInnerPage.short.sales_and_revenue, loading: true },
      },
    },
  });
const getStoreSalesAndRevenueShortSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        sales_and_revenue: { ...state.storeInnerPage.short.sales_and_revenue, loading: false, ...data },
      },
    },
  });
const getStoreSalesAndRevenueShortFailure = (state, { payload: data }) =>
  state.merge({
    ...state,
    storeInnerPage: {
      ...state.storeInnerPage,
      short: {
        ...state.storeInnerPage.short,
        sales_and_revenue: { ...state.storeInnerPage.short.sales_and_revenue, loading: false },
      },
    },
  });

const getStoreHeaderRequest = (state, { payload: data }) =>
  state.merge({ ...state, storeInnerPage: { ...state.storeInnerPage, header: { loading: true } } });
const getStoreHeaderSuccess = (state, { payload: data }) =>
  state.merge({ ...state, storeInnerPage: { ...state.storeInnerPage, header: { loading: false, ...data } } });
const getStoreHeaderFailure = (state, { payload: data }) =>
  state.merge({ ...state, storeInnerPage: { ...state.storeInnerPage, header: { loading: false } } });

const getTrackSuggestionsRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, suggestions: { ...state.stores.suggestions, loading: true } } });
const getTrackSuggestionsSuccess = (state, { payload: data }) => {
  const {domain, ...rest} = data;
  return state.merge({ ...state, stores: { ...state.stores, suggestions: { loading: false, results: [...Object.values(rest)] } } });
}
const getTrackSuggestionsFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, suggestions: { ...state.stores.suggestions, loading: false } } });

const unPinStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, pinLoading: true } });
const unPinStoreByIdSuccess = (state, { payload: data }) => {
  const currentStores = [...state.stores.results];
  const otherStores = [...currentStores].filter(el => el?.internal_shop_id !== data?.internal_shop_id);
  const currentStore = { ...[...currentStores].filter(el => el?.internal_shop_id === data?.internal_shop_id)?.[0], pin_time: false };
  return state.merge({
    ...state,
    stores: { ...state.stores, pinLoading: false, results: [...otherStores, currentStore] },
  });
};
const unPinStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, pinLoading: false } });

const pinStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, pinLoading: true } });
const pinStoreByIdSuccess = (state, { payload: data }) => {
  const currentStores = [...state.stores.results];
  const otherStores = [...currentStores].filter(el => el?.internal_shop_id !== data?.internal_shop_id);
  const currentStore = { ...[...currentStores].filter(el => el?.internal_shop_id === data?.internal_shop_id)?.[0], pin_time: true };
  return state.merge({
    ...state,
    stores: { ...state.stores, pinLoading: false, results: [currentStore, ...otherStores] },
  });
};
const pinStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, pinLoading: false } });

const unTrackStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: true } });
const unTrackStoreByIdSuccess = (state, { payload: data }) => {
  const currentStores = [...state.stores.results];
  const newStores = [...currentStores].filter(el => el?.internal_shop_id !== data?.internal_shop_id);
  const newLimits = state?.stores?.limits?.stores?.used - 1;
  return state.merge({
    ...state,
    stores: {
      ...state.stores,
      trackingLoading: false,
      results: [...newStores],
      limits: {
        ...state.stores.limits,
        stores: {
          ...state.stores.limits.stores,
          used: newLimits,
        }
      },
    } });
};
const unTrackStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: false } });

const trackStoreByUrlRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: true } });
const trackStoreByUrlSuccess = (state, { payload: data }) => {
  const newLimits = state?.stores?.limits?.stores?.used + 1;
  return state.merge({
    ...state,
    stores: {
      ...state.stores,
      limits: {
        ...state.stores.limits,
        stores: {
          ...state.stores.limits.stores,
          used: newLimits,
        }
      },
      trackingLoading: false, results: [...state.stores.results, { ...data }]
    },
  });
}
const trackStoreByUrlFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: false } });

const trackStoreByIdRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: true } });
const trackStoreByIdSuccess = (state, { payload: data }) => {
  const newLimits = state?.stores?.limits?.stores?.used + 1;
  return state.merge({
    ...state,
    stores: {
      ...state.stores,
      limits: {
        ...state.stores.limits,
        stores: {
          ...state.stores.limits.stores,
          used: newLimits,
        }
      },
      trackingLoading: false, results: [...state.stores.results, { ...data }]
    },
  });
}
const trackStoreByIdFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, trackingLoading: false } });

const getTrackedStoresRequest = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, loading: true } });
const getTrackedStoresSuccess = (state, { payload: data }) => {
  const { page, results, ...rest } = data;
  if (page === 1) {
    return state.merge({ ...state, stores: { ...state.stores, loading: false, ...rest, results: results } });
  } else return state.merge({
    ...state,
    stores: { ...state.stores, loading: false, ...rest, results: [...state.stores.results, results] },
  });
};
const getTrackedStoresFailure = (state, { payload: data }) =>
  state.merge({ ...state, stores: { ...state.stores, loading: false } });

const getPopularStoresRequest = (state) =>
  state.merge({ ...state, stores: { ...state.stores, popular: { ...state.stores.popular, loading: true } } });
const getPopularStoresSuccess = (state, { payload: data }) =>
  state.merge({
    ...state,
    stores: { ...state.stores, popular: { ...state.stores.popular, loading: false, results: [...data.results] } },
  });
const getPopularStoresFailure = (state) =>
  state.merge({
    ...state,
    stores: { ...state.stores, popular: { ...state.stores.popular, loading: false, results: [] } },
  });

const getTopStoresFiltersRequest = (state) =>
  state.merge({ topPage: { ...state?.topPage, filtersLoading: true } });
const getTopStoresFiltersSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      filtersLoading: false,
      topStores: {
        ...state?.topPage?.topStores, filters: data,
      },
    },
  });
const getTopStoresFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      filtersLoading: false,
      topStores: {
        ...state?.topPage?.topStores, errors: errors,
      },
    },
  });

const getTopProductsFiltersRequest = (state) =>
  state.merge({ topPage: { ...state?.topPage, filtersLoading: true } });
const getTopProductsFiltersSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      filtersLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, filters: data,
      },
    },
  });
const getTopProductsFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      filtersLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, errors: errors,
      },
    },
  });

const getTopStoresPresetsRequest = (state) =>
  state.merge({ topPage: { ...state?.topPage, presetsLoading: true } });
const getTopStoresPresetsSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      presetsLoading: false,
      topStores: {
        ...state?.topPage?.topStores, presets: data,
      },
    },
  });
const getTopStoresPresetsFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      presetsLoading: false,
      topStores: {
        ...state?.topPage?.topStores, errors: errors,
      },
    },
  });

const getTopProductsPresetsRequest = (state) =>
  state.merge({ topPage: { ...state?.topPage, presetsLoading: true } });
const getTopProductsPresetsSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      presetsLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, presets: data,
      },
    },
  });
const getTopProductsPresetsFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      presetsLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, errors: errors,
      },
    },
  });

const getTopStoresRequest = (state, { payload }) => {
  let newSearch = payload?.new_search || false;
  return state.merge({ topPage: { ...state?.topPage, loading: newSearch, spinner: !newSearch } });
};
const getTopStoresSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      loading: false,
      spinner: false,
      topStores: {
        ...state?.topPage?.topStores, stores: { ...data },
      },
    },
  });
const getTopStoresFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      loading: false,
      spinner: false,
      topStores: {
        ...state?.topPage?.topStores, errors: errors,
      },
    },
  });

const getTopProductsRequest = (state, { payload }) => {
  const newSearch = payload?.new_search || false;
  return state.merge({
    topPage: {
      ...state?.topPage,
      loading: newSearch,
      spinner: !newSearch,
    },
  });
};
const getTopProductsSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      loading: false,
      spinner: false,
      topProducts: {
        ...state?.topPage?.topProducts,
        products: { ...data },
      },
    },
  });
const getTopProductsFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      loading: false,
      spinner: false,
      topProducts: {
        ...state?.topPage?.topProducts,
        errors: errors,
      },
    },
  });

const getTopStoresCountRequest = (state, { payload }) => {
  return state.merge({ topPage: { ...state?.topPage, countLoading: true } });
};
const getTopStoresCountSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      countLoading: false,
      topStores: {
        ...state?.topPage?.topStores, count: data?.count,
      },
    },
  });
const getTopStoresCountFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      countLoading: false,
      topStores: {
        ...state?.topPage?.topStores, errors: errors,
      },
    },
  });

const getTopProductsCountRequest = (state, { payload }) => {
  return state.merge({ topPage: { ...state?.topPage, countLoading: true } });
};
const getTopProductsCountSuccess = (state, { payload: data }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      countLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, count: data?.count,
      },
    },
  });
const getTopProductsCountFailure = (state, { payload: { errors } }) =>
  state.merge({
    topPage: {
      ...state?.topPage,
      countLoading: false,
      topProducts: {
        ...state?.topPage?.topProducts, errors: errors,
      },
    },
  });

const getProductPriceHistorySalesTrackerRequest = (state) =>
  state.merge({ productPriceLoading: true });
const getProductPriceHistorySalesTrackerSuccess = (state, { payload: data }) =>
  state.merge({ productPriceLoading: false, productPriceData: data });
const getProductPriceHistorySalesTrackerFailure = (state, { payload: { errors } }) =>
  state.merge({ productPriceLoading: false });

const setView = (state, { payload: data }) =>
  state.merge({ view: data });
const resetSuggestions = (state) =>
  state.merge({ ...state, stores: { ...state.stores, suggestions: { loading: false, results: [] } } });
const resetProductPriceSalesTracker = (state) =>
  state.merge({ productPriceLoading: true, productPriceData: {} });
const resetStoreProductsModal = (state) =>
  state.merge({ ...state, storeProductsModal: { loading: false, results: [] } });
const resetInnerStore = (state) =>
  state.merge({ ...state, storeInnerPage: {
      downloadReportLoading: false,
      header: {
        loading: false,
        trackingLoading: false,
      },
      short: {
        sales_and_revenue: {
          loading: false,
        },
        products: {
          loading: false,
          trackingLoading: false,
          results: [],
        },
        competitors: {
          loading: false,
          trackingLoading: false,
        },
        ads: {
          loading: false,
        },
        apps: {
          loading: false,
        },
      },
      full: {
        sales_and_revenue: {
          loading: false,
        },
        products: {
          loading: false,
          trackingLoading: false,
          results: [],
        },
        competitors: {
          loading: false,
          trackingLoading: false,
          countLoading: false,
          filtersLoading: false,
          count: 0,
          filters: {},
        },
        ads: {
          loading: false,
          countLoading: false,
          filtersLoading: false,
          count: 0,
          filters: {},
        },
        apps: {
          loading: false,
        },
      },
    } });
const resetInnerProduct = (state) =>
  state.merge({ ...state, productInnerPage: {
      downloadReportLoading: false,
      header: {
        loading: false,
        trackingLoading: false,
      },
      return_and_revenue: {
        loading: false,
      },
    } });

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_STORE_REPORT_REQUEST]: getStoreReportRequest,
  [Types.GET_STORE_REPORT_SUCCESS]: getStoreReportSuccess,
  [Types.GET_STORE_REPORT_FAILURE]: getStoreReportFailure,

  [Types.GET_PRODUCT_REPORT_REQUEST]: getProductReportRequest,
  [Types.GET_PRODUCT_REPORT_SUCCESS]: getProductReportSuccess,
  [Types.GET_PRODUCT_REPORT_FAILURE]: getProductReportFailure,

  [Types.TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST]: trackCompetitorStoreInnerPageRequest,
  [Types.TRACK_COMPETITOR_STORE_INNER_PAGE_SUCCESS]: trackCompetitorStoreInnerPageSuccess,
  [Types.TRACK_COMPETITOR_STORE_INNER_PAGE_FAILURE]: trackCompetitorStoreInnerPageFailure,

  [Types.UN_TRACK_COMPETITOR_STORE_INNER_PAGE_REQUEST]: unTrackCompetitorStoreInnerPageRequest,
  [Types.UN_TRACK_COMPETITOR_STORE_INNER_PAGE_SUCCESS]: unTrackCompetitorStoreInnerPageSuccess,
  [Types.UN_TRACK_COMPETITOR_STORE_INNER_PAGE_FAILURE]: unTrackCompetitorStoreInnerPageFailure,

  [Types.TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST]: trackCompetitorShortStoreInnerPageRequest,
  [Types.TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_SUCCESS]: trackCompetitorShortStoreInnerPageSuccess,
  [Types.TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_FAILURE]: trackCompetitorShortStoreInnerPageFailure,

  [Types.UN_TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_REQUEST]: unTrackCompetitorShortStoreInnerPageRequest,
  [Types.UN_TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_SUCCESS]: unTrackCompetitorShortStoreInnerPageSuccess,
  [Types.UN_TRACK_COMPETITOR_SHORT_STORE_INNER_PAGE_FAILURE]: unTrackCompetitorShortStoreInnerPageFailure,

  [Types.TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST]: trackProductShortStoreInnerPageRequest,
  [Types.TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_SUCCESS]: trackProductShortStoreInnerPageSuccess,
  [Types.TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_FAILURE]: trackProductShortStoreInnerPageFailure,

  [Types.UN_TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_REQUEST]: unTrackProductShortStoreInnerPageRequest,
  [Types.UN_TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_SUCCESS]: unTrackProductShortStoreInnerPageSuccess,
  [Types.UN_TRACK_PRODUCT_SHORT_STORE_INNER_PAGE_FAILURE]: unTrackProductShortStoreInnerPageFailure,

  [Types.TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST]: trackProductStoreInnerPageRequest,
  [Types.TRACK_PRODUCT_STORE_INNER_PAGE_SUCCESS]: trackProductStoreInnerPageSuccess,
  [Types.TRACK_PRODUCT_STORE_INNER_PAGE_FAILURE]: trackProductStoreInnerPageFailure,

  [Types.UN_TRACK_PRODUCT_STORE_INNER_PAGE_REQUEST]: unTrackProductStoreInnerPageRequest,
  [Types.UN_TRACK_PRODUCT_STORE_INNER_PAGE_SUCCESS]: unTrackProductStoreInnerPageSuccess,
  [Types.UN_TRACK_PRODUCT_STORE_INNER_PAGE_FAILURE]: unTrackProductStoreInnerPageFailure,

  [Types.TRACK_STORE_INNER_PAGE_REQUEST]: trackStoreInnerPageRequest,
  [Types.TRACK_STORE_INNER_PAGE_SUCCESS]: trackStoreInnerPageSuccess,
  [Types.TRACK_STORE_INNER_PAGE_FAILURE]: trackStoreInnerPageFailure,

  [Types.UN_TRACK_STORE_INNER_PAGE_REQUEST]: unTrackStoreInnerPageRequest,
  [Types.UN_TRACK_STORE_INNER_PAGE_SUCCESS]: unTrackStoreInnerPageSuccess,
  [Types.UN_TRACK_STORE_INNER_PAGE_FAILURE]: unTrackStoreInnerPageFailure,

  [Types.TRACK_PRODUCT_INNER_PAGE_REQUEST]: trackProductInnerPageRequest,
  [Types.TRACK_PRODUCT_INNER_PAGE_SUCCESS]: trackProductInnerPageSuccess,
  [Types.TRACK_PRODUCT_INNER_PAGE_FAILURE]: trackProductInnerPageFailure,

  [Types.UN_TRACK_PRODUCT_INNER_PAGE_REQUEST]: unTrackProductInnerPageRequest,
  [Types.UN_TRACK_PRODUCT_INNER_PAGE_SUCCESS]: unTrackProductInnerPageSuccess,
  [Types.UN_TRACK_PRODUCT_INNER_PAGE_FAILURE]: unTrackProductInnerPageFailure,

  [Types.GET_STORE_PRODUCTS_MODAL_REQUEST]: getStoreProductsModalRequest,
  [Types.GET_STORE_PRODUCTS_MODAL_SUCCESS]: getStoreProductsModalSuccess,
  [Types.GET_STORE_PRODUCTS_MODAL_FAILURE]: getStoreProductsModalFailure,

  [Types.TRACK_TOP_STORE_BY_ID_REQUEST]: trackTopStoreByIdRequest,
  [Types.TRACK_TOP_STORE_BY_ID_SUCCESS]: trackTopStoreByIdSuccess,
  [Types.TRACK_TOP_STORE_BY_ID_FAILURE]: trackTopStoreByIdFailure,

  [Types.UN_TRACK_TOP_STORE_BY_ID_REQUEST]: unTrackTopStoreByIdRequest,
  [Types.UN_TRACK_TOP_STORE_BY_ID_SUCCESS]: unTrackTopStoreByIdSuccess,
  [Types.UN_TRACK_TOP_STORE_BY_ID_FAILURE]: unTrackTopStoreByIdFailure,

  [Types.TRACK_TOP_PRODUCT_BY_ID_REQUEST]: trackTopProductByIdRequest,
  [Types.TRACK_TOP_PRODUCT_BY_ID_SUCCESS]: trackTopProductByIdSuccess,
  [Types.TRACK_TOP_PRODUCT_BY_ID_FAILURE]: trackTopProductByIdFailure,

  [Types.UN_TRACK_TOP_PRODUCT_BY_ID_REQUEST]: unTrackTopProductByIdRequest,
  [Types.UN_TRACK_TOP_PRODUCT_BY_ID_SUCCESS]: unTrackTopProductByIdSuccess,
  [Types.UN_TRACK_TOP_PRODUCT_BY_ID_FAILURE]: unTrackTopProductByIdFailure,

  [Types.GET_POPULAR_PRODUCTS_REQUEST]: getPopularProductsRequest,
  [Types.GET_POPULAR_PRODUCTS_SUCCESS]: getPopularProductsSuccess,
  [Types.GET_POPULAR_PRODUCTS_FAILURE]: getPopularProductsFailure,

  [Types.GET_TRACKED_PRODUCTS_REQUEST]: getTrackedProductsRequest,
  [Types.GET_TRACKED_PRODUCTS_SUCCESS]: getTrackedProductsSuccess,
  [Types.GET_TRACKED_PRODUCTS_FAILURE]: getTrackedProductsFailure,

  [Types.TRACK_PRODUCT_BY_ID_REQUEST]: trackProductByIdRequest,
  [Types.TRACK_PRODUCT_BY_ID_SUCCESS]: trackProductByIdSuccess,
  [Types.TRACK_PRODUCT_BY_ID_FAILURE]: trackProductByIdFailure,

  [Types.TRACK_PRODUCT_BY_URL_REQUEST]: trackProductByUrlRequest,
  [Types.TRACK_PRODUCT_BY_URL_SUCCESS]: trackProductByUrlSuccess,
  [Types.TRACK_PRODUCT_BY_URL_FAILURE]: trackProductByUrlFailure,

  [Types.UN_TRACK_PRODUCT_BY_ID_REQUEST]: unTrackProductByIdRequest,
  [Types.UN_TRACK_PRODUCT_BY_ID_SUCCESS]: unTrackProductByIdSuccess,
  [Types.UN_TRACK_PRODUCT_BY_ID_FAILURE]: unTrackProductByIdFailure,

  [Types.PIN_PRODUCT_BY_ID_REQUEST]: pinProductByIdRequest,
  [Types.PIN_PRODUCT_BY_ID_SUCCESS]: pinProductByIdSuccess,
  [Types.PIN_PRODUCT_BY_ID_FAILURE]: pinProductByIdFailure,

  [Types.UN_PIN_PRODUCT_BY_ID_REQUEST]: unPinProductByIdRequest,
  [Types.UN_PIN_PRODUCT_BY_ID_SUCCESS]: unPinProductByIdSuccess,
  [Types.UN_PIN_PRODUCT_BY_ID_FAILURE]: unPinProductByIdFailure,

  [Types.GET_PRODUCT_HEADER_REQUEST]: getProductHeaderRequest,
  [Types.GET_PRODUCT_HEADER_SUCCESS]: getProductHeaderSuccess,
  [Types.GET_PRODUCT_HEADER_FAILURE]: getProductHeaderFailure,

  [Types.GET_PRODUCT_RETURN_AND_REVENUE_REQUEST]: getProductReturnAndRevenueRequest,
  [Types.GET_PRODUCT_RETURN_AND_REVENUE_SUCCESS]: getProductReturnAndRevenueSuccess,
  [Types.GET_PRODUCT_RETURN_AND_REVENUE_FAILURE]: getProductReturnAndRevenueFailure,

  [Types.GET_STORE_APPS_REQUEST]: getStoreAppsRequest,
  [Types.GET_STORE_APPS_SUCCESS]: getStoreAppsSuccess,
  [Types.GET_STORE_APPS_FAILURE]: getStoreAppsFailure,

  [Types.GET_STORE_APPS_SHORT_REQUEST]: getStoreAppsShortRequest,
  [Types.GET_STORE_APPS_SHORT_SUCCESS]: getStoreAppsShortSuccess,
  [Types.GET_STORE_APPS_SHORT_FAILURE]: getStoreAppsShortFailure,

  [Types.GET_STORE_ADS_FILTERS_REQUEST]: getStoreAdsFiltersRequest,
  [Types.GET_STORE_ADS_FILTERS_SUCCESS]: getStoreAdsFiltersSuccess,
  [Types.GET_STORE_ADS_FILTERS_FAILURE]: getStoreAdsFiltersFailure,

  [Types.GET_STORE_ADS_COUNT_REQUEST]: getStoreAdsCountRequest,
  [Types.GET_STORE_ADS_COUNT_SUCCESS]: getStoreAdsCountSuccess,
  [Types.GET_STORE_ADS_COUNT_FAILURE]: getStoreAdsCountFailure,

  [Types.GET_STORE_ADS_REQUEST]: getStoreAdsRequest,
  [Types.GET_STORE_ADS_SUCCESS]: getStoreAdsSuccess,
  [Types.GET_STORE_ADS_FAILURE]: getStoreAdsFailure,

  [Types.GET_STORE_ADS_SHORT_REQUEST]: getStoreAdsShortRequest,
  [Types.GET_STORE_ADS_SHORT_SUCCESS]: getStoreAdsShortSuccess,
  [Types.GET_STORE_ADS_SHORT_FAILURE]: getStoreAdsShortFailure,

  [Types.GET_STORE_COMPETITORS_FILTERS_REQUEST]: getStoreCompetitorsFiltersRequest,
  [Types.GET_STORE_COMPETITORS_FILTERS_SUCCESS]: getStoreCompetitorsFiltersSuccess,
  [Types.GET_STORE_COMPETITORS_FILTERS_FAILURE]: getStoreCompetitorsFiltersFailure,

  [Types.GET_STORE_COMPETITORS_COUNT_REQUEST]: getStoreCompetitorsCountRequest,
  [Types.GET_STORE_COMPETITORS_COUNT_SUCCESS]: getStoreCompetitorsCountSuccess,
  [Types.GET_STORE_COMPETITORS_COUNT_FAILURE]: getStoreCompetitorsCountFailure,

  [Types.GET_STORE_COMPETITORS_REQUEST]: getStoreCompetitorsRequest,
  [Types.GET_STORE_COMPETITORS_SUCCESS]: getStoreCompetitorsSuccess,
  [Types.GET_STORE_COMPETITORS_FAILURE]: getStoreCompetitorsFailure,

  [Types.GET_STORE_COMPETITORS_SHORT_REQUEST]: getStoreCompetitorsShortRequest,
  [Types.GET_STORE_COMPETITORS_SHORT_SUCCESS]: getStoreCompetitorsShortSuccess,
  [Types.GET_STORE_COMPETITORS_SHORT_FAILURE]: getStoreCompetitorsShortFailure,

  [Types.GET_STORE_PRODUCTS_REQUEST]: getStoreProductsRequest,
  [Types.GET_STORE_PRODUCTS_SUCCESS]: getStoreProductsSuccess,
  [Types.GET_STORE_PRODUCTS_FAILURE]: getStoreProductsFailure,

  [Types.GET_STORE_PRODUCTS_SHORT_REQUEST]: getStoreProductsShortRequest,
  [Types.GET_STORE_PRODUCTS_SHORT_SUCCESS]: getStoreProductsShortSuccess,
  [Types.GET_STORE_PRODUCTS_SHORT_FAILURE]: getStoreProductsShortFailure,

  [Types.GET_STORE_SALES_AND_REVENUE_REQUEST]: getStoreSalesAndRevenueRequest,
  [Types.GET_STORE_SALES_AND_REVENUE_SUCCESS]: getStoreSalesAndRevenueSuccess,
  [Types.GET_STORE_SALES_AND_REVENUE_FAILURE]: getStoreSalesAndRevenueFailure,

  [Types.GET_STORE_SALES_AND_REVENUE_SHORT_REQUEST]: getStoreSalesAndRevenueShortRequest,
  [Types.GET_STORE_SALES_AND_REVENUE_SHORT_SUCCESS]: getStoreSalesAndRevenueShortSuccess,
  [Types.GET_STORE_SALES_AND_REVENUE_SHORT_FAILURE]: getStoreSalesAndRevenueShortFailure,

  [Types.GET_STORE_HEADER_REQUEST]: getStoreHeaderRequest,
  [Types.GET_STORE_HEADER_SUCCESS]: getStoreHeaderSuccess,
  [Types.GET_STORE_HEADER_FAILURE]: getStoreHeaderFailure,

  [Types.GET_TRACK_SUGGESTIONS_REQUEST]: getTrackSuggestionsRequest,
  [Types.GET_TRACK_SUGGESTIONS_SUCCESS]: getTrackSuggestionsSuccess,
  [Types.GET_TRACK_SUGGESTIONS_FAILURE]: getTrackSuggestionsFailure,

  [Types.UN_PIN_STORE_BY_ID_REQUEST]: unPinStoreByIdRequest,
  [Types.UN_PIN_STORE_BY_ID_SUCCESS]: unPinStoreByIdSuccess,
  [Types.UN_PIN_STORE_BY_ID_FAILURE]: unPinStoreByIdFailure,

  [Types.PIN_STORE_BY_ID_REQUEST]: pinStoreByIdRequest,
  [Types.PIN_STORE_BY_ID_SUCCESS]: pinStoreByIdSuccess,
  [Types.PIN_STORE_BY_ID_FAILURE]: pinStoreByIdFailure,

  [Types.UN_TRACK_STORE_BY_ID_REQUEST]: unTrackStoreByIdRequest,
  [Types.UN_TRACK_STORE_BY_ID_SUCCESS]: unTrackStoreByIdSuccess,
  [Types.UN_TRACK_STORE_BY_ID_FAILURE]: unTrackStoreByIdFailure,

  [Types.TRACK_STORE_BY_URL_REQUEST]: trackStoreByUrlRequest,
  [Types.TRACK_STORE_BY_URL_SUCCESS]: trackStoreByUrlSuccess,
  [Types.TRACK_STORE_BY_URL_FAILURE]: trackStoreByUrlFailure,

  [Types.TRACK_STORE_BY_ID_REQUEST]: trackStoreByIdRequest,
  [Types.TRACK_STORE_BY_ID_SUCCESS]: trackStoreByIdSuccess,
  [Types.TRACK_STORE_BY_ID_FAILURE]: trackStoreByIdFailure,

  [Types.GET_TRACKED_STORES_REQUEST]: getTrackedStoresRequest,
  [Types.GET_TRACKED_STORES_SUCCESS]: getTrackedStoresSuccess,
  [Types.GET_TRACKED_STORES_FAILURE]: getTrackedStoresFailure,

  [Types.GET_POPULAR_STORES_REQUEST]: getPopularStoresRequest,
  [Types.GET_POPULAR_STORES_SUCCESS]: getPopularStoresSuccess,
  [Types.GET_POPULAR_STORES_FAILURE]: getPopularStoresFailure,

  [Types.GET_TOP_STORES_FILTERS_REQUEST]: getTopStoresFiltersRequest,
  [Types.GET_TOP_STORES_FILTERS_SUCCESS]: getTopStoresFiltersSuccess,
  [Types.GET_TOP_STORES_FILTERS_FAILURE]: getTopStoresFiltersFailure,

  [Types.GET_TOP_PRODUCTS_FILTERS_REQUEST]: getTopProductsFiltersRequest,
  [Types.GET_TOP_PRODUCTS_FILTERS_SUCCESS]: getTopProductsFiltersSuccess,
  [Types.GET_TOP_PRODUCTS_FILTERS_FAILURE]: getTopProductsFiltersFailure,

  [Types.GET_TOP_STORES_PRESETS_REQUEST]: getTopStoresPresetsRequest,
  [Types.GET_TOP_STORES_PRESETS_SUCCESS]: getTopStoresPresetsSuccess,
  [Types.GET_TOP_STORES_PRESETS_FAILURE]: getTopStoresPresetsFailure,

  [Types.GET_TOP_PRODUCTS_PRESETS_REQUEST]: getTopProductsPresetsRequest,
  [Types.GET_TOP_PRODUCTS_PRESETS_SUCCESS]: getTopProductsPresetsSuccess,
  [Types.GET_TOP_PRODUCTS_PRESETS_FAILURE]: getTopProductsPresetsFailure,

  [Types.GET_TOP_STORES_REQUEST]: getTopStoresRequest,
  [Types.GET_TOP_STORES_SUCCESS]: getTopStoresSuccess,
  [Types.GET_TOP_STORES_FAILURE]: getTopStoresFailure,

  [Types.GET_TOP_PRODUCTS_REQUEST]: getTopProductsRequest,
  [Types.GET_TOP_PRODUCTS_SUCCESS]: getTopProductsSuccess,
  [Types.GET_TOP_PRODUCTS_FAILURE]: getTopProductsFailure,

  [Types.GET_TOP_STORES_COUNT_REQUEST]: getTopStoresCountRequest,
  [Types.GET_TOP_STORES_COUNT_SUCCESS]: getTopStoresCountSuccess,
  [Types.GET_TOP_STORES_COUNT_FAILURE]: getTopStoresCountFailure,

  [Types.GET_TOP_PRODUCTS_COUNT_REQUEST]: getTopProductsCountRequest,
  [Types.GET_TOP_PRODUCTS_COUNT_SUCCESS]: getTopProductsCountSuccess,
  [Types.GET_TOP_PRODUCTS_COUNT_FAILURE]: getTopProductsCountFailure,

  [Types.GET_PRODUCT_PRICE_HISTORY_SALES_TRACKER_REQUEST]: getProductPriceHistorySalesTrackerRequest,
  [Types.GET_PRODUCT_PRICE_HISTORY_SALES_TRACKER_SUCCESS]: getProductPriceHistorySalesTrackerSuccess,
  [Types.GET_PRODUCT_PRICE_HISTORY_SALES_TRACKER_FAILURE]: getProductPriceHistorySalesTrackerFailure,

  [Types.SET_VIEW]: setView,
  [Types.RESET_SUGGESTIONS]: resetSuggestions,
  [Types.RESET_PRODUCT_PRICE_SALES_TRACKER]: resetProductPriceSalesTracker,
  [Types.RESET_STORE_PRODUCTS_MODAL]: resetStoreProductsModal,
  [Types.RESET_INNER_STORE]: resetInnerStore,
  [Types.RESET_INNER_PRODUCT]: resetInnerProduct,
});
