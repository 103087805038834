import React from 'react';
import utils from '../../../../Utils/utils';

const CollectionsSkeletons = ({isMobile}) => {
  return (
    <div className="product-database-page collections-page">
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          {
            isMobile ?
              <div className="sales-tracker-top-page-subheader" style={{ padding: isMobile && 12 }}>
                <span className={'link skeleton'}
                  style={{ width: 160, height: 20 }}
                />
              </div>
              :
              <>
                <span className={'link skeleton'}
                    style={{ width: 40, height: 40 }}
                />
                <div className="sales-tracker-top-page-subheader">
                  <span className={'link skeleton'}
                        style={{ width: 70, height: 16 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 252, height: 16, marginTop: 4 }}
                  />
                </div>
              </>
          }
        </div>
        {isMobile ? null : (
          <span className={'link skeleton'}
                style={{ width: 40, height: 10 }}
          />
        )}
      </div>
      <div className={'collections-page-options-wrapper'}>
        <div className={'search-wrapper'}>
          <span className={'link skeleton'}
                style={{ width: 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: 70, height: 40 }}
          />
        </div>
        <span className={'link skeleton'}
              style={{ width: 161, height: 40 }}
        />
      </div>
      <div className={'collections-page-list'}>
          <div className={'collections-page-list-wrapper'}>
            {
              [1,2,3,4,5,6,7,8].map((el) => (
                <div className={'collections-page-list-item'}
                     key={el}
                >
                  <div className={'collections-page-list-item-images'}>
                    {
                      ['a', 'b', 'c', 'd'].map(elem => (
                        <div className={'collections-page-list-item-image'}
                             key={el + elem}
                        >
                          <span className={'link skeleton'}
                                style={{ width: '100%', height: '100%', borderRadius: elem === 'a' ? '16px 0 0 0' : elem === 'b' ? '0 16px 0 0' : 0}}
                          />
                        </div>
                      ))
                    }
                  </div>
                  <div className="collections-page-list-item-content-wrapper">
                    <div className="collections-page-list-item-info">
                      <span className={'link skeleton'}
                            style={{ width: 20, height: 20 }}
                      />
                      <span className={'link skeleton'}
                            style={{ width: utils.randomNum(50, 140), height: 20 }}
                      />
                      <span className={'link skeleton'}
                            style={{ width: 24, height: 24 }}
                      />
                      <span className={'link skeleton'}
                            style={{ width: 32, height: 10, marginLeft: 'auto' }}
                      />
                    </div>
                    <div className={'collections-page-list-item-tabs'}>
                      {
                        ['z','x','c','v'].map((ele) =>
                          (
                            <div className={'collections-page-list-item-tab'}
                                 key={ele}
                            >
                              <span className={'link skeleton'}
                                    style={{ width: utils.randomNum(20,50), height: 20 }}
                              />
                            </div>
                          ))}
                    </div>
                    <div className="collections-page-list-item-tab-value-wrapper">
                      <div className={'collections-page-list-item-tab-value-item'}>
                        <span className={'link skeleton'}
                              style={{ width: utils.randomNum(40, 70), height: 20 }}
                        />
                        <span className={'link skeleton'}
                              style={{ width: utils.randomNum(40, 70), height: 20 }}
                        />
                      </div>
                      <div className={'collections-page-list-item-tab-value-item'}>
                        <span className={'link skeleton'}
                              style={{ width: utils.randomNum(40, 70), height: 20 }}
                        />
                        <span className={'link skeleton'}
                              style={{ width: 49, height: 20 }}
                        />
                      </div>
                      <div className={'collections-page-list-item-tab-value-item'}>
                        <span className={'link skeleton'}
                              style={{ width: 51, height: 20 }}
                        />
                        <span className={'link skeleton'}
                              style={{ width: 49, height: 20 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
      </div>
    </div>
  );
}

export default CollectionsSkeletons;
