import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Divider, Spin, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import Icon from '../../../Icon';
import ChartBlock from '../../SalesTrackerPage/components/ChartBlock';
import ButtonComponent from '../../../Components/Button';
import './AdLibraryItemAdSpendModal.less';
import Chart from '../../../Components/Charts/Chart';
import AdLibraryModalDemography from './AdLibraryModalDemography';
import DoughnutChart from './DoughnutChart';
import AdLibraryTable from './Table';

const AdLibraryItemAdSpendModal = ({
  collationId,
  advertiserId,
  getCollationInsightsCharts,
  getCollationInsightsInfo,
  getCollationInsightsBreakDown,
  isMobile,
  isAdsetsRequest,
  archivedId,
  handleOpenModalShopifyConnect,
  domainFld,
  setModal,
  setIsNotModal,
  visibleModalMobileId,
  setMobileModal,
  setVisibleModalMobileId,
}) => {
  const { t } = useTranslation();
  const [historicalDataView, setHistoricalDataView] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (value) => setVisible(value);
  const [view, setView] = useState('charts');
  const insightsInfo = useSelector((store) => store.adLibrary.insightsInfo);
  const insightsCharts = useSelector((store) => store.adLibrary.insightsCharts);
  const insightsBreakDown = useSelector(
    (store) => store.adLibrary.insightsBreakDown,
  );

  const insightsChartsLoading = useSelector(
    (store) => store.adLibrary.insightsChartsLoading,
  );
  const insightsInfoLoading = useSelector(
    (store) => store.adLibrary.insightsInfoLoading,
  );
  const insightsBreakDownLoading = useSelector(
    (store) => store.adLibrary.insightsBreakDownLoading,
  );
  const reachData = insightsCharts?.chart?.map(({ date, reach }) => ({
    date,
    value: reach,
  }));
  const spendData = insightsCharts?.chart?.map(({ date, spend }) => ({
    date,
    value: spend,
  }));
  useEffect(() => {
    setTimeout(() => {
      if (isAdsetsRequest && advertiserId && archivedId) {
        getCollationInsightsCharts({
          advertiser_id: advertiserId,
          ad_archive_id: archivedId,
          view: historicalDataView ? 'cumulative' : 'daily',
          isAdsetsRequest,
        });
      } else if (!isAdsetsRequest && advertiserId && collationId) {
        getCollationInsightsCharts({
          advertiser_id: advertiserId,
          collation_id: collationId,
          view: historicalDataView ? 'cumulative' : 'daily',
          isAdsetsRequest,
        });
      }
    }, []);
  }, [historicalDataView]);

  useEffect(() => {
    setTimeout(() => {
      if (isAdsetsRequest && advertiserId && archivedId) {
        getCollationInsightsInfo({
          advertiser_id: advertiserId,
          ad_archive_id: archivedId,
          isAdsetsRequest,
        });
      } else if (!isAdsetsRequest && advertiserId && collationId) {
        getCollationInsightsInfo({
          advertiser_id: advertiserId,
          collation_id: collationId,
          isAdsetsRequest,
        });
      }
    }, []);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isAdsetsRequest && advertiserId && archivedId) {
        getCollationInsightsBreakDown({
          advertiser_id: advertiserId,
          ad_archive_id: archivedId,
          isAdsetsRequest,
          view,
        });
      } else if (!isAdsetsRequest && advertiserId && collationId) {
        getCollationInsightsBreakDown({
          advertiser_id: advertiserId,
          collation_id: collationId,
          isAdsetsRequest,
          view,
        });
      }
    }, []);
  }, [view]);

  return (
    <div className={'load-filter-preset-wrapper store-products-modal-wrapper'}>
      {insightsInfoLoading &&
      insightsChartsLoading &&
      insightsBreakDownLoading ? (
        <div>
          <div
            className="chart-block_total-top-section"
            style={{
              display: 'flex',
              margin: '0 32px',
              padding: '16px 0 0',
              flexDirection: 'column',
              paddingTop: 24,
              gap: 4,
            }}
          >
            <span
              className={'link skeleton'}
              style={{ width: 120, height: 20 }}
            />
            <span
              className={'link skeleton'}
              style={{ width: 300, height: 20 }}
            />
          </div>
          <Divider style={{ margin: '16px 0' }} />
        </div>
      ) : (
        <div>
          <div className="load-filter-preset-title">{t('Ad insights')}</div>
          <p className="load-filter-preset-subtitle">
            {t(
              'View the ad spend, EU reach, and audience demographics for this ad.',
            )}
          </p>
          <Divider style={{ margin: 0 }} />
        </div>
      )}

      {insightsChartsLoading ? (
        <div style={{margin:'24px 0 0'}}>
          <div
            style={{
              display: 'flex',
              margin: '0 32px',
              gap: 12,
            }}
          >
            <span
              className={'link skeleton'}
              style={{ width: 140, height: 36 }}
            />
            <div
              style={{
                display: 'flex',
                gap: 12,
                marginLeft: 'auto',
              }}
            >
              <span
                className={'link skeleton'}
                style={{ width: 115, height: 36 }}
              />
              <span
                className={'link skeleton'}
                style={{ width: 115, height: 36 }}
              />
              <span
                className={'link skeleton'}
                style={{ width: 36, height: 36 }}
              />
            </div>
          </div>

          <div
            className="chart-block"
            style={{ border: 'unset', padding: '24px 0 0' }}
          >
            <div
              className="chart-block_total-count-block"
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: isMobile ? 16 : 30,
                marginTop: 24,
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <div
                className="chart-block_total-count"
                style={{ marginLeft: 0, height: 40 }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}
                >
                  <span
                    className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
                  />
                  <span
                    className={'link skeleton'}
                    style={{ width: 88, height: 40 }}
                  />
                </div>
              </div>
              <div
                className="chart-block_total-count"
                style={{ marginLeft: 0, height: 40 }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}
                >
                  <span
                    className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
                  />
                  <span
                    className={'link skeleton'}
                    style={{ width: 88, height: 40 }}
                  />
                </div>
              </div>
              <div
                className="chart-block_total-count"
                style={{ marginLeft: 'auto', height: 40 }}
              >
                <div style={{ display: 'flex' }}>
                  <span
                    className={'link skeleton'}
                    style={{ width: 144, height: 40 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <h4 className="chart-block_total-subtitle">
            <span className={'link skeleton'} />
          </h4>
          <div className="store-tracker-page-overview-blocked adLibrary">
            <span
              className={'link skeleton'}
              style={{ width: '100%', height: '236px', borderRadius: 24 }}
            />
          </div>
        </div>
      ) : (
        <div className="chart-section">
          <div className="chart-section-top">
            <div className="chart-section-left">
              <span className="chart-section-title">{t('Ad details')}</span>
            </div>
            <div className={'chart-section-right'}>
              <ButtonComponent
                className={'report-main-block-new-button'}
                text={''}
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&is_targeted_country=false&media_type=all&search_type=page&view_all_page_id=${advertiserId}`,
                    '_blank',
                  )
                }
              >
                <Icon
                  role={'icon'}
                  type={'search_facebook'}
                  width={18}
                  height={18}
                />
                {t('Ad library')}
              </ButtonComponent>
              {isMobile ? (
                <span
                  className="adLibrary-modal-item-header-action"
                  onClick={() => {
                    setMobileModal('ad_item_action');
                    setVisibleModalMobileId(collationId ? collationId : visibleModalMobileId)
                    setIsNotModal(false);
                  }}
                >
                  <Icon type={'three_dots'} role={'icon'} />
                </span>
              ) : (
                <Dropdown
                  destroyPopupOnHide={true}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onOpenChange={handleVisibleChange}
                  placement="bottomRight"
                  dropdownRender={() => (
                    <div className="adLibrary-item-header-action-dropdown">
                      {insightsInfo?.shopify_metadata.length ? (
                        <div
                          className="adLibrary-item-header-action-dropdown-option"
                          onClick={() => {
                            setVisible(false);
                            handleOpenModalShopifyConnect(
                              `${insightsInfo?.shopify_metadata[0]?.internal_shop_id}_${insightsInfo?.shopify_metadata[0]?.shopify_product_id}`,
                            );
                          }}
                        >
                          <Icon
                            type={'shopify_btn_icon'}
                            role={'icon'}
                            isLogo={true}
                          />
                          <span>{t('Import to Shopify store')}</span>
                        </div>
                      ) : null}
                      <div
                        className="adLibrary-item-header-action-dropdown-option"
                        onClick={() =>
                          window.open(
                            `https://www.similarweb.com/website/${domainFld}`,
                            '_blank',
                          )
                        }
                      >
                        <Icon
                          role={'icon'}
                          type={'similar_web'}
                          isDark={false}
                        />
                        <span>{t('View with Similarweb')}</span>
                      </div>
                    </div>
                  )}
                  trigger={['click']}
                  open={visible}
                >
                  <span className="adLibrary-modal-item-header-action">
                    <Icon type={'three_dots'} role={'icon'} />
                  </span>
                </Dropdown>
              )}
            </div>
          </div>

          <ChartBlock
            type={'adSpendModal'}
            totalCount={insightsCharts?.spend_total}
            rawTotalCount={insightsCharts?.spend_total}
            link={false}
            historicalDataViewToggleText={[t('Daily'), t('Cumulative')]}
            historicalDataView={historicalDataView}
            setHistoricalDataView={setHistoricalDataView}
            returnsCount={insightsCharts?.reach_total}
            rawReturnsCount={insightsCharts?.reach_total}
            withReturns={true}
          >
            <Spin spinning={insightsChartsLoading}>
              <Chart
                data={spendData ? [...spendData] : [] || []}
                height={264}
                type={'Ad spends'}
                animation={false}
                isMobile={isMobile}
                withReturns={true}
                returnsData={reachData?.length ? [...reachData] : []}
                rawReturnsData={[]}
                rawData={[]}
                chartType="line"
              />
            </Spin>
          </ChartBlock>
          <Divider style={{ margin: 0 }} />
        </div>
      )}

      <div className="chart-section">
        <div className="chart-section-top">
          <div className="chart-section-left">
            <span className={`chart-section-title ${insightsInfoLoading && "skeleton"}`}>{t('Demography')}</span>
          </div>
        </div>
        <AdLibraryModalDemography
          insightsInfo={insightsInfo}
          isMobile={isMobile}
          insightsInfoLoading={insightsInfoLoading}
        />
      </div>

      <Divider style={{ margin: '24px 0' }} />
      <div className="chart-section">
        <div className="chart-section-down">
          <div className="chart-section-left">
          <span className={`chart-section-title ${insightsBreakDownLoading && "skeleton"}`}>
              {t('Audience demographics distribution')}
            </span>
          </div>
          <div className={'chart-section-right'}>
            <div
              className="chart-view"
              onClick={() => setView(view === 'charts' ? 'table' : 'charts')}
            >
              <div
                className={cls('chart-view-btn', {
                  'chart-view-btn--active': view === 'charts',
                  "skeleton": insightsBreakDownLoading
                })}
              >
                <Icon role="icon" type="pie_chart" className="first-svg" />
              </div>
              <div
                className={cls('chart-view-btn', {
                  'chart-view-btn--active': view === 'table',
                  "skeleton": insightsBreakDownLoading
                })}
              >
                <Icon role="icon" type="table_icon" className="second-svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {view === 'charts' ? (
        <div className="break-down-section">
          <DoughnutChart
            data={insightsBreakDown?.country || []}
            chartName="Country"
            loading={insightsBreakDownLoading}
          />
          <DoughnutChart
            data={insightsBreakDown?.age_range || []}
            chartName="Age"
            loading={insightsBreakDownLoading}
          />
        </div>
      ) : (
        <AdLibraryTable
          data={insightsBreakDown?.length ? insightsBreakDown : [] }
          insightsBreakDownLoading={insightsBreakDownLoading}
        />
      )}
    </div>
  );
};

export default AdLibraryItemAdSpendModal;
