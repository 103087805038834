import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import cls from 'classname';
import NavHeader from '../components/NavHeader';
import { connect } from 'react-redux';
// import Creators from '../reducer';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import AdLibraryItemOverview from "./AdLibraryItemOverview";
import AdLibraryCreators from './../reducer';
import './AdLibraryItemPage.less';
import AdLibraryNavigation from '../components/AdLibraryNavigation';
import MainBlockAdset from '../components/MainBlockAdset';
import AdLibraryItemAds from './AdLibraryItemAds'

const AdLibraryItemPage = ({
  isMobile,
  loading,
  selectedAdv,
  getAds,
  userInfo,
  getOverviewMainInfo,
  overviewInfo,
  creditsLeft,
  presets,
  sortOrder,
  setSortOrder,
  getAdLibraryPresets,
}) => {
  const mainInfo = {
    ads_count: 77,
    advertiser: {
      icon: 'https://adsavermedia.s3.amazonaws.com/bfe9f3f1e84c0cd177ae9e5f3b14f91e.jpg',
      id: '100064047097381',
      is_verified: true,
      name: 'Ninja Kitchen Australia',
      total_ads: 78,
      url: 'https://www.facebook.com/100064047097381/',
    },
    created: '2025-01-28 07:11:36',
    domain: 'ninjakitchen.com.au',
    last_updated: '2025-01-29 09:49:52',
    mainInfoLoading: false,
    post_url:
      'https://www.facebook.com/100064047097381/posts/620964910445407',
    provider: 'shopify',
  };
  const { id, mode, search } = useParams();
  let params = new URLSearchParams(search);
  const isYearly =
    userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Ad Library - Dropship`;
    getAdLibraryPresets();
  }, []);

  const innerContent = {
    overview: <AdLibraryItemOverview mainInfo={mainInfo}/>,
    all_ads: <AdLibraryItemAds mainInfo={mainInfo}/>,
  };

  // const innerContent = {
  //   'all_ads': <AdItemAllAdsPage id={id}
  //                                isMobile={isMobile}
  //                                loading={loading}
  //                                selectedAdv={selectedAdv}
  //                                userInfo={userInfo}
  //                                getAds={getAds}
  //                                getOverviewMainInfo={getOverviewMainInfo}
  //                                mainInfo={overviewInfo?.mainInfo}
  //                                creditsLeft={creditsLeft}
  //                                setSortOrder={setSortOrder}
  //                                sortOrder={sortOrder}
  //   />,
  //   'overview': <AdItemOverview />,
  //   'products': <AdItemProducts />,
  //   'detailed': <AdItemProducts sortOrder={sortOrder} setSortOrder={setSortOrder}/>
  // }

  return (
    <OverlayScrollbar visibility="hidden">
      <AdLibraryNavigation
        mainInfo={mainInfo}
        isMobile={isMobile}
        mode={mode}
      />
      <MainBlockAdset
        data={mainInfo}
        isMobile={isMobile}
        isShopify={params.get('internal_shop_id')}
        skeleton={overviewInfo?.mainInfo?.mainInfoLoading}
      />
      <NavHeader mainInfo={mainInfo} navigate={navigate} isMobile={isMobile} />
      {innerContent[mode]}
    </OverlayScrollbar>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state?.nav?.isMobile,
  presets: state.adLibrary.presets,
  //   loading: state.adSpot.selectedAdvLoading,
  //   selectedAdv: state.adSpot.selectedAdv,
  //   view: state.adSpot.view,
  userInfo: state.auth.userInfo,
  //   overviewInfo: state?.adSpot?.innerPagesInfo?.overview,
  // creditsLeft: state.adLibrary.creditsLeft,
  //   sortOrder: state.adSpot.selectedSort,
});

const mapDispatchToProps = (dispatch) => ({
  getAdLibraryPresets: () =>
    dispatch(AdLibraryCreators.getAdLibraryPresetsRequest()),
  //   getAds: (data) => dispatch(Creators.getAdsByIDRequest(data)),
  //   getOverviewMainInfo: (data) => dispatch(Creators.getOverviewMainInfoRequest(data)),
  //   setSortOrder: (data) => dispatch(Creators.setSelectedSort(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdLibraryItemPage);
