import React from 'react';
import { Col, Divider, Row } from 'antd';
import Panel from '../../../Components/Tabs/panel';
import utils from '../../../Utils/utils';

const PortfolioSkeletons = ({isMobile}) => {

  return (
    <div className="product-database-page portfolio-page">
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          {
            isMobile ?
              <div className="sales-tracker-top-page-subheader" style={{ padding: 12 }}>
                <span className={'link skeleton'}
                  style={{ width: 160, height: 20 }}
                />
                <span className={'link skeleton'}
                  style={{ width: 150, height: 36 }}
                />
              </div>
              :
              <>
                <span className={'link skeleton'}
                      style={{ width: 40, height: 40 }}
                />
                <div className="sales-tracker-top-page-subheader">
                  <span className={'link skeleton'}
                        style={{ width: 70, height: 16 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 252, height: 16, marginTop: 4 }}
                  />
                </div>
              </>
          }
        </div>
        {isMobile ? null : (
          <span className={'link skeleton'}
                style={{ width: 40, height: 10 }}
          />
        )}
      </div>
      <div className="portfolio_wrap tabs_wrapper">
        <Panel>
          <Row gutter={{ xs: 8, sm: 0 }}>
            <Col span={24}
                 className="drops"
            >
              <div className="drops_timer">
                <div className="drop">
                  <div className="drop_info">
                    <div className="drop_info-week">
                      <span className={'link skeleton'}
                            style={{ width: 63, height: 20 }}
                      />
                      <span className="drop_info-circle" />
                      <span className={'link skeleton'}
                            style={{ width: 72, height: 20 }}
                      />
                    </div>
                    <span className={'link skeleton'}
                          style={{ width: 172, height: 20 }}
                    />
                  </div>
                  {
                    isMobile ?
                      null
                      :
                      <Divider type={'vertical'}
                               style={{ margin: '8px auto', height: 64 }}
                      />
                  }
                  <div className="drop_timer">
                    <div className="timer">
                      <div className="timer_days"
                           style={{
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                           }}
                      >
                        <div className="timer_num">
                          <span className={'link skeleton'}
                                style={{ width: 37, height: 32, display: 'block' }}
                          />
                        </div>
                        <div className="timer_text">
                          <span className={'link skeleton'}
                                style={{ width: 33, height: 20, display: 'block' }}
                          />
                        </div>
                      </div>
                      <span className="timer_days-separator">
                        :
                      </span>
                      <div className="timer_days"
                           style={{
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                           }}
                      >
                        <div className="timer_num">
                          <span className={'link skeleton'}
                                style={{ width: 37, height: 32, display: 'block' }}
                          />
                        </div>
                        <div className="timer_text">
                          <span className={'link skeleton'}
                                style={{ width: 38, height: 20, display: 'block' }}
                          />
                        </div>
                      </div>
                      <span className="timer_days-separator">
                        :
                      </span>
                      <div className="timer_days"
                           style={{
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                           }}
                      >
                        <div className="timer_num">
                          <span className={'link skeleton'}
                                style={{ width: 37, height: 32, display: 'block' }}
                          />
                        </div>
                        <div className="timer_text">
                          <span className={'link skeleton'}
                                style={{ width: 44, height: 20, display: 'block' }}
                          />
                        </div>
                      </div>
                      <span className="timer_days-separator">
                        :
                      </span>
                      <div className="timer_days"
                           style={{
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                           }}
                      >
                        <div className="timer_num">
                          <span className={'link skeleton'}
                                style={{ width: 37, height: 32, display: 'block' }}
                          />
                        </div>
                        <div className="timer_text">
                          <span className={'link skeleton'}
                                style={{ width: 47, height: 20, display: 'block' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    isMobile ?
                      null
                      :
                      <Divider type={'vertical'}
                               style={{ margin: '8px auto', height: 64 }}
                      />
                  }
                  <div className="drop_data">
                    <div className="drop_row">
                      <span className="left strong">
                        <span className={'link skeleton'}
                              style={{ width: 62, height: 20, display: 'block' }}
                        />
                      </span>
                      <span className="drop_link right">
                        <span className={'link skeleton'}
                              style={{ width: 42, height: 20, display: 'block' }}
                        />
                      </span>
                    </div>
                    <div className="drop_row">
                      <span className="left">
                        <span className={'link skeleton'}
                              style={{ width: 93, height: 20, display: 'block' }}
                        />
                      </span>
                      <span className="right strong">
                        <span className={'link skeleton'}
                              style={{ width: 40, height: 20, display: 'block' }}
                        />
                      </span>
                    </div>
                    <div className="drop_row">
                      <span className="left">
                        <span className={'link skeleton'}
                              style={{ width: 73, height: 20, display: 'block' }}
                        />
                      </span>
                      <span className="right strong">
                        <span className={'link skeleton'}
                              style={{ width: 28, height: 20, display: 'block' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Panel>
      </div>
      <div className="drops_wrapper">
        <div className={'drops_wrapper_block'}>
          <h3>
            <span className={'link skeleton'}
                  style={{ width: 84, height: 20, display: 'block' }}
            />
          </h3>
          <div className={'calendar-wrapper'}>
            {[0].map((value) => (
              <div className="calendar" key={value}>
                <div className="calendar_header">
                  <div className="calendar_date">
                    <div className="calendar_month">
                      <span className={'link skeleton'}
                            style={{ width: 34, height: 20, display: 'block' }}
                      />
                    </div>
                    <div className="calendar_year">
                      <span className={'link skeleton'}
                            style={{ width: 42, height: 20, display: 'block' }}
                      />
                    </div>
                  </div>
                  <span className={'link skeleton'}
                        style={{ width: 92, height: 20, display: 'block' }}
                  />
                </div>
                <div className="calendar_wrapper">
                  {
                    [1, 2, 3, 4].map((item) => (
                      <div className="calendar_item-wrapper"
                           key={`week-key-${item}`}
                      >
                        <div className={'week calendar_item'}>
                          <div className="week_glob_wrap">
                            <div className="week_wrap">
                              <div className="week_num"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: utils.randomNum(14, 30), height: 32, display: 'block' }}
                                />
                              </div>
                              <div className="week_text"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: utils.randomNum(40, 60), height: 20, display: 'block' }}
                                />
                              </div>
                            </div>
                            <div className="week_wrap">
                              <div className="week_day"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: 62, height: 20, display: 'block' }}
                                />
                              </div>
                            </div>
                            <div className="week_id"
                                 style={{
                                   display: 'flex',
                                   justifyContent: 'center'
                                 }}
                            >
                              <span className={'link skeleton'}
                                    style={{ width: utils.randomNum(35, 60), height: 16, display: 'block' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
          </div>
          <h3>
            <span className={'link skeleton'}
                  style={{ width: 123, height: 20, display: 'block' }}
            />
          </h3>
          <div className={'calendar-wrapper'}>
            {[0,1,2,3].map((value) => (
              <div className="calendar" key={(value+1)*3}>
                <div className="calendar_header">
                  <div className="calendar_date">
                    <div className="calendar_month">
                      <span className={'link skeleton'}
                            style={{ width: 34, height: 20, display: 'block' }}
                      />
                    </div>
                    <div className="calendar_year">
                      <span className={'link skeleton'}
                            style={{ width: 42, height: 20, display: 'block' }}
                      />
                    </div>
                  </div>
                  <span className={'link skeleton'}
                        style={{ width: 92, height: 20, display: 'block' }}
                  />
                </div>
                <div className="calendar_wrapper">
                  {
                    (value % 2 === 0 ? [1, 2, 3, 4] : [1, 2, 3, 4, 5]).map((item) => (
                      <div className="calendar_item-wrapper"
                           key={`week-key-${item*6}`}
                      >
                        <div className={'week calendar_item'}>
                          <div className="week_glob_wrap">
                            <div className="week_wrap">
                              <div className="week_num"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: 32, height: 32, display: 'block' }}
                                />
                              </div>
                              <div className="week_text"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: 62, height: 20, display: 'block' }}
                                />
                              </div>
                            </div>
                            <div className="week_wrap">
                              <div className="week_day"
                                   style={{
                                     display: 'flex',
                                     justifyContent: 'center'
                                   }}
                              >
                                <span className={'link skeleton'}
                                      style={{ width: 62, height: 20, display: 'block' }}
                                />
                              </div>
                            </div>
                            <div className="week_id"
                                 style={{
                                   display: 'flex',
                                   justifyContent: 'center'
                                 }}
                            >
                              <span className={'link skeleton'}
                                    style={{ width: 62, height: 16, display: 'block' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSkeletons;
