import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useParams} from 'react-router';
import {connect, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Row, Col, Spin, Modal} from 'antd';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import AdminMainBlock from './components/AdminMainBlock';
import Chart from '../../Components/Charts/Chart';
import {BreadcrumbsComponent} from '../Breadcrumbs';
import {OverlayScrollbar} from '../../Components/ScrollBar';
import AdminTrackerTable from './components/AdminTrackerTable';
import NumbersBreakdownDatabaseBlock from "../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock";
import {dateOptions} from "../../Utils/utils";
import ChartBlock from "../SalesTrackerPage/components/ChartBlock";
import Icon from "../../Icon";
import './styles.less';

export const AdminStoreTrackerPage = (
  {
    fetching,
    error,
    userInfo,
    salesTracker,
    breadcrumbs,
    dispatch,
    getDetailedStoreInfo,
    getProductListByStore,
    selectedStoreTableDataLoading,
    getExpandableChart,
    downloadSalesReport,
    downloadRevenueReport,
    filterDates,
    setFilters,
    setPeriod,
    ...props
  }) => {
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const {state} = location;

  //3 charts info + selected store main info
  const detailedInfo = useSelector(store => store?.adminStoreTable?.selectedStore) || [];

  const stores = useSelector(store => store?.adminStoreTable?.stores?.results) || [];

  const detailedInfoNew = stores?.filter(el => +el.id === +id)?.[0] || {};
  //3 charts page table data
  const storeProducts = useSelector(store => store?.adminStoreTable?.selectedStoreTableData) || [];

  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const timeZone = detailedInfoNew?.store_timezone ? detailedInfoNew?.store_timezone.split('UTC')[1] : null;

  //table pagination options
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  // modal show/hide
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState('');
  // numbers breakdown row to calc
  const [recordToCalc, setRecordToCalc] = useState(null);

  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);
  const [isLineChartSales, setIsLineChartSales] = useState(true);
  const [isLineChartUniqueProductsSold, setIsLineChartUniqueProductsSold] = useState(true);

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    if(!stores.length) navigate('/admin/store-table', {replace: true});
    if (firsLoading) {
      setFirsLoading(false)
      dispatch(Creators.resetIsAvailable());
    }
    if (
      detailedInfoNew?.first_sale_date
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 30
      && dayjs(minDate) < dayjs(detailedInfoNew?.first_sale_date)
    ) {
      const newData = dateOptions.filter(el => dayjs().utcOffset(timeZone ? timeZone : '+0:00').diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= el.days);
      let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1]
      let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2]
      let period = newData?.[newData?.length - 1]?.id
      let min = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs(detailedInfoNew?.first_sale_date).format('YYYY-MM-DD')
        :
        dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
      let max = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs().format('YYYY-MM-DD')
          :
        [1,2].includes(period) ? min : dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min, max}});
      setPeriod(dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        [...newData]
          :
        [newData[newData?.length - 1]]);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
      getDetailedStoreInfo({id: id, date_range: {min: minDate, max: maxDate}});
      getProductListByStore({page: pageNumber, store_id: id, page_size: pageSize, ordering: sortOrder, date_range: {min: minDate, max: maxDate}});
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [minDate, maxDate])

  //breadcrumbs display management
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      props.removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      //props.removeBreadCrumb();
      props.addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else if (breadCrumbs?.length < 2) {
      props.addBreadCrumb({
        name: state?.name || id,
        link: state?.link || `/admin/store-table/store/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state]);

  useEffect(() => {
    getProductListByStore({page: pageNumber, store_id: id, page_size: pageSize, ordering: sortOrder, date_range: {min: minDate, max: maxDate}});
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder]);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);


  const modalBlocks = {
    breakdown: <NumbersBreakdownDatabaseBlock product={recordToCalc} />,
  }

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const modalBlocksWidth = {
    breakdown: 1040,
  }
  const handleSort = useCallback(({order, columnKey}) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  return (
    //custom scrollbars
    <Spin spinning={fetching}>
      <OverlayScrollbar visibility="hidden">
        <div className="product-database-page sales-tracker-page admin-store-tracket-page">
          <div className="admin-store-tracket-page-header">
            <BreadcrumbsComponent
              breadCrumbs={breadCrumbs}
              isMobile={false}
              isAdmin={false}
              timeInfo={detailedInfoNew?.last_updated}
            />
          </div>

          <AdminMainBlock
            data={{
              title: detailedInfoNew?.title,
              link: detailedInfoNew?.custom_domain,
              logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
              report: 'sales',
              id: id,
              reportName: 'Store Report',
              dates: {min: minDate, max: maxDate},
              // timeInfo - 1st sale date
              timeInfo: detailedInfoNew?.first_sale_date,
              timeZone: timeZone,
            }}
            downloadSalesReport={downloadSalesReport}
            downloadRevenueReport={downloadRevenueReport}
            dateOptions={dateOptions}
          />

          <Row gutter={[16, 16]} style={{ padding: "0 24px", margin: 0 }}>
            <Col span={24}>
              <ChartBlock type={'revenue'}
                          subTitle={minDate && maxDate ?
                            `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                            :
                            `${dayjs(detailedInfo?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(detailedInfo?.charts?.revenue[detailedInfo?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`}
                          totalCount={detailedInfo?.total ? detailedInfo?.total?.revenue : 0}
                          link={`/admin/store-table/store/${id}/revenue`}
                          isLineChart={isLineChartRevenue}
                          setIsLineChart={setIsLineChartRevenue}
              >
                <Chart data={detailedInfo?.charts ? [...detailedInfo?.charts?.revenue?.chart] : [] || []}
                       type={'revenue'}
                       animation={false}
                       chartType={!isLineChartRevenue && 'bar'}
                />
              </ChartBlock>
            </Col>

            <Col md={24} xl={12}>
              <ChartBlock type={'sales'}
                          totalCount={detailedInfo?.total ? detailedInfo?.total?.sales : 0}
                          link={`/admin/store-table/store/${id}/sales`}
                          isLineChart={isLineChartSales}
                          setIsLineChart={setIsLineChartSales}
              >
                <Chart height={'123px'}
                       data={detailedInfo?.charts ? [...detailedInfo?.charts?.sales?.chart] : [] || []}
                       type={'sales'}
                       animation={false}
                       chartType={!isLineChartSales && 'bar'}
                />
              </ChartBlock>
            </Col>

            <Col md={24} xl={12}>
              <ChartBlock type={'unique_products_sold'}
                          totalCount={detailedInfo?.total ? detailedInfo?.total?.unique : 0}
                          link={`/admin/store-table/store/${id}/unique_products_sold`}
                          isLineChart={isLineChartUniqueProductsSold}
                          setIsLineChart={setIsLineChartUniqueProductsSold}
              >
                <Chart height={'123px'}
                       data={detailedInfo?.charts ? [...detailedInfo?.charts?.unique?.chart] : [] || []}
                       type={'unique_products_sold'}
                       animation={false}
                       chartType={!isLineChartUniqueProductsSold && 'bar'}
                />
              </ChartBlock>
            </Col>
          </Row>

          <AdminTrackerTable fetching={fetching}
                             minDate={minDate}
                             maxDate={maxDate}
                             loading={selectedStoreTableDataLoading}
                             onSort={handleSort}
                             pageSize={pageSize}
                             setPageSize={handleChangePageSize}
                             pageNumber={pageNumber}
                             setPageNumber={handleChangePageNumber}
                             totalProducts={storeProducts?.count}
                             data={storeProducts?.results || []}
                             sortOrder={sortOrder}
                             report={'sales'}
                             setVisible={setVisible}
                             setModal={setModal}
                             setRecordToCalc={setRecordToCalc}
                             getExpandableChart={getExpandableChart}
          />
          <Modal
            className="change-modal custom-modal"
            getContainer={() => document.getElementById('global-wrap')}
            open={visible}
            centered={true}
            closeIcon={
              <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
            }
            width={modalBlocksWidth[modal]}
            footer={null}
            closable="true"
            onCancel={() => {
              setVisible(false);
            }}
            destroyOnClose
          >
            <Spin size="large" spinning={fetching}>
              {modalBlocks[modal]}
            </Spin>
          </Modal>
        </div>
      </OverlayScrollbar>
    </Spin>
  );
};

AdminStoreTrackerPage.propTypes = {
  isAdmin: PropTypes.bool,
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
  breadcrumbs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  fetching: state.adminStoreTable.loading,
  salesTracker: state.adminStoreTable,
  selectedStoreTableDataLoading: state.adminStoreTable.selectedStoreTableDataLoading,
  error: state.adminStoreTable.error,
  view: state.adminStoreTable.view,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  filterDates: state.adminStoreTable.filters.report_period,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getDetailedStoreInfo: (data) => dispatch(Creators.getAdminDetailedStoreInfoRequest(data)),
  getProductListByStore: (data) => dispatch(Creators.getAdminProductListByStoreRequest(data)),
  getExpandableChart: (data) => dispatch(Creators.getAdminExpandableChartRequest(data)),
  downloadSalesReport: (data) => dispatch(Creators.getAdminSalesReportRequest(data)),
  downloadRevenueReport: (data) => dispatch(Creators.getAdminRevenueReportRequest(data)),
  setFilters: (data) => dispatch(Creators.setAdminFilters(data)),
  setPeriod: (data) => dispatch(Creators.setAdminCheckedList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoreTrackerPage);
