import React from 'react';
import { Input } from 'antd';
import './modal.less';

const { TextArea } = Input;

export default function addProductsModalContent({ value, onChange }) {
  return (
    <>
      <p key="text" style={{ margin: '0 0 16px 0' }}>
        Paste one or more product links seperated by a line
      </p>

      <TextArea
        className='method-add-product-card_textarea'
        placeholder="<product_link>,<supplier_2_link>,<supplier_3_link>
https://www.aliexpress.com/item/1005007619684794.html,https://www.aliexpress.com/item/1005007952036070.html,https://www.aliexpress.com/item/1005007805592267.html"
        wrap="off"
        onChange={(e) => {
          e.persist();
          onChange(e);
        }}
        autoSize={{ minRows: 3, maxRows: 3 }}
      />
    </>
  );
}
