import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import ButtonComponent from '../../../Components/Button';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import Icon from '../../../Icon';
import './MainBlock.less';

const dateOptions = [
  {id: 3, name: 'Last 7 days', value: 'Last 7 days', days: 7},
  {id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30},
  {id: 5, name: 'Last 90 days', value: 'Last 90 days', days: 90},
  {id: 6, name: 'Last 6 months', value: 'Last 6 months', days: 180},
  {id: 7, name: 'Last 12 months', value: 'Last 12 months', days: 365}
];

 const MainBlock = (
  {
    data,
    downloadReport,
    showRawData = false,
    setShowRawData = null,
    rawDataAccess = false,
    isMobile,
    disabledCalendar = false,
    disabledCalendarTooltip = ''
  }) => {

  const { t } = useTranslation();

  const dropdownFilters = {
    report_period: {
      min: data?.dates?.min,
      max: data?.dates?.max,
    },
  };
  const [visibleModal, setVisibleModal] = useState(false);

  const isProductPage = data.reportName?.includes('Product');

  const fileLoading = useSelector(state => state?.salesTracker?.[isProductPage ? 'productInnerPage' : 'storeInnerPage']?.downloadReportLoading);

  const setDropdownFilters = (prop) => {
    let result = prop();
    data?.setDateParams(result);
  };

  const handleDownload = () => {
    downloadReport({
      internal_shop_id: isProductPage ? data?.id?.split('_')[0] : data?.id,
      product_id: isProductPage ? data?.id?.split('_')[1] : null,
      date_gte: data.dates?.min,
      date_lte: data.dates?.max,
      name: `${data.title} - ${data.reportName}`,
      raw_data: rawDataAccess && showRawData,
    })
  };

  return (
    <div className="report-main-block">
      <span className={'report-main-block-title'}>
        <span className='title'>
          {t('Overview')}
        </span>
        <span className='key'>
          {t('Store timezone')}:
          <span className="value">
            UTC {data?.timeZone ? data?.timeZone : '+0:00'}
          </span>
        </span>
      </span>

      {
        rawDataAccess ?
          <div className="report-main-block-switch">
            <Switch checked={showRawData}
                    onChange={(state) => setShowRawData(state)}
            />
            <span className="report-main-block-switch-text"
              onClick={() => setShowRawData((prev) => !prev)}
            >
              {t('Raw data')}
            </span>
          </div>
          :
          null
      }

      <div className="report-main-block-actions">
        <ButtonComponent className={'report-main-block-btn'}
                         onClick={handleDownload}
                         disabled={fileLoading}
        >
          <Icon type="download" />
          {t('Download Report')}
        </ButtonComponent>

        <DropdownDateBlock limit={data?.timeInfo}
                           timeZone={data?.timeZone}
                           label={null}
                           customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                           customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                           id={'report_period'}
                           iconType={'database_calendar'}
                           data={dateOptions}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           withName
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           position={'bottomRight'}
                           isMobile={isMobile}
                           disabledCalendar={disabledCalendar}
                           disabledCalendarTooltip={disabledCalendarTooltip}
        />
      </div>
    </div>
  );
};

export default MainBlock;
