import React, { useState, useCallback, useEffect } from 'react';
import { Dropdown, Spin, Tooltip } from "antd";
import cls from "classname";
import { useTranslation } from "react-i18next";
import ArrowSelectDown from "../../../../Icon/img/ArrowSelectDown";
import Icon from "../../../../Icon";
import InputComponent from "../../../../Components/Input";
import ButtonComponent from "../../../../Components/Button";
import acc from "accounting";
import useDebounce from "../../../../hooks/use-debounce";
import './DropdownBarFilter.less';
import Modal from '../../../../Components/Modal';

const DropdownBarContent = ({
  countLoading,
  title,
  tooltip,
  localValue,
  value,
  setValue,
  inputChangeHandler,
  suffix,
  disabled,
  count,
  t,
  isModal = false,
  isStandard = false,
  setVisible,
  min,
  max,
  id,
  setLocalValue,
  isAdSpot = false,
}) => {
  return (
    <div className={cls('main-filter-dropdown-content', {
      'main-filter-modal': isModal
    })}>
      <Spin spinning={countLoading}>
        <div className='main-filter-dropdown-content-top-section'>
        <div className="main-filter-dropdown-content-title">
          {t(`${title}`)}
          {
            tooltip ?
              <Icon
                type={'attention_outline'}
                role="button"
                width={16}
                height={16}
                tooltipProps={{
                  trigger: 'hover',
                  placement: 'bottom',
                  overlayClassName: 'subscription-tooltip dropdown-bar-tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(tooltip)}
              />
              :
              null
          }
        </div>
        </div>
        <div className="min-max-input-wrapper">
          <div className="min-max-input-item-wrapper">
            <div className='min-max-input-item-title'>{t('From')}</div>
            <InputComponent
              placeholder={t(title)}
              name={title + '_min'}
              value={acc.formatNumber(localValue?.min, '0', ',', '.')}
              onChange={e => inputChangeHandler(e, 'min')}
              suffix={suffix}
            />
          </div>
          <div className="min-max-input-item-wrapper">
          <div className='min-max-input-item-title'>{t('To')}</div>
          <InputComponent
            placeholder={t(title)}
            name={title + '_max'}
            value={acc.formatNumber(localValue?.max, '0', ',', '.')}
            onChange={e => inputChangeHandler(e, 'max')}
            suffix={suffix}
          />
          </div>
        </div>
        <div className="main-filter-dropdown-content-footer">
          <div className="main-filter-dropdown-content-button-wrapper">
            <ButtonComponent text={t('Clear all')}
              className={'button-cancel'}
              onClick={() => {
                setLocalValue({ min: min, max: max})
                }}
            />

            {
              disabled ?
                <Tooltip placement='top'
                  title={
                    isStandard ? 'To preform this action, you would need to upgrade to Premium plan.'
                    : isAdSpot ? 'To perform this action, you need to skip the trial and activate your plan'
                    : 'To perform this action, you would need to upgrade to Standard or Premium plan.'
                  }
                  overlayClassName={'subscription-tooltip scheduled skip'}
                  arrow={true}
                  trigger={'hover'}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  destroyTooltipOnHide={true}
                >
                  <ButtonComponent text={t(`Show _name_ results`, {name: acc.formatNumber(count, 0, ',', '.')})}
                    className={cls('button-apply', {
                      'button-apply-disabled': disabled || localValue.min > localValue.max
                    })}
                    onClick={() => null}
                  />
                </Tooltip>
                :
                <ButtonComponent text={t(`Show _name_ results`, {name: acc.formatNumber(count, 0, ',', '.')})}
                  className={cls('button-apply', {
                    'button-apply-disabled': disabled
                  })}
                  onClick={() => {
                    setVisible(false);
                    if (!(min === localValue?.min && max === localValue?.max && (Boolean(value) && value?.[id]?.min === min && (value?.[id]?.max === max || value?.[id]?.max === null)))) {
                      setValue(prev => ({
                        ...prev,
                        [id]: {
                          min: localValue?.min,
                          max: localValue?.max === max ? null : localValue?.max
                        }
                      }))
                    }
                  }}
                  disabled={false}
                />
            }
          </div>
        </div>
      </Spin>
    </div>
  );
};

const DropdownBarFilter = (
  {
    title,
    tooltip = null,
    position,
    id,
    isStore = false,
    min,
    max,
    value,
    setValue,
    disabled,
    countLoading,
    count = 0,
    handleCount,
    isStandard = false,
    isMobile,
    suffix = "$ (USD)",
    isAdSpot = false,
  }
) => {

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [localValue, setLocalValue] = useState({});

  const debouncedValue = useDebounce(localValue, 700);

  const inputChangeHandler = useCallback((e, field) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setLocalValue(state => ({ ...state, [field]: e.target.value.trim().length ? +parsedValue : null }));
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const handleVisibleChange = (value) => {
    if (!value) setLocalValue({ min: value?.[id]?.min, max: value?.[id]?.max });
    setVisible(value);
  }

  useEffect(() => {
    setLocalValue({
      min: value?.[id]?.min ? value?.[id]?.min : min,
      max: value?.[id]?.max ? value?.[id]?.max : max,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (debouncedValue?.min > debouncedValue?.max) {
        setLocalValue(state => ({ ...state, max: state.min }));
      } else {
        handleCount({
          id: id === 'products_count' ? 'products' : id,
          value: { min: debouncedValue?.min, max: debouncedValue?.max },
        });
      }
    }
  }, [debouncedValue, visible]);
  return (
    <div className={cls('main-filter-dropdown', id, {
      'product': !isStore
    })}>
      {isMobile ? (
        <>
          <div className='main-filter-dropdown-inner' onClick={()=> {
            setVisible(true)
          }}>
            {title}
            <ArrowSelectDown />
          </div>
          <Modal
            onCancel={() => setVisible(false)}
            title={null}
            width={300}
            className="change-modal custom-modal adspot-sort-order"
            getContainer={() => document.getElementById('global-wrap')}
            open={visible}
            isMobile={isMobile}
            destroyOnClose
            footer={null}
            closeIcon={
              <Icon
                role="icon"
                type="close_modal"
                color="#707BA0"
                opacity={1}
              />
            }
          >
            <DropdownBarContent
              countLoading={countLoading}
              title={title}
              tooltip={tooltip}
              localValue={localValue}
              value={value}
              inputChangeHandler={inputChangeHandler}
              suffix={suffix}
              disabled={disabled}
              count={count}
              t={t}
              isModal={true}
              isStandard={isStandard}
              setValue={setValue}
              setVisible={setVisible}
              min={min}
              max={max}
              id={id}
              setLocalValue={setLocalValue}
              isAdSpot={isAdSpot}
            />
          </Modal>
        </>
      ) : (
      <Dropdown
        overlayClassName={'main-filter-dropdown-content-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={position}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
            <DropdownBarContent
            countLoading={countLoading}
            title={title === t('Price') ? t('Price range') : title}
            tooltip={tooltip}
            localValue={localValue}
            value={value}
            inputChangeHandler={inputChangeHandler}
            suffix={suffix}
            disabled={disabled}
            count={count}
            t={t}
            isStandard={isStandard}
            setValue={setValue}
            setVisible={setVisible}
            min={min}
            max={max}
            id={id}
            setLocalValue={setLocalValue}
            isAdSpot={isAdSpot}
          />
        )}
      >
        <div className='main-filter-dropdown-inner'>
          {title}
          <ArrowSelectDown />
        </div>
      </Dropdown>
      )}
    </div>
  );
};

export default DropdownBarFilter;
