import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import StoreProductsModalTable from "./StoreProductsModalTable";
import './StoreProductsModal.less';

const StoreProductsModal = (
  {
    getStoreProductsModal,
    storeProductsModalTableDataLoading,
    storeProductsModalTableData,
    minDate,
    maxDate,
    storeId,
    resetStoreProductsModal,
    isMobile=false,
    isSt = true
  }) => {

  const {t} = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    resetStoreProductsModal();
    setTimeout(() => setFirstLoading(false), 3000)
    return () => resetStoreProductsModal();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (isSt) {
      getStoreProductsModal(
        {
          internal_shop_id: storeId,
          page: 1,
          page_size: 50,
          created_at__gte: minDate,
          created_at__lte: maxDate,
          ordering: sortOrder
        }
      );
    } else {
      getStoreProductsModal(
        {
          page: 1,
          store_id: storeId,
          page_size: 50,
          ordering: sortOrder,
          date_range: {min: minDate, max: maxDate
          }}
      );
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [sortOrder]);

  useEffect(() => {
    if(!firstLoading && !storeProductsModalTableDataLoading) {
      if (isSt) {
        getStoreProductsModal(
          {
            internal_shop_id: storeId,
            page: pageNumber,
            page_size: 50,
            created_at__gte: minDate,
            created_at__lte: maxDate,
            ordering: sortOrder
          }
        );
      } else {
        getStoreProductsModal(
          {
            page: pageNumber,
            store_id: storeId,
            page_size: 50,
            ordering: sortOrder,
            date_range: {min: minDate, max: maxDate }
          }
        );
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber]);

  const handleSort = useCallback(({order, columnKey}) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  return (
    <div className={'load-filter-preset-wrapper store-products-modal-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Store products')}
      </div>
      <p className="load-filter-preset-subtitle">
          {t('Here you can see a list of all products that are available in the store for this product.')}
      </p>
      <StoreProductsModalTable fetching={storeProductsModalTableDataLoading}
                               data={storeProductsModalTableData}
                               sortOrder={sortOrder}
                               handleSort={handleSort}
                               handleChangePageNumber={setPageNumber}
                               isMobile={isMobile}
      />
    </div>
  );
};

export default StoreProductsModal;
