import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import utils from '../../../Utils/utils';
import cls from 'classname';
import Icon from '../../../Icon';
import './PopularToday.less';

const PopularToday = (
  {
    view,
    skeleton,
    data,
  },
) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [scrollProps, setScrollProps] = useState({
    current: 0,
    max: 0,
  });

  function handleClick(type) {
    if (!containerRef.current) return;
    let value = type === 'next' ?
      scrollProps?.current + 300 >= scrollProps?.max ?
        scrollProps?.max - 1
        :
        scrollProps?.current + 300
      :
      scrollProps?.current - 300 < 0 ?
        0
        :
        scrollProps?.current - 300;
    containerRef.current.scrollTo({
      left: value,
      behavior: 'smooth',
    });
    setScrollProps(prev => ({ ...prev, current: value }));
  }

  useEffect(() => {
    if (containerRef.current) {
      const max = containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth;
      setScrollProps({ current: 0, max });
    }
  }, [containerRef.current]);

  if (skeleton) return (
    <div className="popular-today">
      <span className={'link skeleton'}
            style={{ width: 135, height: 20 }}
      />
      <div className="popular-today-items"
           style={{ marginLeft: 12 }}
      >
        {
          [1, 2, 3].map(el => (
            <div className="popular-today-item"
                 key={el}
                 style={{ width: 120, height: 32 }}
            >
              <span className={'link skeleton'}
                    style={{ width: 20, height: 20, borderRadius: '50%' }}
              />
              <span className={'link skeleton'}
                    style={{ width: 67, height: 20 }}
              />
            </div>
          ))
        }
      </div>
      <div className="popular-today-handlers">
        <span className={'link skeleton'}
              style={{ width: 20, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 20, height: 20 }}
        />
      </div>
    </div>
  );


  return (
    <div className="popular-today">
      <span className="popular-today-title">
        {t(`Popular ${view} today:`)}
      </span>
      <div ref={containerRef} className="popular-today-items"
           onScroll={e => {
             setScrollProps(prev => ({ ...prev, current: e?.target?.scrollLeft }));
           }}
      >
        {
          data.map(el => (
            <div className="popular-today-item"
                 key={view === 'stores' ? el?.internal_shop_id : el?.product_id}
                 onClick={() => view === 'stores' ? navigate(`/sales-tracker/stores/store/${el?.internal_shop_id}`) : navigate(`/sales-tracker/products/product/${el?.store?.internal_shop_id}_${el?.product_id}`)}
            >
              <img
                src={view === 'stores' ? el?.favicon : el?.main_image}
                alt=""
                onError={utils.addDefaultSrc}
                width="20"
                height="20"
              />
              <span>
                {
                  el?.title || el?.custom_domain
                }
              </span>
            </div>
          ))
        }
      </div>
      {
        data?.length > 3 ?
          <div className="popular-today-handlers">
            <span className={cls('popular-today-handler prev', {
              disabled: scrollProps?.current === 0 || scrollProps?.max === 0,
            })}
                  onClick={() => handleClick('prev')}
            >
              <Icon type={'arrow_next'} role={'icon'} />
            </span>
            <span className={cls('popular-today-handler next', {
              disabled: scrollProps?.current + 10 >= scrollProps?.max || scrollProps?.max === 0,
            })}
                  onClick={() => handleClick('next')}
            >
              <Icon type={'arrow_next'} role={'icon'} />
            </span>
          </div>
          :
          null
      }
    </div>
  );
};

export default PopularToday;
