import React from 'react';
import '../../SalesTrackerPage/components/StoreProductsModalTableSkeleton.less';

const TableSkeleton = ({isMobile=false}) => {

  return (
    <div className={'store-products-modal-table-skeleton-wrapper'}>
      <div className="store-products-modal-table-skeleton-header">
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '20%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '20%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '20%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: '20%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", width: '20%'}}>
          <span className="link skeleton" style={{ width: 61, height: 24  }}/>
        </div>
      </div>

      {[1,2,3,4,5,6,7,8].map(el => (
        <div key={el} className={'store-products-modal-table-skeleton-row'} style={{display: "flex", alignItems: "center", padding: '12px 24px'}}>

          <div style={{display: isMobile ? 'none' : 'flex', width: '20%'}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', width: '20%'}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', width: '20%'}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', width: '20%'}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>
          <div style={{display: isMobile ? 'none' : 'flex', width: '20%', justifyContent: "flex-end"}}>
            <span className="link skeleton" style={{ width: 85, height: 20 }}/>
          </div>
        </div>
      ))}

    </div>
  );
};

export default TableSkeleton;
