import React, { useState } from 'react';
import { connect } from 'react-redux';
import Creators from '../reducer';
import { useTranslation } from 'react-i18next';
import acc from 'accounting';
import dayjs from 'dayjs';
import MainBlock from './MainBlock';
import ChartBlock from './ChartBlock';
import Chart from '../../../Components/Charts/Chart';
import Icon from '../../../Icon';
import { Spin } from 'antd';
import StoreTrackerPageOverviewBlocked from './StoreTrackerPageOverviewBlocked';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import InnerPageInfoBlock from './InnerPageInfoBlock';
import ProductCard from './ProductCard';
import AdItem from '../../AdSpotPage/components/AdItem';
import AppCard from './AppCard';
import cls from 'classname';

const StoreTrackerPageOverview = (
  {
    id,
    minDate,
    maxDate,
    showRawData,
    setShowRawData,
    dateParams,
    setDateParams,
    setTab,
    setModal,
    userInfo,
    isMobile,
    storeInnerPage,
    trackStoreById,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setRecordToCalc,
    trackProductById,
    unTrackProductById,
    setDeleteType,
    trackCompetitorById,
    unTrackCompetitorById,
    getStoreReport
  }) => {

  const { t } = useTranslation();

  const isTracked = storeInnerPage?.header?.is_tracked;
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const timeZone = storeInnerPage?.header?.store_timezone ? storeInnerPage?.header?.store_timezone.split('UTC')[1] : null;

  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);
  const [currentSlide, setCurrentSlide] = useState({
    'products': 1,
    'competitors': 1,
    'ads': 1,
    'apps': 1,
  });

  const trackingHandler = (data) => {
    if (data?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            product_id: data?.id,
            internal_shop_id: id,
            title: data?.title,
          },
          cb: unTrackProductById
        });
        setModal('remove_tracking');
      }
    } else {
      trackProductById({
        product_id: data?.id,
        full_data: false,
        internal_shop_id: id,
      });
    }
  }

  const trackingCompetitorHandler = (data) => {
    if (data?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            product_id: data?.id,
            internal_shop_id: id,
            title: data?.title,
          },
          cb: unTrackCompetitorById
        });
        setModal('remove_tracking');
      }
    } else {
      trackCompetitorById({
        product_id: data?.id,
        full_data: false,
        internal_shop_id: id,
      });
    }
  }

  function dotsHandler(e, key) {
    let target = e.target;
    const {clientWidth, scrollLeft} = target;
    let slide = Math.ceil(scrollLeft / clientWidth) === 0 ? 1 : Math.ceil(scrollLeft / clientWidth);
    setCurrentSlide(prev => ({...prev, [key]: slide}))
  }

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-overview">
      <InnerPageInfoBlock data={{
        type: 'store',
        tracked_by: storeInnerPage?.header?.tracked_by,
        created_at: storeInnerPage?.header?.created_at,
        theme: storeInnerPage?.header?.theme?.name,
        language: storeInnerPage?.header?.language,
        store_products: acc.formatNumber(storeInnerPage?.short?.products?.count || storeInnerPage?.full?.products?.count, 0, ','),
        store_apps: acc.formatNumber(storeInnerPage?.short?.apps?.count || storeInnerPage?.full?.apps?.count, 0, ','),
        social_channels: storeInnerPage?.header?.social_channels,
      }}
                          setModal={setModal}
                          isTracked={isTracked}
      />
      <MainBlock data={{
        title: storeInnerPage?.header?.title,
        link: storeInnerPage?.header?.custom_domain,
        logo: storeInnerPage?.header?.logo || storeInnerPage?.header?.favicon,
        report: 'sales',
        id: id,
        reportName: 'Store Report',
        dates: { min: minDate, max: maxDate },
        dateId: dateParams?.report_period?.id,
        setDateParams: setDateParams,
        timeInfo: null,
        timeZone: timeZone,
      }}
                 downloadReport={getStoreReport}
                 showRawData={showRawData}
                 setShowRawData={setShowRawData}
                 rawDataAccess={userInfo?.raw_data_access}
                 isMobile={isMobile}
                 disabledCalendar={!isTracked}
                 disabledCalendarTooltip={!isTracked ? 'To change timeframe you need to start tracking this store' : ''}
      />
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Sales & Revenue')}
        <span onClick={() => setTab('sales_&_revenue')}>
          {t('View Report')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      <ChartBlock type={'revenue'}
                  subTitle={!isMobile ? minDate && maxDate ?
                      `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                      :
                      `${dayjs(storeInnerPage?.short?.sales_and_revenue?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(storeInnerPage?.short?.sales_and_revenue?.charts?.revenue[storeInnerPage?.short?.sales_and_revenue?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`
                    :
                    null
                  }
                  totalCount={storeInnerPage?.short?.sales_and_revenue?.total ? storeInnerPage?.short?.sales_and_revenue?.total?.revenue : 0}
                  rawTotalCount={0}
                  returnsCount={Object.keys(storeInnerPage?.short?.sales_and_revenue?.total || {})?.length ? storeInnerPage?.short?.sales_and_revenue?.total?.returns_revenue : 0}
                  rawReturnsCount={0}
                  showRawData={showRawData}
                  withReturns={true}
                  link={null}
                  isLineChart={isLineChartRevenue}
                  setIsLineChart={setIsLineChartRevenue}
                  className={'st-inner-chart'}
      >
        <Spin spinning={storeInnerPage?.short?.sales_and_revenue?.loading}>
          <Chart data={
            storeInnerPage?.short?.sales_and_revenue?.charts?.revenue ?
              [...storeInnerPage?.short?.sales_and_revenue?.charts?.revenue]
              :
              []
              || []
          }
                 height={264}
                 type={'revenue'}
                 animation={false}
                 isMobile={isMobile}
                 withRawData={showRawData}
                 withReturns={true}
                 returnsData={storeInnerPage?.short?.sales_and_revenue?.charts?.returns_revenue?.length ?
                   [...storeInnerPage?.short?.sales_and_revenue?.charts?.returns_revenue]
                   :
                   []}
                 rawReturnsData={[]}
                 rawData={[]}
                 chartType={!isLineChartRevenue && 'bar'}
          />
        </Spin>
      </ChartBlock>
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Products')}
        {
          isTracked ?
            <span className="count">
              {acc.formatNumber(storeInnerPage?.short?.products?.count || storeInnerPage?.full?.products?.count, 0, ',')}
            </span>
            :
            null
        }
        {
          isTracked ?
            <span onClick={() => setTab('products')}>
              {t('View all products')}
              <Icon type={'arrow_link'} role={'icon'} />
            </span>
            :
            null
        }
      </h4>
      {
        isTracked ?
          storeInnerPage?.short?.products?.results?.length ?
            <Spin spinning={storeInnerPage?.short?.products?.trackingLoading}>
              <div className="store-tracker-page-tab-card-wrapper"
                   onScroll={(e) => dotsHandler(e, 'products')}
              >
                {
                  storeInnerPage?.short?.products?.results?.map(el => (
                    <ProductCard key={el?.id}
                                 data={el}
                                 setRecordToCalc={setRecordToCalc}
                                 setModal={setModal}
                                 handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                 handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                 trackingHandler={trackingHandler}
                                 isMobile={isMobile}
                                 id={id}
                    />
                  ))
                }
              </div>
              {
                isMobile ?
                  <div className="store-tracker-page-tab-card-wrapper-dots">
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.products === 1,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.products === 2,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.products === 3,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.products === 4,
                    })} />
                  </div>
                  :
                  null
              }
            </Spin>
            :
            <StoreTrackerPageOverviewEmpty type={'products'} />
          :
          <StoreTrackerPageOverviewBlocked id={id} trackStore={trackStoreById} />
      }
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Competitors')}
        {
          isTracked ?
            <span className="count">
              {acc.formatNumber(storeInnerPage?.short?.competitors?.count || storeInnerPage?.full?.competitors?.count, 0, ',')}
            </span>
            :
            null
        }
        {
          isTracked ?
            <span onClick={() => setTab('competitors')}>
              {t('View all competitors')}
              <Icon type={'arrow_link'} role={'icon'} />
            </span>
            :
            null
        }
      </h4>
      {
        isTracked ?
          storeInnerPage?.short?.competitors?.results?.length ?
            <Spin spinning={storeInnerPage?.short?.competitors?.trackingLoading}>
              <div className="store-tracker-page-tab-card-wrapper"
                   onScroll={(e) => dotsHandler(e, 'competitors')}
              >
                {
                  storeInnerPage?.short?.competitors?.results?.map(el => (
                    <ProductCard key={el?.id}
                                 data={el}
                                 setRecordToCalc={setRecordToCalc}
                                 setModal={setModal}
                                 handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                 handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                 trackingHandler={trackingCompetitorHandler}
                                 isMobile={isMobile}
                                 id={id}
                    />
                  ))
                }
              </div>
              {
                isMobile ?
                  <div className="store-tracker-page-tab-card-wrapper-dots">
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.competitors === 1,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.competitors === 2,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.competitors === 3,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.competitors === 4,
                    })} />
                  </div>
                  :
                  null
              }
            </Spin>
            :
            <StoreTrackerPageOverviewEmpty type={'competitors'} />
          :
          <StoreTrackerPageOverviewBlocked id={id} trackStore={trackStoreById} />
      }
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Facebook Ads')}
        {
          isTracked ?
            <span className="count">
              {acc.formatNumber(storeInnerPage?.short?.ads?.count || storeInnerPage?.full?.ads?.count, 0, ',')}
            </span>
            :
            null
        }
        {
          isTracked ?
            <span onClick={() => setTab('facebook_ads')}>
              {t('View all Facebook ads')}
              <Icon type={'arrow_link'} role={'icon'} />
            </span>
            :
            null
        }
      </h4>
      {
        isTracked ?
          storeInnerPage?.short?.ads?.results?.length ?
            <>
              <div className="store-tracker-page-tab-card-wrapper"
                   onScroll={(e) => dotsHandler(e, 'ads')}
              >
                {
                  storeInnerPage?.short?.ads?.results?.map(el => (
                    <AdItem data={{ ...el }}
                            key={el?.id}
                            isMobile={isMobile}
                            isAlive={el?.status}
                            disabledNavigate={false}
                    />
                  ))
                }
              </div>
              {
                isMobile ?
                  <div className="store-tracker-page-tab-card-wrapper-dots">
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.ads === 1,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.ads === 2,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.ads === 3,
                    })} />
                  </div>
                  :
                  null
              }
            </>
            :
            <StoreTrackerPageOverviewEmpty type={'facebook_ads'} />
          :
          <StoreTrackerPageOverviewBlocked id={id} trackStore={trackStoreById} />
      }
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Apps')}
        {
          isTracked ?
            <span className="count">
              {acc.formatNumber(storeInnerPage?.short?.apps?.count || storeInnerPage?.full?.apps?.count, 0, ',')}
            </span>
            :
            null
        }
        {
          isTracked ?
            <span onClick={() => setTab('apps')}>
              {t('View all apps')}
              <Icon type={'arrow_link'} role={'icon'} />
            </span>
            :
            null
        }
      </h4>
      {
        isTracked ?
          storeInnerPage?.short?.apps?.results?.length ?
            <>
              <div className="store-tracker-page-tab-card-wrapper"
                   onScroll={(e) => dotsHandler(e, 'apps')}
              >
                {
                  storeInnerPage?.short?.apps?.results?.map(el => (
                    <AppCard data={el}
                             key={el?.name}
                    />
                  ))
                }
              </div>
              {
                isMobile ?
                  <div className="store-tracker-page-tab-card-wrapper-dots">
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.apps === 1,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.apps === 2,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.apps === 3,
                    })} />
                    <div className={cls('store-tracker-page-tab-card-wrapper-dot', {
                      'active': currentSlide?.apps === 4,
                    })} />
                  </div>
                  :
                  null
              }
            </>
            :
            <StoreTrackerPageOverviewEmpty type={'apps'} />
        :
        <StoreTrackerPageOverviewBlocked id={id} trackStore={trackStoreById} />
      }
      <div style={{width:'100%', height: 1 }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  trackStoreById: (data) => dispatch(Creators.trackStoreInnerPageRequest(data)),
  trackProductById: (data) => dispatch(Creators.trackProductShortStoreInnerPageRequest(data)),
  unTrackProductById: (data) => dispatch(Creators.unTrackProductShortStoreInnerPageRequest(data)),
  trackCompetitorById: (data) => dispatch(Creators.trackCompetitorShortStoreInnerPageRequest(data)),
  unTrackCompetitorById: (data) => dispatch(Creators.unTrackCompetitorShortStoreInnerPageRequest(data)),
  getStoreReport: (data) => dispatch(Creators.getStoreReportRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageOverview);
