/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import { useTranslation } from 'react-i18next';
import Creators from '../User/reducer';
import OnboardingActions from '../../Redux/OnboardingRedux';
import SettingCreators from './reducer';
import TrackerCreators from '../../Containers/SalesTrackerPage/reducer';
import CategoryCreators from '../../Redux/CategoryRedux';
import PlanCreators from '../../Redux/PlanRedux';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import actions from '../Layout/actions';
import AccountSubPage from '../../Components/AccountSubPage';
import AccountSkeleton from '../../Components/AccountSubPage/AccountSkeleton';
import BillingSkeleton from '../../Components/BillingSubPage/BillingSkeleton';
import BillingSubPage from '../../Components/BillingSubPage';
import PlanSubPage from '../../Components/PlanSubPage';
import PlanSkeleton from "../../Components/PlanSubPage/PlanSkeleton";
import IntegrationsSkeleton from '../../Components/IntegrationsSubPage/IntegrationsSkeleton';
import IntegrationsSubPage from '../../Components/IntegrationsSubPage';
import utils from '../../Utils/utils';
import { ArrowSelectDown } from '../../Icon/img';
import './styles.less';

const SettingPage = (
  {
    isMobile,
    authFetching,
    userFetching,
    settingFetching,
    countriesFetching,
    getCountries,
    getLanguagesList,
    getUserInfo,
    confirmEmail,
    getSubscriptionsList,
    getPayments,
    getLimits,
    getUserCountry,
    getCategory,
    getPlan,
    plansFetching,
    plans,
    openSidebar,
    nav,
    getShopifyStore,
    getAutoStore,
    getNextBillingDetails,
    code,
    action,
    ...props
  }) => {

  /* account, billing, plan*/
  const { hash } = useParams();
  const navigate = useNavigate();

  const [denyUserReq, setDenyUserReq] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [modal, setModal] = useState(null);
  const [passwordCode,setPasswordCode] = useState('')

  useEffect(() => {
      if (props.userInfo?.subscriptions?.length > 0) {
        const firstSubscriptionId = props.userInfo.subscriptions[0].id;
        setSubscriptionId(firstSubscriptionId);
      }
  }, [props.userInfo]);

  useEffect(() => {
    document.title = 'Settings - Dropship';
    if(code && action){
      if (code && action === 'verify_email') {
        setDenyUserReq(true);
      }
      if (code && action === 'forgot_password') {
        setModal('create_new_password')
        setPasswordCode(code)
      }

      code && action !== 'forgot_password' && confirmEmail({ hash:code, isVerify: action === 'verify_email' });
      navigate('/setting/account');
    }
    if (hash) {
      if (['create_charge', 'account', 'billing', 'plan', 'integrations'].includes(hash)) {
        navigate(`/setting/${hash}`, {replace: true});
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (hash === 'account' && !denyUserReq) {
      if(!props.onBoarding.countries) getCountries();
      if(!props.setting.languages.length) getLanguagesList();
      getUserInfo();
      getUserCountry();
    }

    if (hash === 'integrations') {
      getShopifyStore();
      getAutoStore();
    }
    if (hash === 'billing') {
      getUserInfo();
      getPayments();
      getLimits({page: 1, page_size: 50});
      getPlan();
      if(subscriptionId){
        getNextBillingDetails(subscriptionId)
      }
    }
    if (hash === 'plan') {
      props.setLastLanguagePlanPage(props.userInfo?.language);
      if(!props.onBoarding.countries) getCountries();
      if(!props.categories) getCategory();
      getPlan();
      getSubscriptionsList('active');
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [hash]);
  const { t } = useTranslation();

  const skeleton = {
    account: userFetching || settingFetching || countriesFetching || authFetching,
    billing: userFetching || settingFetching || settingFetching || plansFetching,
    plan: settingFetching || plansFetching,
    integrations: settingFetching,
  };

  const header = {
    account: {
      title: t('Account'),
      subtitle: t('View or manage your account details.'),
    },
    billing: {
      title: t('Billing'),
      subtitle: t('View or manage your billing details.'),
    },
    plan: {
      title: t('Plans'),
      subtitle: t('View or manage your Dropship membership.'),
    },
    integrations: {
      title: t('Integrations'),
      subtitle: t('Integrate with our partners.'),
    }
  }

  const content = {
    account: skeleton.account ? <AccountSkeleton isMobile={isMobile}/> : <AccountSubPage  setModal={setModal} modal={modal} code={passwordCode} getUserInfo={getUserInfo}/>,
    billing: skeleton.billing ? <BillingSkeleton isMobile={isMobile} /> : <BillingSubPage />,
    integrations: skeleton.integrations ? <IntegrationsSkeleton isMobile={isMobile} /> : <IntegrationsSubPage />,
    plan: skeleton.plan ? <PlanSkeleton isMobile={isMobile} /> : <PlanSubPage />,
  };

  return (
    <div className="settings-page">
      {
        isMobile ?
          <div className="settings-page-header-wrapper"
               onClick={() => openSidebar(!nav.isShowMenu)}
          >
            {
              skeleton?.[hash] ?
                <span className="link skeleton" style={{ width: 20, height: 20 }} />
                :
                <ArrowSelectDown />
            }
            <div className="settings-page-header">
              <h3>
                {
                  skeleton?.[hash] ?
                    <span className="link skeleton" style={{ width: utils.randomNum(150, 250), height: 28 }} />
                    :
                    header?.[hash]?.title
                }
              </h3>
              <h4>
                {
                  skeleton?.[hash] ?
                    <span className="link skeleton" style={{ width: 200, height: 20 }} />
                    :
                    header?.[hash]?.subtitle
                }
              </h4>
            </div>
          </div>
          :
          <div className="settings-page-header">
            <h3>
              {
                skeleton?.[hash] ?
                  <span className="link skeleton" style={{ width: utils.randomNum(150, 250), height: 28 }} />
                  :
                  header?.[hash]?.title
              }
            </h3>
            <h4>
              {
                skeleton?.[hash] ?
                  <span className="link skeleton" style={{ width: utils.randomNum(300, 400), height: 20 }} />
                  :
                  header?.[hash]?.subtitle
              }
            </h4>
          </div>
      }
      <div className={`settings-content settings-content-${hash}`}>
        {content?.[hash]}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state?.auth?.userInfo,
  nav: state.nav,
  isMobile: state?.nav?.isMobile,
  authFetching: state.auth.fetching,
  userFetching: state.users.fetching,
  setting: state.setting,
  settingFetching: state.setting.fetching,
  onBoarding: state.onBoarding,
  plans: state.plan.plans,
  plansFetching: state.plan.plansFetching,
  categories: state.category.categories,
  countriesFetching: state.onBoarding.countriesFetching,
});
const mapDispatchToProps = (dispatch) => ({
  openSidebar: (value) =>dispatch(actions.changeNavSetProp('isShowMenu', value)),
  getUserInfo: () => dispatch(Creators.userRequest()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  getLanguagesList: () => dispatch(SettingCreators.getLanguagesListRequest()),
  setLastLanguagePlanPage: (data) => dispatch(SettingCreators.setLastLanguagePlanPage(data)),
  getSubscriptionsList: (filter) => dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  confirmEmail: (hash, isVerify) => dispatch(Creators.confirmEmailsRequest(hash, isVerify)),
  getPayments: () => dispatch(SettingCreators.getPaymentsRequest()),
  getUserCountry: () => dispatch(SettingCreators.getUserCountryRequest()),
  getCategory: () => dispatch(CategoryCreators.categoriesRequest()),
  getPlan: () => dispatch(PlanCreators.plansRequest()),
  getLimits: (data) => dispatch(TrackerCreators.getTrackedStoresRequest(data)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
  getNextBillingDetails:(sub_id) => dispatch(SettingCreators.getNextBillingDetailsRequest(sub_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
