import React from 'react';

const PinIconNotification = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.97917 13.0212L10.1743 16.2164C11.1396 17.1817 12.7925 16.6406 13.0001 15.2913L13.6163 11.2858C13.7 10.7421 14.0465 10.2747 14.5424 10.0367L16.8378 8.9349C17.8736 8.43772 18.1075 7.06626 17.2951 6.25385L13.7465 2.70526C12.9341 1.89285 11.5627 2.12677 11.0655 3.16256L9.96369 5.45797C9.72566 5.95388 9.25826 6.3004 8.71458 6.38404L4.7091 7.00027C3.3598 7.20785 2.81869 8.86075 3.78401 9.82607L6.97917 13.0212ZM6.97917 13.0212L6.98529 13.0151M6.97917 13.0212L3.125 16.8754"
        stroke="#225AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PinIconNotification;
