import React, { useCallback, useEffect, useRef, useState } from 'react';
import Creators from '../reducer';
import ProductDatabaseCreators from '../../ProductDatabasePage/reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cls from 'classname';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import ProductView from '../../ProductsPage/components/product-view';
import { Spin, Table } from 'antd';
import ProductCard from './ProductCard';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import { getColumns } from './StoreTrackerPageSalesColumns';

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const cardSortOptions = [
  {
    id: null,
    text: 'All products',
  },
  {
    id: '-sales',
    text: 'Best sellers',
  },
  {
    id: '-created_at',
    text: 'Newest',
  },
];

const LocalTable = (props) => <Table {...props} />;

const MemoTable = React.memo(LocalTable, (prev, next) =>
  JSON.stringify(prev?.dataSource) === JSON.stringify(next?.dataSource) && prev?.loading === next?.loading && prev?.sortOrder === next?.sortOrder);

const StoreTrackerPageProducts = (
  {
    setModal,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setRecordToCalc,
    setDeleteType,
    id,
    userInfo,
    isMobile,
    storeInnerPage,
    trackProduct,
    unTrackProduct,
    getProducts,
    setCompetitor,
  }) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);

  const [firstLoading, setFirstLoading] = useState(true);
  const [displayFormat, setDisplayFormat] = useState('overview');
  const [dateParams, setDateParams] = useState({
    'report_period': {
      'min': dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      'max': dayjs().format('YYYY-MM-DD'),
      'id': {
        'id': 0,
        'name': 'Last 7 days',
      },
    },
  });
  const [sortOrder, setSortOrder] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [visibleModal, setVisibleModal] = useState(false);

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const dropdownFilters = {
    report_period: {
      min: dateParams?.report_period?.min,
      max: dateParams?.report_period?.max,
    },
  };

  const setDropdownFilters = (prop) => {
    let result = prop();
    setDateParams(result);
  };

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && storeInnerPage?.full?.products?.results?.length < storeInnerPage?.full?.products?.count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleSetCompetitor = useCallback(data =>
      setCompetitor(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);

  const toggleConnectProduct = (record) => {
    if (record?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            internal_shop_id: id,
            product_id: record?.id,
            title: record?.title,
          },
          cb: unTrackProduct
        });
        setModal('remove_tracking');
      }
    } else {
      trackProduct({
        internal_shop_id: id,
        product_id: record?.id,
        full_data: false,
      });
    }
  };

  useEffect(() => {
    getProducts({
      internal_shop_id: id,
      page: pageNumber,
      page_size: 50,
      created_at__gte: dateParams?.report_period?.min,
      created_at__lte: dateParams?.report_period?.max,
      ordering: sortOrder,
    });
  }, []);

  useEffect(() => {
    setSortOrder(null);
  }, [displayFormat]);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, storeInnerPage?.full?.products?.count, storeInnerPage?.full?.products?.results?.length]);

  useEffect(() => {
    if(firstLoading || pageNumber !== 1) setPageNumber(1);
    else {
      getProducts({
        internal_shop_id: id,
        page: pageNumber,
        page_size: 50,
        created_at__gte: dateParams?.report_period?.min,
        created_at__lte: dateParams?.report_period?.max,
        ordering: sortOrder,
      });
    }
  }, [sortOrder, dateParams?.report_period?.min, dateParams?.report_period?.max]);

  useEffect(() => {
    if (firstLoading || storeInnerPage?.full?.products?.loading) setTimeout(() => setFirstLoading(false), 1000);
    else {
      getProducts({
        internal_shop_id: id,
        page: pageNumber,
        page_size: 50,
        created_at__gte: dateParams?.report_period?.min,
        created_at__lte: dateParams?.report_period?.max,
        ordering: sortOrder,
      });
    }
  }, [pageNumber]);

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-products">
      <Spin spinning={false}>
        <div className="report-main-block">
        <span className={'report-main-block-title'}>
          <span className='title'>
            {t('Products')}
          </span>
        </span>
          <div className="report-main-block-actions">
            {
              displayFormat === 'overview' ?
                <div className='report-main-block-cards-sort'>
                  {
                    cardSortOptions.map(el => (
                      <div className={cls('report-main-block-cards-sort-btn', {
                        'active': sortOrder === el?.id,
                      })}
                           key={el?.id}
                           onClick={() => setSortOrder(el?.id)}
                      >
                        {
                          t(el?.text)
                        }
                      </div>
                    ))
                  }
                </div>
                :
                null
            }
            <DropdownDateBlock limit={null}
                               timeZone={storeInnerPage?.header?.store_timezone ? storeInnerPage?.header?.store_timezone.split('UTC')[1] : null}
                               label={null}
                               customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                               customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                               id={'report_period'}
                               iconType={'database_calendar'}
                               data={dateOptions}
                               dropdownFilters={dropdownFilters}
                               setDropdownFilters={setDropdownFilters}
                               withName
                               visibleModal={visibleModal}
                               setVisibleModal={setVisibleModal}
                               position={'bottomRight'}
                               isMobile={isMobile}
                               disabledCalendar={!storeInnerPage?.header?.is_tracked}
                               disabledCalendarTooltip={!storeInnerPage?.header?.is_tracked ? 'To change timeframe you need to start tracking this store' : ''}
            />
            <ProductView view={displayFormat}
                         setView={(val) => setDisplayFormat(val)}
            />
          </div>
        </div>
      </Spin>
      <Spin spinning={storeInnerPage?.full?.products?.loading || storeInnerPage?.full?.products?.trackingLoading}>
        {
          storeInnerPage?.full?.products?.results?.length ?
            displayFormat === 'overview' ?
              <div className="product-database-table-body store-tracker-page-sales-table-wrapper cards"
                   id={'product-database-table-body'}
              >
                {
                  storeInnerPage?.full?.products?.results?.map((el, index) => (
                    <ProductCard key={el?.id}
                                 data={el}
                                 setRecordToCalc={setRecordToCalc}
                                 setModal={setModal}
                                 handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                 handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                 trackingHandler={toggleConnectProduct}
                                 isMobile={isMobile}
                                 id={id}
                                 rank={index}
                    />
                  ))
                }
              </div>
              :
              <div className="product-database-table-body store-tracker-page-sales-table-wrapper"
                   id={'product-database-table-body'}
              >
                <MemoTable components={{
                  // eslint-disable-next-line react/prop-types
                  table: ({ className, children, ...other }) => (
                    <table  {...other}
                            ref={tableRef}
                            className={cls(className, 'other-wrapper')}
                    >
                      {children}
                    </table>
                  ),
                }}
                           className="list-table"
                           loading={false}
                           rowClassName={'product-database-table-row'}
                           rowKey={(record) => record.id}
                           dataSource={
                             storeInnerPage?.full?.products?.results?.length ?
                               [...storeInnerPage?.full?.products?.results]
                               :
                               []
                           }
                           sortOrder={sortOrder}
                           columns={getColumns({
                             sortOrder,
                             handleSetRecordToCalc: setRecordToCalc,
                             setModal,
                             handleOpenModalShopifyConnect,
                             handleOpenModalAutoDSConnect,
                             setCompetitor: handleSetCompetitor,
                             toggleConnectProduct,
                             t,
                             isMobile,
                             view: 'details',
                             id,
                           })
                           }
                           pagination={false}
                           locale={{ emptyText: '-' }}
                           scroll={{
                             x: '100dvh', //'max-content',
                             y: 'calc(100dvh - 125px)',
                           }}
                           sticky={{
                             offsetHeader: 0,
                             offsetScroll: 0,
                             getContainer: () => document.querySelector('.product-database-table-body'),
                           }}
                           sortDirections={['descend', 'ascend', null]}
                           onChange={(pagination, filters, sorter) => {
                             handleSort(sorter);
                           }}
                />
              </div>
            :
            <StoreTrackerPageOverviewEmpty type={'products'} />
        }
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  trackProduct: (data) => dispatch(Creators.trackProductStoreInnerPageRequest(data)),
  unTrackProduct: (data) => dispatch(Creators.unTrackProductStoreInnerPageRequest(data)),
  getProducts: (data) => dispatch(Creators.getStoreProductsRequest(data)),
  setCompetitor: (data) => dispatch(ProductDatabaseCreators.setCompetitorResearch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageProducts);
