import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useSelector } from 'react-redux';

export const BD = ({className, outline }) => {
  const theme = useSelector((state) => state?.nav?.theme);

  return outline ? (
    <svg
      width="20"
      height="20"
      className={cls(className, 'icon icon-bd icon-bd-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6939 7.03884C12.6939 8.2744 13.6955 9.27601 14.9311 9.27601C16.1666 9.27601 17.1682 8.2744 17.1682 7.03884C17.1682 5.80328 16.1666 4.80166 14.9311 4.80166C13.6955 4.80166 12.6939 5.80328 12.6939 7.03884ZM14.9311 11.077C13.0102 11.077 11.4026 9.73588 10.9936 7.93893L6.41525 7.93893C5.91792 7.93893 5.51475 7.53576 5.51475 7.03843C5.51475 6.5411 5.91792 6.13793 6.41525 6.13793L10.9938 6.13793C11.4031 4.34139 13.0105 3.00067 14.9311 3.00067C17.1613 3.00067 18.9692 4.80862 18.9692 7.03884C18.9692 9.26906 17.1613 11.077 14.9311 11.077ZM11.3061 16.9612C11.3061 18.1967 10.3045 19.1983 9.06893 19.1983C7.83337 19.1983 6.83175 18.1967 6.83175 16.9612C6.83175 15.7256 7.83337 14.724 9.06893 14.724C10.3045 14.724 11.3061 15.7256 11.3061 16.9612ZM9.06893 20.9993C10.9895 20.9993 12.597 19.6585 13.0063 17.8619L17.5847 17.8619C18.0821 17.8619 18.4852 17.4587 18.4852 16.9614C18.4852 16.4641 18.0821 16.0609 17.5847 16.0609L13.0064 16.0609C12.5973 14.264 10.9897 12.923 9.06893 12.923C6.83871 12.923 5.03076 14.7309 5.03076 16.9612C5.03076 19.1914 6.83871 20.9993 9.06893 20.9993Z"
        fill={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      className={cls(className, 'icon icon-bd icon-bd-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6939 7.03884C12.6939 8.2744 13.6955 9.27601 14.9311 9.27601C16.1666 9.27601 17.1682 8.2744 17.1682 7.03884C17.1682 5.80328 16.1666 4.80166 14.9311 4.80166C13.6955 4.80166 12.6939 5.80328 12.6939 7.03884ZM14.9311 11.077C13.0102 11.077 11.4026 9.73588 10.9936 7.93893L6.41525 7.93893C5.91792 7.93893 5.51475 7.53576 5.51475 7.03843C5.51475 6.5411 5.91792 6.13793 6.41525 6.13793L10.9938 6.13793C11.4031 4.34139 13.0105 3.00067 14.9311 3.00067C17.1613 3.00067 18.9692 4.80862 18.9692 7.03884C18.9692 9.26906 17.1613 11.077 14.9311 11.077ZM11.3061 16.9612C11.3061 18.1967 10.3045 19.1983 9.06893 19.1983C7.83337 19.1983 6.83175 18.1967 6.83175 16.9612C6.83175 15.7256 7.83337 14.724 9.06893 14.724C10.3045 14.724 11.3061 15.7256 11.3061 16.9612ZM9.06893 20.9993C10.9895 20.9993 12.597 19.6585 13.0063 17.8619L17.5847 17.8619C18.0821 17.8619 18.4852 17.4587 18.4852 16.9614C18.4852 16.4641 18.0821 16.0609 17.5847 16.0609L13.0064 16.0609C12.5973 14.264 10.9897 12.923 9.06893 12.923C6.83871 12.923 5.03076 14.7309 5.03076 16.9612C5.03076 19.1914 6.83871 20.9993 9.06893 20.9993Z"
        fill={theme === 'light' ? '#225AEA' : 'white'}
      />
    </svg>
  );
};

BD.defaultProps = {
  outline: true,
};

BD.propTypes = {
  className: PropTypes.string,
  outline: PropTypes.bool,
};

export default BD;
