import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useSelector } from 'react-redux';

export const Settings = ({ width, height, className, outline }) => {
  const theme = useSelector((state) => state?.nav?.theme);

  return outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cls(className, 'icon icon-settings icon-settings-outline')}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5911 19.8066C14.6041 20.174 14.4935 20.5271 14.3458 20.816C14.03 21.3946 13.4186 21.7155 12.7248 21.7304L11.4567 21.7126C10.7819 21.7059 10.1474 21.3331 9.81806 20.7626C9.65571 20.4814 9.55342 20.1227 9.56222 19.7748C9.57526 19.4887 9.48327 19.2205 9.33484 18.9634C8.87561 18.168 7.83807 17.8779 7.04584 18.3031C6.14173 18.8251 5.00742 18.4944 4.47397 17.5705L3.84311 16.4778C3.30503 15.5458 3.58583 14.3981 4.48993 13.8762C5.25897 13.4107 5.52649 12.3671 5.06727 11.5717C4.91419 11.3066 4.72786 11.0928 4.47824 10.9691C4.17257 10.8028 3.90841 10.5268 3.75069 10.2536C3.41671 9.67514 3.41111 8.93921 3.74263 8.35144L4.36598 7.25239C4.70536 6.66008 5.3093 6.28997 5.96837 6.30582C6.27844 6.2982 6.65345 6.40307 6.96054 6.58999C7.20049 6.75141 7.4648 6.79163 7.75593 6.80567C8.66398 6.81703 9.4132 6.06309 9.41986 5.14868C9.40875 4.09455 10.2588 3.261 11.3015 3.2696L12.54 3.2723C13.5873 3.28893 14.4377 4.12634 14.4456 5.19304C14.4718 6.1099 15.2475 6.87254 16.1523 6.89648C16.4467 6.89794 16.7243 6.83408 16.9509 6.68184C17.2482 6.48875 17.5901 6.40919 17.9141 6.42567C18.5731 6.44153 19.1984 6.79827 19.5528 7.3757L20.2068 8.50855C20.5501 9.1031 20.5307 9.83204 20.2149 10.4107C20.0625 10.6915 19.8273 10.9559 19.5068 11.1088C19.2616 11.2289 19.0675 11.4374 18.9216 11.693C18.4899 12.4886 18.7625 13.5417 19.5481 14.0309C20.4633 14.563 20.768 15.7262 20.2541 16.6335L19.6372 17.7074C19.1279 18.6228 17.981 18.9421 17.069 18.3974C16.2738 17.946 15.2462 18.2178 14.8067 19.018C14.6686 19.2691 14.5713 19.5181 14.5911 19.8066ZM10.6995 10.2159C9.46525 10.9285 9.07164 12.5162 9.79991 13.7776C10.5282 15.0391 12.0861 15.4679 13.3204 14.7553C14.5547 14.0427 14.9701 12.4745 14.2418 11.2131C13.5135 9.95171 11.9338 9.50326 10.6995 10.2159Z"
        stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, .26'}
        strokeWidth="1.59089"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cls(className, 'icon icon-settings icon-settings-bold')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7425 19.7333C14.7562 20.1222 14.6392 20.496 14.4828 20.8017C14.1486 21.4141 13.5014 21.7537 12.7672 21.7695L11.425 21.7507C10.7108 21.7435 10.0392 21.349 9.69065 20.7452C9.51881 20.4476 9.41055 20.0679 9.41986 19.6997C9.43366 19.3969 9.33631 19.113 9.1792 18.8409C8.69315 17.999 7.59502 17.692 6.75652 18.1421C5.79961 18.6945 4.59904 18.3446 4.03444 17.3666L3.36674 16.2101C2.79723 15.2237 3.09443 14.009 4.05134 13.4566C4.86529 12.9639 5.14844 11.8594 4.66239 11.0175C4.50037 10.7369 4.30316 10.5106 4.03896 10.3797C3.71544 10.2037 3.43585 9.91157 3.26892 9.62245C2.91543 9.01019 2.90951 8.23127 3.26039 7.60918L3.92014 6.44594C4.27935 5.81903 4.91856 5.42731 5.61612 5.44409C5.9443 5.43602 6.34121 5.54701 6.66623 5.74486C6.9202 5.9157 7.19995 5.95827 7.50808 5.97312C8.46916 5.98515 9.26213 5.18718 9.26919 4.21937C9.25743 3.10367 10.1571 2.22144 11.2607 2.23054L12.5715 2.2334C13.68 2.25101 14.5801 3.13731 14.5884 4.26631C14.6161 5.23673 15.4372 6.04391 16.3949 6.06924C16.7064 6.07079 17.0002 6.0032 17.24 5.84207C17.5547 5.6377 17.9166 5.55349 18.2595 5.57094C18.9571 5.58772 19.6188 5.9653 19.9939 6.57645L20.6861 7.77547C21.0494 8.40474 21.0289 9.17625 20.6947 9.78874C20.5334 10.0859 20.2844 10.3657 19.9452 10.5276C19.6857 10.6547 19.4803 10.8753 19.3259 11.1459C18.869 11.9879 19.1575 13.1026 19.9889 13.6203C20.9576 14.1835 21.2801 15.4146 20.7361 16.3749L20.0832 17.5116C19.5442 18.4804 18.3303 18.8184 17.3651 18.2419C16.5234 17.7641 15.4358 18.0518 14.9706 18.8986C14.8245 19.1644 14.7215 19.428 14.7425 19.7333ZM10.6236 9.58251C9.31723 10.3367 8.90063 12.0172 9.67143 13.3523C10.4422 14.6874 12.0911 15.1413 13.3975 14.3871C14.7039 13.6328 15.1436 11.9731 14.3728 10.638C13.602 9.30291 11.93 8.82826 10.6236 9.58251Z"
        stroke={theme === 'light' ? '#225AEA' : 'white'}
        fill='transparent'
        strokeWidth="1.59089"
      />
    </svg>
  );
};

Settings.defaultProps = {
  width: 20,
  height: 20,
  outline: true,
};

Settings.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  outline: PropTypes.bool,
};

export default Settings;
