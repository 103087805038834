import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'antd';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import ProductView from '../../ProductsPage/components/product-view';

const { Search } = Input;

const PageOptions = ({ 
	setShowFilters,
  showFilters,
	setModal, 
	setVisible, 
	checkedList, 
	plainOptions, 
	isChanged,
  disabledSearch,
  isMobile,
  showTable,
  view,
  setView,
  inputText,
  setInputText,
  applyFilters,
  setPageSize,
  setPageNumber,
  setSortOrder,
  setDefaultSearch,
  productsLoading,
  resetProducts,
  resetFilters
}) => {
	const { t } = useTranslation();
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleVisibleChange = (value) => !disabledSearch && setVisibleDropdown(value);

  const handleSearch = (e) => {
    setInputText(e.target.value);
    setShowFilters(false);
  }

  useEffect(() => {
    if (inputText === '') {
      const clearIcon = document.querySelector('.clear-icon');
      if (clearIcon) {
        clearIcon.classList.add('initial-appearance');
      }
    }
  }, []);

  const handleSubmitSearch = () => {
    resetProducts();
    setPageNumber(1);
    setPageSize(50);
    setSortOrder(null);
    setDefaultSearch(false);
    applyFilters(true);
  };
	
  return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <Search placeholder={t('Search for products...')}
                  className={'search-input'}
                  onChange={(e) => {
                    e.persist();
                    handleSearch(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && inputText && !disabledSearch) handleSubmitSearch();
                  }}
                  defaultValue={inputText && inputText}
                  {...(inputText ? { value: inputText } : null)}
                  prefix={<Icon type="search_icon"
                                role="icon"
                  />}
                  suffix={
                    Boolean(inputText.length) && 
                    <div className="clear-icon" onClick={() => {
                      setInputText('');
                      setShowFilters(false);
                      resetFilters();              
                    }}>
                      <Icon 
                        type="close_modal"
                        role="icon"
                      />
                    </div>
                  }
          />
          <div className={cls('search-submit', {
            'disabled': !inputText.length || disabledSearch || productsLoading,
          })}
            onClick={handleSubmitSearch}
          >
            {t('Search')}
          </div>
        </div>
        {
          isMobile ?
            null
            :
            <span className={cls('filters', {
              "active": showFilters,
              "disabled": inputText.length
            })}
                  onClick={() => {
                    if (inputText.length) return
                    else setShowFilters(!showFilters);
                  }}
            >
              <Icon type={'filter_icon'} role={'icon'} />
              {t('Filters')}
        </span>
        }
      </div>
      <div className={'view-options'}>
        {
          isMobile ?
            <span className={cls('filters', {
              "active": showFilters,
              "disabled": inputText.length,
            })}
                  onClick={() => {
                    if (inputText.length) setInputText('');
                    else setShowFilters(!showFilters);
                  }}
            >
              <Icon type={'filter_icon'} role={'icon'} />
              { t('Filters') }
            </span>
          :
          null
        }
        {
          isMobile ? null 
          :
          <Dropdown destroyPopupOnHide={true}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onOpenChange={handleVisibleChange}
                    placement="bottomRight"
                    dropdownRender={() => (
                      <div className={'sales-tracker-top-page-header-dropdown'}>
                        <div className="sales-tracker-top-page-header-dropdown-option"
                              onClick={() => {
                                setVisible(true);
                                setVisibleDropdown(false);
                                setModal('loadFilterPreset');
                              }}
                        >
                          <Icon type="load_preset" role="icon" />
                          <span>
                        {t('Load preset')}
                      </span>
                        </div>
                        <div className={cls('sales-tracker-top-page-header-dropdown-option', {
                          disabled: checkedList.length === plainOptions.length ? false : !isChanged,
                        })}
                              onClick={() => {
                                if (isChanged) {
                                  setVisible(true);
                                  setVisibleDropdown(false);
                                  setModal('saveFilterPreset');
                                }
                              }}
                        >
                          <Icon type={'save_preset'}
                                role={'icon'}
                          />
                          <span>
                        {t('Save this search as preset')}
                      </span>
                        </div>
                      </div>
                    )}
                    trigger={['click']}
                    open={visibleDropdown}
          >
            <span className={'presets'}>
            <Icon type={'preset_icon'} role={'icon'} />
              {t('Presets')}
              <Icon type="arrow_select_down"
                    role="icon"
              />
            </span>
          </Dropdown>
        }
        <ProductView view={view}
                     setView={showTable ? (val) => setView(val) : () => null}
        />
      </div>
    </div>
	);
};

export default PageOptions;
