import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import acc from 'accounting';
import dayjs from 'dayjs';
import { ArrowSelectDown } from '../../Icon/img';
import { Divider } from 'antd';
import cls from 'classname';
import Icon from '../../Icon';

const Plan = (
  {
    subscription,
    isMobile,
    nextBillingDetail
  }
) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className='billing-subpage-block-title'>
          {t('Plan')}
        </span>
        <span className='billing-subpage-block-link'
              onClick={() => navigate('/setting/plan')}
        >
          {t('Upgrade plan')}
          <ArrowSelectDown />
        </span>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div className="billing-subpage-block-stat-wrapper vertical">
        <span className='billing-subpage-block-stat-key'>
          {t('Next billing amount')}
        </span>
        <span className='billing-subpage-block-stat-value'>
          {
            subscription?.expire_date && subscription?.next_payment_date === null ?
              '$0,00'
              :
              subscription?.plan?.is_trial ?
                '-'
                :
                `$${acc.formatNumber((nextBillingDetail?.total_amount || 0)/100, 2, ' ', ',')}`
          }
        </span>
      </div>
      <div className="billing-subpage-block-stat-wrapper">
        <span className='billing-subpage-block-stat-key'>
          {t('Billing period')}
        </span>
        <span className='billing-subpage-block-stat-value'>
          {
            (!subscription?.next_payment_date || nextBillingDetail?.plan?.is_trial || isMobile) ?
              null
              :
              <span>
                {`${t('Next payment on')} ${dayjs(nextBillingDetail?.next_payment_date).format('MMMM DD, YYYY')} `}
              </span>
          }
          {
            isMobile && nextBillingDetail?.next_payment_date && !subscription?.plan?.is_trial ?
              <Icon type="attention_outline"
                    role="button"
                    titleText={t(`${t('Next payment on')} ${dayjs(nextBillingDetail?.next_payment_date).format('MMMM DD, YYYY')}`)}
                    tooltipProps={{
                      placement: 'left',
                      overlayClassName: cls('details-tooltip', 'shipping'),
                      getPopupContainer: (trigger) => trigger.parentNode,
                    }}
              />
              :
              null
          }
          {
            subscription?.plan?.is_trial ?
              '-'
              :
              t(subscription?.plan?.price_type?.value) || '-'
          }
        </span>
      </div>
      <div className="billing-subpage-block-stat-wrapper">
        <span className='billing-subpage-block-stat-key'>
          {
            `${subscription?.plan?.plan?.label} plan`
          }
        </span>
        <span className='billing-subpage-block-stat-value'>
          ${acc.formatNumber(subscription?.plan?.price, 2, ',', '.')}
        </span>
      </div>
      <div className="billing-subpage-block-stat-wrapper">
        <span className='billing-subpage-block-stat-key'>
          {t('Additional tracking limits - stores')}
        </span>
        <span className='billing-subpage-block-stat-value'>
        ${acc.formatNumber((nextBillingDetail?.st_store_limit_amount || 0)/100, 2, ' ', ',')}
        </span>
      </div>
      <div className="billing-subpage-block-stat-wrapper">
        <span className='billing-subpage-block-stat-key'>
          {t('Additional tracking limits - products')}
        </span>
        <span className='billing-subpage-block-stat-value'>
          ${acc.formatNumber((nextBillingDetail?.st_product_limit_amount || 0)/100, 2, ' ', ',')}
        </span>
      </div>
      {nextBillingDetail?.discount_amount && (
        <div className="billing-subpage-block-stat-wrapper">
          <span className='billing-subpage-block-stat-key'>
            {t('Discount')}
          </span>
          <span className='billing-subpage-block-stat-value'>
            -${acc.formatNumber((nextBillingDetail?.discount_amount || 0)/100, 2, ' ', ',')}
          </span>
        </div>
      )}
      <Divider style={{ margin: '16px 0' }} />
      <div className="billing-subpage-block-stat-wrapper">
        <span className='billing-subpage-block-stat-key'>
          {t('Total')}
        </span>
        <span className='billing-subpage-block-stat-value'>
          {
            subscription?.expire_date && subscription?.next_payment_date === null ?
              '$0,00'
              :
              subscription?.plan?.is_trial ?
                '-'
                :
                `$${acc.formatNumber((nextBillingDetail?.total_amount || 0)/100, 2, ' ', ',')}`
          }
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.auth.userInfo?.subscriptions?.[0],
  isMobile: state.nav.isMobile,
  nextBillingDetail: state.setting.billing
});

export default connect(mapStateToProps, null)(Plan);
