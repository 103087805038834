import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { columnTitles } from '../../../Components/titleSortFilters';
import acc from 'accounting';

dayjs.extend(utc);

export const getColumns = ({ t, sortOrder }) => {
  return [
    {
      title: () => t('Location'),
      dataIndex: 'country',
      key: 'country',
      width: 200,
      sorter: false,
      render: (text, record) =>(
        <div className={'adLibrary-table-cell'}>
          <p className={'product-name'}>{record.country}</p>
        </div>
      ),
    },
    {
      title: () => {
        let result = t('Age');
        return columnTitles({
          sortOrder,
          title: result,
          key: 'age_range',
        });
      },
      dataIndex: 'age_range',
      key: 'age_range',
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={'adLibrary-table-cell'}>
          <div className="product-creation-date">{record.age_range}</div>
        </div>
      ),
    },
    {
      title: () => {
        let result = t('Gender');
        return columnTitles({
          sortOrder,
          title: result,
          key: 'gender',
        });
      },
      dataIndex: 'gender',
      key: 'gender',
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={'adLibrary-table-cell'}>
       {record.gender ? record.gender.charAt(0).toUpperCase() + record.gender.slice(1) : "-"}
        </div>
      ),
    },
    {
      title: () => {
        let result = t('Reach');
        return columnTitles({
          sortOrder,
          title: result,
          key: 'reach',
        });
      },
      dataIndex: 'reach',
      key: 'reach',
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={'adLibrary-table-cell'}>
          <div className="product-creation-date">
          {record?.reach
                          ? `${acc.format(record.reach, 0, '.', '.')}`
                          : '-'}
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = t('Ad spend');
        return columnTitles({
          sortOrder,
          title: result,
          key: 'spend',
        });
      },
      dataIndex: 'spend',
      key: 'spend',
      width: 120,
      sorter: true,
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={'adLibrary-table-cell'}>
          <div className="product-creation-date">
          {record?.spend
                          ? `$${acc.format(record.spend, 2,  ',', '.')}`
                          : '-'}
          </div>
        </div>
      ),
    },
  ];
};
