import React from 'react';
import UniversityPageCourseWrapperSkeleton from "./UniversityPageCourseWrapperSkeleton";
import utils from '../../../../Utils/utils';

const UniversityPageSkeleton = ({isMobile}) => {
  return (
    <div className={'product-database-page university-page'}>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          {
            isMobile ?
              <div className="sales-tracker-top-page-subheader" style={{ padding: 12 }}>
                <span className={'link skeleton'}
                  style={{ width: 160, height: 20 }}
                />
              </div>
              :
              <>
                <span className={'link skeleton'}
                    style={{ width: 40, height: 40 }}
                />
                <div className="sales-tracker-top-page-subheader">
                  <span className={'link skeleton'}
                        style={{ width: 70, height: 16 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 252, height: 16, marginTop: 4 }}
                  />
                </div>
              </>
          }
        </div>
      </div>

      <UniversityPageCourseWrapperSkeleton isMobile={isMobile} />

      <div className="university-page-tools-wrapper">
        <div className="university-page-tools-header">
          <span className="link skeleton" style={{ width: 198, height: 24 }} />
        </div>
      </div>

      <div className="university-page-cards">
        {
          (isMobile ? [1] : [1, 2, 3, 4, 5, 6, 7, 8]).map(el => (
            <div className={'university-video-card-wrapper'}
                 key={el**2}
            >
              <span className={'link skeleton'}
                    style={{ width: '100%', height: isMobile ? 261 : 184, borderRadius: 8 }}
              />
              <div className="university-video-card-info">
                <h4 className="university-video-card-title">
                  <span className={'link skeleton'}
                        style={{ width: utils.randomNum(60, 130), height: 20 }}
                  />
                </h4>
                <p className="university-video-card-description"
                   style={{ width: '100%' }}
                >
                  <span className={'link skeleton'}
                        style={{ width: '100%', height: 18 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: utils.randomNum(30, 150), height: 18, marginTop: 2 }}
                  />
                </p>
                <div className={'university-video-card-status'}>
                  <span className={'link skeleton'}
                        style={{ width: 50, height: 20 }}
                  />
                  <div className={'progress-wrapper'} />
                  <span className={'link skeleton'}
                        style={{ width: 50, height: 20 }}
                  />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default UniversityPageSkeleton;
