import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Icon from '../../../Icon';
import { Dropdown, Modal, Spin, Tooltip } from 'antd';
import { FadspotComponent } from './AddLiibraryContent';
import AdLibraryAdsetsModal from './AdLibraryAdsetsModal';
import ActiveAdsModal from './ActiveAdsModal';
import AdLibraryItemAdSpendModal from './AdLibraryItemAdSpendModal';
import './AdLibraryItem.less';

export const getDiff = (date, t) => {
  const today = dayjs();
  const inputDate = dayjs(date);

  const hourDiff = today.diff(inputDate, 'hours');
  const dayDiff = today.diff(inputDate, 'days');
  const monthDiff = today.diff(inputDate, 'months');
  const yearDiff = today.diff(inputDate, 'years');

  if (hourDiff < 24) {
    return `${hourDiff || 1}${t('h ago')}`;
  }

  return yearDiff
    ? `${yearDiff}${t('y ago')}`
    : monthDiff
    ? `${monthDiff}${t('m ago')}`
    : dayDiff
    ? `${dayDiff}${t('d ago')}`
    : `1 ${t('d ago')}`;
};
const AdLibraryItem = ({
  id,
  setModal,
  modal,
  handleOpenModalShopifyConnect,
  data,
  getAdLibraryAdsCollation,
  collations,
  skeleton,
  adsLoading,
  getCollationInsightsCharts,
  getCollationInsightsInfo,
  getCollationInsightsBreakDown,
  getAdLibraryStatusChart,
  setIsNotModal,
  setMobileModal,
  setVisibleModalMobileId
}) => {
  const { t } = useTranslation();
  const isExtended = data.is_eu_targeted;
  const isMobile = useSelector((state) => state?.nav?.isMobile);
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleTooltipAction, setVisibleTooltipAction] = useState(false);
  const [visibleTooltipHeadline, setVisibleTooltipHeadline] = useState(false);
  const [adLibrarySlide, setAdLibrarySlide] = useState(0);
  const navigate = useNavigate();
  const handleVisibleChange = (value) => setVisible(value);
  const [newModal, setNewModal] = useState(null);
  const modalBlocks = {
    ad_sets_count: {
      content: (
        <Spin size="large" spinning={false}>
          <AdLibraryAdsetsModal
            isMobile={isMobile}
            collationId={data.collation_id}
            getAdLibraryAdsCollation={getAdLibraryAdsCollation}
            collations={collations}
            skeleton={skeleton}
            handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
            adsLoading={adsLoading}
            setModal={setModal}
            advertiserId={data.advertiser.id}
            setVisibleModal={setVisibleModal}
            visibleModal={visibleModal}
            modal={modal}
            getCollationInsightsCharts={getCollationInsightsCharts}
            getCollationInsightsInfo={getCollationInsightsInfo}
            getCollationInsightsBreakDown={getCollationInsightsBreakDown}
            newModal={newModal}
            setNewModal={setNewModal}
            setIsNotModal={setIsNotModal}
            setMobileModal={setMobileModal}
            visibleModalMobileId={setVisibleModalMobileId}
            setVisibleModalMobileId={setVisibleModalMobileId}
          />
        </Spin>
      ),
      width: 1135,
    },
    active_ads: {
      content: (
        <Spin size="large" spinning={false}>
          <ActiveAdsModal getAdLibraryStatusChart={getAdLibraryStatusChart} advertiserId={data.advertiser.id} isMobile={isMobile}/>
        </Spin>
      ),
      width: 828,
    },
    ad_item_ad_spend: {
      content: (
        <Spin size="large" spinning={false}>
          <AdLibraryItemAdSpendModal
            collationId={data.collation_id}
            advertiserId={data.advertiser.id}
            getCollationInsightsCharts={getCollationInsightsCharts}
            getCollationInsightsInfo={getCollationInsightsInfo}
            getCollationInsightsBreakDown={getCollationInsightsBreakDown}
            isMobile={isMobile}
            isAdsetsRequest={false}
            handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
            domainFld={data?.domain_fld}
            setModal={setModal}
            setIsNotModal={setIsNotModal}
            setMobileModal={setMobileModal}
            visibleModalMobileId={setVisibleModalMobileId}
            setVisibleModalMobileId={setVisibleModalMobileId}
          />
        </Spin>
      ),
      width: 828,
    },
  };

  return (
    <div
      className={cls('adLibrary-item', {
        extended: isExtended,
      })}
    >
      <div className="adLibrary-item-header">
        <div className="adLibrary-item-header-info-wrapper">
          <div className="adLibrary-item-header-logo-wrapper">
            <img
              src={data?.advertiser?.profile_photo}
              alt=""
              className={'adLibrary-item-header-logo'}
              onError={utils.addDefaultSrc}
            />
          </div>
          <div className="adLibrary-item-header-title-wrapper">
            <span
              className="adLibrary-item-header-title"
              onClick={() => {
                navigate(`/ad-library/adset/${data?.advertiser?.id}/overview/`);
              }}
            >
              {`${data?.advertiser?.page_name}`}
              {data.is_verified && (
                <Icon role={'icon'} type={'ad_spot_checkmark'} />
              )}
            </span>
            <div className="adLibrary-item-header-section">
              <div
                className={cls('adLibrary-item-header-status', {
                  red: !data?.is_active,
                })}
              >
                <div
                  className={cls('adLibrary-item-header-status-circle', {
                    red: !data?.is_active,
                  })}
                />
                {!data?.is_active ? t('Inactive') : t('Active')}
              </div>
              <div className="adLibrary-item-header-items">
                {data?.advertiser?.followers ? (
                  <span className="adLibrary-item-header-item">
                    <div className="vertical-divider" />
                    <Icon role={'icon'} type={'followers'} />
                    {utils.convertNumber(data?.advertiser?.followers, 2)}
                  </span>
                ) : null}
                {data?.advertiser?.likes ? (
                  <span className="adLibrary-item-header-item">
                    <div className="vertical-divider" />
                    <Icon role={'icon'} type={'likes'} />
                    {utils.convertNumber(data?.advertiser?.likes, 2)}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isMobile ? (
          <span
            className="adLibrary-item-header-action"
            onClick={() => {
              setMobileModal('ad_item_action');
              setVisibleModalMobileId(id)
              setIsNotModal(true)
            }}
          >
            <Icon type={'three_dots'} role={'icon'} />
          </span>
        ) : (
          <Dropdown
            destroyPopupOnHide={true}
            getPopupContainer={(trigger) => trigger.parentNode}
            onOpenChange={handleVisibleChange}
            placement="bottomRight"
            dropdownRender={() => (
              <div className="adLibrary-item-header-action-dropdown">
                <div
                  className="adLibrary-item-header-action-dropdown-option"
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&is_targeted_country=false&media_type=all&search_type=page&view_all_page_id=${data.advertiser.id}`,
                      '_blank',
                    )
                  }
                >
                  <Icon role={'icon'} type={'search_facebook'} isDark={false} />
                  <span>{t('View Facebook Ad Library')}</span>
                </div>
                {data?.shopify_metadata.length ? (
                  <div
                    className="adLibrary-item-header-action-dropdown-option"
                    onClick={() => {
                      setVisible(false);
                      handleOpenModalShopifyConnect(
                        `${data?.shopify_metadata[0]?.internal_shop_id}_${data?.shopify_metadata[0]?.shopify_product_id}`,
                      );
                    }}
                  >
                    <Icon
                      type={'shopify_btn_icon'}
                      role={'icon'}
                      isLogo={true}
                    />
                    <span>{t('Import to Shopify store')}</span>
                  </div>
                ) : null}
                <div
                  className="adLibrary-item-header-action-dropdown-option"
                  onClick={() =>
                    window.open(
                      `https://www.similarweb.com/website/${data.domain_fld}`,
                      '_blank',
                    )
                  }
                >
                  <Icon role={'icon'} type={'similar_web'} isDark={false} />
                  <span>{t('View with Similarweb')}</span>
                </div>
              </div>
            )}
            trigger={['click']}
            open={visible}
          >
            <span className="adLibrary-item-header-action">
              <Icon type={'three_dots'} role={'icon'} />
            </span>
          </Dropdown>
        )}
      </div>
      <div className="adLibrary-item-stats">
        {data.ad_sets_count && (
          <div
            className="badge"
            onClick={() => {
              setVisibleModal(true);
              setModal('ad_sets_count');
            }}
          >
            <span>
              {data.ad_sets_count} {t('adsets')}
            </span>
            {!isMobile && (
              <span className="sales-tracker-top-page-credits-title">
                <Icon
                  type={'attention_outline'}
                  role="button"
                  width={16}
                  height={16}
                  tooltipProps={{
                    trigger: 'hover',
                    placement: 'bottom',
                    overlayClassName: 'subscription-tooltip scheduled',
                    getPopupContainer: (trigger) => trigger.parentNode,
                  }}
                  titleText={t('Define the quantity of adsets used in the Ad')}
                />
              </span>
            )}
          </div>
        )}
        {data.advertiser.active_ads_count ? (
          <span
            className="active-ads"
            onClick={() => {
              setModal('active_ads');
              setVisibleModal(true);
            }}
          >
            {data.advertiser.active_ads_count} {t('active ads')}
          </span>
        ) : null}
      </div>
      <p className="adLibrary-item-description">
        {data?.creative_type === 'carousel'
          ? data?.snapshot?.cards?.[adLibrarySlide]?.body
          : data?.snapshot?.body?.text}
      </p>
      {isExtended ? (
        <div className="adLibrary-item-target-wrapper">
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Region')}</span>
            <span className="adLibrary-item-target-value-with-flag">
              <Icon type={'e_u_flag'} />
              <span>EU</span>
            </span>
          </div>
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Ad spend')}</span>
            <span className="adLibrary-item-target-value">
              {data?.ad_spend
                ? `$${acc.format(data.ad_spend, 2, ',', '.')}`
                : 'No Data'}
            </span>
          </div>
          <div className="adLibrary-item-target">
            <span className="adLibrary-item-target-title">{t('Reach')}</span>
            <span className="adLibrary-item-target-value">
              {`${utils.convertNumber(data?.total_reaches_count)}`}
            </span>
          </div>
        </div>
      ) : null}
      {isExtended ? (
        <div className="adLibrary-item-insights">
          <span
            onClick={() => {
              setVisibleModal(true);
              setModal('ad_item_ad_spend');
            }}
          >
            {t('View ad insights')}
          </span>
        </div>
      ) : null}

      <div className={cls('fadspot-page-item-wrapper', { addLibrary: true })}>
        <div className="fadspot-page-item">
          <FadspotComponent
            data={data}
            visibleTooltipAction={visibleTooltipAction}
            visibleTooltipHeadline={visibleTooltipHeadline}
            setAdLibrarySlide={setAdLibrarySlide}
          />
        </div>
      </div>

      <div className="adLibrary-item-info-wrapper">
        {isExtended && data?.demographics?.country.length > 0 && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Countries')}</span>
            <div className="adLibrary-item-info-value">
              {Array.isArray(data?.demographics?.country) ? (
                <div className="country-flags">
                  {data.demographics.country
                    .slice(0, 4)
                    .map((country, index) => (
                      <Tooltip key={index} title={country.name}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: country.flag,
                          }}
                        />
                      </Tooltip>
                    ))}
                  {data?.demographics?.country?.length > 4 && (
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {data.demographics.country
                            .slice(4)
                            .map((country) => country.name)
                            .join('\n')}
                        </div>
                      }
                    >
                      <span className="extra-flags">
                        +{data?.demographics.country?.length - 4}
                      </span>
                    </Tooltip>
                  )}
                </div>
              ) : (
                data?.demographics?.country || null
              )}
            </div>
          </div>
        )}
        {isExtended && data?.demographics?.gender?.length > 0 && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Gender')}</span>
            <div className="adLibrary-item-info-value">
              {Array.isArray(data.demographics.gender)
                ? data.demographics.gender.join(', ')
                : data.demographics.gender}
            </div>
          </div>
        )}
        {isExtended && data?.demographics?.age && (
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">{t('Age')}</span>

            {typeof data?.demographics?.age === 'string' ? (
              <div className="adLibrary-item-info-value">
                {data?.demographics?.age || t('any')}
              </div>
            ) : (
              <div className="adLibrary-item-info-value">
                {`${data?.demographics?.age?.min} - ${data?.demographics?.age?.max}`}
              </div>
            )}
          </div>
        )}
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Creation date')}
          </span>
          <div className="adLibrary-item-info-value-date">
            <div>{dayjs(data?.created_at).format('DD.MM.YYYY HH:mm')}</div>
            <div className="adLibrary-item-info-value-date-gray">
              {getDiff(data?.created_at, t)}
            </div>
          </div>
        </div>
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">{t('Last seen')}</span>
          <div className="adLibrary-item-info-value-date">
            <div>{dayjs(data?.last_seen_date).format('DD.MM.YYYY HH:mm')}</div>
            <div className="adLibrary-item-info-value-date-gray">
              {getDiff(data?.last_seen_date, t)}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={cls(
          `change-modal custom-modal ${isMobile && 'full-screen'} ${(isMobile && modal === 'ad_item_ad_spend') && 'scrollable'}`,
        )}
        rootClassName={`${
          newModal === 'ad_item_ad_spend_modal' ? 'hiddenParentModal' : ''
        }`}
        getContainer={() => document.getElementById('global-wrap')}
        open={visibleModal}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocks[modal]?.width}
        height={!isMobile && modal === 'ad_item_ad_spend' ? '95vh' : 'unset'}
        footer={null}
        closable="true"
        onCancel={() => {
          setVisibleModal(false);
        }}
        destroyOnClose
      >
        {modalBlocks[modal]?.content}
      </Modal>
    </div>
  );
};

export default AdLibraryItem;
