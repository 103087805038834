import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cls from 'classname';
import Creators from "../../Containers/AutoDS/reducer";
import ShopifyCreators from "../../Containers/ShopifyStore/reducer";
import Icon from '../../Icon';
import { Dropdown, Tooltip } from 'antd';
import ButtonComponent from '../Button';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import ModalShopifyDelete from '../../Containers/ShopifyStore/ModalShopifyDelete';
import ShopifyStoresModal from './ShopifyStoresModal';
import './styles.less';

const IntegrationsSubPage = (
  {
    isMobile,
    shopifyStore,
    autoDs,
    changeVisibleModalAuto,
    setNichesStep,
    changeVisibleModalShopify,
    userInfo
  }
) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [innerModal, setInnerModal] = useState(false);
  const [deleteStore, setDeleteStore] = useState(null);

  const disabledImport = !userInfo?.subscriptions?.[0]?.plan?.price
    && userInfo?.subscriptions?.[0]?.plan?.plan?.name !== 'free'
    && userInfo?.role?.id !== 4 ;

  const integrations = [
    {
      key: 'shopify',
      icon: 'shopify_connect_new_have',
      title: 'Shopify',
      text: shopifyStore?.results?.length ?
        `Your Shopify store ${shopifyStore?.results?.[0]?.domain} is currently connected to Dropship.`
        :
        'Connect your Shopify store to start importing products in just a few clicks.',
      status: shopifyStore?.results?.length ?
        'Connected'
        :
        'Not connected',
    },
    {
      key: 'autods',
      icon: 'auto_ds',
      title: 'AutoDS',
      text: autoDs?.email ?
        `Your Shopify store ${autoDs?.email} is currently connected to Dropship.`
        :
        'Connect your Shopify store to start importing products in just a few clicks.',
      status: autoDs?.email ?
        'Connected'
        :
        'Not connected',
    },
  ];

  const handleDisconnect = (store) => {
    setInnerModal(false);
    setVisible(false);
    setDeleteStore(store);
    setVisibleModal(true);
  }

  const handleCloseModal = () => {
    setVisibleModal(false)
    setDeleteStore(null)
  }

  const handleCheckLimit = (domain) => {
    const url = `https://bundles.dropship.io/?shop=${domain}`;
    window.open(url, "_blank");
  }

  return (
    <div className="integrations-subpage">
      <div className="billing-subpage-block">
        <div className="billing-subpage-block-title-wrapper">
          <span className='billing-subpage-block-title'>
            {t('Integrations')}
          </span>
        </div>
        <div className="integrations-subpage-block-integrations-wrapper">
          {
            integrations.map((el) => (
              <div className="item"
                   key={el?.key}
              >
                <div className={`item-icon ${el?.key}`}>
                  <Icon type={el?.icon} role={'icon'} />
                </div>
                <div className="item-info">
                  <span className="title">
                    {el?.title}
                    <span className={cls("tag", {
                      'connected': el?.status === 'Connected',
                      'not-connected': el?.status === 'Not connected',
                    })}
                    >
                      {t(el?.status)}
                    </span>
                  </span>
                  <span className="description">
                    {el?.text}
                  </span>
                </div>
                {
                  el?.key === 'autods' ?
                    autoDs?.email ?
                      <div className="item-action active"
                           onClick={() => changeVisibleModalAuto({ isVisibleModal: 'disconnect', importProductId: null })}
                      >
                        {
                          t('Disconnect')
                        }
                      </div>
                      :
                      <div className="item-action"
                           onClick={() => changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId: null })}
                      >
                        {
                          t('Connect')
                        }
                      </div>
                    :
                    shopifyStore?.results?.length ?
                      isMobile ?
                        <div className="dropdown-store"
                             onClick={() => setInnerModal(true)}
                        >
                          <span className="dropdown-store-info">
                            <Icon type={'shopify_connect_new_have'}
                                  role={'icon'}
                            />
                            <span className="name">
                              {
                                shopifyStore?.results?.[0]?.name
                              }
                            </span>
                            <span className="domain">
                              {
                                shopifyStore?.results?.[0]?.domain
                              }
                            </span>
                          </span>
                          <ArrowSelectDown />
                        </div>
                        :
                        <Dropdown overlayClassName={'dropdown-store-wrapper'}
                                  destroyPopupOnHide={true}
                                  getPopupContainer={(trigger) => trigger?.parentNode}
                                  onOpenChange={value => setVisible(value)}
                                  dropdownRender={() => (
                                    <ul className="user_store-list">
                                      {
                                        shopifyStore?.results?.map(({ id, name, domain }) => (
                                          <li key={id} className="user_store-item">
                                            <Icon type={'shopify_connect_new_have'}
                                                  role={'icon'}
                                            />
                                            <span className="name">{name}</span>
                                            {userInfo?.need_block_pages ? (
                                              <Tooltip 
                                                overlayClassName="product_name-tooltip details-tooltip"
                                                title={domain}
                                                trigger={isMobile ? 'click' : 'hover'}
                                                destroyTooltipOnHide={true}
                                                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                                              >
                                                <span className="domain">{domain}</span>
                                              </Tooltip>
                                            ) : (
                                              <span className="domain">{domain}</span>
                                            )}
                                            <ButtonComponent className="btn-cancel"
                                                             onClick={() => handleDisconnect({ id, name, domain })}
                                            >
                                              {t('Disconnect')}
                                            </ButtonComponent>
                                            {userInfo?.need_block_pages && (
                                              <ButtonComponent className="btn-check-limit"
                                                              onClick={() => {
                                                                setVisible(false);
                                                                handleCheckLimit(domain);
                                                              }}
                                              >
                                                {t('Check limits')}
                                              </ButtonComponent>
                                            )}
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  )}
                                  trigger={['click']}
                                  open={visible}
                        >
                          <div className="dropdown-store">
                          <span className="dropdown-store-info">
                            <Icon type={'shopify_connect_new_have'}
                                  role={'icon'}
                            />
                            <span className="name">
                              {
                                shopifyStore?.results?.[0]?.name
                              }
                            </span>
                            <span className="domain">
                              {
                                shopifyStore?.results?.[0]?.domain
                              }
                            </span>
                          </span>
                            <ArrowSelectDown />
                          </div>
                        </Dropdown>
                      :
                      <div className={cls('item-action', {
                        disabled: disabledImport,
                      })}
                           onClick={() => {
                             if (!disabledImport) {
                               setNichesStep(1);
                               changeVisibleModalShopify({
                                 isVisibleModal: 'connect',
                                 initialUrl: document?.location?.pathname + (document?.location?.search || ''),
                               });
                             }
                           }}
                      >
                        {t('Connect')}
                      </div>
                }
              </div>
            ))
          }
        </div>
      </div>
      <ModalShopifyDelete isVisible={visibleModal && !!deleteStore}
                          closeModal={handleCloseModal}
                          deleteStore={deleteStore}
      />
      <ShopifyStoresModal isMobile={isMobile}
                          visible={innerModal}
                          setModal={setInnerModal}
                          handleDisconnect={handleDisconnect}
                          shopifyStore={shopifyStore}
                          isCheckLimit={userInfo?.need_block_pages}
                          handleCheckLimit={handleCheckLimit}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  shopifyStore: state.shopifyStore,
  autoDs: state.autoDS,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAuto: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
  setNichesStep: (data) => dispatch(ShopifyCreators.setNichesStep(data)),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSubPage);
