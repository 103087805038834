import React, { useCallback, useEffect, useRef, useState } from 'react';
import Creators from '../reducer';
import ProductDatabaseCreators from '../../ProductDatabasePage/reducer';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Spin, Switch, Table } from 'antd';
import Chart from '../../../Components/Charts/Chart';
import ChartBlock from './ChartBlock';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import { getColumns } from './StoreTrackerPageSalesColumns';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const LocalTable = (props) => <Table {...props} />;

const MemoTable = React.memo(LocalTable, (prev, next) =>
  JSON.stringify(prev?.dataSource) === JSON.stringify(next?.dataSource) && prev?.loading === next?.loading && prev?.sortOrder === next?.sortOrder);

const StoreTrackerPageSales = (
  {
    userInfo,
    isMobile,
    storeInnerPage,
    getStoreProducts,
    getStoreSalesAndRevenue,
    setModal,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setRecordToCalc,
    setDeleteType,
    setCompetitor,
    id,
    unTrackProduct,
    trackProduct,
  }
) => {

  const {t} = useTranslation();

  const tableRef = useRef(null);

  const [firstLoading, setFirstLoading] = useState(true);
  const [dateParams, setDateParams] = useState({
    'report_period': {
      'min': dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      'max': dayjs().format('YYYY-MM-DD'),
      'id': {
        'id': 0,
        'name': 'Last 7 days',
      },
    },
  });
  const [showRawData, setShowRawData] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);
  const [isLineChartSales, setIsLineChartSales] = useState(true);
  const [isLineChartUnique, setIsLineChartUnique] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;
  const dropdownFilters = {
    report_period: {
      min: dateParams?.report_period?.min,
      max: dateParams?.report_period?.max,
    },
  };

  const setDropdownFilters = (prop) => {
    let result = prop();
    setDateParams(result);
  };

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && storeInnerPage?.full?.products?.results?.length < storeInnerPage?.full?.products?.count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  const handleSetCompetitor = useCallback(data =>
      setCompetitor(data),
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    []);

  const toggleConnectProduct = (record) => {
    if (record?.is_tracked) {
      if (isTrial) {
        setModal('canNotRemoveTracking');
      } else {
        setDeleteType({
          type: 'product',
          record: {
            internal_shop_id: id,
            product_id: record?.id,
            title: record?.title,
          },
          cb: unTrackProduct
        });
        setModal('remove_tracking');
      }
    } else {
      trackProduct({
        internal_shop_id: id,
        product_id: record?.id,
        full_data: false,
      });
    }
  };

  useEffect(() => {
    getStoreSalesAndRevenue({
      internal_shop_id: id,
      date_gte: dateParams?.report_period?.min,
      date_lte: dateParams?.report_period?.max
    });
    getStoreProducts({
      internal_shop_id: id,
      page: pageNumber,
      page_size: 50,
      created_at__gte: dateParams?.report_period?.min,
      created_at__lte: dateParams?.report_period?.max,
      ordering: sortOrder,
    });
  }, []);

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, storeInnerPage?.full?.products?.count, storeInnerPage?.full?.products?.results?.length]);

  useEffect(() => {
    if(firstLoading || pageNumber !== 1) setPageNumber(1);
    else {
      getStoreProducts({
        internal_shop_id: id,
        page: pageNumber,
        page_size: 50,
        created_at__gte: dateParams?.report_period?.min,
        created_at__lte: dateParams?.report_period?.max,
        ordering: sortOrder,
      });
    }
  }, [sortOrder, dateParams?.report_period?.min, dateParams?.report_period?.max]);

  useEffect(() => {
    !firstLoading && getStoreSalesAndRevenue({
      internal_shop_id: id,
      date_gte: dateParams?.report_period?.min,
      date_lte: dateParams?.report_period?.max
    });
  }, [dateParams?.report_period?.min, dateParams?.report_period?.max]);

  useEffect(() => {
    if (firstLoading || storeInnerPage?.full?.products?.loading) setTimeout(() => setFirstLoading(false), 1000);
    else {
      getStoreProducts({
        internal_shop_id: id,
        page: pageNumber,
        page_size: 50,
        created_at__gte: dateParams?.report_period?.min,
        created_at__lte: dateParams?.report_period?.max,
        ordering: sortOrder,
      });
    }
  }, [pageNumber]);

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-sales">
      <div className="report-main-block">
        <span className={'report-main-block-title'}>
          <span className='title'>
            {t('Sales and Revenue')}
          </span>
        </span>
        {
          userInfo?.raw_data_access ?
            <div className="report-main-block-switch">
              <Switch checked={showRawData}
                      onChange={(state) => setShowRawData(state)}
              />
              <span className="report-main-block-switch-text"
                    onClick={() => setShowRawData((prev) => !prev)}
              >
                {t('Raw data')}
              </span>
            </div>
            :
            null
        }
        <div className="report-main-block-actions">
          <DropdownDateBlock limit={null}
                             timeZone={storeInnerPage?.header?.store_timezone ? storeInnerPage?.header?.store_timezone.split('UTC')[1] : null}
                             label={null}
                             customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                             customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                             id={'report_period'}
                             iconType={'database_calendar'}
                             data={dateOptions}
                             dropdownFilters={dropdownFilters}
                             setDropdownFilters={setDropdownFilters}
                             withName
                             visibleModal={visibleModal}
                             setVisibleModal={setVisibleModal}
                             position={'bottomRight'}
                             isMobile={isMobile}
                             disabledCalendar={!storeInnerPage?.header?.is_tracked}
                             disabledCalendarTooltip={!storeInnerPage?.header?.is_tracked ? 'To change timeframe you need to start tracking this store' : ''}
          />
        </div>
      </div>
      <div className="store-tracker-page-sales-chart-wrapper">
        <ChartBlock type={'revenue'}
                    subTitle={!isMobile ? dateParams?.report_period?.min && dateParams?.report_period?.max ?
                        `${dayjs(dateParams?.report_period?.min).format('MMM DD, YYYY')} - ${dayjs(dateParams?.report_period?.max).format('MMM DD, YYYY')}`
                        :
                        `${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.revenue[storeInnerPage?.full?.sales_and_revenue?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`
                      :
                      null
                    }
                    totalCount={storeInnerPage?.full?.sales_and_revenue?.total ? storeInnerPage?.full?.sales_and_revenue?.total?.revenue : 0}
                    rawTotalCount={0}
                    returnsCount={Object.keys(storeInnerPage?.full?.sales_and_revenue?.total || {})?.length ? storeInnerPage?.full?.sales_and_revenue?.total?.returns_revenue : 0}
                    rawReturnsCount={0}
                    showRawData={showRawData}
                    withReturns={true}
                    link={null}
                    isLineChart={isLineChartRevenue}
                    setIsLineChart={setIsLineChartRevenue}
                    className={'st-inner-chart'}
        >
          <Spin spinning={storeInnerPage?.full?.sales_and_revenue?.loading}>
            <Chart data={
              storeInnerPage?.full?.sales_and_revenue?.charts?.revenue ?
                [...storeInnerPage?.full?.sales_and_revenue?.charts?.revenue]
                :
                []
                || []
            }
                   height={220}
                   type={'revenue'}
                   animation={false}
                   isMobile={isMobile}
                   withRawData={showRawData}
                   withReturns={true}
                   returnsData={storeInnerPage?.full?.sales_and_revenue?.charts?.returns_revenue?.length ?
                     [...storeInnerPage?.full?.sales_and_revenue?.charts?.returns_revenue]
                     :
                     []}
                   rawReturnsData={[]}
                   rawData={[]}
                   chartType={!isLineChartRevenue && 'bar'}
            />
          </Spin>
        </ChartBlock>
      </div>
      <div className="store-tracker-page-sales-charts-wrapper">
        <div className="store-tracker-page-sales-chart-wrapper small">
          <ChartBlock type={'sales'}
                      subTitle={!isMobile ? dateParams?.report_period?.min && dateParams?.report_period?.max ?
                          `${dayjs(dateParams?.report_period?.min).format('MMM DD, YYYY')} - ${dayjs(dateParams?.report_period?.max).format('MMM DD, YYYY')}`
                          :
                          `${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.sales[0]?.date).format('MMM DD, YYYY')} - ${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.sales[storeInnerPage?.full?.sales_and_revenue?.charts?.sales?.length - 1]?.date).format('MMM DD, YYYY')}`
                        :
                        null
                      }
                      totalCount={storeInnerPage?.full?.sales_and_revenue?.total ? storeInnerPage?.full?.sales_and_revenue?.total?.sales : 0}
                      rawTotalCount={0}
                      returnsCount={Object.keys(storeInnerPage?.full?.sales_and_revenue?.total || {})?.length ? storeInnerPage?.full?.sales_and_revenue?.total?.returns_sales : 0}
                      rawReturnsCount={0}
                      showRawData={showRawData}
                      withReturns={true}
                      link={null}
                      isLineChart={isLineChartSales}
                      setIsLineChart={setIsLineChartSales}
                      className={'st-inner-chart'}
          >
            <Spin spinning={storeInnerPage?.full?.sales_and_revenue?.loading}>
              <Chart data={
                storeInnerPage?.full?.sales_and_revenue?.charts?.sales ?
                  [...storeInnerPage?.full?.sales_and_revenue?.charts?.sales]
                  :
                  []
                  || []
              }
                     height={220}
                     type={'sales'}
                     animation={false}
                     isMobile={isMobile}
                     withRawData={showRawData}
                     withReturns={true}
                     returnsData={storeInnerPage?.full?.sales_and_revenue?.charts?.returns_sales?.length ?
                       [...storeInnerPage?.full?.sales_and_revenue?.charts?.returns_sales]
                       :
                       []}
                     rawReturnsData={[]}
                     rawData={[]}
                     chartType={!isLineChartSales && 'bar'}
              />
            </Spin>
          </ChartBlock>
        </div>
        <div className="store-tracker-page-sales-chart-wrapper small">
          <ChartBlock type={'unique_products_sold'}
                      subTitle={!isMobile ? dateParams?.report_period?.min && dateParams?.report_period?.max ?
                          `${dayjs(dateParams?.report_period?.min).format('MMM DD, YYYY')} - ${dayjs(dateParams?.report_period?.max).format('MMM DD, YYYY')}`
                          :
                          `${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.unique[0]?.date).format('MMM DD, YYYY')} - ${dayjs(storeInnerPage?.full?.sales_and_revenue?.charts?.unique[storeInnerPage?.full?.sales_and_revenue?.charts?.unique?.length - 1]?.date).format('MMM DD, YYYY')}`
                        :
                        null
                      }
                      totalCount={storeInnerPage?.full?.sales_and_revenue?.total ? storeInnerPage?.full?.sales_and_revenue?.total?.unique : 0}
                      rawTotalCount={0}
                      returnsCount={Object.keys(storeInnerPage?.full?.sales_and_revenue?.total || {})?.length ? storeInnerPage?.full?.sales_and_revenue?.total?.returns_unique : 0}
                      rawReturnsCount={0}
                      showRawData={showRawData}
                      withReturns={true}
                      link={null}
                      isLineChart={isLineChartUnique}
                      setIsLineChart={setIsLineChartUnique}
                      className={'st-inner-chart'}
          >
            <Spin spinning={storeInnerPage?.full?.sales_and_revenue?.loading}>
              <Chart data={
                storeInnerPage?.full?.sales_and_revenue?.charts?.unique ?
                  [...storeInnerPage?.full?.sales_and_revenue?.charts?.unique]
                  :
                  []
                  || []
              }
                     height={220}
                     type={'unique_products_sold'}
                     animation={false}
                     isMobile={isMobile}
                     withRawData={showRawData}
                     withReturns={true}
                     returnsData={storeInnerPage?.full?.sales_and_revenue?.charts?.returns_unique?.length ?
                       [...storeInnerPage?.full?.sales_and_revenue?.charts?.returns_unique]
                       :
                       []}
                     rawReturnsData={[]}
                     rawData={[]}
                     chartType={!isLineChartUnique && 'bar'}
              />
            </Spin>
          </ChartBlock>
        </div>
      </div>
      {
        storeInnerPage?.full?.products?.results?.length ?
          <div className="product-database-table-body store-tracker-page-sales-table-wrapper"
               id={'product-database-table-body'}
          >
            <MemoTable components={{
              // eslint-disable-next-line react/prop-types
              table: ({ className, children, ...other }) => (
                <table  {...other}
                        ref={tableRef}
                        className={cls(className, 'other-wrapper')}
                >
                  {children}
                </table>
              ),
            }}
                       className="list-table"
                       loading={storeInnerPage?.full?.products?.loading || storeInnerPage?.full?.products?.trackingLoading}
                       rowClassName={'product-database-table-row'}
                       rowKey={(record) => record.id}
                       dataSource={
                         storeInnerPage?.full?.products?.results?.length ?
                           [...storeInnerPage?.full?.products?.results]
                           :
                           []
                       }
                       sortOrder={sortOrder}
                       columns={getColumns({
                         sortOrder,
                         handleSetRecordToCalc: setRecordToCalc,
                         setModal,
                         handleOpenModalShopifyConnect,
                         handleOpenModalAutoDSConnect,
                         setCompetitor: handleSetCompetitor,
                         toggleConnectProduct,
                         t,
                         isMobile,
                         view: 'details',
                         id,
                       })
                       }
                       pagination={false}
                       locale={{ emptyText: '-' }}
                       scroll={{
                         x: '100dvh', //'max-content',
                         y: 'calc(100dvh - 45px)',
                       }}
                       sticky={{
                         offsetHeader: 0,
                         offsetScroll: 0,
                         getContainer: () => document.querySelector('.product-database-table-body'),
                       }}
                       sortDirections={['descend', 'ascend', null]}
                       onChange={(pagination, filters, sorter) => {
                         handleSort(sorter);
                       }}
            />

          </div>
          :
          <StoreTrackerPageOverviewEmpty type={'products'} />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  getStoreProducts: (data) => dispatch(Creators.getStoreProductsRequest(data)),
  getStoreSalesAndRevenue: (data) => dispatch(Creators.getStoreSalesAndRevenueRequest(data)),
  setCompetitor: (data) => dispatch(ProductDatabaseCreators.setCompetitorResearch(data)),
  unTrackProduct: (data) => dispatch(Creators.unTrackProductStoreInnerPageRequest(data)),
  trackProduct: (data) => dispatch(Creators.trackProductStoreInnerPageRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageSales);
