import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from "../../../Components/Image";
import Images from "../../../Images";
import IconWithText from "../../../Components/Text";
import {Dropdown} from "antd";

//product database columns
export const getColumns = (
  {
    setRecordToDelete,
    setVisible,
    setModal,
    setFlag,
    t,
  }) => (
  [
    {
      title: 'Store',
      dataIndex: 'title',
      key: 'name',
      width: 372,
      fixed: 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <a href={`https://${record?.original_domain}`}
             target={'_blank'}
             rel="noopener noreferrer"
          >
            <div className={'product-database-table-image sales-store-table-image'}
            >
              {record?.logo || record?.favicon ?
                <img src={record?.logo || record?.favicon}
                     alt="" onError={utils.addDefaultSrc}
                     width="62" height="62"
                />
                :
                <Image src={Images.defaultImg} small={true}/>
              }
            </div>
            <div className={'product-info-wrapper'}>
              <p className={'product-name'}>{record?.title}</p>
              <p>
              <span className={'product-url'}>
                {record?.all_domains?.length > 0 ?
                  <>
                    {record?.custom_domain}
                    <Icon type={'attention_outline'}
                          role={'button'}
                          width={13}
                          height={13}
                          tooltipProps={{
                            placement: 'bottom',
                            trigger: 'hover',
                            arrow: true,
                            destroyTooltipOnHide: true,
                            overlayClassName: cls('details-tooltip', 'domains-tooltip'),
                            getPopupContainer: () =>
                              document.getElementById('product-database-table-body'),
                          }}
                          titleText={() => record?.all_domains?.map(el => (
                              <p key={el}>
                                {el}
                              </p>
                            ),
                          )}
                    />
                  </>
                  :
                  record?.custom_domain
                }
              </span>
              </p>
            </div>
          </a>
        </div>
      ),
    },
    {
      title: 'Is in PD',
      dataIndex: 'in_product_db',
      key: 'in_product_db',
      width: 207,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-store-info">
            {record?.in_product_db ? '+' : '-'}
          </div>
        </div>
      ),
    },
    {
      title: 'Users Tracking',
      dataIndex: 'tracked_by_users',
      key: 'tracked_by_users',
      width: 207,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-store-info">
            {record?.tracked_by_users}
          </div>
        </div>
      ),
    },
    {
      title: 'Store ID',
      dataIndex: 'id',
      key: 'id',
      width: 207,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-store-info">
            {record?.id}
          </div>
        </div>
      ),
    },
    {
      title: 'Shopify ID',
      dataIndex: 'shopify_shop_id',
      key: 'shopify_shop_id',
      width: 207,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-store-info">
            {record?.shopify_shop_id}
          </div>
        </div>
      ),
    },
    {
      title: 'Store Info',
      dataIndex: 'products_count',
      key: 'products_count',
      width: 216,
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="product-store-info">
            <p>{acc.format(record?.products_count, 0, ',', '.')} {t('Products')}</p>
            <p style={{textTransform: 'capitalize'}}>{record?.language}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Store creation date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 216,
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
        >
          <div className="store-creation-date">
            {
              record?.created_at ? dayjs(record?.created_at).format('MMM DD, YYYY') : 'No Data'
            }
          </div>
        </div>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 48,
      sorter: false,
      fixed: 'right',
      render: (text, record) => (
        <div className={'product-database-table-cell sales-tracker-store-table-cell cell-actions'}>
          <div className="product-actions product-actions-admin">

            <Dropdown
              dropdownRender={() => (
                <ul className={'filter-preset-action-dropdown database-action-dropdown'} style={{width: 'unset'}}>
                  <li className="action-dropdown_item tracked"
                      onClick={() => {
                        setRecordToDelete({type: 'store', data: {...record}})
                        setVisible(true);
                        setFlag('only_PD')
                        setModal('deleteTracking');
                      }}>
                    <IconWithText
                      size={24}
                      icon={() => <Icon type="remove_preset" role="icon" color={'#707ba0'}/> }
                      text={'Delete from PD'}
                    />
                  </li>
                  <li className="action-dropdown_item tracked"
                      onClick={() => {
                        setRecordToDelete({type: 'store', data: {...record}})
                        setVisible(true);
                        setFlag('all')
                        setModal('deleteTracking');
                      }}>
                    <IconWithText
                      size={24}
                      icon={() => <Icon type="remove_preset" role="icon" color={'#707ba0'}/> }
                      text={'Delete Everywhere'}
                    />
                  </li>
                </ul>
              )}
              destroyPopupOnHide={true}
              placement="bottomRight"
              getPopupContainer={() => document.getElementById('product-database-table-body')}
              overlayClassName={'filter-preset-cell-menu-wrapper'}
            >
                <span>
                  <Icon type="three_dots" role="button" width={24} height={24}/>
                </span>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ]
);
