import React from 'react';
import { Spin, Tooltip } from 'antd';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import ButtonComponent from '../Button';

const ShopifyStoresModal = (
  {
    isMobile,
    visible,
    setModal,
    shopifyStore,
    handleDisconnect,
    isCheckLimit,
    handleCheckLimit,
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={t('Shopify stores')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal language-modal shopify-stores-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Manage your Shopify stores.')
            }
          </p>
          <ul className="user_store-list">
            {
              shopifyStore?.results?.length ?
                shopifyStore?.results?.map(({ id, name, domain }) => (
                  <li key={id} className="user_store-item">
                    <Icon type={'shopify_connect_new_have'}
                          role={'icon'}
                    />
                    <span className="name">{name}</span>
                    {isCheckLimit ? (
                      <Tooltip 
                        overlayClassName="product_name-tooltip details-tooltip"
                        title={domain}
                        trigger={isMobile ? 'click' : 'hover'}
                        destroyTooltipOnHide={true}
                        getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                      >
                        <span className="domain">{domain}</span>
                      </Tooltip>
                    ) : (
                      <span className="domain">{domain}</span>
                    )}
                    <ButtonComponent className="btn-cancel"
                                   onClick={() => handleDisconnect({ id, name, domain })}
                    >
                      {t('Disconnect')}
                    </ButtonComponent>
                    {isCheckLimit && (
                      <ButtonComponent className="btn-check-limit"
                                      onClick={() => {
                                        setModal(false);
                                        handleCheckLimit(domain);
                                      }}
                      >
                        {t('Check limits')}
                      </ButtonComponent>
                    )}
                  </li>
                ))
                :
                null
            }
          </ul>
        </div>
      </Spin>
    </Modal>
);
};

export default ShopifyStoresModal;
