import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = [
  '#661AC6',
  '#225AEA',
  '#DF7009',
  '#D71313',
  '#F6B60E',
  '#16895A',
];

const CustomTooltip = ({ active, payload, theme }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          background: theme === 'light' ? '#030625' : '#1D1D1D',
          padding: '12px',
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: 500,
          letterSpacing: '-0.3px',
          color: '#F2F3F8',
          border: theme === 'light' ? 'unset' : '1px solid rgba(255, 255, 255, 0.12)',
        }}
      >
        <div style={{ marginBottom: '8px', color: '#C5CCE3' }}>{data.name}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 8 }}>Reach</span>
          <span style={{ textAlign: 'right' }}>{data.reach.toLocaleString()}</span>
          <span style={{ textAlign: 'right', color: '#C5CCE3' }}>({data.reach_percentage}%)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 8 }}>Spend</span>
          <span style={{ textAlign: 'right' }}>${data.spend.toLocaleString()}</span>
          <span style={{ textAlign: 'right', color: '#C5CCE3' }}>({data.spend_percentage}%)</span>
        </div>
      </div>
    );
  }
  return null;
};

const renderCustomLabel =
  (theme) =>
  ({ cx, cy, midAngle, outerRadius, name }) => {
    const RADIAN = Math.PI / 180;
    const x = cx + (outerRadius + 16) * Math.cos(-midAngle * RADIAN);
    const y = cy + (outerRadius + 16) * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme === 'light' ? '#151E3A' : '#F2F3F8'}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={12}
        fontWeight={500}
        letterSpacing="-0.1px"
      >
        {name}
      </text>
    );
  };

const DoughnutChart = ({ data, chartName,loading }) => {
  const adjustedData = data.map((item) => ({
    ...item,
    adjusted_reach_percentage: item.reach_percentage === 0 ? 0.1 : item.reach_percentage,
    adjusted_spend_percentage: item.spend_percentage === 0 ? 0.1 : item.spend_percentage,
  }));

  const theme = useSelector((state) => state?.nav?.theme);
  if (data.length === 0) {
    return (
      <ResponsiveContainer width='100%' height={230}>
        <PieChart>
          <Pie
            data={[{ name: 'No Data', value: 100 }]}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={65}
            outerRadius={90}
            cornerRadius={4}
            stroke="none"
            fill="#D1D3D8"
          >
            <Cell fill="#D1D3D8" />
          </Pie>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={14}
            fontWeight={500}
            letterSpacing="-0.3px"
            fill={theme === 'light' ? '#151E3A' : '#F2F3F8'}
          >
            {loading ? 'Data pending' : 'No Data'}
          </text>
        </PieChart>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width='100%' height={230}>
      <PieChart>
        <Pie
          data={adjustedData}
          dataKey="adjusted_reach_percentage"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={65}
          outerRadius={90}
          cornerRadius={6}
          label={renderCustomLabel(theme)}
          labelLine={false}
          stroke={theme === 'light' ? 'white' : '#1D1D1D'}
          strokeWidth={adjustedData?.length <= 1 ? 0 : 3}
          minAngle={5}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip theme={theme} />} cursor="pointer" />

        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={14}
          fontWeight={500}
          letterSpacing="-0.3px"
          fill={theme === 'light' ? '#151E3A' : '#F2F3F8'}
        >
          {chartName}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutChart;
