import React, { useState } from 'react';
import cls from 'classname';
import acc from 'accounting';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import Image from "../../../Components/Image";
import {columnTitles} from "../../../Components/titleSortFilters";
import Images from "../../../Images";
import TooltipColumnIcon from "../../ProductDatabasePage/components/TooltipColumnIcon";
import UserTrackingsActionsModal from './UserTrackingsActionsModal';
import { Divider, Dropdown } from 'antd';
import IconWithText from '../../../Components/Text';

export const getColumns = (
  {
    sortOrder,
    setRecordToDelete,
    setModal,
    t,
    isMobile = false,
    isTrial,
    pinHandler
  }) => (
  [
    {
      title: () => 'Product',
      dataIndex: 'title',
      key: 'name',
      width: 500,
      fixed: isMobile ? false : 'left',
      sorter: false,
      render: (text, record) => (
        <div className={'product-database-table-cell'}>
          <span onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            pinHandler({view: 'products', record: record});
          }}
                style={{ cursor: 'pointer', marginRight: 16 }}
          >
              <Icon type={'pin_icon'} role={'icon'} active={record?.pin_time} />
            </span>
          <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`} style={{ flex: 'unset' }}>
            <div className={'product-database-table-image'}>
              {
                record?.main_image
                  ? <img src={utils.changeImageSize(record?.main_image, 124)}
                         alt=""
                         onError={utils.addDefaultSrc}
                         width="62" height="62"
                  />
                  : <Image src={Images.defaultImg} small={true} />
              }
            </div>
          </a>
          <div className={'product-info-wrapper'}>
            <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`} style={{ width: '100%' }}>
              <p className={'product-name'}>
                {record?.title}
                {record?.is_demo &&
                  <span className="product-name-tag">
                  {t('example')}
                </span>}
              </p>
            </a>
            <div className={'product-info'}>
              <a href={`https://${record?.store?.custom_domain}/products/${record?.handle}`}
                 target={'_blank'}
                 rel="noopener noreferrer"
              >
                  <span className={'product-url'}>
                    {
                      record?.store?.all_domains?.length > 0 && !isMobile ?
                        <>
                          {record?.store?.custom_domain}
                          <TooltipColumnIcon record={record} />
                        </>
                        :
                        record?.store?.custom_domain
                    }
                  </span>
              </a>
              <Divider style={{ margin: '0 8px' }} type={'vertical'} />
              <span
                className={'product-variants'}>{record?.variants} {record?.variants === 1 ? 'Variant' : 'Variants'}</span>
              <Divider style={{ margin: '0 8px' }} type={'vertical'} />
              <span className={'product-variants'}>{record?.images} {record?.images === 1 ? 'Image' : 'Images'}</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Price';
        return columnTitles({
          sortOrder,
          title: result,
          key: 'usd_price',
        });
      },
      dataIndex: 'usd_price',
      key: 'usd_price',
      width: 200,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['usd_price'] || 0) - (b['usd_price'] || 0),
      render: (text, record) => (
        <div className={'product-database-table-cell product-database-table-cell_clickable'}
             onClick={() => {
               setRecordToDelete(record);
               setModal('numbers_breakdown');
             }}
        >
          <div className="product-price">
            <p>{`$${acc.format(record.usd_price, 2, ',', '.')}`} {record?.price_max &&
              <span>- ${acc.format(record?.price_max, 2, ',', '.')}</span>}</p>
            {record?.store?.currency !== 'USD' &&
              <p style={{marginTop: 8, fontSize: 12, lineHeight: '16px', color: '#707ba0'}}>
                {(record?.original_price > 0 || record?.original_price_max > 0) && acc.format(record?.original_price, 2, ',', '.')} {record?.original_price_max > 0 &&
                <span>- {acc.format(record?.original_price_max, 2, ',', '.')}</span>} {(record?.original_price > 0 || record?.original_price_max > 0) && record?.store?.currency}
              </p>
            }
          </div>
        </div>
      ),
    },
    {
      title: () => {
        let result = 'Sales today'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'day_revenue',
        })
      },
      dataIndex: 'day_revenue',
      key: 'day_revenue',
      width: 150,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['day_revenue'] || 0) - (b['day_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
          <div className="product-database-table-cell product-database-table-cell_clickable">
            <div className="product-store-info">
              {
                record?.day_revenue ?
                  <p>${acc.format(record?.day_revenue, 2, ',', '.')}</p>
                  :
                  <p>-</p>
              }
              {
                record?.day_sales ?
                  <p>{acc.format(record?.day_sales, 0, ',', '.')} {record?.day_sales === 1 ? t('Sale') : t('Sales')}</p>
                  :
                  <p>-</p>
              }
            </div>
          </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales yesterday'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'yesterday_revenue',
        })
      },
      dataIndex: 'yesterday_revenue',
      key: 'yesterday_revenue',
      width: 180,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['yesterday_revenue'] || 0) - (b['yesterday_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.yesterday_revenue ?
                <p>${acc.format(record?.yesterday_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.yesterday_sales ?
                <p>{acc.format(record?.yesterday_sales, 0, ',', '.')} {record?.yesterday_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales 7 days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'week_revenue',
        })
      },
      dataIndex: 'week_revenue',
      key: 'week_revenue',
      width: 180,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['week_revenue'] || 0) - (b['week_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.week_revenue ?
                <p>${acc.format(record?.week_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.week_sales ?
                <p>{acc.format(record?.week_sales, 0, ',', '.')} {record?.week_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Sales 30 days'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'month_revenue',
        })
      },
      dataIndex: 'month_revenue',
      key: 'month_revenue',
      width: 180,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a['month_revenue'] || 0) - (b['month_revenue'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            {
              record?.month_revenue ?
                <p>${acc.format(record?.month_revenue, 2, ',', '.')}</p>
                :
                <p>-</p>
            }
            {
              record?.month_sales ?
                <p>{acc.format(record?.month_sales, 0, ',', '.')} {record?.month_sales === 1 ? t('Sale') : t('Sales')}</p>
                :
                <p>-</p>
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Store info'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'store_products_count',
        })
      },
      dataIndex: 'store_products_count',
      key: 'store_products_count',
      width: 200,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : (a?.store['products_count'] || 0) - (b?.store['products_count'] || 0),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="product-store-info">
            <p>{
              record?.store?.products_count
                ?
                `${acc.format(record?.store?.products_count, 0, ',', '.')} ${t('Products')}`
                :
                '-'
            }</p>
            <p style={{textTransform: 'capitalize'}}>{record?.store?.language}</p>
          </div>
        </div>
        </a>
      ),
    },
    {
      title: () => {
        let result = 'Product creation date'
        return columnTitles({
          sortOrder,
          title: result,
          key: 'created_at',
        })
      },
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      sorter: (a, b) => a?.pin_time || b?.pin_time ? 0 : dayjs(a['created_at']).valueOf() - dayjs(b['created_at']).valueOf(),
      render: (text, record) => (
        <a href={`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`}>
        <div className="product-database-table-cell product-database-table-cell_clickable">
          <div className="store-creation-date">
            {
              record?.created_at ? dayjs(record?.created_at).format('MMM DD, YYYY') : '-'
            }
          </div>
        </div>
        </a>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 40,
      sorter: false,
      fixed: 'right',
      render: (text, record) => (
        <div className={'product-database-table-cell sales-tracker-store-table-cell cell-actions'}>
          <div className="product-actions">
            <span>
              <DropdownRender record={record}
                              t={t}
                              isMobile={isMobile}
                              isTrial={isTrial}
                              setModal={setModal}
                              setRecordToDelete={setRecordToDelete}
              />
            </span>
          </div>
        </div>
      ),
    },
  ]
);

export const DropdownRender = (
  {
    record,
    t,
    isMobile,
    isTrial,
    setModal,
    setRecordToDelete
  }) => {

  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [innerModal, setInnerModal] = useState(false);

  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return (
    isMobile ?
      <>
        <span className={'product-actions-options'}
              onClick={() => setInnerModal(true)}
        >
          <Icon type="three_dots" role="button" />
        </span>
        <UserTrackingsActionsModal setInnerModal={setInnerModal}
                                   view={'Product'}
                                   visible={innerModal}
                                   record={record}
                                   setRecordToDelete={setRecordToDelete}
                                   setModal={setModal}
                                   isTrial={isTrial}
        />
      </>
      :
      <Dropdown open={visibleDropdown}
                onOpenChange={handleInnerVisibleChange}
                dropdownRender={() => (
                  <ul className={'filter-preset-action-dropdown database-action-dropdown'}>
                    <li className={'action-dropdown_item active'}
                        onClick={() => {
                          setVisibleDropdown(false);
                          window.open(`/sales-tracker/products/product/${record?.internal_shop_id}_${record?.product_id}`, '_self');
                        }}
                    >
                      <IconWithText size={24}
                                    icon={() => <Icon type={'product_insights'} role={'icon'} />}
                                    text={t(`Product insights`)}
                      />
                    </li>
                    <li className={'action-dropdown_item active tracked'}
                        onClick={() => {
                          setVisibleDropdown(false);
                          if (isTrial) {
                            setModal('can_not_remove_tracking');
                          } else {
                            setRecordToDelete({type: 'product', data: {...record}})
                            setModal('delete_tracking');
                          }
                        }}
                    >
                      <IconWithText size={24}
                                    icon={() =>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                           fill="none">
                                        <path d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                                              stroke="#D71313" strokeWidth="1.5" strokeLinecap="round" />
                                      </svg>
                                    }
                                    text={t(`Stop tracking product`)}
                      />
                    </li>
                  </ul>
                )}
                destroyPopupOnHide={true}
                trigger={['click']}
                placement="bottomRight"
                getPopupContainer={() => document.getElementById('product-database-table-body') || document.getElementById('product-database-table-cards')}
                overlayClassName={'filter-preset-cell-menu-wrapper'}
      >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" />
      </span>
      </Dropdown>
  );
};
