import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Creators from '../reducer';
import { useTranslation } from 'react-i18next';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import { Spin } from 'antd';
import AppCard from './AppCard';

const StoreTrackerPageApps = (
  {
    storeInnerPage,
    getApps,
    id,
  }
) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);

  const [pageNumber, setPageNumber] = useState(1);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && storeInnerPage?.full?.apps?.results?.length < storeInnerPage?.full?.apps?.count) {
      setPageNumber(prev => prev + 1);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    getApps({ internal_shop_id: id, page: pageNumber, page_size: 20 });
  }, [pageNumber]);

  useEffect(() => {
    const tableContent = tableRef.current || document.getElementById('product-database-table-body');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [tableRef.current, storeInnerPage?.full?.apps?.count, storeInnerPage?.full?.apps?.results?.length]);

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-competitors">
      <div className="report-main-block">
        <span className={'report-main-block-title'}>
          <span className="title">
            {t('Store apps')}
          </span>
        </span>
      </div>
      <Spin spinning={storeInnerPage?.full?.apps?.loading}>
        {
          storeInnerPage?.full?.apps?.results?.length ?
            <div className="product-database-table-body store-tracker-page-sales-table-wrapper cards"
                 id={'product-database-table-body'}
                 ref={tableRef}
                 style={{marginTop: 0}}
            >
              {
                storeInnerPage?.full?.apps?.results?.map((el) => (
                  <AppCard data={el}
                           key={el?.name}
                  />
                ))
              }
            </div>
            :
            <StoreTrackerPageOverviewEmpty tags={[]}
                                           type={'apps'}
                                           clearFilters={null}
            />
        }
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  storeInnerPage: state.salesTracker.storeInnerPage,
});

const mapDispatchToProps = (dispatch) => ({
  getApps: (data) => dispatch(Creators.getStoreAppsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreTrackerPageApps);
